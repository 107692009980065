import React, { memo, useState, useEffect, useRef, useCallback } from 'react';
import { Search, HandCoins, ShoppingCart, Plus, Minus, X, Barcode, User, Clock, ArrowLeft, Grid, List, Lock, Unlock, User2, Package, Loader2, Receipt, ReceiptText, HandCoinsIcon, CreditCard, Smartphone } from 'lucide-react';
import {
    query,
    collection,
    orderBy,
    getDocs,
    getDoc,
    doc,
    getFirestore,
    limit,
    serverTimestamp,
    updateDoc,
    setDoc,
    writeBatch,
    increment,
    arrayUnion,
    Timestamp
} from 'firebase/firestore';
import { getAuth } from 'firebase/auth';
import { Link } from 'react-router-dom';
import CustomerSection from '../components/customer/customer_section_pos';
import AuthorizationModal from '../../auth/AuthorizationModal';
import { dbService } from '../services/db-service';
import { useAuth } from '../../auth/AuthContext';
import cashDrawerService from '../services/cash-drawer-service';
import DraftManager from '../components/sales_drafts';
import CartItems from '../components/sales/sale_item_edit';
import RecentReceiptsModal from '../components/sales/sales_receipts_modal';

// Constants
const ITEMS_PER_PAGE = 20;
const NOTIFICATION_DURATION = 5000;

const AddSale = () => {
    const { hasProAccess } = useAuth();
    // Core state
    const [saleItems, setSaleItems] = useState([]);
    const [allInventoryItems, setAllInventoryItems] = useState([]);
    const [displayedItems, setDisplayedItems] = useState([]);
    const [searchQuery, setSearchQuery] = useState('');
    const [totalCost, setTotalCost] = useState(0);
    const [isLoading, setIsLoading] = useState(true);
    const [isLoadingMore, setIsLoadingMore] = useState(false);
    const [hasMore, setHasMore] = useState(true);
    const [isScannerEnabled, setIsScannerEnabled] = useState(true);
    const [scanBuffer, setScanBuffer] = useState('');
    const [isTestMode, setIsTestMode] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const [viewMode, setViewMode] = useState('list');
    const [isLocked, setIsLocked] = useState(false);
    const [showExitPrompt, setShowExitPrompt] = useState(false);
    const [showAuthModal, setShowAuthModal] = useState(false);
    const [drafts, setDrafts] = useState([]);

    const [allItems, setAllItems] = useState([]);

    // Customer and payment state
    const [customer, setCustomer] = useState(null);
    const [paymentMethod, setPaymentMethod] = useState('cash');
    const [amountReceived, setAmountReceived] = useState('');
    const [change, setChange] = useState(0);

    // UI state
    const [isProcessing, setIsProcessing] = useState(false);
    const [showPaymentModal, setShowPaymentModal] = useState(false);

    // Filter state
    const [categories] = useState(['All']);
    const [selectedCategory, setSelectedCategory] = useState('All');

    // Notifications state
    const [notifications, setNotifications] = useState([]);
    const notificationIdCounter = useRef(0);
    const scanTimeout = useRef(null);
    const amountReceivedInputRef = useRef(null);

    const [storeId, setStoreId] = useState(null);
    const [userId, setUserId] = useState(null);
    const [isInitializing, setIsInitializing] = useState(true);

    const [isCreditSale, setIsCreditSale] = useState(false);
    const [momoPhone, setMomoPhone] = useState('');

    const [isSyncing, setIsSyncing] = useState(false);
    const [hasPendingSales, setHasPendingSales] = useState(false);
    const [checkedPendingSales, setCheckedPendingSales] = useState(false);
    const [showReceiptsModal, setShowReceiptsModal] = useState(false);

    const [isOnline, setIsOnline] = useState(navigator.onLine);

    // Firebase setup
    const db = getFirestore();
    const auth = getAuth();

    // Modified intersection observer setup
    const observer = useRef();
    const lastItemRef = useCallback(node => {
        if (isLoadingMore || !hasMore) return;
        if (observer.current) observer.current.disconnect();

        observer.current = new IntersectionObserver(entries => {
            if (entries[0].isIntersecting && hasMore) {
                setIsLoadingMore(true);
                setCurrentPage(prev => prev + 1);
            }
        });

        if (node) observer.current.observe(node);
    }, [isLoadingMore, hasMore]);

    // Notifications
    const addNotification = (message, type = 'error', duration = NOTIFICATION_DURATION) => {
        const id = notificationIdCounter.current++;
        setNotifications(prev => {
            // Remove existing notification of the same type if it exists
            const filtered = prev.filter(n => n.type !== type);
            return [...filtered, { id, message, type }];
        });

        if (duration > 0) {
            setTimeout(() => {
                setNotifications(prev => prev.filter(n => n.id !== id));
            }, duration);
        }
    };

    const NotificationsDisplay = () => (
        <div className="fixed top-4 left-1/2 -translate-x-1/2 z-50 w-full max-w-md flex flex-col items-center gap-2">
            {notifications.map(({ id, message, type }) => (
                <div
                    key={id}
                    className={`px-4 py-3 rounded-lg shadow-lg w-full text-center ${type === 'connection'
                        ? 'bg-yellow-100 border border-yellow-400 text-yellow-700'
                        : type === 'error'
                            ? 'bg-red-100 border border-red-400 text-red-700'
                            : 'bg-green-100 border border-green-400 text-green-700'
                        }`}
                >
                    {message}
                </div>
            ))}
        </div>
    );

    const showError = (message) => addNotification(message, 'error');
    const showSuccess = (message) => addNotification(message, 'success');
    const [imageLoadStatus, setImageLoadStatus] = useState({});

    // Function to handle image loading errors
    const handleImageError = (itemId) => {
        setImageLoadStatus(prev => ({
            ...prev,
            [itemId]: 'error'
        }));
    };

    // Function to handle successful image loads
    const handleImageLoad = (itemId) => {
        setImageLoadStatus(prev => ({
            ...prev,
            [itemId]: 'loaded'
        }));
    };

    // Check internet connection
    useEffect(() => {
        const handleOnline = () => {
            setIsOnline(true);
            // Remove the offline notification if it exists
            setNotifications(prev => prev.filter(n => n.type !== 'connection'));
        };

        const handleOffline = () => {
            setIsOnline(false);
            // Add offline notification
            addNotification('No Internet Connection', 'connection', -1); // -1 means no auto-dismiss
        };

        window.addEventListener('online', handleOnline);
        window.addEventListener('offline', handleOffline);

        // Check initial status
        if (!navigator.onLine) {
            handleOffline();
        }

        return () => {
            window.removeEventListener('online', handleOnline);
            window.removeEventListener('offline', handleOffline);
        };
    }, []);

    // Sync pending sales
    const handleSync = useCallback(async () => {
        // Function to check for pending sales
        const checkPendingSales = async () => {
            try {
                const pendingSales = await dbService.getPendingSales();
                setHasPendingSales(pendingSales.length > 0);
                setCheckedPendingSales(true);
                return pendingSales;
            } catch (error) {
                console.error('Error checking pending sales:', error);
                return [];
            }
        };

        if (isSyncing || !storeId) {
            console.log('Sync already in progress or store ID not available');
            return;
        }

        // Internal function to get next order number
        const getNextOrderNumber = async (storeId, isCredit) => {
            const collectionPath = isCredit ? 'CreditSales' : 'Sales';
            const salesRef = collection(db, 'Stores', storeId, collectionPath);
            const q = query(salesRef, orderBy('createdAt', 'desc'), limit(1));

            try {
                const querySnapshot = await getDocs(q);

                if (querySnapshot.empty) {
                    return isCredit ? 'CR-ORD-0001' : 'ORD-0001';
                }

                const lastDoc = querySnapshot.docs[0];
                const lastOrderNumber = lastDoc.data().orderNumber;
                const lastNumber = parseInt(lastOrderNumber.split('-').pop());
                const newNumber = lastNumber + 1;

                return isCredit ?
                    `CR-ORD-${newNumber.toString().padStart(4, '0')}` :
                    `ORD-${newNumber.toString().padStart(4, '0')}`;
            } catch (error) {
                console.error('Error getting next order number:', error);
                throw error;
            }
        };

        setIsSyncing(true);
        try {
            const pendingSales = await checkPendingSales();

            if (!pendingSales.length) {
                console.log('No pending sales to sync');
                setHasPendingSales(false);
                return;
            }

            // Show initial sync notification
            addNotification(
                `Starting to sync ${pendingSales.length} offline sale${pendingSales.length > 1 ? 's' : ''}...`,
                'connection',
                -1
            );

            console.log(`Starting sync of ${pendingSales.length} pending sales`);
            let successCount = 0;
            let failCount = 0;

            // Process sales sequentially to maintain order number integrity
            for (let i = 0; i < pendingSales.length; i++) {
                const sale = pendingSales[i];
                try {
                    // Update progress notification
                    const progressMessage = `Syncing sales (${i + 1}/${pendingSales.length})...`;
                    addNotification(progressMessage, 'connection', -1);

                    const newOrderNumber = await getNextOrderNumber(storeId, sale.isCreditSale);

                    const collectionPath = sale.isCreditSale ? 'CreditSales' : 'Sales';
                    const saleRef = doc(collection(db, 'Stores', storeId, collectionPath));

                    await setDoc(saleRef, {
                        ...sale,
                        orderNumber: newOrderNumber,
                        createdAt: serverTimestamp(),
                        syncedFromOffline: true,
                        originalOrderNumber: sale.orderNumber,
                        saleDate: serverTimestamp(),
                        originalSaleDate: sale.saleDate,
                        syncStatus: 'completed',
                        synced: true,
                        syncedAt: serverTimestamp()
                    });

                    await Promise.allSettled([
                        updateInventory(storeId, sale.items),
                        sale.customerId && updateCustomerRecord(
                            storeId,
                            sale.customerId,
                            saleRef.id,
                            newOrderNumber
                        )
                    ]);

                    await dbService.markSaleAsSynced(sale.offlineId);

                    successCount++;
                    console.log(`Synced sale ${sale.orderNumber} as ${newOrderNumber}`);
                } catch (error) {
                    failCount++;
                    console.error(`Failed to sync sale ${sale.orderNumber}:`, error);
                }
            }

            // Remove the progress notification
            setNotifications(prev => prev.filter(n => !n.message.includes('Syncing sales')));

            // Show final status
            if (failCount === 0) {
                showSuccess(`Successfully synced ${successCount} offline sale${successCount > 1 ? 's' : ''}`);

            } else {
                showError(
                    `Sync completed with ${successCount} successful and ${failCount} failed sale${failCount > 1 ? 's' : ''}`
                );
            }

            // Final check for remaining pending sales
            await checkPendingSales();

        } catch (error) {
            console.error('Error during sync:', error);
            showError('Failed to sync offline sales');
            setNotifications(prev => prev.filter(n => !n.message.includes('Syncing sales')));
        } finally {
            setIsSyncing(false);
        }
    }, [storeId, isSyncing, addNotification, showSuccess, showError, setNotifications]);

    // Periodic check effect
    useEffect(() => {
        let interval;

        // Set up periodic checks
        interval = setInterval(() => {
            if (!isSyncing && navigator.onLine) {
                handleSync();
            }
        }, 1200000); // Check every 30 seconds

        return () => {
            if (interval) clearInterval(interval);
        };
    }, [handleSync, isSyncing]);

    // Online/offline handlers
    const handleOnline = useCallback(() => {
        setIsOnline(true);
        if (!isSyncing) {
            handleSync();
        }
        setNotifications(prev => prev.filter(n => n.type !== 'connection'));
    }, [isSyncing, handleSync, setNotifications]);

    const handleOffline = useCallback(() => {
        setIsOnline(false);
        addNotification('No Internet Connection', 'connection', -1);
    }, [addNotification]);

    // Main effect for online/offline handling
    useEffect(() => {
        window.addEventListener('online', handleOnline);
        window.addEventListener('offline', handleOffline);

        // Initial check on mount
        if (navigator.onLine) {
            if (checkedPendingSales === false) {
                handleSync();
            }
        } else {
            handleOffline();
        }

        return () => {
            window.removeEventListener('online', handleOnline);
            window.removeEventListener('offline', handleOffline);
        };
    }, [handleOnline, handleOffline, handleSync]);

    const HeaderSyncIndicator = hasPendingSales && (
        <div className="ml-2 text-sm bg-yellow-100 text-yellow-800 px-2 py-1 rounded-full flex items-center">
            {isSyncing ? (
                <>
                    <Loader2 className="w-3 h-3 mr-1 animate-spin" />
                    Syncing...
                </>
            ) : (
                <>
                    <Clock className="w-3 h-3 mr-1" />
                    Pending Sync
                </>
            )}
        </div>
    );

    // Load user and store data on component mount
    useEffect(() => {
        const initializeData = async () => {
            try {
                const currentUser = auth.currentUser;
                if (!currentUser) throw new Error('No authenticated user found');

                const userDocRef = doc(db, 'Users', currentUser.uid);
                const userDoc = await getDoc(userDocRef);

                if (!userDoc.exists()) throw new Error('User document not found');

                const userStoreId = userDoc.data().storeId;
                if (!userStoreId) throw new Error('No store ID found for user');

                setUserId(currentUser.uid);
                setStoreId(userStoreId);
            } catch (error) {
                console.error('Initialization error:', error);
                showError(error.message);
                // Could redirect to login or show error state
            } finally {
                setIsInitializing(false);
            }
        };

        initializeData();
    }, []);

    useEffect(() => {
        if (!allInventoryItems.length) return;

        const filterItems = () => {
            let filtered = [...allInventoryItems];

            if (searchQuery) {
                const searchLower = searchQuery.toLowerCase().trim();
                filtered = filtered.filter(item =>
                    (item.itemName?.toLowerCase() || '').includes(searchLower) ||
                    (item.sku?.toLowerCase() || '').includes(searchLower) ||
                    (item.barcode?.toLowerCase() || '').includes(searchLower)
                );
            }

            if (selectedCategory !== 'All') {
                filtered = filtered.filter(item =>
                    (item.category || 'Uncategorized') === selectedCategory
                );
            }

            const startIndex = 0;
            const endIndex = currentPage * ITEMS_PER_PAGE;
            return filtered.slice(startIndex, endIndex);
        };

        const newDisplayedItems = filterItems();
        if (JSON.stringify(newDisplayedItems) !== JSON.stringify(displayedItems)) {
            setDisplayedItems(newDisplayedItems);
            setHasMore(newDisplayedItems.length < allInventoryItems.length);
        }
        setIsLoadingMore(false);
    }, [searchQuery, selectedCategory, currentPage, allInventoryItems]);

    // Reset pagination when filters change
    useEffect(() => {
        setCurrentPage(1);
        setDisplayedItems([]);
    }, [searchQuery, selectedCategory]);

    // Load initial data
    useEffect(() => {
        const loadInventory = async () => {
            try {
                // Check cache first
                const cachedInventory = localStorage.getItem('inventoryItems');
                const cachedTimestamp = localStorage.getItem('inventoryTimestamp');

                let items = [];
                if (cachedInventory && cachedTimestamp) {
                    const isStale = Date.now() - parseInt(cachedTimestamp) > 3600000; // 1 hour
                    if (!isStale) {
                        items = JSON.parse(cachedInventory);
                        console.log('Loading from cache:', items.length, 'items');
                    }
                }

                // If no cache or stale, fetch from Firestore
                if (items.length === 0) {
                    const userId = auth.currentUser?.uid;
                    if (!userId) throw new Error('No authenticated user found');

                    const userDoc = await getDoc(doc(db, 'Users', userId));
                    if (!userDoc.exists()) throw new Error('User document not found');

                    const storeId = userDoc.data().storeId;
                    if (!storeId) throw new Error('No store ID found for user');

                    const snapshot = await getDocs(collection(db, 'Stores', storeId, 'Inventory'));
                    items = snapshot.docs.map(doc => {
                        const data = doc.data();
                        return {
                            id: doc.id,
                            ...data,
                            hasImage: !!data.imageUrl,
                            imageUrl: data.imageUrl || null
                        };
                    });

                    // Cache the fresh data
                    localStorage.setItem('inventoryItems', JSON.stringify(items));
                    localStorage.setItem('inventoryTimestamp', Date.now().toString());
                }

                setAllItems(items);
                setDisplayedItems(items);
                console.log('Items loaded:', items.length);
            } catch (error) {
                console.error('Error loading inventory:', error);
            } finally {
                setIsLoading(false);
            }
        };

        loadInventory();
    }, []);

    // Save Inventory Locally
    useEffect(() => {
        let isMounted = true;

        const syncInventoryToIndexedDB = async () => {
            if (!allItems.length) {
                console.log('No items to sync');
                return;
            }

            try {
                console.log('Starting background sync...');
                const result = await dbService.saveInventory(allItems);

                if (result.success) {
                    console.log(`Background sync completed: ${result.itemsProcessed} items saved`);
                } else {
                    console.error('Background sync failed:', result.error);
                }
            } catch (error) {
                console.error('Background sync error:', error);
            }
        };

        // Start sync after a short delay
        const syncTimeout = setTimeout(syncInventoryToIndexedDB, 2000);

        return () => {
            isMounted = false;
            clearTimeout(syncTimeout);
        };
    }, [allItems]);

    // Handle search
    useEffect(() => {
        if (!searchQuery.trim()) {
            setDisplayedItems(allItems);
            return;
        }

        const query = searchQuery.toLowerCase().trim();
        const filtered = allItems.filter(item =>
            (item.itemName?.toLowerCase() || '').includes(query) ||
            (item.sku?.toLowerCase() || '').includes(query) ||
            (item.barcode?.toLowerCase() || '').includes(query)
        );

        setDisplayedItems(filtered);
        console.log('Filtered items:', filtered.length);
    }, [searchQuery, allItems]);

    // Calculate totals whenever cart changes
    useEffect(() => {
        const total = saleItems.reduce((sum, item) => sum + (item.sellingPrice * item.quantity), 0);
        setTotalCost(total);

        if (amountReceived && paymentMethod === 'cash') {
            setChange(Math.max(0, amountReceived - total));
        }
    }, [saleItems, amountReceived]);

    useEffect(() => {
        if (showPaymentModal && amountReceivedInputRef.current) {
            amountReceivedInputRef.current.focus();
        }
    }, [showPaymentModal]);

    useEffect(() => {
        const shouldPreventNavigation = isLocked || saleItems.length > 0 || !isOnline;

        if (shouldPreventNavigation) {
            const handleBeforeUnload = (e) => {
                e.preventDefault();
                e.returnValue = '';
                return '';
            };

            const handlePopState = (e) => {
                e.preventDefault();
                setShowExitPrompt(true);
                window.history.pushState(null, '', window.location.pathname);
            };

            window.addEventListener('beforeunload', handleBeforeUnload);
            window.addEventListener('popstate', handlePopState);
            window.history.pushState(null, '', window.location.pathname);

            return () => {
                window.removeEventListener('beforeunload', handleBeforeUnload);
                window.removeEventListener('popstate', handlePopState);
            };
        }
    }, [isLocked, saleItems.length, isOnline]);

    const ItemImage = ({ item, className }) => {
        const shouldShowPlaceholder = !item.imageUrl || imageLoadStatus[item.id] === 'error';

        if (shouldShowPlaceholder) {
            return (
                <div className={`${className} bg-gray-100 flex items-center justify-center`}>
                    <Package className="text-gray-400" size={28} />
                </div>
            );
        }

        return (
            <div className={`relative ${className}`}>
                <img
                    src={item.imageUrl}
                    alt={item.itemName}
                    className="w-full h-full object-cover"
                    onError={() => handleImageError(item.id)}
                    onLoad={() => handleImageLoad(item.id)}
                />
            </div>
        );
    };

    const handleExit = () => {
        if (window.confirm('Are you sure you want to exit? Any unsaved changes will be lost.')) {
            setIsLocked(false);
            window.history.back();
        }
    };

    // Cart functions
    const addToSale = useCallback((item) => {
        setSaleItems(prevItems => {
            const existingItem = prevItems.find(i => i.id === item.id);
            if (existingItem) {
                return prevItems.map(i =>
                    i.id === item.id ? { ...i, quantity: i.quantity + 1 } : i
                );
            }
            return [...prevItems, { ...item, quantity: 1 }];
        });
    }, []);

    const updateQuantity = (itemId, newQuantity, event) => {
        if (event) {
            event.stopPropagation();
        }

        setSaleItems(prevItems => {
            return prevItems.map(item => {
                if (item.id !== itemId) return item;

                // Only validate that quantity is not negative
                const validatedQuantity = Math.max(0, newQuantity);

                if (validatedQuantity === 0) return null;

                return {
                    ...item,
                    quantity: validatedQuantity
                };
            }).filter(Boolean);
        });
    };

    const removeFromSale = (itemId, event) => {
        if (event) {
            event.stopPropagation();
        }
        setSaleItems(items => items.filter(item => item.id !== itemId));
    };

    useEffect(() => {
        // Add logging before the scanner useEffect
        console.log('Scanner enabled:', isScannerEnabled);
        console.log('Scan buffer:', scanBuffer);

        if (!isScannerEnabled) return;

        const handleKeyPress = (e) => {
            // Prevent default for Enter key when scanner is enabled
            if (e.key === 'Enter' && isScannerEnabled) {
                e.preventDefault();

                // Process the complete barcode
                if (scanBuffer) {
                    const scannedItem = allItems.find(item =>
                        item.barcode === scanBuffer ||
                        item.sku === scanBuffer
                    );

                    if (scannedItem) {
                        addToSale(scannedItem);
                        setScanBuffer('');
                    } else {
                        showError(`No item found with barcode: ${scanBuffer}`);
                    }
                    setScanBuffer('');
                }
                return;
            }

            // Add logging for each keypress
            console.log('Key pressed:', e.key);
            console.log('Current scan buffer:', scanBuffer);

            // Accumulate barcode characters
            if (isScannerEnabled) {
                setScanBuffer(prev => prev + e.key);

                // Reset scan buffer after delay
                if (scanTimeout.current) {
                    clearTimeout(scanTimeout.current);
                }

                scanTimeout.current = setTimeout(() => {
                    setScanBuffer('');
                }, 100);
            }
        };

        window.addEventListener('keypress', handleKeyPress);
        return () => {
            window.removeEventListener('keypress', handleKeyPress);
            if (scanTimeout.current) clearTimeout(scanTimeout.current);
        };
    }, [isScannerEnabled, scanBuffer, allItems, addToSale, showError]);

    useEffect(() => {
        const shouldOpenDrawer =
            showPaymentModal &&
            paymentMethod === 'cash' &&
            Number(amountReceived) >= totalCost &&
            !isProcessing;

        if (shouldOpenDrawer) {
            cashDrawerService.openDrawer().catch(error => {
                console.error('Failed to open cash drawer:', error);
                // Optionally show a notification if drawer fails to open
                // showError('Failed to open cash drawer');
            });
        }
    }, [amountReceived, showPaymentModal, paymentMethod, totalCost, isProcessing]);


    const updateCustomerRecord = async (storeId, customerId, saleId, orderNumber) => {
        try {
            const customerRef = doc(db, 'Stores', storeId, 'Customers', customerId);
            await updateDoc(customerRef, {
                totalPurchases: increment(1),
                totalSpent: increment(totalCost),
                lastPurchaseDate: serverTimestamp(),
                lastPurchaseAmount: totalCost,
                purchases: arrayUnion({
                    saleId,
                    orderNumber,
                    amount: totalCost,
                    itemCount: saleItems.reduce((sum, item) => sum + item.quantity, 0),
                    date: new Date()
                })
            });
        } catch (error) {
            console.error('Error updating customer record:', error);
        }
    };

    // Separate function for non-critical inventory updates
    const updateInventory = async (storeId, items) => {
        try {
            // First get all current quantities
            const inventorySnapshots = await Promise.all(
                items.map(item =>
                    getDoc(doc(db, 'Stores', storeId, 'Inventory', item.inventoryItemId))
                )
            );

            const batch = writeBatch(db);

            // Process each item
            items.forEach((item, index) => {
                const inventoryDoc = inventorySnapshots[index];
                if (!inventoryDoc.exists()) {
                    console.error(`Inventory item ${item.inventoryItemId} not found`);
                    return;
                }

                const currentQuantity = inventoryDoc.data().quantity || 0;
                const newQuantity = Math.max(0, currentQuantity - item.quantity);

                // If we're trying to reduce below 0, log it
                if (currentQuantity < item.quantity) {
                    console.warn(`Warning: Attempted to reduce inventory below 0 for item ${item.inventoryItemId}. Current: ${currentQuantity}, Requested: ${item.quantity}`);
                }

                const inventoryRef = doc(db, 'Stores', storeId, 'Inventory', item.inventoryItemId);
                batch.update(inventoryRef, {
                    quantity: newQuantity
                });
            });

            await batch.commit();
        } catch (error) {
            console.error('Error updating inventory:', error);
            // Here you could add proper error reporting
            // reportInventoryError({ items, error, storeId });
        }
    };

    const handlePayment = async () => {
        if (!storeId || !userId) {
            showError('System not properly initialized');
            return;
        }

        const cashierName = auth.currentUser?.displayName || auth.currentUser?.email || 'Not Set';

        if (isCreditSale && (!customer || !customer.phone)) {
            showError('Customer information and phone number are required for credit sales');
            return;
        }

        setIsProcessing(true);
        try {


            const orderNumber = await generateOrderNumber(storeId, isCreditSale);

            const saleTransaction = {
                orderNumber,
                items: saleItems.map(item => ({
                    costPrice: item.costPrice,
                    inventoryItemId: item.id,
                    itemName: item.itemName,
                    price: item.sellingPrice,
                    quantity: item.quantity,
                    sku: item.sku
                })),
                totalCost,
                saleDate: isOnline ? serverTimestamp() : new Date().toISOString(),
                userId,
                cashierId: userId,
                cashierName: cashierName,
                isCreditSale,
                isPaid: !isCreditSale,
                paymentMethod: isCreditSale ? 'credit' : paymentMethod,
                amountReceived: isCreditSale ? 0 : parseFloat(amountReceived),
                change: isCreditSale ? 0 : change,
                storeId,
                ...(customer && {
                    customerId: customer.id,
                    customerName: customer.name,
                    customerPhone: customer.phone
                }),
                ...(isCreditSale && {
                    remainingBalance: totalCost,
                    lastPaymentDate: null,
                    amountPaid: 0,
                }),
                ...(paymentMethod === 'mobile_money' && momoPhone && {
                    momoPhone: momoPhone
                })
            };

            if (isOnline) {
                // Online flow - save directly to Firebase
                const collectionPath = isCreditSale ? 'CreditSales' : 'Sales';
                const saleRef = doc(collection(db, 'Stores', storeId, collectionPath));
                await setDoc(saleRef, {
                    ...saleTransaction,
                    createdAt: serverTimestamp()
                });

                // Update inventory and customer record
                Promise.allSettled([
                    updateInventory(
                        storeId,
                        saleTransaction.items
                    ),
                    customer && updateCustomerRecord(
                        storeId,
                        customer.id,
                        saleRef.id,
                        orderNumber
                    )
                ]).catch(console.error);

                showSuccess(`${isCreditSale ? 'Credit sale' : 'Sale'} completed successfully!`);
            } else {
                // Offline flow - just save the transaction for later
                await dbService.savePendingSale({
                    ...saleTransaction,
                    offlineCreated: true,
                    syncStatus: 'pending'
                });
                showSuccess('Sale saved offline. Will sync when connection is restored.', 10000);
            }

            // Reset UI
            setShowPaymentModal(false);
            setSaleItems([]);
            setAmountReceived('');
            setChange(0);
            setCustomer(null);
            setMomoPhone('');
            setPaymentMethod('cash');

        } catch (err) {
            console.error('Payment error:', err);
            showError(`Failed to process sale: ${err.message}`);
        } finally {
            setIsProcessing(false);
        }
    };

    const generateOrderNumber = async (storeId, isCredit = false) => {
        const collectionPath = isCredit ? 'CreditSales' : 'Sales';
        const salesRef = collection(db, 'Stores', storeId, collectionPath);
        const q = query(salesRef, orderBy('createdAt', 'desc'), limit(1));
        const querySnapshot = await getDocs(q);

        if (querySnapshot.empty) {
            return isCredit ? 'CR-ORD-0001' : 'ORD-0001';
        } else {
            const lastDoc = querySnapshot.docs[0];
            const lastOrderNumber = lastDoc.data().orderNumber;
            const lastNumber = parseInt(lastOrderNumber.split('-').pop());
            const newNumber = lastNumber + 1;
            return isCredit ?
                `CR-ORD-${newNumber.toString().padStart(4, '0')}` :
                `ORD-${newNumber.toString().padStart(4, '0')}`;
        }
    };

    const Header = (
        <div className={`border-b px-6 py-4 shadow-lg transition-colors ${isCreditSale
            ? 'bg-gradient-to-r from-orange-500 to-yellow-500'
            : 'bg-gradient-to-r from-indigo-600 to-purple-600'
            }`}>
            <div className="flex justify-between items-center">
                <div className="flex items-center">
                    {!isLocked && isOnline && (
                        <Link
                            to="/dashboard"
                            className="mr-4 text-white/80 hover:text-white transition-colors"
                        >
                            <ArrowLeft className="h-6 w-6" />
                        </Link>
                    )}
                    <h1 className="text-2xl font-bold text-white">Point of Sale Terminal</h1>
                    {HeaderSyncIndicator}
                </div>
                <div className="flex items-center space-x-4">
                    {/* New Segmented Button Control */}
                    <div className="bg-white/20 rounded-lg p-1 flex items-center space-x-2">
                        <button
                            onClick={() => setIsCreditSale(false)}
                            className={`flex items-center space-x-1 px-3 py-1.5 rounded-lg transition-all ${!isCreditSale
                                ? 'bg-white text-gray-800'
                                : 'text-white hover:bg-white/10'
                                }`}
                        >
                            <ShoppingCart size={16} />
                            <span>Normal Sales Mode</span>
                        </button>

                        {/* <button
                            onClick={() => setIsCreditSale(true)}
                            className={`flex items-center space-x-1 px-3 py-1.5 rounded-lg transition-all ${isCreditSale
                                ? 'bg-white text-gray-800'
                                : 'text-white hover:bg-white/10'
                                }`}
                        >
                            <HandCoins size={16} />
                            <span>Credit Sales Mode</span>
                        </button>

                        <button
                            onClick={() => {
                                if (!isCreditSale) {
                                    setShowAuthModal(true);
                                } else {
                                    setIsCreditSale(false);
                                }
                            }}
                            className={`flex items-center space-x-1 px-3 py-1.5 rounded-lg transition-all ${isCreditSale
                                ? 'bg-white text-gray-800'
                                : 'text-white hover:bg-white/10'
                                }`}
                        >
                            <HandCoins size={16} />
                            <span>Credit Sales Mode</span>
                        </button> */}

                        <button
                            onClick={() => {
                                if (hasProAccess) {
                                    if (!isCreditSale) {
                                        setShowAuthModal(true);
                                    } else {
                                        setIsCreditSale(false);
                                    }
                                }
                            }}
                            className={`flex items-center space-x-1 px-3 py-1.5 rounded-lg transition-all ${isCreditSale
                                ? 'bg-white text-gray-800'
                                : 'text-white hover:bg-white/10'
                                } ${!hasProAccess ? 'opacity-50 cursor-not-allowed' : ''}`}
                            disabled={!hasProAccess}
                        >
                            <HandCoins size={16} />
                            <div className="flex items-center space-x-1">
                                <span>Credit Sales Mode</span>
                                {!hasProAccess && (
                                    <span className="ml-2 inline-flex items-center rounded-md bg-white/20 px-2 py-0.5 text-xs font-medium text-white ring-1 ring-inset ring-white/30">
                                        Pro
                                    </span>
                                )}
                            </div>
                        </button>
                    </div>

                    {/* <button
                        onClick={() => setIsLocked(!isLocked)}
                        className={`flex items-center space-x-1 px-3 py-1.5 rounded-lg transition-all ${isLocked
                            ? 'bg-red-400 text-white hover:bg-red-500'
                            : 'bg-white/20 text-white hover:bg-white/30'
                            }`}
                    >
                        {isLocked ? <Lock size={16} /> : <Unlock size={16} />}
                        <span>{isLocked ? 'Unlock' : 'Lock'}</span>
                    </button> */}

                    <button
                        onClick={() => setShowReceiptsModal(true)}
                        className="flex items-center space-x-1 px-3 py-1.5 rounded-lg transition-all bg-white/20 text-white hover:bg-white/30"
                    >
                        <ReceiptText size={16} />
                        <span>Receipts</span>
                    </button>
                    <div className="flex items-center space-x-2 text-white/80">
                        <User className="" size={20} />
                        <span className="text-sm">{auth.currentUser?.email}</span>
                    </div>
                </div>
            </div>

            {/* Optional: Add a warning banner when in credit mode */}
            {isCreditSale && !customer && (
                <div className="flex justify-center items-center w-full">
                    <div className="w-full mt-4 bg-white px-4 py-2 rounded-lg text-sm flex items-center text-center">
                        YOU ARE CURRENTLY IN CREDIT SALES MODE : Please select a customer to complete a credit sale
                    </div>
                </div>
            )}
        </div>
    );

    // Add exit confirmation modal
    const ExitPromptModal = showExitPrompt && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center p-4 z-50">
            <div className="bg-white rounded-lg p-6 max-w-md w-full">
                <h2 className="text-xl font-bold mb-4">Confirm Exit</h2>
                <p className="mb-6">Are you sure you want to exit? Any unsaved changes will be lost.</p>
                <div className="flex justify-end space-x-3">
                    <button
                        onClick={() => setShowExitPrompt(false)}
                        className="px-4 py-2 border border-gray-300 rounded-lg"
                    >
                        Cancel
                    </button>
                    <button
                        onClick={handleExit}
                        className="px-4 py-2 bg-red-500 text-white rounded-lg"
                    >
                        Exit
                    </button>
                </div>
            </div>
        </div>
    );

    const ListItem = memo(({ item, lastItemRef, onAddToSale }) => (
        <div
            ref={lastItemRef}
            onClick={() => onAddToSale(item)}
            className="bg-white p-3 rounded-lg border px-7s shadow-sm hover:shadow-md hover:scale-[1.01] cursor-pointer transition-all flex space-x-3"
        >
            <ItemImage item={item} className="w-12 h-12 rounded-lg overflow-hidden flex-shrink-0" />
            <div className="flex-1 min-w-0">
                <h3 className="font-semibold text-sm leading-tight break-words">{item.itemName}</h3>
                <div className="text-xs text-gray-500 mt-0.5">SKU: {item.sku}</div>
                <div className="flex items-center justify-between mt-1">
                    <div className="text-xs text-indigo-600 font-medium">{item.quantity} in stock</div>
                    <div className="font-bold text-sm bg-indigo-50 text-indigo-700 px-2 py-0.5 rounded">
                        GHS {item.sellingPrice.toFixed(2)}
                    </div>
                </div>
            </div>
        </div>
    ));

    // Modified list view rendering
    const renderListView = useCallback(() => (
        <div className="space-y-2 p-1 px-4">
            {displayedItems.map((item, index) => (
                <ListItem
                    key={item.id}
                    item={item}
                    lastItemRef={index === displayedItems.length - 1 ? lastItemRef : null}
                    onAddToSale={addToSale}
                />
            ))}
        </div>
    ), [displayedItems, lastItemRef, addToSale]);

    const GridItem = memo(({ item, lastItemRef, onAddToSale }) => (
        <div
            ref={lastItemRef}
            onClick={() => onAddToSale(item)}
            className="bg-white rounded-lg border shadow-sm hover:shadow-md hover:scale-[1.01] cursor-pointer transition-all"
        >
            <div className="p-3">
                <ItemImage
                    item={item}
                    className="aspect-square w-full mb-3 rounded-lg overflow-hidden"
                />
                <div className="flex flex-col mt-1">
                    <h3 className="font-semibold text-sm leading-tight break-words">
                        {item.itemName}
                    </h3>
                    <div className="flex items-center justify-between mt-4">
                        <div className="text-xs text-indigo-600 font-medium">
                            {item.quantity} left
                        </div>
                        <div className="font-bold text-sm bg-indigo-50 text-indigo-700 px-2 py-0.5 rounded">
                            GHS {item.sellingPrice.toFixed(2)}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    ));

    // Memoized grid view rendering
    const renderGridView = useCallback(() => (
        <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4 p-4">
            {displayedItems.map((item, index) => (
                <GridItem
                    key={item.id}
                    item={item}
                    lastItemRef={index === displayedItems.length - 1 ? lastItemRef : null}
                    onAddToSale={addToSale}
                />
            ))}
        </div>
    ), [displayedItems, lastItemRef, addToSale]);

    // Show loading state while initializing
    if (isInitializing) {
        return (
            <div className="h-screen flex items-center justify-center">
                <div className="text-center flex flex-col items-center ">
                    <Loader2 className="animate-spin rounded-full h-12 w-12" />
                    <p className="mt-2 text-gray-600">Initializing...</p>
                </div>
            </div>
        );
    }

    // Show error state if initialization failed
    if (!storeId || !userId) {
        return (
            <div className="h-screen flex items-center justify-center">
                <div className="text-center">
                    <h2 className="text-xl font-bold text-red-600 mb-2">Initialization Error</h2>
                    <p className="text-gray-600">Unable to load required data. Please refresh or contact support.</p>
                </div>
            </div>
        );
    }

    const PaymentModal = showPaymentModal && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center p-4 z-50">
            <div className="bg-white rounded-lg p-6 w-full max-w-md">
                {/* Header */}
                <div className="flex justify-between items-center mb-6">
                    <h2 className="text-xl font-bold">
                        {isCreditSale ? 'Complete Credit Sale' : 'Complete Payment'}
                    </h2>
                    <button
                        onClick={() => setShowPaymentModal(false)}
                        className="text-gray-500 hover:text-gray-700 rounded-full p-1 hover:bg-gray-100"
                    >
                        <X size={24} />
                    </button>
                </div>

                <div className="space-y-6">

                    {/* Credit Sale Flow */}
                    {isCreditSale && (
                        <div className="space-y-4">
                            {/* Customer Selection Warning */}
                            {!customer ? (
                                <div className="space-y-4">
                                    <div className="p-4 bg-yellow-50 border border-yellow-200 rounded-lg">
                                        <div className="flex items-center space-x-2 text-yellow-800">
                                            <User2 size={20} />
                                            <span className="font-medium">Select a Customer</span>
                                        </div>
                                        <p className="mt-2 text-sm text-yellow-700">
                                            A customer is required to proceed with the credit sale.
                                        </p>
                                    </div>

                                    {/* Customer Selection Widget */}
                                    <div className="bg-white border border-gray-200 rounded-lg p-4">
                                        <div className="text-sm text-gray-600 mb-3">
                                            Click below to select or add a customer
                                        </div>
                                        <CustomerSection className='w-full'
                                            customer={customer}
                                            setCustomer={setCustomer}
                                        />
                                    </div>
                                </div>
                            ) : (
                                /* Customer Info Display */
                                <div className="p-4 bg-blue-50 border border-blue-200 rounded-lg">
                                    <div className="flex items-center justify-between">
                                        <div className="flex items-center space-x-2 text-blue-800">
                                            <User2 size={20} />
                                            <span className="font-medium">{customer.name}</span>
                                        </div>
                                        <button
                                            onClick={() => setCustomer(null)}
                                            className="text-blue-600 hover:text-blue-800 p-1 rounded-full hover:bg-blue-100"
                                        >
                                            <X size={16} />
                                        </button>
                                    </div>
                                    <div className="mt-2 text-sm text-blue-700">
                                        <p>Phone: {customer.phone || 'Not provided'}</p>
                                    </div>
                                </div>
                            )}
                        </div>
                    )}

                    {/* Sale Summary */}
                    <div className="bg-gray-50 p-4 rounded-lg space-y-3">
                        <div className="flex justify-between items-center text-sm text-gray-600">
                            <span>Items Total</span>
                            <span>{saleItems.reduce((sum, item) => sum + item.quantity, 0)} items</span>
                        </div>
                        <div className="flex justify-between items-center text-lg font-bold">
                            <span>Total Amount</span>
                            <span>GHS {totalCost.toFixed(2)}</span>
                        </div>
                    </div>

                    {/* Credit Sale Flow */}
                    {isCreditSale ? (
                        <div className="space-y-4">
                            {/* Credit Sale Submit Button */}
                            <button
                                onClick={handlePayment}
                                disabled={isProcessing || !customer}
                                className={`w-full p-4 rounded-lg text-white font-medium transition-colors flex items-center justify-center space-x-2 ${isProcessing || !customer
                                    ? 'bg-gray-400 cursor-not-allowed'
                                    : 'bg-yellow-500 hover:bg-yellow-600'
                                    }`}
                            >
                                {isProcessing ? (
                                    <>
                                        <Loader2 className="animate-spin" size={20} />
                                        <span>Processing...</span>
                                    </>
                                ) : (
                                    <>
                                        <HandCoinsIcon size={20} />
                                        <span>Complete Credit Sale</span>
                                    </>
                                )}
                            </button>
                        </div>
                    ) : (
                        /* Cash Sale Flow */
                        <div className="space-y-4">
                            {/* Payment Method Selection */}
                            <div className="space-y-2">
                                <label className="block text-sm font-medium text-gray-700">
                                    Payment Method
                                </label>
                                <div className="grid grid-cols-3 gap-2">
                                    {['cash', 'card', 'mobile_money'].map(method => (
                                        <button
                                            key={method}
                                            onClick={() => setPaymentMethod(method)}
                                            className={`p-2 rounded-lg border text-sm transition-colors ${paymentMethod === method
                                                ? 'bg-blue-500 text-white border-blue-500'
                                                : 'bg-white border-gray-300 hover:bg-gray-50'
                                                }`}
                                        >
                                            {method.replace('_', ' ').toUpperCase()}
                                        </button>
                                    ))}
                                </div>
                            </div>

                            {/* Cash Payment Input */}
                            {paymentMethod === 'cash' && (
                                <div className="space-y-2">
                                    <label className="block text-sm font-medium text-gray-700">
                                        Amount Received
                                    </label>
                                    <input
                                        type="number"
                                        value={amountReceived}
                                        onChange={(e) => setAmountReceived(e.target.value)}
                                        className="w-full p-4 border text-xl rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                                        placeholder="Enter amount"
                                        ref={amountReceivedInputRef}
                                    />

                                    {/* Change Calculation Display */}
                                    {Number(amountReceived) > 0 && (
                                        <div className={`mt-4 p-3 rounded-lg ${Number(amountReceived) >= totalCost
                                            ? 'bg-green-50 border-2 border-green-200'
                                            : 'bg-red-50 border-2 border-red-200'
                                            }`}>
                                            <div className="flex justify-between items-center">
                                                <span className={`text-lg font-semibold ${Number(amountReceived) >= totalCost
                                                    ? 'text-green-700'
                                                    : 'text-red-700'
                                                    }`}>
                                                    {Number(amountReceived) >= totalCost ? 'Change Due:' : 'Remaining:'}
                                                </span>
                                                <span className={`text-xl font-bold ${Number(amountReceived) >= totalCost
                                                    ? 'text-green-700'
                                                    : 'text-red-700'
                                                    }`}>
                                                    GHS {Math.abs(Number(amountReceived) - totalCost).toFixed(2)}
                                                </span>
                                            </div>
                                        </div>
                                    )}
                                </div>
                            )}

                            {/* Card/Mobile Money Fields */}
                            {paymentMethod !== 'cash' && (
                                <div className="p-4 bg-gray-50 border border-gray-200 rounded-lg space-y-3">
                                    <div className="flex items-center space-x-2 text-gray-800">
                                        {paymentMethod === 'card' ? <CreditCard size={20} /> : <Smartphone size={20} />}
                                        <span className="font-medium">
                                            {paymentMethod === 'card' ? 'Card Payment' : 'Mobile Money Number (optional)'}
                                        </span>
                                    </div>

                                    {paymentMethod === 'mobile_money' && (
                                        <div className="space-y-2">
                                            <input
                                                type="tel"
                                                value={momoPhone}
                                                onChange={(e) => setMomoPhone(e.target.value)}
                                                placeholder="Enter customer's mobile money number"
                                                className="w-full p-2 border rounded-lg text-sm focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                                            />
                                        </div>
                                    )}

                                    {paymentMethod !== 'mobile_money' &&
                                        <p className="text-sm text-gray-600">
                                            Process payment of GHS {totalCost.toFixed(2)} through your terminal
                                        </p>
                                    }
                                </div>
                            )}

                            {/* Customer Selection (Optional for Cash Sales) */}
                            {customer && (
                                <div className="p-3 bg-blue-50 border border-blue-100 rounded-lg">
                                    <div className="flex items-center justify-between">
                                        <div className="flex items-center space-x-2">
                                            <User2 size={16} className="text-blue-600" />
                                            <span className="text-sm font-medium text-blue-700">
                                                {customer.name}
                                            </span>
                                        </div>
                                        <button
                                            onClick={() => setCustomer(null)}
                                            className="text-blue-600 hover:text-blue-800"
                                        >
                                            <X size={16} />
                                        </button>
                                    </div>
                                </div>
                            )}

                            {/* Submit Button */}
                            <button
                                onClick={handlePayment}
                                disabled={
                                    isProcessing ||
                                    (paymentMethod === 'cash' && Number(amountReceived) < totalCost)
                                }
                                className={`w-full p-4 rounded-lg text-white font-medium transition-colors flex items-center justify-center space-x-2 ${isProcessing || (paymentMethod === 'cash' && Number(amountReceived) < totalCost)
                                    ? 'bg-gray-400 cursor-not-allowed'
                                    : 'bg-green-500 hover:bg-green-600'
                                    }`}
                            >
                                {isProcessing ? (
                                    <>
                                        <Loader2 className="animate-spin" size={20} />
                                        <span>Processing...</span>
                                    </>
                                ) : (
                                    <>
                                        <Receipt size={20} />
                                        <span>Complete Sale</span>
                                    </>
                                )}
                            </button>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );

    const saveDraft = () => {
        if (drafts.length >= 5) {
            showError('Maximum number of drafts reached (5). Please complete or remove existing drafts.');
            return;
        }

        if (!saleItems.length) {
            showError('No items in current sale to save as draft.');
            return;
        }

        const newDraft = {
            id: Date.now(),
            items: saleItems,
            timestamp: new Date(),
            customer: customer,
            isCreditSale: isCreditSale,
            total: totalCost
        };

        setDrafts([...drafts, newDraft]);
        setSaleItems([]);
        setCustomer(null);
        setIsCreditSale(false);
        showSuccess('Sale saved as draft');
    };

    const updateItemPrice = (itemId, newPrice) => {
        setSaleItems(prevItems =>
            prevItems.map(item =>
                item.id === itemId
                    ? { ...item, sellingPrice: newPrice }
                    : item
            )
        );
    };

    return (
        <div className="h-screen flex flex-col bg-gray-50">
            {/* Header */}
            {Header}

            {/* Main Content Area */}
            <div className="flex-1 flex overflow-hidden">
                {/* Left Side - Products */}
                <div className="w-2/3 p-6 pb-0 overflow-hidden flex flex-col">
                    <div className="flex justify-between items-center mb-3 px-1.5">
                        <div className="mb-4 mr-7 w-full">
                            <div className="relative">
                                <input
                                    type="text"
                                    placeholder="Search products..."
                                    value={searchQuery}
                                    onChange={(e) => setSearchQuery(e.target.value)}
                                    className="w-full pl-10 pr-4 py-3 border-2 border-indigo-200 rounded-lg focus:border-indigo-500 focus:ring-2 focus:ring-indigo-200 transition-all"
                                />
                                <Search className="absolute left-3 top-1/2 transform -translate-y-1/2 text-indigo-400" size={20} />
                            </div>
                        </div>
                        <div className="flex rounded-lg border border-gray-200 bg-white">
                            <button
                                onClick={() => setViewMode('list')}
                                className={`flex items-center p-2 transition-colors ${viewMode === 'list'
                                    ? 'rounded-l-lg bg-blue-500 text-white'
                                    : 'text-gray-600 hover:bg-gray-50'
                                    }`}
                            >
                                <List className="h-4 w-4" />
                            </button>
                            <button
                                onClick={() => setViewMode('grid')}
                                className={`flex items-center p-2 border-l border-gray-200 transition-colors ${viewMode === 'grid'
                                    ? 'rounded-r-lg bg-blue-500 text-white'
                                    : 'text-gray-600 hover:bg-gray-50'
                                    }`}
                            >
                                <Grid className="h-4 w-4" />
                            </button>
                        </div>
                    </div>

                    <div className="flex-1 overflow-y-auto pb-6">
                        {isLoading ? (
                            <div className="flex items-center justify-center h-full">
                                <Loader2 className="animate-spin h-12 w-12 text-indigo-500" />
                            </div>
                        ) : viewMode === 'list' ? (
                            renderListView()
                        ) : (
                            renderGridView()
                        )}
                    </div>

                    {/* Draft Manager */}
                    <DraftManager
                        drafts={drafts}
                        setDrafts={setDrafts}
                        currentSale={saleItems}
                        loadDraft={(draft) => {
                            setSaleItems(draft.items);
                            setCustomer(draft.customer);
                            setIsCreditSale(draft.isCreditSale);
                        }}
                        clearCurrentSale={() => {
                            setSaleItems([]);
                            setCustomer(null);
                            setIsCreditSale(false);
                        }}
                        customer={customer}
                        isCreditSale={isCreditSale}
                    />
                </div>


                {/* Right Side - Cart */}
                <div className="w-1/3 border-l bg-white p-6 pr-0 flex flex-col">
                    <div className="mb-4 pr-6 flex items-center justify-between">
                        <h2 className="text-xl font-bold text-gray-800">Current Sale</h2>
                        <div className="flex items-center space-x-4">
                            <div className="">
                                <CustomerSection customer={customer} setCustomer={setCustomer} />
                            </div>
                            <div className="flex items-center space-x-2">
                                <button
                                    onClick={() => setIsScannerEnabled(!isScannerEnabled)}
                                    className={`flex items-center space-x-2 px-3 py-1.5 rounded-lg transition-all ${isScannerEnabled
                                        ? 'bg-indigo-100 text-indigo-600 font-medium'
                                        : 'bg-gray-100 text-gray-400 hover:bg-gray-200'
                                        }`}
                                >
                                    <Barcode size={20} />
                                    <span className="text-sm">
                                        {isScannerEnabled ? 'Scanner On' : 'Scanner Off'}
                                    </span>
                                </button>
                            </div>
                        </div>
                    </div>

                    <CartItems
                        saleItems={saleItems}
                        updateQuantity={updateQuantity}
                        removeFromSale={removeFromSale}
                        updateItemPrice={updateItemPrice}
                        storeId={storeId}
                    />

                    <div className=" pr-6">
                        {saleItems.length > 0 && (
                            <div className="border-t pt-4 space-y-4 ">
                                <div className="space-y-2">
                                    <div className="flex justify-between items-center text-sm text-gray-600">
                                        <span>Subtotal</span>
                                        <span>GHS {totalCost.toFixed(2)}</span>
                                    </div>
                                    <div className="flex justify-between items-center text-sm text-gray-600">
                                        <span>Total Items</span>
                                        <span>{saleItems.reduce((sum, item) => sum + item.quantity, 0)} items</span>
                                    </div>
                                    <div className="flex justify-between items-center text-lg font-bold">
                                        <span>Total</span>
                                        <span>GHS {totalCost.toFixed(2)}</span>
                                    </div>
                                </div>

                                <div className="grid grid-cols-3 gap-2">
                                    <button
                                        onClick={() => {
                                            if (window.confirm('Are you sure you want to clear the cart?')) {
                                                setSaleItems([]);
                                            }
                                        }}
                                        className="px-4 py-4 border border-gray-300 rounded-lg text-gray-700 hover:bg-gray-50 transition-colors"
                                    >
                                        Clear Sale
                                    </button>
                                    <button
                                        onClick={saveDraft}
                                        disabled={drafts.length >= 5 || !saleItems.length}
                                        className={`px-4 py-4 border rounded-lg transition-colors ${drafts.length >= 5 || !saleItems.length
                                            ? 'border-gray-200 text-gray-400 bg-gray-50 cursor-not-allowed'
                                            : 'border-indigo-300 text-indigo-600 hover:bg-indigo-50'
                                            }`}
                                    >
                                        Save Draft
                                    </button>

                                    <button
                                        onClick={() => setShowPaymentModal(true)}
                                        className="px-4 py-4 bg-blue-500 text-white rounded-lg hover:bg-blue-600 transition-colors"
                                    >
                                        Complete Sale
                                    </button>
                                </div>
                            </div>
                        )}
                    </div>

                </div>
            </div>

            {/* Payment Modal */}
            {/* {showPaymentModal && (
                <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center p-4">
                    <div className="bg-white rounded-lg p-6 w-full max-w-md">
                        <div className="flex justify-between items-center mb-6">
                            <h2 className="text-xl font-bold">Complete Payment</h2>
                            <button
                                onClick={() => setShowPaymentModal(false)}
                                className="text-gray-500 hover:text-gray-700 rounded-full p-1 hover:bg-gray-100"
                            >
                                <X size={24} />
                            </button>
                        </div>

                        <div className="space-y-6">
                            <div className="text-lg font-semibold flex justify-between">
                                <span>Total Amount:</span>
                                <span>GHS {totalCost.toFixed(2)}</span>
                            </div>

                            <div className="space-y-2">
                                <label className="block text-sm font-medium text-gray-700">Payment Method</label>
                                <div className="grid grid-cols-3 gap-2">
                                    {['cash', 'card', 'mobile_money'].map(method => (
                                        <button
                                            key={method}
                                            onClick={() => setPaymentMethod(method)}
                                            className={`p-2 rounded-lg border transition-colors ${paymentMethod === method
                                                ? 'bg-blue-500 text-white border-blue-500'
                                                : 'bg-white border-gray-300 hover:bg-gray-50'
                                                }`}
                                        >
                                            {method.replace('_', ' ').toUpperCase()}
                                        </button>
                                    ))}
                                </div>
                            </div>

                            {paymentMethod === 'cash' && (
                                <div className="space-y-2">
                                    <label className="block text-sm font-medium text-gray-700">
                                        Amount Received
                                    </label>
                                    <input
                                        type="number"
                                        value={amountReceived}
                                        onChange={(e) => setAmountReceived(e.target.value)}
                                        className="w-full p-4 border text-xl rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                                        placeholder="Enter amount"
                                        ref={amountReceivedInputRef}
                                    />
                                    {Number(amountReceived) > totalCost && (
                                        <div className="mt-4 p-3 bg-green-50 border-2 border-green-200 rounded-lg">
                                            <div className="flex justify-between items-center">
                                                <span className="text-lg font-semibold text-green-700">Change Due:</span>
                                                <span className="text-xl font-bold text-green-700">
                                                    GHS {(Number(amountReceived) - totalCost).toFixed(2)}
                                                </span>
                                            </div>
                                        </div>
                                    )}
                                </div>
                            )}

                            <button
                                onClick={handlePayment}
                                disabled={isProcessing || (paymentMethod === 'cash' && Number(amountReceived) < totalCost)}
                                className={`w-full p-3 rounded-lg text-white transition-colors ${isProcessing || (paymentMethod === 'cash' && Number(amountReceived) < totalCost)
                                    ? 'bg-gray-400 cursor-not-allowed'
                                    : 'bg-green-500 hover:bg-green-600'
                                    }`}
                            >
                                {isProcessing ? 'Processing...' : 'Complete Payment'}
                            </button>
                        </div>
                    </div>
                </div>
            )} */}

            {PaymentModal}

            {/* Notifications */}
            <NotificationsDisplay />

            {ExitPromptModal}

            <AuthorizationModal
                isOpen={showAuthModal}
                onClose={() => setShowAuthModal(false)}
                onAuthorized={() => {
                    setIsCreditSale(true);
                    setShowAuthModal(false);
                }}
            />

            <RecentReceiptsModal
                isOpen={showReceiptsModal}
                onClose={() => setShowReceiptsModal(false)}
            />
        </div>
    );
};

export default AddSale;