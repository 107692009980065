import React from 'react';
import { useState, useEffect } from 'react';

const PaymentPopupHandler = ({ authUrl, onSuccess, onFailure, onClose }) => {
    const [popup, setPopup] = useState(null);

    const openPopup = () => {
        const width = 700;
        const height = 800;
        const left = window.screen.width / 2 - width / 2;
        const top = window.screen.height / 2 - height / 2;

        const newPopup = window.open(
            authUrl,
            'Payment Authorization',
            `width=${width},height=${height},left=${left},top=${top}`
        );
        setPopup(newPopup);

        // Poll the popup to check URL changes and closure
        const timer = setInterval(() => {
            try {
                // Check if popup is closed
                if (newPopup.closed) {
                    clearInterval(timer);
                    onClose();
                    return;
                }

                // Check if URL contains reference parameter
                if (newPopup.location.href.includes('reference=')) {
                    clearInterval(timer);
                    newPopup.close();
                    // Force page refresh instead of calling onSuccess
                    window.location.reload();
                    return;
                }
            } catch (error) {
                // Handle cross-origin errors silently
                if (error.name === 'SecurityError') {
                    return;
                }
            }
        }, 500);

        // Listen for messages from the popup
        const handleMessage = (event) => {
            const allowedOrigins = [window.location.origin];
            if (!allowedOrigins.includes(event.origin)) return;

            if (event.data.status === 'success') {
                newPopup.close();
                window.location.reload();
            } else if (event.data.status === 'failure') {
                onFailure(event.data);
                newPopup.close();
            }
        };

        window.addEventListener('message', handleMessage);

        return () => {
            clearInterval(timer);
            window.removeEventListener('message', handleMessage);
        };
    };

    useEffect(() => {
        let cleanup;
        if (authUrl) {
            cleanup = openPopup();
        }

        return () => {
            if (cleanup) cleanup();
            if (popup && !popup.closed) popup.close();
        };
    }, [authUrl]);

    return null;
};

export default PaymentPopupHandler;