import React, { useState, useEffect } from 'react';
import { getFunctions, httpsCallable } from 'firebase/functions';
import { onAuthStateChanged } from 'firebase/auth';
import { doc, getDoc, collection, getDocs, query, where, onSnapshot } from 'firebase/firestore';
import { auth, db } from '../../../../firebase-config';
import { Check, ArrowRight, Loader2 } from 'lucide-react';
import CancelSubscriptionModal from './cancel_subscription';
import SubscriptionDetailsSection from './subscription_details';
import PaymentSelectionModal from './payment_type_modal';
import { useToast } from '../toast';
import PaymentPopupHandler from './payment_popup_handler';
import { useAuth } from '../../../auth/AuthContext';
import AccessDenied from '../access_denied_page';

const functions = getFunctions();

const TIER_NAMES = {
    1: 'Basic',
    2: 'Pro',
    3: 'Premium'
};

const PLAN_FEATURES = {
    1: [ // Basic
        "Record sales with inbuilt POS system",
        "Advanced inventory management",
        "Notifications & Low Stock Alerts",
        "Up to 300 products in inventory",
        "Basic analytics & reports",
        "Up to 3 users",
    ],
    2: [ // Pro
        "Record sales with inbuilt POS system",
        "Advanced inventory management",
        "Notifications & Low Stock Alerts",
        'Unlimited products in inventory',
        'Credit sales management',
        'Standard analytics & reports',
        'Up to 10 users',
        'Expenses tracking',
    ],
    3: [ // Enterprise
        "Record sales with inbuilt POS system",
        "Advanced inventory management",
        "Notifications & Low Stock Alerts",
        'Unlimited products in inventory',
        'Credit sales management',
        'Advanced sales analytics',
        'Unlimited users',
        'Expenses & Payments tracking',
        'Advanced financial reports',
        'Quotes, Purchase Orders & Invoices',
        'Supplier & Customer Management',
    ]
};

const SubscriptionSuccessPopup = ({ isOpen, onClose, plan }) => {
    if (!isOpen) return null;

    return (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
            <div className="bg-white rounded-xl p-8 max-w-md w-full mx-4 relative">
                <div className="flex flex-col items-center text-center">
                    {/* Success Icon */}
                    <div className="w-16 h-16 bg-green-100 rounded-full flex items-center justify-center mb-4">
                        <svg
                            className="w-10 h-10 text-green-500"
                            fill="none"
                            stroke="currentColor"
                            viewBox="0 0 24 24"
                        >
                            <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth={2}
                                d="M5 13l4 4L19 7"
                            />
                        </svg>
                    </div>

                    {/* Celebration Icon */}
                    <div className="absolute top-6 right-6">
                        <span className="text-2xl">🎉</span>
                    </div>

                    {/* Content */}
                    <h3 className="text-2xl font-bold text-gray-900 mb-2">
                        Welcome Aboard!
                    </h3>
                    <p className="text-lg text-gray-700 mb-4">
                        Your subscription has been successfully activated
                    </p>
                    <p className="text-sm text-gray-500 mb-6">
                        Thank you for choosing us. We're excited to help you grow your business!
                    </p>

                    {/* Divider */}
                    <div className="w-full border-t border-gray-200 mb-6" />

                    {/* Close Button */}
                    <button
                        onClick={onClose}
                        className="w-full bg-orange-500 hover:bg-orange-600 text-white font-medium py-3 px-6 rounded-lg transition-colors"
                    >
                        Get Started
                    </button>
                </div>
            </div>
        </div>
    );
};

const SubscriptionProcessingModal = ({ isOpen, progress, message }) => {
    if (!isOpen) return null;

    return (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
            <div className="bg-white p-6 rounded-lg max-w-md w-full mx-4">
                <div className="flex flex-col items-center">
                    <Loader2 className="w-8 h-8 text-orange-500 animate-spin" />
                    <h3 className="text-lg font-semibold mt-4 mb-2">Processing Your Subscription</h3>
                    <div className="w-full bg-gray-200 rounded-full h-2 mb-4">
                        <div
                            className="bg-orange-500 h-2 rounded-full transition-all duration-500"
                            style={{ width: `${progress}%` }}
                        />
                    </div>
                    <p className="text-gray-600 text-center">{message}</p>
                </div>
            </div>
        </div>
    );
};

const BillingToggle = ({ billingPeriod, onToggle, disabled }) => (
    <div className="flex items-center justify-center gap-6 mb-6">
        <button
            onClick={() => onToggle('monthly')}
            className={`text-sm px-3 py-1.5 rounded-lg ${billingPeriod === 'monthly'
                ? 'bg-gray-100 text-orange-600 font-medium'
                : 'text-gray-500 hover:text-gray-700'
                }`}
            disabled={disabled}
        >
            Monthly
        </button>
        <button
            onClick={() => onToggle('annually')}
            className={`text-sm px-3 py-1.5 rounded-lg ${billingPeriod === 'annually'
                ? 'bg-gray-100 text-orange-600 font-medium'
                : 'text-gray-500 hover:text-gray-700'
                }`}
            disabled={disabled}
        >
            Yearly
            <span className="ml-1 text-xs text-green-600">Best Deal!</span>
        </button>
        <button
            onClick={() => onToggle('quarterly')}
            className={`text-sm px-3 py-1.5 rounded-lg ${billingPeriod === 'quarterly'
                ? 'bg-gray-100 text-orange-600 font-medium'
                : 'text-gray-500 hover:text-gray-700'
                }`}
            disabled={disabled}
        >
            Every 3 Months
            {/* <span className="ml-1 text-xs text-green-600">Save 10%</span> */}
        </button>
        <button
            onClick={() => onToggle('semiannually')}
            className={`text-sm px-3 py-1.5 rounded-lg ${billingPeriod === 'semiannually'
                ? 'bg-gray-100 text-orange-600 font-medium'
                : 'text-gray-500 hover:text-gray-700'
                }`}
            disabled={disabled}
        >
            Every 6 Months
            {/* <span className="ml-1 text-xs text-green-600">Save 10%</span> */}
        </button>

    </div>
);

const PricingPlanSkeleton = () => (
    <div className="grid grid-cols-1 md:grid-cols-3 gap-4 mb-6">
        {[1, 2, 3].map((index) => (
            <div key={index} className="border p-4 rounded flex flex-col h-full animate-pulse">
                <div className="flex-grow space-y-4">
                    {/* Plan name skeleton */}
                    <div className="h-6 w-24 bg-gray-200 rounded"></div>

                    {/* Price skeleton */}
                    <div className="h-8 w-32 bg-gray-200 rounded"></div>

                    {/* Features skeleton */}
                    <div className="space-y-3">
                        {[1, 2, 3, 4, 5].map((featureIndex) => (
                            <div key={featureIndex} className="flex items-center">
                                <div className="w-4 h-4 mr-2 bg-gray-200 rounded-full"></div>
                                <div className="h-4 bg-gray-200 rounded w-full"></div>
                            </div>
                        ))}
                    </div>
                </div>

                {/* Button skeleton */}
                <div className="h-10 w-full bg-gray-200 rounded mt-4"></div>
            </div>
        ))}
    </div>
);

const PricingPlans = ({ plans, onSubscribe, isLoading, billingPeriod }) => {
    const [processingPlanId, setProcessingPlanId] = useState(null);

    const handleSubscribe = async (plan) => {
        setProcessingPlanId(plan.planId);
        try {
            await onSubscribe(plan);
        } finally {
            setProcessingPlanId(null);
        }
    };

    const getMonthlyAmount = (amount, period) => {
        switch (period) {
            case 'quarterly':
                return (amount / 3).toFixed(2);
            case 'semiannually':
                return (amount / 12).toFixed(2);
            case 'annually':
                return (amount / 12).toFixed(2);
            default:
                return null;
        }
    };

    return (
        <div className="grid grid-cols-1 md:grid-cols-3 gap-4 mb-6">
            {plans.map(plan => (
                <div
                    key={plan.id}
                    className={`border p-4 rounded flex flex-col h-full relative ${plan.current ? 'bg-orange-50 border-orange-200' : ''}`}
                >
                    {/* Discount Badge */}
                    {plan.hasDiscount && (
                        <div className="absolute top-2 right-2 bg-green-500 text-white text-xs font-bold px-2 py-1 rounded-full">
                            {(((plan.amount_original - plan.amount) / plan.amount_original) * 100).toFixed(0)}% OFF
                        </div>
                    )}

                    <div className="flex-grow">
                        <h3 className="font-medium mb-2">{plan.name}</h3>
                        <div className="mb-1">
                            {isLoading ? (
                                <span className="inline-block w-24 h-8 bg-gray-200 rounded animate-pulse" />
                            ) : (
                                <div className="space-y-1">

                                    <div className="text-2xl font-bold">
                                        GHS {plan.amount}
                                        <span className="text-sm text-gray-500">
                                            {billingPeriod === 'monthly' && ' / month'}
                                            {billingPeriod === 'quarterly' && ' / 3months'}
                                            {billingPeriod === 'annually' && ' / year'}
                                        </span>
                                    </div>
                                    {plan.hasDiscount && (
                                        <div className="text-[16px] text-gray-500 line-through">
                                            GHS {plan.amount_original}
                                        </div>
                                    )}
                                </div>
                            )}
                        </div>
                        <ul className="text-sm text-gray-600 space-y-2 mb-4 mt-7">
                            {PLAN_FEATURES[plan.tier].map((feature, index) => (
                                <li key={index} className="flex items-center">
                                    <Check className="w-4 h-4 mr-2 text-orange-500" />
                                    {feature}
                                </li>
                            ))}
                        </ul>
                    </div>
                    <button
                        onClick={() => handleSubscribe(plan)}
                        className={`w-full px-4 py-2 rounded transition-colors mt-auto flex items-center justify-center
                            ${processingPlanId ? 'bg-orange-400 cursor-not-allowed' : 'bg-orange-500 hover:bg-orange-700'} 
                            text-white`}
                        disabled={processingPlanId !== null}
                    >
                        {processingPlanId === plan.planId ? (
                            <>
                                <Loader2 className="animate-spin -ml-1 mr-2 h-5 w-5" />
                                Processing...
                            </>
                        ) : processingPlanId !== null ? (
                            'Please wait...'
                        ) : (
                            'Subscribe'
                        )}
                    </button>
                </div>
            ))}
        </div>
    );
};

const SubscriptionSettings = () => {
    const { refreshPlanLevel } = useAuth();
    const [billingPeriod, setBillingPeriod] = useState('monthly');
    const [plansLoading, setPlansLoading] = useState(false);
    const [plans, setPlans] = useState([]);
    const [subscription, setSubscription] = useState(null);
    const [hasSubscription, setHasSubscription] = useState(false);
    const [currentUser, setCurrentUser] = useState(null);
    const [currentStore, setCurrentStore] = useState(null);
    const [authChecked, setAuthChecked] = useState(false);
    const [showPlans, setShowPlans] = useState(false);
    const [paymentProcessing, setPaymentProcessing] = useState(false);
    const [showSuccessPopup, setShowSuccessPopup] = useState(false);
    const [showPaymentModal, setShowPaymentModal] = useState(false);
    const [selectedPlan, setSelectedPlan] = useState(null);
    const [paymentUrl, setPaymentUrl] = useState(null);
    const [subscriptionLoading, setSubscriptionLoading] = useState(true);
    const { showToast, Toast } = useToast();
    const [loading, setLoading] = useState(true);
    const [isOwner, setIsOwner] = useState(false);
    const [alert, setAlert] = useState({
        isOpen: false,
        title: '',
        message: '',
        type: 'error'
    });

    useEffect(() => {
        const checkAccess = async () => {
            try {
                const user = auth.currentUser;
                if (user) {
                    const userDoc = await getDoc(doc(db, 'Users', user.uid));
                    if (userDoc.exists()) {
                        const userData = userDoc.data();
                        setIsOwner(userData.accessLevel === 1);
                    }
                }
            } catch (err) {
                console.error('Error checking access:', err);
            } finally {
                setLoading(false);
            }
        };

        checkAccess();
    }, []);

    const [processingState, setProcessingState] = useState({
        isProcessing: false,
        progress: 0,
        message: ''
    });

    const updateProcessingState = (progress, message) => {
        setProcessingState({
            isProcessing: true,
            progress,
            message
        });
    };

    const resetProcessingState = () => {
        setProcessingState({
            isProcessing: false,
            progress: 0,
            message: ''
        });
    };

    useEffect(() => {
        const unsubscribe = onAuthStateChanged(auth, async (user) => {
            if (user) {
                setCurrentUser(user);
                const userDoc = await getDoc(doc(db, 'Users', user.uid));
                if (userDoc.exists()) {
                    const userData = userDoc.data();
                    if (userData.storeId) {
                        const storeDoc = await getDoc(doc(db, 'Stores', userData.storeId));
                        if (storeDoc.exists()) {
                            setCurrentStore({
                                id: storeDoc.id,
                                ...storeDoc.data()
                            });
                            await refreshPlanLevel();
                        }
                    }
                }
            }
            setAuthChecked(true);
        });
        return () => unsubscribe();
    }, [refreshPlanLevel]);

    useEffect(() => {
        if (currentStore?.id) {
            fetchData();
        }
    }, [currentStore, billingPeriod]);

    const fetchData = async () => {
        try {
            setPlansLoading(true);
            const [plansData, subscriptionResponse] = await Promise.all([
                fetchPlans(),
                fetchCurrentSubscription()
            ]);

            // Always set plans regardless of subscription status
            setPlans(plansData);

            if (subscriptionResponse.hasSubscription && subscriptionResponse.data) {
                setSubscription(subscriptionResponse.data);
                setHasSubscription(true);
                setShowPlans(false);
            } else {
                // Clear subscription data if there isn't one
                setSubscription(null);
                setHasSubscription(false);
                // Don't hide plans if there's no subscription
                setShowPlans(true);
            }

        } catch (error) {
            console.error('Error:', error);
            setPlans([]);
            setSubscription(null);
            setHasSubscription(false);
            // Show error to user
            setAlert({
                isOpen: true,
                title: 'Error Loading Data',
                message: 'Failed to load subscription data. Please try again.',
                type: 'error'
            });
        } finally {
            setSubscriptionLoading(false);
            setPlansLoading(false);
        }
    };

    const fetchPlans = async () => {
        try {
            const plansCollectionRef = collection(db, 'Plans');
            const plansQuery = query(
                plansCollectionRef,
                where('interval', '==', billingPeriod),
                where('status', '==', true)
            );

            const plansSnapshot = await getDocs(plansQuery);
            return plansSnapshot.docs
                .map(doc => {
                    const plan = doc.data();
                    return {
                        id: doc.id,           // Document ID for React key
                        docId: doc.id,        // Explicitly store document ID for subscription
                        name: TIER_NAMES[plan.tier],
                        amount: plan.amount,
                        amount_original: plan.amount_original || plan.amount,  // Use original amount if exists, fallback to regular amount
                        hasDiscount: plan.amount_original ? plan.amount_original > plan.amount : false,
                        features: plan.features || [],
                        tier: plan.tier,
                        planId: plan.planId,  // Paystack plan ID
                        planCode: plan.planCode,  // Paystack plan ID
                        productCode: plan.productCode,  // Paystack plan ID
                        interval: plan.interval,
                        current: plan.tier === (subscription?.tier || 1)
                    };
                })
                .sort((a, b) => a.tier - b.tier);
        } catch (error) {
            console.error('Error fetching plans:', error);
            throw error;
        }
    };

    const fetchCurrentSubscription = async () => {
        if (!currentStore?.id) {
            console.log('No store ID available');
            return { hasSubscription: false };
        }

        try {
            const storeDoc = await getDoc(doc(db, 'Stores', currentStore.id));
            if (!storeDoc.exists()) throw new Error('Store not found');

            const storeData = storeDoc.data();
            const subscriptionData = storeData.subscription;

            if (!subscriptionData || (subscriptionData.status !== 'active' && subscriptionData.status !== 'non-renewing')) {
                return { hasSubscription: false };
            }

            const billingHistoryRef = collection(db, 'Stores', currentStore.id, 'BillingHistory');
            const billingHistorySnapshot = await getDocs(billingHistoryRef);
            const billingHistory = billingHistorySnapshot.docs.map(doc => {
                const data = doc.data();
                return {
                    date: data.paidAt?.toDate().toLocaleDateString() || data.createdAt?.toDate().toLocaleDateString(),
                    total: data.amount || 0,
                    status: data.status?.toLowerCase() === 'success' ? 'Paid' :
                        (data.status?.charAt(0).toUpperCase() + data.status?.slice(1)) || 'Paid',
                    reference: doc.id
                };
            }).sort((a, b) => new Date(b.date) - new Date(a.date));

            const amount = subscriptionData.planDetails?.amount
                ? parseInt(subscriptionData.planDetails.amount)
                : 0;

            const formattedData = {
                hasSubscription: true,
                data: {
                    subscriptionId: subscriptionData.subscriptionId,
                    planId: subscriptionData.currentPlan,
                    tier: subscriptionData.planDetails?.tier || 0,
                    price: amount,
                    interval: subscriptionData.planDetails?.interval || 'monthly',
                    provider: subscriptionData.provider || 'card', // Added provider with default fallback
                    nextBillingDate: subscriptionData.nextPaymentDate?.toDate().toLocaleDateString(),
                    paymentMethod: subscriptionData.cardDetails ?
                        `${subscriptionData.cardDetails.brand} ending in ${subscriptionData.cardDetails.last4}` :
                        'No payment method',
                    status: subscriptionData.status,
                    billingHistory: billingHistory,
                    features: PLAN_FEATURES[subscriptionData.planDetails?.tier || 0],
                    startDate: subscriptionData.startDate?.toDate().toLocaleDateString(),
                    planName: subscriptionData.planDetails?.name || 'Basic Plan',
                    currency: subscriptionData.planDetails?.currency || 'GHS'
                }
            };

            console.log('Formatted subscription data:', formattedData);
            return formattedData;

        } catch (error) {
            console.error('Error checking subscription:', {
                error: error,
                errorCode: error.code,
                errorMessage: error.message,
                storeId: currentStore.id,
                storeData: currentStore
            });

            return {
                hasSubscription: false,
                error: error.message || 'Failed to fetch subscription details'
            };
        }
    };

    const handleUpgrade = (plan) => {
        setSelectedPlan(plan);
        setShowPaymentModal(true);
    };

    const handleCardUpgrade = async (plan) => {
        if (!currentStore?.id) {
            setAlert({
                isOpen: true,
                title: 'Error Loading Data',
                message: 'Store information not found. Please refresh the page.',
                type: 'error'
            });
            return;
        }

        setShowPaymentModal(false);
        setPaymentProcessing(true);
        updateProcessingState(20, 'Initializing subscription...');

        console.log('Selected plan:', plan);
        try {
            const initializeSubscriptionFunction = httpsCallable(functions, 'initializeSubscription');
            const subscriptionData = {
                planCode: plan.planCode,
                planDocId: plan.docId,
                storeId: currentStore.id,
                interval: billingPeriod
            };

            updateProcessingState(40, 'Contacting payment provider...');
            const response = await initializeSubscriptionFunction(subscriptionData);

            if (!response.data?.status || !response.data.data?.authorizationUrl) {
                throw new Error('Invalid response from payment provider');
            }

            // Instead of redirecting, set the payment URL
            setPaymentUrl(response.data.data.authorizationUrl);

        } catch (error) {
            console.error('Subscription error:', error);
            resetProcessingState();
            setPaymentProcessing(false);

            let errorMessage = 'An error occurred while processing your subscription.';
            if (error.code === 'functions/unauthenticated') {
                errorMessage = 'Please log in to continue.';
            } else if (error.code === 'functions/invalid-argument') {
                errorMessage = 'Invalid subscription details. Please try again.';
            } else if (error.code === 'functions/already-exists') {
                errorMessage = 'You already have an active subscription.';
            } else if (error.response?.data?.message) {
                errorMessage = error.response.data.message;
            }

            showToast(errorMessage, 'error');
        }
    };

    const handleMomoUpgrade = async () => {
        setShowPaymentModal(false);
        setPaymentProcessing(true);
        updateProcessingState(20, 'Initializing Mobile Money payment...');

        try {
            const initializeMomoPayment = httpsCallable(functions, 'initializeMomoPayment');
            const response = await initializeMomoPayment({
                productCode: selectedPlan.productCode,
                planDocId: selectedPlan.docId,
                storeId: currentStore.id,
                interval: selectedPlan.interval
            });

            if (!response.data?.status || !response.data.data?.authorizationUrl) {
                throw new Error('Invalid response from payment provider');
            }

            // Instead of redirecting, set the payment URL
            setPaymentUrl(response.data.data.authorizationUrl);

        } catch (error) {
            console.error('Subscription error:', error);
            resetProcessingState();
            setPaymentProcessing(false);

            let errorMessage = 'An error occurred while processing your subscription.';
            if (error.code === 'functions/unauthenticated') {
                errorMessage = 'Please log in to continue.';
            } else if (error.code === 'functions/invalid-argument') {
                errorMessage = 'Invalid subscription details. Please try again.';
            } else if (error.code === 'functions/already-exists') {
                errorMessage = 'You already have an active subscription.';
            } else if (error.response?.data?.message) {
                errorMessage = error.response.data.message;
            }

            showToast(errorMessage, 'error');
        }
    };

    // const handleMomoUpgrade = async () => {
    //     setShowPaymentModal(false);
    //     setPaymentProcessing(true);
    //     updateProcessingState(20, 'Initializing Mobile Money payment...');

    //     try {
    //         const initializeMomoPayment = httpsCallable(functions, 'initializeMomoPayment');
    //         const response = await initializeMomoPayment({
    //             productCode: 'PROD_1r6n79mws0l5lw8', // selectedPlan.productCode,
    //             planDocId: selectedPlan.docId,
    //             storeId: currentStore.id,
    //             interval: 'hourly' //billingPeriod
    //         });

    //         if (!response.data?.status || !response.data.data?.authorizationUrl) {
    //             throw new Error('Invalid response from payment provider');
    //         }

    //         updateProcessingState(80, 'Redirecting to Mobile Money payment...');
    //         await new Promise(resolve => setTimeout(resolve, 500));
    //         window.location.href = response.data.data.authorizationUrl;
    //     } catch (error) {
    //         console.error('Subscription error:', error);
    //         resetProcessingState();
    //         setPaymentProcessing(false);

    //         let errorMessage = 'An error occurred while processing your subscription.';

    //         if (error.code === 'functions/unauthenticated') {
    //             errorMessage = 'Please log in to continue.';
    //         } else if (error.code === 'functions/invalid-argument') {
    //             errorMessage = 'Invalid subscription details. Please try again.';
    //         } else if (error.code === 'functions/already-exists') {
    //             errorMessage = 'You already have an active subscription.';
    //         } else if (error.response?.data?.message) {
    //             errorMessage = error.response.data.message;
    //         }

    //         showToast(errorMessage, 'error');
    //     }
    // };

    // const handleCardUpgrade = async (plan) => {
    //     // Validate required data
    //     if (!currentStore?.id) {
    //         setAlert({
    //             isOpen: true,
    //             title: 'Error Loading Data',
    //             message: 'Store information not found. Please refresh the page.',
    //             type: 'error'
    //         });
    //         return;
    //     }

    //     console.log('Sending planId to backend:', plan.planId);

    //     // Start processing state
    //     setShowPaymentModal(false);
    //     setPaymentProcessing(true);
    //     updateProcessingState(20, 'Initializing subscription...');

    //     try {
    //         // Initialize subscription
    //         const initializeSubscriptionFunction = httpsCallable(functions, 'initializeSubscription');

    //         const subscriptionData = {
    //             planCode: 'PLN_7jav8lwk59242t3',     // Paystack plan ID plan.planCode
    //             planDocId: plan.docId,   // Firestore document ID
    //             storeId: currentStore.id,
    //             interval: billingPeriod
    //         };

    //         updateProcessingState(40, 'Contacting payment provider...');
    //         const response = await initializeSubscriptionFunction(subscriptionData);

    //         // Validate response
    //         if (!response.data?.status || !response.data.data?.authorizationUrl) {
    //             throw new Error('Invalid response from payment provider');
    //         }

    //         // Track subscription attempt if analytics is available
    //         try {
    //             if (window.analytics) {
    //                 window.analytics.track('Subscription Initiated', {
    //                     planId: plan.planId,
    //                     storeId: currentStore.id,
    //                     interval: billingPeriod,
    //                     timestamp: new Date().toISOString()
    //                 });
    //             }
    //         } catch (analyticsError) {
    //             console.error('Analytics error:', analyticsError);
    //             // Don't throw - analytics errors shouldn't stop the subscription process
    //         }

    //         // Save attempt to local storage for recovery
    //         try {
    //             localStorage.setItem('subscriptionAttempt', JSON.stringify({
    //                 planId: plan.planId,
    //                 storeId: currentStore.id,
    //                 timestamp: new Date().toISOString()
    //             }));
    //         } catch (storageError) {
    //             console.error('Storage error:', storageError);
    //         }

    //         // Prepare for redirect
    //         updateProcessingState(80, 'Redirecting to secure payment page...');

    //         // Add a small delay for smooth transition
    //         await new Promise(resolve => setTimeout(resolve, 500));

    //         // Redirect to Paystack checkout
    //         window.location.href = response.data.data.authorizationUrl;

    //     } catch (error) {
    //         console.error('Subscription error:', error);

    //         // Reset states
    //         resetProcessingState();
    //         setPaymentProcessing(false);

    //         // Determine appropriate error message
    //         let errorMessage = 'An error occurred while processing your subscription.';

    //         if (error.code === 'functions/unauthenticated') {
    //             errorMessage = 'Please log in to continue.';
    //         } else if (error.code === 'functions/invalid-argument') {
    //             errorMessage = 'Invalid subscription details. Please try again.';
    //         } else if (error.code === 'functions/already-exists') {
    //             errorMessage = 'You already have an active subscription.';
    //         } else if (error.response?.data?.message) {
    //             errorMessage = error.response.data.message;
    //         }

    //         // Show error to user
    //         showToast(errorMessage, 'error');
    //     }
    // };

    // Add this function to your SubscriptionSettings component

    const handlePaymentSuccess = async (data) => {
        setPaymentUrl(null);
        resetProcessingState();
        setPaymentProcessing(true);
        updateProcessingState(60, 'Verifying payment...');

        // If we have a reference, start monitoring the subscription status
        if (data.reference) {
            const unsubscribe = onSnapshot(doc(db, 'Stores', currentStore.id),
                async (doc) => {
                    const subscriptionData = doc.data()?.subscription;

                    if (subscriptionData?.status === 'active' || subscriptionData?.status === 'non-renewing') {
                        unsubscribe();

                        await Promise.all([
                            fetchData(),
                            refreshPlanLevel() // Add this
                        ]);

                        setShowPlans(false);
                        setHasSubscription(true);
                        resetProcessingState();
                        setPaymentProcessing(false);
                        setShowSuccessPopup(true);
                    }
                    else if (['failed', 'cancelled', 'expired'].includes(subscriptionData?.status)) {
                        unsubscribe();
                        setAlert({
                            isOpen: true,
                            title: 'Activation Failed',
                            message: 'Subscription activation failed. Please try again.',
                            type: 'error'
                        });
                        resetProcessingState();
                        setPaymentProcessing(false);
                    }
                },
                (error) => {
                    console.error('Error:', error);
                    unsubscribe();
                    setAlert({
                        isOpen: true,
                        title: 'Error Checking Subscription',
                        message: 'Unable to check subscription status. Please try again.',
                        type: 'error'
                    });
                    resetProcessingState();
                    setPaymentProcessing(false);
                }
            );

            // Set a timeout to stop listening after 2 minutes
            setTimeout(() => {
                unsubscribe();
                resetProcessingState();
                setPaymentProcessing(false);
                setAlert({
                    isOpen: true,
                    title: 'Timeout',
                    message: 'Payment verification timed out. Please check your subscription status.',
                    type: 'warning'
                });
            }, 120000);
        }
    };

    const handlePaymentFailure = (error) => {
        setPaymentUrl(null);
        resetProcessingState();
        setPaymentProcessing(false);
        showToast('Payment failed. Please try again.', 'error');
    };

    const handlePopupClose = () => {
        setPaymentUrl(null);
        resetProcessingState();
        setPaymentProcessing(false);

        // Force a page refresh
        window.location.reload();
    };

    useEffect(() => {
        const queryParams = new URLSearchParams(window.location.search);
        const reference = queryParams.get('reference');

        if (reference && currentStore?.id) {
            setProcessingState({
                isProcessing: true,
                progress: 30,
                message: 'Checking subscription status...'
            });

            const unsubscribe = onSnapshot(doc(db, 'Stores', currentStore.id),
                async (doc) => {
                    const subscriptionData = doc.data()?.subscription;

                    if (subscriptionData?.status === 'active' || subscriptionData?.status === 'non-renewing') {
                        await Promise.all([
                            fetchData(),
                            refreshPlanLevel() // Add this
                        ]);
                        setShowPlans(false);
                        setHasSubscription(true);
                        resetProcessingState();
                        setShowSuccessPopup(true);
                        window.history.replaceState({}, '', window.location.pathname);
                        unsubscribe();
                    }
                    else if (['failed', 'cancelled', 'expired'].includes(subscriptionData?.status)) {
                        setAlert({
                            isOpen: true,
                            title: 'Activation Failed',
                            message: 'Subscription activation failed. Please try again.',
                            type: 'error'
                        });
                        resetProcessingState();
                        window.history.replaceState({}, '', window.location.pathname);
                        unsubscribe();
                    }
                },
                (error) => {
                    console.error('Error:', error);
                    setAlert({
                        isOpen: true,
                        title: 'Error Checking Subscription',
                        message: 'Unable to check subscription status. Please try again.',
                        type: 'error'
                    });
                    resetProcessingState();
                    unsubscribe();
                }
            );

            return () => unsubscribe();
        }
    }, [currentStore, refreshPlanLevel]);

    // Real-time listener for store changes
    useEffect(() => {
        if (!currentStore?.id) return;

        // Initial fetch
        fetchData();
        refreshPlanLevel();

        // Set up real-time listener for store changes
        const unsubscribe = onSnapshot(
            doc(db, 'Stores', currentStore.id),
            async (snapshot) => {
                if (snapshot.exists()) {
                    const storeData = snapshot.data();
                    // Only refresh if there's an active subscription
                    if (storeData?.subscription?.status === 'active' ||
                        storeData?.subscription?.status === 'non-renewing') {
                        await Promise.all([
                            fetchData(),
                            refreshPlanLevel()
                        ]);
                    }
                }
            },
            (error) => {
                console.error('Error listening to store changes:', error);
            }
        );

        // Cleanup listener on unmount or when store changes
        return () => unsubscribe();
    }, [currentStore?.id, refreshPlanLevel]);

    if (!authChecked || !currentUser || !currentStore) {
        return (
            <div className="space-y-6">
                <div className="bg-white border p-6">
                    <div className="animate-pulse flex space-x-4">
                        <div className="flex-1 space-y-4 py-1">
                            <div className="h-4 bg-gray-200 rounded w-3/4"></div>
                            <div className="space-y-2">
                                <div className="h-4 bg-gray-200 rounded"></div>
                                <div className="h-4 bg-gray-200 rounded w-5/6"></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    if (loading) {
        return (
            <div className="mt-32 flex items-center justify-center p-6">
                <div className="text-gray-600">Loading...</div>
            </div>
        );
    }

    if (!isOwner) {
        return <AccessDenied message="Only store owners can manage subscription settings." />;
    }

    return (
        <div className="space-y-6">
            <Toast />

            {paymentUrl && (
                <PaymentPopupHandler
                    authUrl={paymentUrl}
                    onSuccess={handlePaymentSuccess}
                    onFailure={handlePaymentFailure}
                    onClose={handlePopupClose}
                />
            )}
            <SubscriptionProcessingModal
                isOpen={processingState.isProcessing}
                progress={processingState.progress}
                message={processingState.message}
            />

            <SubscriptionSuccessPopup
                isOpen={showSuccessPopup}
                onClose={() => setShowSuccessPopup(false)}
            />

            <PaymentSelectionModal
                isOpen={showPaymentModal}
                onClose={() => setShowPaymentModal(false)}
                onCardSelect={handleCardUpgrade}
                onMomoSelect={handleMomoUpgrade}
                selectedPlan={selectedPlan} // Add this line
            />

            <div className="bg-white border p-6">

                {(!hasSubscription && !subscriptionLoading) && (
                    <BillingToggle
                        billingPeriod={billingPeriod}
                        onToggle={(period) => setBillingPeriod(period)}
                        disabled={processingState.isProcessing}
                    />
                )}

                {(!hasSubscription || showPlans) && (
                    <PricingPlans
                        plans={plans}
                        onSubscribe={handleUpgrade}
                        isLoading={plansLoading}
                        billingPeriod={billingPeriod}
                    />
                )}

                {hasSubscription && !showPlans && (
                    <SubscriptionDetailsSection
                        subscription={subscription}
                        storeId={currentStore.id}
                    />
                )}
            </div>
        </div>
    );
};

export default SubscriptionSettings;