import React, { useState, useEffect } from 'react';
import { Import, X, Check, Plus } from 'lucide-react';
import { getFirestore, collection, addDoc, getDocs, query, orderBy, limit, Timestamp } from 'firebase/firestore';
import DateInputWithQuickSelect from '../date_input_select';
// import DateInputWithQuickSelect from './date_input_select';

const AddExpenseModal = ({ isOpen, onClose, onExpenseAdded }) => {
    const [loading, setLoading] = useState(false);
    const [isRecurring, setIsRecurring] = useState(false);
    const [categories, setCategories] = useState([]);
    const [loadingCategories, setLoadingCategories] = useState(true);
    const [showAddCategory, setShowAddCategory] = useState(false);
    const [newCategory, setNewCategory] = useState({
        name: '',
        description: ''
    });
    const [addingCategory, setAddingCategory] = useState(false);
    const [customPeriod, setCustomPeriod] = useState({
        value: 1,
        unit: 'days'
    });

    const db = getFirestore();
    const storeId = localStorage.getItem('storeId');

    // Fetch categories when modal opens
    useEffect(() => {
        const fetchCategories = async () => {
            if (!isOpen) return;

            try {
                setLoadingCategories(true);
                const categoriesRef = collection(db, 'Stores', storeId, 'ExpenseCategories');
                const snapshot = await getDocs(categoriesRef);
                const categoriesData = snapshot.docs.map(doc => ({
                    id: doc.id,
                    name: doc.data().name,
                    description: doc.data().description
                }));
                setCategories(categoriesData);
            } catch (error) {
                console.error('Error fetching categories:', error);
            } finally {
                setLoadingCategories(false);
            }
        };

        fetchCategories();
    }, [db, storeId, isOpen]);

    const [formData, setFormData] = useState({
        description: '',
        amount: '',
        category: '',
        date: new Date().toISOString().split('T')[0],
        paid: false,
        paymentMethod: 'cash',
        notes: '',
        recurring: false,
        recurringPeriod: 'monthly',
        nextDueDate: '',
        status: 'active'
    });

    const recurringPeriods = [
        { value: 'daily', label: 'Daily (every day)' },
        { value: 'weekly', label: 'Weekly (every week)' },
        { value: 'monthly', label: 'Monthly (every month)' },
        { value: 'quarterly', label: 'Quarterly (every 3 months)' },
        { value: 'yearly', label: 'Yearly (every year)' },
        { value: 'custom', label: 'Custom - Every...' }
    ];

    const timeUnits = [
        { value: 'days', label: 'Days' },
        { value: 'weeks', label: 'Weeks' },
        { value: 'months', label: 'Months' }
    ];

    const calculateNextDueDate = (date, period, customPeriodObj = null) => {
        const nextDate = new Date(date);

        if (period === 'custom' && customPeriodObj) {
            const { value, unit } = customPeriodObj;
            switch (unit) {
                case 'days':
                    nextDate.setDate(nextDate.getDate() + parseInt(value));
                    break;
                case 'weeks':
                    nextDate.setDate(nextDate.getDate() + (parseInt(value) * 7));
                    break;
                case 'months':
                    nextDate.setMonth(nextDate.getMonth() + parseInt(value));
                    break;
                default:
                    break;
            }
        } else {
            switch (period) {
                case 'daily':
                    nextDate.setDate(nextDate.getDate() + 1);
                    break;
                case 'weekly':
                    nextDate.setDate(nextDate.getDate() + 7);
                    break;
                case 'monthly':
                    nextDate.setMonth(nextDate.getMonth() + 1);
                    break;
                case 'quarterly':
                    nextDate.setMonth(nextDate.getMonth() + 3);
                    break;
                case 'yearly':
                    nextDate.setFullYear(nextDate.getFullYear() + 1);
                    break;
                default:
                    break;
            }
        }
        return nextDate.toISOString().split('T')[0];
    };

    // Update setIsRecurring to handle nextDueDate calculation when recurring is enabled
    const handleRecurringChange = (isChecked) => {
        setIsRecurring(isChecked);
        if (isChecked) {
            // When enabling recurring, calculate the next due date using the default period
            setFormData(prev => ({
                ...prev,
                recurring: true,
                nextDueDate: calculateNextDueDate(
                    prev.date,
                    prev.recurringPeriod,
                    prev.recurringPeriod === 'custom' ? customPeriod : null
                )
            }));
        } else {
            // When disabling recurring, clear the next due date
            setFormData(prev => ({
                ...prev,
                recurring: false,
                nextDueDate: ''
            }));
        }
    };

    // const handleSubmit = async (e) => {
    //     e.preventDefault();
    //     setLoading(true);

    //     try {
    //         // Prepare base expense data with Firebase Timestamp
    //         const expenseData = {
    //             description: formData.description,
    //             amount: parseFloat(formData.amount),
    //             category: formData.category,
    //             date: Timestamp.fromDate(new Date(formData.date)),
    //             paid: formData.paid,
    //             paymentMethod: formData.paymentMethod,
    //             notes: formData.notes,
    //             status: 'active',
    //             createdAt: Timestamp.now(),
    //             updatedAt: Timestamp.now()
    //         };

    //         // Add recurring-specific fields only for recurring expenses
    //         if (isRecurring) {
    //             expenseData.recurring = true;
    //             expenseData.recurringPeriod = formData.recurringPeriod;
    //             expenseData.nextDueDate = Timestamp.fromDate(new Date(formData.nextDueDate));

    //             // Add custom period if applicable
    //             if (formData.recurringPeriod === 'custom') {
    //                 expenseData.customPeriod = {
    //                     value: parseInt(customPeriod.value),
    //                     unit: customPeriod.unit
    //                 };
    //             }
    //         } else {
    //             expenseData.recurring = false;
    //             // Remove nextDueDate for non-recurring expenses
    //         }

    //         // Determine the correct collection path based on expense type
    //         const collectionPath = isRecurring
    //             ? collection(db, 'Stores', storeId, 'RecurringExpenses')
    //             : collection(db, 'Stores', storeId, 'Expenses');

    //         // Add document to Firebase
    //         const docRef = await addDoc(collectionPath, expenseData);

    //         // Call success callback with the new expense data
    //         onExpenseAdded({ id: docRef.id, ...expenseData }, isRecurring);

    //         // Reset form
    //         setFormData({
    //             description: '',
    //             amount: '',
    //             category: '',
    //             date: new Date().toISOString().split('T')[0],
    //             paid: false,
    //             paymentMethod: 'cash',
    //             notes: '',
    //             recurring: false,
    //             recurringPeriod: 'monthly',
    //             nextDueDate: '',
    //             status: 'active'
    //         });
    //         setIsRecurring(false);
    //         setCustomPeriod({
    //             value: 1,
    //             unit: 'days'
    //         });
    //         onClose();

    //     } catch (error) {
    //         console.error('Error adding expense:', error);
    //         alert('Failed to add expense. Please try again.');
    //     } finally {
    //         setLoading(false);
    //     }
    // };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);

        try {
            // Prepare base expense data with Firebase Timestamp
            const expenseData = {
                description: formData.description,
                amount: parseFloat(formData.amount),
                category: formData.category,
                date: Timestamp.fromDate(new Date(formData.date)),
                paid: formData.paid,
                paymentMethod: formData.paymentMethod,
                notes: formData.notes,
                status: 'active',
                createdAt: Timestamp.now(),
                updatedAt: Timestamp.now()
            };

            // Add recurring-specific fields for recurring expenses
            if (isRecurring) {
                // 1. Save the recurring expense record
                const recurringExpenseData = {
                    ...expenseData,
                    recurring: true,
                    recurringPeriod: formData.recurringPeriod,
                    nextDueDate: Timestamp.fromDate(new Date(formData.nextDueDate))
                };

                // Add custom period if applicable
                if (formData.recurringPeriod === 'custom') {
                    recurringExpenseData.customPeriod = {
                        value: parseInt(customPeriod.value),
                        unit: customPeriod.unit
                    };
                }

                // Save to RecurringExpenses collection
                const recurringExpensesRef = collection(db, 'Stores', storeId, 'RecurringExpenses');
                const recurringDocRef = await addDoc(recurringExpensesRef, recurringExpenseData);

                // 2. If the expense is marked as paid, save it as a single expense as well
                if (formData.paid) {
                    const singleExpenseData = {
                        ...expenseData,
                        recurring: false,
                        linkedRecurringExpenseId: recurringDocRef.id, // Reference to the recurring expense
                        paymentDate: Timestamp.now() // When the payment was actually made
                    };

                    // Save to regular Expenses collection
                    const expensesRef = collection(db, 'Stores', storeId, 'Expenses');
                    await addDoc(expensesRef, singleExpenseData);
                }

                // Call success callback with the recurring expense data
                onExpenseAdded({ id: recurringDocRef.id, ...recurringExpenseData }, true);
            } else {
                // Handle non-recurring expense as before
                const expensesRef = collection(db, 'Stores', storeId, 'Expenses');
                const docRef = await addDoc(expensesRef, expenseData);
                onExpenseAdded({ id: docRef.id, ...expenseData }, false);
            }

            // Reset form
            setFormData({
                description: '',
                amount: '',
                category: '',
                date: new Date().toISOString().split('T')[0],
                paid: false,
                paymentMethod: 'cash',
                notes: '',
                recurring: false,
                recurringPeriod: 'monthly',
                nextDueDate: '',
                status: 'active'
            });
            setIsRecurring(false);
            setCustomPeriod({
                value: 1,
                unit: 'days'
            });
            onClose();

        } catch (error) {
            console.error('Error adding expense:', error);
            alert('Failed to add expense. Please try again.');
        } finally {
            setLoading(false);
        }
    };

    const handleDateChange = (e) => {
        const newDate = e.target.value;
        setFormData(prev => ({
            ...prev,
            date: newDate,
            nextDueDate: isRecurring ?
                calculateNextDueDate(
                    newDate,
                    formData.recurringPeriod,
                    formData.recurringPeriod === 'custom' ? customPeriod : null
                ) : ''
        }));
    };

    const handleRecurringPeriodChange = (e) => {
        const newPeriod = e.target.value;
        setFormData(prev => ({
            ...prev,
            recurringPeriod: newPeriod,
            nextDueDate: calculateNextDueDate(
                formData.date,
                newPeriod,
                newPeriod === 'custom' ? customPeriod : null
            )
        }));
    };

    const handleCustomPeriodChange = (field, value) => {
        let updatedCustomPeriod;

        if (field === 'value') {
            // Handle empty or invalid number input
            const numValue = value === '' ? 1 : Math.max(1, parseInt(value) || 1);
            updatedCustomPeriod = { ...customPeriod, value: numValue };
        } else {
            updatedCustomPeriod = { ...customPeriod, [field]: value };
        }

        setCustomPeriod(updatedCustomPeriod);

        if (formData.recurringPeriod === 'custom') {
            setFormData(prev => ({
                ...prev,
                nextDueDate: calculateNextDueDate(formData.date, 'custom', updatedCustomPeriod)
            }));
        }
    };

    const formatDate = (dateString) => {
        const date = new Date(dateString);
        return date.toLocaleDateString('en-US', {
            weekday: 'short',
            year: 'numeric',
            month: 'short',
            day: 'numeric'
        });
    };

    const handleAddCategory = async () => {
        if (!newCategory.name.trim()) return;

        try {
            setAddingCategory(true);
            const categoryData = {
                name: newCategory.name.trim().toLowerCase(),
                description: newCategory.description.trim(),
                createdAt: new Date(),
                storeId
            };

            const categoriesRef = collection(db, 'Stores', storeId, 'ExpenseCategories');
            const docRef = await addDoc(categoriesRef, categoryData);

            // Add new category to local state
            setCategories(prev => [...prev, { id: docRef.id, ...categoryData }]);

            // Select the newly added category
            setFormData(prev => ({ ...prev, category: categoryData.name }));

            // Reset and close the inline form
            setNewCategory({ name: '', description: '' });
            setShowAddCategory(false);
        } catch (error) {
            console.error('Error adding category:', error);
            alert('Failed to add category. Please try again.');
        } finally {
            setAddingCategory(false);
        }
    };

    const categorySelect = (
        <div>
            <label className="block text-sm font-medium text-gray-700 mb-1">
                Category *
            </label>
            <div className="relative">
                {showAddCategory ? (
                    <div className="border rounded-md p-3 bg-gray-50">
                        <div className="mb-3">
                            <input
                                type="text"
                                value={newCategory.name}
                                onChange={(e) => setNewCategory(prev => ({ ...prev, name: e.target.value }))}
                                className="w-full px-3 py-2 border rounded-md focus:outline-none focus:ring-1 focus:ring-blue-500"
                                placeholder="Category name"
                            />
                        </div>
                        <div className="mb-3">
                            <input
                                type="text"
                                value={newCategory.description}
                                onChange={(e) => setNewCategory(prev => ({ ...prev, description: e.target.value }))}
                                className="w-full px-3 py-2 border rounded-md focus:outline-none focus:ring-1 focus:ring-blue-500"
                                placeholder="Description (optional)"
                            />
                        </div>
                        <div className="flex justify-end gap-2">
                            <button
                                type="button"
                                onClick={() => setShowAddCategory(false)}
                                className="px-3 py-1.5 text-sm text-gray-600 hover:text-gray-800"
                            >
                                Cancel
                            </button>
                            <button
                                type="button"
                                onClick={handleAddCategory}
                                disabled={addingCategory || !newCategory.name.trim()}
                                className="px-3 py-1.5 text-sm bg-blue-600 text-white rounded-md hover:bg-blue-700 disabled:opacity-50 flex items-center gap-1"
                            >
                                {addingCategory ? (
                                    'Adding...'
                                ) : (
                                    <>
                                        <Check className="h-4 w-4" />
                                        Add Category
                                    </>
                                )}
                            </button>
                        </div>
                    </div>
                ) : (
                    <div className="flex gap-2">
                        <select
                            required
                            value={formData.category}
                            onChange={(e) => setFormData({ ...formData, category: e.target.value })}
                            className="w-full px-3 py-2 border rounded-md focus:outline-none focus:ring-1 focus:ring-blue-500"
                            disabled={loadingCategories}
                        >
                            <option value="">Select category</option>
                            {categories.map((category) => (
                                <option key={category.id} value={category.name} className="capitalize">
                                    {category.name}
                                </option>
                            ))}
                        </select>
                        <button
                            type="button"
                            onClick={() => setShowAddCategory(true)}
                            className="px-2 py-2 text-gray-600 hover:text-gray-800 border rounded-md hover:bg-gray-50"
                            title="Add new category"
                        >
                            <Plus className="h-5 w-5" />
                        </button>
                    </div>
                )}
                {loadingCategories && (
                    <p className="text-sm text-gray-500 mt-1">Loading categories...</p>
                )}
            </div>
        </div>
    );

    const handleImportPrevious = async () => {
        try {
            setLoading(true);
            // Query the most recent expense
            const expensesRef = collection(db, 'Stores', storeId, 'Expenses');
            const q = query(expensesRef, orderBy('createdAt', 'desc'), limit(1));
            const snapshot = await getDocs(q);

            if (!snapshot.empty) {
                const lastExpense = snapshot.docs[0].data();
                // Update form data with the previous expense's details
                setFormData({
                    ...formData,
                    description: lastExpense.description,
                    amount: lastExpense.amount.toString(),
                    category: lastExpense.category,
                    paymentMethod: lastExpense.paymentMethod || 'cash',
                    notes: lastExpense.notes || '',
                });
            } else {
                alert('No previous expenses found to import.');
            }
        } catch (error) {
            console.error('Error importing previous expense:', error);
            alert('Failed to import previous expense. Please try again.');
        } finally {
            setLoading(false);
        }
    };

    if (!isOpen) return null;

    return (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
            <div className="bg-white rounded-lg w-full max-w-4xl max-h-[90vh] overflow-y-auto">
                <div className="flex justify-between items-center p-6 border-b sticky top-0 bg-white">
                    <h2 className="text-xl font-semibold">Add New Expense</h2>
                    <div className="flex items-center gap-4">
                        <button
                            onClick={handleImportPrevious}
                            disabled={loading}
                            className="text-gray-500 hover:text-gray-700 flex items-center gap-1 px-2 py-1 rounded-md hover:bg-gray-100"
                        >
                            <Import className="h-5 w-5" />
                            <span className="text-sm">Import Previous</span>
                        </button>
                        <button
                            onClick={onClose}
                            className="text-gray-500 hover:text-gray-700"
                        >
                            <X className="h-5 w-5" />
                        </button>
                    </div>
                </div>

                <form onSubmit={handleSubmit} className="p-6">
                    <div className="space-y-6">
                        {/* Description and Amount Row */}
                        <div className="grid grid-cols-2 gap-6">
                            <div>
                                <label className="block text-sm font-medium text-gray-700 mb-1">
                                    Description *
                                </label>
                                <input
                                    type="text"
                                    required
                                    value={formData.description}
                                    onChange={(e) => setFormData({ ...formData, description: e.target.value })}
                                    className="w-full px-3 py-2 border rounded-md focus:outline-none focus:ring-1 focus:ring-blue-500"
                                    placeholder="Enter expense description"
                                />
                            </div>

                            <div>
                                <label className="block text-sm font-medium text-gray-700 mb-1">
                                    Amount (GHS) *
                                </label>
                                <input
                                    type="number"
                                    required
                                    min="0"
                                    step="0.01"
                                    value={formData.amount}
                                    onChange={(e) => setFormData({ ...formData, amount: e.target.value })}
                                    className="w-full px-3 py-2 border rounded-md focus:outline-none focus:ring-1 focus:ring-blue-500"
                                    placeholder="0.00"
                                />
                            </div>
                        </div>

                        {/* Category and Date Row */}
                        <div className="grid grid-cols-2 gap-6">
                            {categorySelect}

                            {/* Payment Status & Method Row */}
                            <div className="grid grid-cols-2 gap-6">
                                <div>
                                    <label className="block text-sm font-medium text-gray-700 mb-1">
                                        Payment Method
                                    </label>
                                    <select
                                        value={formData.paymentMethod}
                                        onChange={(e) => setFormData({ ...formData, paymentMethod: e.target.value })}
                                        className="w-full px-3 py-2 border rounded-md focus:outline-none focus:ring-1 focus:ring-blue-500"
                                    >
                                        <option value="cash">Cash</option>
                                        <option value="bank">Bank Transfer</option>
                                        <option value="mobile_money">Mobile Money</option>
                                        <option value="card">Card</option>
                                        <option value="other">Other</option>
                                    </select>
                                </div>

                                <div>
                                    <label className="block text-sm font-medium text-gray-700 mb-1">
                                        Payment Status
                                    </label>
                                    <div className="flex items-center gap-2 h-[42px] px-3">
                                        <input
                                            type="checkbox"
                                            checked={formData.paid}
                                            onChange={(e) => setFormData({ ...formData, paid: e.target.checked })}
                                            className="rounded text-blue-600"
                                        />
                                        <span className="text-sm">Mark as paid</span>
                                    </div>
                                </div>
                            </div>
                        </div>

                        {/* Recurring Section */}
                        <div className="border-t pt-4">
                            <div className="mb-4">
                                <label className="flex items-center gap-2 text-sm font-medium text-gray-700">
                                    <input
                                        type="checkbox"
                                        checked={isRecurring}
                                        onChange={(e) => handleRecurringChange(e.target.checked)}
                                        className="rounded text-blue-600"
                                    />
                                    This is a recurring expense
                                </label>
                            </div>

                            {isRecurring && (
                                <>
                                    <div className="grid grid-cols-2 gap-6">
                                        <div>
                                            <label className="block text-sm font-medium text-gray-700 mb-1">
                                                Recurring Period *
                                            </label>
                                            {formData.recurringPeriod === 'custom' ? (
                                                <div className="flex gap-2">
                                                    <select
                                                        required
                                                        value={formData.recurringPeriod}
                                                        onChange={handleRecurringPeriodChange}
                                                        className="w-2/4 px-3 py-2 border rounded-md focus:outline-none focus:ring-1 focus:ring-blue-500"
                                                    >
                                                        {recurringPeriods.map(({ value, label }) => (
                                                            <option key={value} value={value}>
                                                                {label}
                                                            </option>
                                                        ))}
                                                    </select>
                                                    <input
                                                        type="number"
                                                        min="1"
                                                        value={customPeriod.value}
                                                        onChange={(e) => handleCustomPeriodChange('value', e.target.value)}
                                                        onBlur={() => {
                                                            // Ensure a valid number on blur
                                                            if (!customPeriod.value || customPeriod.value < 1) {
                                                                handleCustomPeriodChange('value', '1');
                                                            }
                                                        }}
                                                        className="w-1/4 px-3 py-2.5 border rounded-md focus:outline-none focus:ring-1 focus:ring-blue-500"
                                                        placeholder="Enter number"
                                                    />
                                                    <select
                                                        value={customPeriod.unit}
                                                        onChange={(e) => handleCustomPeriodChange('unit', e.target.value)}
                                                        className="w-1/4 px-3 py-2.5 border rounded-md focus:outline-none focus:ring-1 focus:ring-blue-500"
                                                    >
                                                        {timeUnits.map(({ value, label }) => (
                                                            <option key={value} value={value}>
                                                                {label}
                                                            </option>
                                                        ))}
                                                    </select>
                                                </div>
                                            ) : (
                                                <select
                                                    required
                                                    value={formData.recurringPeriod}
                                                    onChange={handleRecurringPeriodChange}
                                                    className="w-full px-3 py-2.5 border rounded-md focus:outline-none focus:ring-1 focus:ring-blue-500"
                                                >
                                                    {recurringPeriods.map(({ value, label }) => (
                                                        <option key={value} value={value}>
                                                            {label}
                                                        </option>
                                                    ))}
                                                </select>
                                            )}
                                        </div>

                                        <DateInputWithQuickSelect
                                            value={formData.date}
                                            onChange={handleDateChange}
                                        />
                                    </div>

                                    {formData.nextDueDate && (
                                        <p className="text-sm text-gray-600 mt-2 mb-4 float-end">
                                            Next payment will be due on <span className="font-medium">{formatDate(formData.nextDueDate)}</span>
                                        </p>
                                    )}
                                </>
                            )}
                        </div>

                        {/* Regular date field when not recurring */}
                        {!isRecurring && (
                            <div>
                                <label className="block text-sm font-medium text-gray-700 mb-1">
                                    Date *
                                </label>
                                <input
                                    type="date"
                                    required
                                    value={formData.date}
                                    onChange={handleDateChange}
                                    className="w-full px-3 py-2 border rounded-md focus:outline-none focus:ring-1 focus:ring-blue-500"
                                />
                            </div>
                        )}

                        {/* Notes Section */}
                        <div>
                            <label className="block text-sm font-medium text-gray-700 mb-1">
                                Notes
                            </label>
                            <textarea
                                value={formData.notes}
                                onChange={(e) => setFormData({ ...formData, notes: e.target.value })}
                                className="w-full px-3 py-2 border rounded-md focus:outline-none focus:ring-1 focus:ring-blue-500"
                                rows={3}
                                placeholder="Add any additional notes..."
                            />
                        </div>
                    </div>

                    <div className="mt-6 flex justify-end gap-3">
                        <button
                            type="button"
                            onClick={onClose}
                            className="px-4 py-2 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded-md hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                        >
                            Cancel
                        </button>
                        <button
                            type="submit"
                            disabled={loading}
                            className="px-4 py-2 text-sm font-medium text-white bg-blue-600 border border-transparent rounded-md hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 disabled:opacity-50 disabled:cursor-not-allowed"
                        >
                            {loading ? 'Adding...' : 'Add Expense'}
                        </button>
                    </div>
                </form>
            </div>
        </div>
    );
};

export default AddExpenseModal;