import React, { useState, useEffect } from 'react';
import { CrownIcon, Plus } from 'lucide-react';
import { useNavigate, useLocation } from 'react-router-dom';
import Sidebar from '../components/sidebar';
import InventoryOverview from '../components/inventory/inventory_overview';
import InventoryItems from '../components/inventory/Inventory_items';
import PriceHistory from '../components/inventory/inventory_price_history';
import StockHistory from '../components/inventory/inventory_stock_history';
import PriceDigest from '../components/inventory/inventory_price_digest';
import { useAuth } from '../../auth/AuthContext';

// Restricted Access Message Component
const RestrictedFeature = ({ featureName }) => (
    <div className="flex items-center justify-center p-8">
        <div className="text-center">
            <CrownIcon className="h-16 w-16 text-yellow-500 mx-auto mb-4" />
            <h2 className="text-2xl font-semibold mb-2">Pro Feature</h2>
            <p className="text-gray-600 mb-4">
                {featureName} is only available on Pro and Enterprise plans.
            </p>
            <p className="text-gray-500 text-sm">
                Please upgrade your plan to access advanced inventory features.
            </p>
        </div>
    </div>
);

const Inventory = () => {
    const { hasProAccess } = useAuth();
    const navigate = useNavigate();
    const location = useLocation();
    const [selectedTab, setSelectedTab] = useState('overview');

    const tabs = [
        {
            id: 'overview',
            name: 'Overview',
            component: <InventoryOverview />,
            requiresPro: false
        },
        {
            id: 'items',
            name: 'Inventory Items',
            component: <InventoryItems />,
            requiresPro: false
        },
        {
            id: 'stock-history',
            name: 'Stock History',
            component: hasProAccess ? <StockHistory /> : <RestrictedFeature featureName="Stock History" />,
            requiresPro: true
        },
        {
            id: 'price-history',
            name: 'Price History',
            component: hasProAccess ? <PriceHistory /> : <RestrictedFeature featureName="Price History" />,
            requiresPro: true
        },
        {
            id: 'price-digest',
            name: 'Price Issues',
            component: <PriceDigest />,
            requiresPro: false
        },
    ];

    useEffect(() => {
        const tabFromUrl = location.hash.slice(1);
        const validTab = tabs.find(tab => tab.id === tabFromUrl);
        if (validTab) {
            setSelectedTab(validTab.id);
        } else if (location.hash) {
            navigate('#overview', { replace: true });
        } else {
            navigate('#overview', { replace: true });
        }
    }, [location.hash, navigate]);

    const handleTabChange = (tabId) => {
        setSelectedTab(tabId);
        navigate(`#${tabId}`);
    };

    return (
        <div className="flex h-screen bg-white overflow-hidden">
            <Sidebar />
            <div className="flex-1 flex flex-col overflow-hidden">
                <div className="flex-1 overflow-y-auto p-8">
                    <div className="flex justify-between items-center mb-8">
                        <div className="flex items-center space-x-4">
                            <h1 className="text-3xl font-semibold">Inventory Management</h1>
                        </div>
                        <button
                            onClick={() => navigate('/inventory/add')}
                            className="bg-orange-600 text-white px-4 py-2 rounded-lg flex items-center hover:bg-orange-700 transition-colors"
                        >
                            <Plus className="h-5 w-5 mr-2" />
                            Add New Inventory Item
                        </button>
                    </div>

                    <div className="flex space-x-6 mb-8">
                        {tabs.map((tab) => (
                            <button
                                key={tab.id}
                                className={`text-lg font-medium flex items-center ${selectedTab === tab.id
                                    ? 'text-blue-600 border-b-2 border-blue-600'
                                    : 'text-gray-500 hover:text-gray-700'
                                    } ${tab.requiresPro && !hasProAccess ? 'opacity-50 cursor-not-allowed' : ''}`}
                                onClick={() => (!tab.requiresPro || hasProAccess) && handleTabChange(tab.id)}
                            >
                                {tab.name}
                                {tab.requiresPro && !hasProAccess && (
                                    <span className="ml-2 text-xs bg-gray-100 text-gray-600 px-2 py-1 rounded">
                                        Pro
                                    </span>
                                )}
                            </button>
                        ))}
                    </div>

                    <div className="space-y-8">
                        {tabs.find(tab => tab.id === selectedTab)?.component}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Inventory;