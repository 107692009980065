import React from 'react';
import phoneMockup from '../../assets/hero-screen1.png';
import analyticsMockup from '../../assets/hero-screen2.png';
import playStoreBadge from '../../assets/svgs/google-play-button-light.svg';
import appStoreBadge from '../../assets/svgs/app-store-button-light.svg';

const Hero = () => {
    return (
        <section className="relative min-h-screen">
            <div className="absolute inset-0 bg-gradient-to-br from-gray-900 via-gray-800 to-[#FF6B35] opacity-60"></div>

            <div className="relative container mx-auto px-4 pt-20 sm:pt-24 md:pt-32 pb-20 flex flex-col md:flex-row items-center justify-between min-h-screen">
                <div className="md:w-1/2 space-y-4 sm:space-y-6 md:pr-8 lg:pr-12 text-center md:text-left">
                    {/* <h2 className="text-4xl sm:text-5xl md:text-6xl font-bold leading-tight">
                        Jump Start Your Business with HotKiosk
                    </h2> */}
                    <h2 className="text-4xl sm:text-5xl md:text-6xl font-bold leading-tight">
                        Transform Your Shop Into a Profit Making Machine
                    </h2>
                    <p className="text-lg sm:text-xl text-gray-300 max-w-xl mx-auto md:mx-0">
                        The hottest sales & inventory management app for stores & retail businesses in Ghana
                    </p>
                    <div className="flex flex-row gap-4 sm:gap-6 pt-4 sm:pt-8 justify-center md:justify-start">
                        <a
                            href="https://apps.apple.com/us/app/hotkiosk/id6738089148"
                            className="transform hover:scale-105 transition duration-300"
                        >
                            <img
                                src={appStoreBadge}
                                alt="Download on the App Store"
                                className="h-12 sm:h-14 md:h-16"
                            />
                        </a>
                        <a
                            href="https://play.google.com/store/apps/details?id=com.jaydaylabs.hot_kiosk"
                            className="transform hover:scale-105 transition duration-300"
                        >
                            <img
                                src={playStoreBadge}
                                alt="Get it on Google Play"
                                className="h-12 sm:h-14 md:h-16"
                            />
                        </a>
                    </div>
                </div>

                <div className="md:w-1/2 mt-24 md:mt-0 flex justify-center items-center relative">
                    {/* Back Phone */}
                    <div className="absolute transform 
                        w-[280px] h-[635px]
                        translate-x-12 md:translate-x-32 lg:translate-x-48 
                        -translate-y-4 md:-translate-y-8 3xl:-translate-y-18 
                        rounded-xl overflow-hidden shadow-2xl opacity-75
                        ring-4 ring-white/20 ring-offset-2 ring-offset-gray-900">
                        <img
                            src={analyticsMockup}
                            alt="HotKiosk App Interface"
                            className="w-full h-full object-cover rounded-xl"
                        />
                    </div>

                    {/* Front Phone */}
                    <div className="relative w-[280px] h-[625px] rounded-[32px] overflow-hidden shadow-2xl 
                        transform hover:scale-105 transition duration-500 z-10
                        ring-4 ring-white/30 ring-offset-2 ring-offset-gray-900">
                        <img
                            src={phoneMockup}
                            alt="HotKiosk App Interface"
                            className="w-full h-full object-cover rounded-[32px]"
                        />
                    </div>
                </div>
            </div>

            <div className="absolute bottom-0 left-0 right-0 h-24 sm:h-32 bg-gradient-to-t from-gray-900 to-transparent"></div>
        </section>
    );
};

export default Hero;