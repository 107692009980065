import React, { useState, useEffect, useMemo } from 'react';
import { LineChart, Line, XAxis, YAxis, ResponsiveContainer, Tooltip, CartesianGrid, Area } from 'recharts';
import { Info, Loader2, Package, AlertTriangle, CrownIcon } from 'lucide-react';
import { getFirestore, collection, query, getDocs } from 'firebase/firestore';
import { useAuth } from '../../../auth/AuthContext';

// Restricted Access Component
const RestrictedAccess = () => (
    <div className="flex pt-16 bg-white">
        <div className="flex-1 flex items-center justify-center">
            <div className="text-center p-8">
                <CrownIcon className="h-16 w-16 text-yellow-500 mx-auto mb-4" />
                <h1 className="text-2xl font-semibold mb-2">Pro Feature</h1>
                <p className="text-gray-600 mb-4">
                    The Inventory Analytics feature is only available on Pro and Enterprise plans.
                </p>
                <p className="text-gray-500 text-sm">
                    Please upgrade your plan to access detailed inventory analytics and insights.
                </p>
            </div>
        </div>
    </div>
);

const MetricBox = ({ value, label, comparison, onClick, isSelected }) => (
    <div
        className={`text-center border-r last:border-r-0 cursor-pointer transition-colors duration-200 ${isSelected ? 'bg-blue-50' : 'hover:bg-gray-50'}`}
        onClick={onClick}
    >
        <div className="space-y-2 py-4">
            <div className="text-sm text-gray-500">{label}</div>
            <div className="text-2xl font-semibold">{value}</div>
            {comparison && <div className="text-xs text-gray-400">{comparison}</div>}
        </div>
    </div>
);

const SectionHeader = ({ title, description, bulletPoints, timeRange }) => (
    <div>
        <div className="flex justify-between items-start mb-1">
            <h3 className="text-lg font-semibold">{title}</h3>
            <div className="group relative inline-block">
                <Info className="h-4 w-4 text-gray-400 hover:text-gray-600 cursor-help" />
                <div className="opacity-0 invisible group-hover:opacity-100 group-hover:visible transition-opacity duration-200 absolute z-10 w-64 right-0 mt-2 p-4 bg-white border rounded-lg shadow-lg text-sm">
                    <p className="text-gray-600 mb-2">{description}</p>
                    {bulletPoints && (
                        <ul className="list-disc pl-4 text-gray-600 space-y-1">
                            {bulletPoints.map((point, index) => (
                                <li key={index}>{point}</li>
                            ))}
                        </ul>
                    )}
                </div>
            </div>
        </div>
        {timeRange && <div className="text-xs text-gray-500 mb-4">{timeRange}</div>}
    </div>
);

const StockLevelCard = ({ level, items, total, color }) => {
    const percentage = (items.length / total) * 100;

    return (
        <div className="bg-white pb-4 border-b border-gray-10">
            <div className="flex items-center justify-between mb-3">
                <div>
                    <h4 className="font-medium text-gray-900">{level}</h4>
                    <p className="text-sm text-gray-500">{items.length} items</p>
                </div>
                <div className={`w-12 h-12 bg-opacity-10 flex items-center justify-center`}>
                    <span className={`text-lg font-semibold ${color.replace('bg-', 'text-')}`}>
                        {percentage.toFixed(0)}%
                    </span>
                </div>
            </div>
            <div className="w-full bg-gray-100 rounded-sm h-4">
                <div
                    className={`${color} h-4 rounded-sm transition-all duration-500`}
                    style={{ width: `${percentage}%` }}
                />
            </div>
        </div>
    );
};

const InventoryReports = ({ timeRange, storeId }) => {
    const { hasProAccess } = useAuth();
    const [selectedMetric, setSelectedMetric] = useState('stockValue');
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState(null);
    const [data, setData] = useState({
        currentPeriod: [],
        previousPeriod: []
    });

    const db = getFirestore();

    useEffect(() => {
        const fetchData = async () => {
            if (!storeId) return;

            setIsLoading(true);
            try {
                const inventoryRef = collection(db, 'Stores', storeId, 'Inventory');
                const snapshot = await getDocs(inventoryRef);

                const inventoryData = snapshot.docs.map(doc => ({
                    id: doc.id,
                    ...doc.data()
                }));

                setData({
                    currentPeriod: inventoryData,
                    previousPeriod: []
                });
            } catch (err) {
                setError('Failed to load inventory data');
                console.error('Error loading inventory data:', err);
            } finally {
                setIsLoading(false);
            }
        };

        fetchData();
    }, [storeId, db]);

    const processedData = useMemo(() => {
        if (!data.currentPeriod?.length) return null;

        const metrics = data.currentPeriod.reduce((acc, item) => {
            const stockValue = (item.quantity || 0) * (item.costPrice || 0);
            const retailValue = (item.quantity || 0) * (item.sellingPrice || 0);

            return {
                stockValue: acc.stockValue + stockValue,
                retailValue: acc.retailValue + retailValue,
                totalItems: acc.totalItems + (item.quantity || 0),
                outOfStock: acc.outOfStock + (item.quantity === 0 ? 1 : 0),
                lowStock: acc.lowStock + (item.quantity <= item.reorderPoint && item.quantity > 0 ? 1 : 0)
            };
        }, { stockValue: 0, retailValue: 0, totalItems: 0, outOfStock: 0, lowStock: 0 });

        return { metrics };
    }, [data]);

    if (isLoading) {
        return (
            <div className="flex items-center justify-center h-96">
                <div className="flex flex-col items-center gap-3">
                    <Loader2 className="h-8 w-8 animate-spin text-gray-600" />
                    <div className="text-lg">Loading inventory data...</div>
                </div>
            </div>
        );
    }

    if (error) {
        return (
            <div className="flex items-center justify-center h-96 text-red-500">
                {error}
            </div>
        );
    }

    const stockLevels = [
        {
            level: 'Healthy Stock',
            items: data.currentPeriod.filter(i => i.quantity > i.reorderPoint),
            color: 'bg-green-500'
        },
        {
            level: 'Low Stock',
            items: data.currentPeriod.filter(i => i.quantity <= i.reorderPoint && i.quantity > 0),
            color: 'bg-yellow-500'
        },
        {
            level: 'Out of Stock',
            items: data.currentPeriod.filter(i => i.quantity === 0),
            color: 'bg-red-500'
        }
    ];

    if (!hasProAccess) {
        return <RestrictedAccess />;
    }

    return (
        <div className="bg-white">
            <div className="border">
                <div className="grid grid-cols-4">
                    <MetricBox
                        value={`GHS ${processedData.metrics.stockValue.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}`}
                        label="Total Stock Value"
                        comparison="At cost price"
                        onClick={() => setSelectedMetric('stockValue')}
                        isSelected={selectedMetric === 'stockValue'}
                    />
                    <MetricBox
                        value={processedData.metrics.totalItems}
                        label="Total Number of Items"
                        comparison="Units in stock"
                        onClick={() => setSelectedMetric('totalItems')}
                        isSelected={selectedMetric === 'totalItems'}
                    />
                    <MetricBox
                        value={`GHS ${processedData.metrics.retailValue.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}`}
                        label="Potential Revenue"
                        comparison="At selling price"
                        onClick={() => setSelectedMetric('retailValue')}
                        isSelected={selectedMetric === 'retailValue'}
                    />
                    <MetricBox
                        value={`${processedData.metrics.lowStock} / ${processedData.metrics.outOfStock}`}
                        label="Stock Alerts"
                        comparison="Low stock / Out of stock"
                        onClick={() => setSelectedMetric('stockAlerts')}
                        isSelected={selectedMetric === 'stockAlerts'}
                    />
                </div>
            </div>

            <div className="flex flex-col items-center justify-center gap-4 py-8">
                <div className="h-[2px] mt-12 rounded-lg w-32 bg-gray-200" />
            </div>

            <div className="grid grid-cols-2 gap-6 mt-8">
                <div className="bg-white p-6 border">
                    <SectionHeader
                        title="Stock Level Distribution"
                        description="Analyze your inventory health"
                        bulletPoints={[
                            "Track stock levels",
                            "Monitor reorder points",
                            "Identify stock-outs"
                        ]}
                        timeRange={`Last ${Math.ceil((new Date(timeRange.end) - new Date(timeRange.start)) / (1000 * 60 * 60 * 24))} days`}
                    />
                    <div className="grid gap-4 mt-6">
                        {stockLevels.map(({ level, items, color }) => (
                            <StockLevelCard
                                key={level}
                                level={level}
                                items={items}
                                total={data.currentPeriod.length}
                                color={color}
                            />
                        ))}
                    </div>
                </div>

                <div className="bg-white p-6 border">
                    <SectionHeader
                        title="Highest Value Items"
                        description="Top items by stock value"
                        bulletPoints={[
                            "Track valuable inventory",
                            "Monitor high-value stock",
                            "Identify key items"
                        ]}
                        timeRange={`Last ${Math.ceil((new Date(timeRange.end) - new Date(timeRange.start)) / (1000 * 60 * 60 * 24))} days`}
                    />
                    <div className="space-y-4 mt-6">
                        {[...data.currentPeriod]
                            .sort((a, b) => (b.quantity * b.costPrice) - (a.quantity * a.costPrice))
                            .slice(0, 5)
                            .map(item => (
                                <div key={item.id} className="flex items-center space-x-4">
                                    <div className="w-10 h-10 bg-gray-100 rounded flex items-center justify-center">
                                        <Package className="w-5 h-5 text-gray-400" />
                                    </div>
                                    <div className="flex-1">
                                        <div className="text-sm font-medium">{item.itemName}</div>
                                        <div className="text-xs text-gray-500">
                                            Value: GHS {(item.quantity * item.costPrice).toFixed(2)}
                                        </div>
                                    </div>
                                    <div className="text-sm text-gray-600">
                                        {item.quantity} units
                                    </div>
                                </div>
                            ))}
                    </div>
                </div>
            </div>
        </div>
    );
};

export { InventoryReports };