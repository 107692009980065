// UserPermissions.js
import React, { useState, useEffect } from 'react';
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import { getFirestore, doc, getDoc, collection, query, where, getDocs, updateDoc } from 'firebase/firestore';
import AccessDenied from '../access_denied_page';

const Alert = ({ message, onClose }) => (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
        <div className="bg-white rounded-lg p-6 max-w-sm w-full mx-4 shadow-xl">
            <div className="text-xl font-semibold mb-4">Action Blocked</div>
            <p className="text-gray-600 mb-6">{message}</p>
            <div className="flex justify-end">
                <button
                    onClick={onClose}
                    className="px-4 py-2 bg-orange-500 text-white rounded hover:bg-orange-700 transition-colors"
                >
                    OK
                </button>
            </div>
        </div>
    </div>
);

const PAGE_ACCESS_CONFIG = [
    {
        group: "Sales",
        pages: [
            { id: "sales", name: "Sales Overview", url: "/sales" },
            { id: "sales/add", name: "Add Sale", url: "/sales/add" },
            { id: "sales/list", name: "Sales List", url: "/sales/list" },
            { id: "sales/quotes", name: "Sales Quotes", url: "/sales/quotes" },
            { id: "sales/receipts", name: "Sales Receipts", url: "/sales/receipts" },
            { id: "sales/returns", name: "Sales Returns", url: "/sales/returns" },
            { id: "sales/invoices", name: "Sales Invoices", url: "/sales/invoices" }
        ]
    },
    {
        group: "Inventory",
        pages: [
            { id: "inventory", name: "Inventory Overview", url: "/inventory" },
            { id: "inventory/list", name: "Inventory Items", url: "/inventory/list" },
            { id: "inventory/categories", name: "Categories", url: "/inventory/categories" },
            { id: "inventory/stock", name: "Stock Management", url: "/inventory/stock" }
        ]
    },
    {
        group: "Clients",
        pages: [
            { id: "clients", name: "Clients Overview", url: "/clients" },
            { id: "clients/customers", name: "Customers", url: "/clients/customers" },
            { id: "clients/suppliers", name: "Suppliers", url: "/clients/suppliers" },
            { id: "clients/suppliers/purchase-orders", name: "Purchase Orders", url: "/clients/suppliers/purchase-orders" }
        ]
    },
    {
        group: "Expenses",
        pages: [
            { id: "expenses", name: "Expenses Overview", url: "/expenses" },
            { id: "expenses/list", name: "Expenses List", url: "/expenses/list" },
            { id: "expenses/categories", name: "Categories", url: "/expenses/categories" },
            { id: "expenses/recurring", name: "Recurring Expenses", url: "/expenses/recurring" }
        ]
    },
    {
        group: "Reports",
        pages: [
            { id: "reports", name: "Reports Overview", url: "/reports" },
            { id: "reports/analytics", name: "Analytics", url: "/reports/analytics" },
            { id: "reports/finance", name: "Financial Reports", url: "/reports/finance" },
            { id: "reports/clients", name: "Client Reports", url: "/reports/clients" }
        ]
    }
];

function PageAccessTab() {
    const [loading, setLoading] = useState(true);
    const [pageAccess, setPageAccess] = useState({
        2: {}, // Cashier access
        3: {}  // Admin access
    });
    const [selectedRole, setSelectedRole] = useState(2);
    const [error, setError] = useState(null);

    useEffect(() => {
        loadPageAccess();
    }, []);

    const loadPageAccess = async () => {
        const db = getFirestore();
        const storeId = localStorage.getItem('storeId');

        try {
            const storeDoc = await getDoc(doc(db, 'Stores', storeId));
            if (storeDoc.exists()) {
                const data = storeDoc.data();
                // Convert old format to new format if necessary
                const pageAccessData = data.pageAccess || { 2: {}, 3: {} };

                if (Array.isArray(pageAccessData[2])) {
                    // Convert array format to object format
                    const converted = {
                        2: {},
                        3: {}
                    };

                    [2, 3].forEach(role => {
                        if (Array.isArray(pageAccessData[role])) {
                            pageAccessData[role].forEach(pageId => {
                                const page = PAGE_ACCESS_CONFIG.flatMap(g => g.pages)
                                    .find(p => p.id === pageId);
                                if (page) {
                                    converted[role][page.url] = true;
                                }
                            });
                        }
                    });

                    setPageAccess(converted);
                } else {
                    setPageAccess(pageAccessData);
                }
            }
        } catch (err) {
            setError('Failed to load page access settings');
            console.error(err);
        } finally {
            setLoading(false);
        }
    };

    const handlePageAccessChange = (page, isChecked) => {
        setPageAccess(prev => ({
            ...prev,
            [selectedRole]: {
                ...prev[selectedRole],
                [page.url]: isChecked
            }
        }));
    };

    const handleSelectAll = (group, isChecked) => {
        const groupPages = PAGE_ACCESS_CONFIG.find(g => g.group === group).pages;

        setPageAccess(prev => {
            const newAccess = { ...prev[selectedRole] };
            groupPages.forEach(page => {
                newAccess[page.url] = isChecked;
            });

            return {
                ...prev,
                [selectedRole]: newAccess
            };
        });
    };

    const handleSave = async () => {
        const db = getFirestore();
        const storeId = localStorage.getItem('storeId');

        try {
            setLoading(true);
            await updateDoc(doc(db, 'Stores', storeId), {
                pageAccess
            });
        } catch (err) {
            setError('Failed to save page access settings');
            console.error(err);
        } finally {
            setLoading(false);
        }
    };

    if (loading) return <div className="text-center py-4">Loading...</div>;
    if (error) return <div className="text-red-600 py-4">{error}</div>;

    return (
        <div className="space-y-6">
            <div className="flex space-x-4 mb-6">
                <button
                    onClick={() => setSelectedRole(2)}
                    className={`px-4 py-2 rounded ${selectedRole === 2
                        ? 'bg-orange-500 text-white'
                        : 'bg-gray-100 hover:bg-gray-200'
                        }`}
                >
                    Cashier Access
                </button>
                <button
                    onClick={() => setSelectedRole(3)}
                    className={`px-4 py-2 rounded ${selectedRole === 3
                        ? 'bg-orange-500 text-white'
                        : 'bg-gray-100 hover:bg-gray-200'
                        }`}
                >
                    Admin Access
                </button>
            </div>

            <div className="bg-white border rounded-lg">
                {PAGE_ACCESS_CONFIG.map(group => (
                    <div key={group.group} className="border-b last:border-b-0">
                        <div className="flex items-center p-4 bg-gray-50">
                            <label className="flex items-center space-x-2">
                                <input
                                    type="checkbox"
                                    checked={group.pages.every(page =>
                                        pageAccess[selectedRole][page.url]
                                    )}
                                    onChange={(e) => handleSelectAll(group.group, e.target.checked)}
                                    className="rounded border-gray-300 text-orange-500 focus:ring-orange-500"
                                />
                                <span className="font-medium">{group.group}</span>
                            </label>
                        </div>
                        <div className="p-4 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
                            {group.pages.map(page => (
                                <label key={page.url} className="flex items-center space-x-2">
                                    <input
                                        type="checkbox"
                                        checked={pageAccess[selectedRole][page.url] || false}
                                        onChange={(e) => handlePageAccessChange(page, e.target.checked)}
                                        className="rounded border-gray-300 text-orange-500 focus:ring-orange-500"
                                    />
                                    <span>{page.name}</span>
                                </label>
                            ))}
                        </div>
                    </div>
                ))}
            </div>

            <div className="flex justify-end">
                <button
                    onClick={handleSave}
                    disabled={loading}
                    className="px-6 py-2 bg-orange-500 text-white rounded hover:bg-orange-600 disabled:bg-orange-300"
                >
                    {loading ? 'Saving...' : 'Save Changes'}
                </button>
            </div>
        </div>
    );
}

function UserPermissions() {
    const [activeTab, setActiveTab] = useState('users');
    const [accessLevels] = useState([
        { id: 1, name: 'Store Owner', description: 'Full access to all features and settings' },
        { id: 3, name: 'Administrator', description: 'Full access to features except owner settings' },
        { id: 2, name: 'Cashier', description: 'Can process sales and view basic reports' }
    ]);
    const generalAccessLevels = accessLevels.filter(level => level.id !== 1);

    const [loading, setLoading] = useState(true);
    const [isOwner, setIsOwner] = useState(false);
    const [error, setError] = useState(null);
    const [storeUsers, setStoreUsers] = useState([]);
    const [selectedUser, setSelectedUser] = useState(null);
    const [currentUserId, setCurrentUserId] = useState(null);
    const [showAlert, setShowAlert] = useState(false);
    const [alertMessage, setAlertMessage] = useState('');
    const [generalAccessLevel, setGeneralAccessLevel] = useState(2);

    useEffect(() => {
        const auth = getAuth();
        const db = getFirestore();
        const storeId = localStorage.getItem('storeId');

        if (!storeId) {
            setError('Store ID not found');
            setLoading(false);
            return;
        }

        const unsubscribe = onAuthStateChanged(auth, async (user) => {
            if (user) {
                try {
                    setCurrentUserId(user.uid);
                    const userDoc = await getDoc(doc(db, 'Users', user.uid));

                    if (userDoc.exists()) {
                        const userData = userDoc.data();
                        setIsOwner(userData.accessLevel === 1);

                        if (userData.accessLevel === 1) {
                            const storeUsersQuery = query(
                                collection(db, 'StoreUsers'),
                                where('storeId', '==', storeId)
                            );

                            const storeUsersSnapshot = await getDocs(storeUsersQuery);
                            const userPromises = storeUsersSnapshot.docs.map(async (storeUserDoc) => {
                                const storeUserData = storeUserDoc.data();
                                const userDetailDoc = await getDoc(doc(db, 'Users', storeUserData.userId));
                                if (userDetailDoc.exists()) {
                                    const userData = userDetailDoc.data();
                                    return {
                                        id: storeUserData.userId,
                                        email: userData.email,
                                        accessLevel: userData.accessLevel || 2,
                                        name: userData.name
                                    };
                                }
                                return null;
                            });

                            const usersData = await Promise.all(userPromises);
                            const filteredUsers = usersData.filter(user => user !== null);
                            setStoreUsers(filteredUsers);

                            if (filteredUsers.length > 0 && !selectedUser) {
                                setSelectedUser(filteredUsers[0]);
                            }
                        }
                    }
                } catch (err) {
                    console.error('Error fetching data:', err);
                    setError('Failed to fetch user data');
                }
            } else {
                setIsOwner(false);
                setCurrentUserId(null);
            }
            setLoading(false);
        });

        return () => unsubscribe();
    }, []);

    const handleAccessLevelChange = (level) => {
        if (!selectedUser) return;

        if (selectedUser.accessLevel === 1) {
            setAlertMessage("Store Owner's access level cannot be changed.");
            setShowAlert(true);
            return;
        }

        const updatedUser = {
            ...selectedUser,
            accessLevel: level
        };

        setSelectedUser(updatedUser);
        setStoreUsers(storeUsers.map(user =>
            user.id === selectedUser.id ? updatedUser : user
        ));
    };

    const handleGeneralAccessChange = async (level) => {
        const db = getFirestore();
        const storeId = localStorage.getItem('storeId');

        try {
            setLoading(true);
            const storeRef = doc(db, 'Stores', storeId);
            await updateDoc(storeRef, {
                defaultAccessLevel: level
            });
            setGeneralAccessLevel(level);
        } catch (err) {
            console.error('Error updating general access level:', err);
            setError('Failed to update general access level');
        } finally {
            setLoading(false);
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (!selectedUser) return;

        if (selectedUser.accessLevel === 1) {
            setAlertMessage("Store Owner's access level cannot be changed.");
            setShowAlert(true);
            return;
        }

        const db = getFirestore();

        try {
            setLoading(true);
            const userRef = doc(db, 'Users', selectedUser.id);
            await updateDoc(userRef, {
                accessLevel: selectedUser.accessLevel
            });
        } catch (err) {
            console.error('Error updating access level:', err);
            setError('Failed to update access level');
        } finally {
            setLoading(false);
        }
    };

    if (loading) {
        return (
            <div className="mt-32 flex items-center justify-center p-6">
                <div className="text-gray-600">Loading...</div>
            </div>
        );
    }

    if (error) {
        return (
            <div className="bg-red-50 border border-red-200 p-6 rounded">
                <div className="text-red-600">{error}</div>
            </div>
        );
    }

    if (!isOwner) {
        return <AccessDenied message="Only store owners can manage user permissions." />;;
    }

    return (
        <div className="space-y-8">
            {showAlert && (
                <Alert
                    message={alertMessage}
                    onClose={() => setShowAlert(false)}
                />
            )}

            <div className="flex space-x-4 border-b">
                <button
                    onClick={() => setActiveTab('users')}
                    className={`px-4 py-2 border-b-2 ${activeTab === 'users'
                        ? 'border-orange-500 text-orange-600'
                        : 'border-transparent hover:border-gray-300'
                        }`}
                >
                    User Management
                </button>
                <button
                    onClick={() => setActiveTab('pages')}
                    className={`px-4 py-2 border-b-2 ${activeTab === 'pages'
                        ? 'border-orange-500 text-orange-600'
                        : 'border-transparent hover:border-gray-300'
                        }`}
                >
                    Page Access
                </button>
            </div>

            {activeTab === 'users' ? (
                // User Management Content
                <>
                    <div className="bg-white">
                        <h2 className="text-xl font-semibold mb-4">Default Access Level for New Users</h2>
                        <div className="space-y-3 border p-4">
                            {generalAccessLevels.map(level => (
                                <div
                                    key={level.id}
                                    className="flex items-center justify-between p-3 bg-gray-50 rounded"
                                >
                                    <div>
                                        <span className="font-medium">{level.name}</span>
                                        <p className="text-sm text-gray-500">{level.description}</p>
                                    </div>
                                    <input
                                        type="radio"
                                        name="generalAccessLevel"
                                        checked={generalAccessLevel === level.id}
                                        onChange={() => handleGeneralAccessChange(level.id)}
                                        className="h-4 w-4 border-gray-300 text-orange-500 focus:ring-orange-400"
                                    />
                                </div>
                            ))}
                        </div>
                    </div>

                    <div>
                        <h2 className="text-xl font-semibold mb-3">Store Users & Access Levels</h2>
                        <div className="flex gap-6">
                            <div className="w-1/3 bg-white border p-4">
                                <h3 className="font-medium mb-4">Store Users</h3>
                                <div className="space-y-2">
                                    {storeUsers.map(user => (
                                        <div
                                            key={user.id}
                                            onClick={() => setSelectedUser(user)}
                                            className={`p-3 rounded cursor-pointer transition-colors ${selectedUser?.id === user.id
                                                ? 'bg-orange-50 border-orange-200 border'
                                                : 'hover:bg-gray-50 border border-transparent'
                                                }`}
                                        >
                                            <div className="font-medium">
                                                {user.email}
                                                {user.id === currentUserId && (
                                                    <span className="ml-2 text-sm text-orange-500 font-normal">(You)</span>
                                                )}
                                            </div>
                                            <div className="text-sm text-gray-500">
                                                Current Level: {accessLevels.find(level => level.id === user.accessLevel)?.name || 'Not Set'}
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </div>

                            <div className="w-2/3 bg-white border rounded p-4">
                                {selectedUser ? (
                                    <form onSubmit={handleSubmit} className="space-y-6">
                                        <div>
                                            <h3 className="font-medium mb-2">
                                                Access Level for {selectedUser.email}
                                                {selectedUser.id === currentUserId && (
                                                    <span className="ml-2 text-sm text-orange-500">(You)</span>
                                                )}
                                            </h3>
                                            <p className="text-sm text-gray-500 mb-4">Select user access level</p>
                                        </div>

                                        <div className="space-y-3">
                                            {accessLevels.map(level => (
                                                <div key={level.id}
                                                    className={`flex items-center justify-between p-3 bg-gray-50 rounded ${selectedUser.accessLevel === 1 ? 'opacity-50' : ''
                                                        }`}
                                                >
                                                    <div>
                                                        <span className="font-medium">{level.name}</span>
                                                        <p className="text-sm text-gray-500">{level.description}</p>
                                                    </div>
                                                    <input
                                                        type="radio"
                                                        name="accessLevel"
                                                        checked={selectedUser.accessLevel === level.id}
                                                        onChange={() => handleAccessLevelChange(level.id)}
                                                        disabled={selectedUser.accessLevel === 1}
                                                        className="h-4 w-4 border-gray-300 text-orange-500 focus:ring-orange-500"
                                                    />
                                                </div>
                                            ))}
                                        </div>

                                        <button
                                            type="submit"
                                            disabled={loading || selectedUser.accessLevel === 1}
                                            className="w-full bg-orange-500 text-white px-4 py-2 rounded hover:bg-orange-700 transition-colors disabled:bg-orange-300"
                                        >
                                            {loading ? 'Updating...' : 'Update Access Level'}
                                        </button>
                                    </form>
                                ) : (
                                    <div className="text-center text-gray-500 py-8">
                                        Select a user to manage their access level
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                </>
            ) : (
                // Page Access Content
                <PageAccessTab />
            )}
        </div>
    );
}

export default UserPermissions;