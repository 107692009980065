import React from 'react';
import { ArrowRight, CreditCard, Phone, Smartphone } from 'lucide-react';

const PaymentSelectionModal = ({ isOpen, onClose, onCardSelect, onMomoSelect, selectedPlan }) => {
    if (!isOpen) return null;

    const handleCardSelect = () => {
        if (selectedPlan) {
            onCardSelect(selectedPlan);
        }
    };

    const handleMomoSelect = () => {
        if (selectedPlan) {
            onMomoSelect(selectedPlan);
        }
    };

    return (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
            <div className="bg-white rounded-xl p-6 max-w-md w-full mx-4">
                <h3 className="text-lg font-semibold mb-4">Select Payment Method</h3>

                <div className="space-y-3">
                    <button
                        onClick={handleCardSelect}
                        className="w-full flex items-center justify-between p-4 border rounded-lg hover:bg-gray-50 hover:border-gray-300 transition-all"
                    >
                        <div className="flex items-start gap-3">
                            <CreditCard className="w-12 h-12 text-gray-500" />
                            <div className="text-left">
                                <div className="font-medium">Card Payment</div>
                                <div className="text-sm text-gray-500">Credit Card or Debit / ATM Card</div>
                                <div className="text-xs text-green-500">(Automatically renews)</div>
                            </div>
                        </div>
                        <ArrowRight className="w-5 h-5 text-gray-400" />
                    </button>

                    <button
                        onClick={handleMomoSelect}
                        className="w-full flex items-center justify-between p-4 border rounded-lg hover:bg-gray-50 hover:border-gray-300 transition-all"
                    >
                        <div className="flex items-start gap-3">
                            <Smartphone className="w-12 h-12 text-gray-500" />
                            <div className="text-left">
                                <div className="font-medium">Mobile Money</div>
                                <div className="text-sm text-gray-500">MTN, Vodafone, or AirtelTigo</div>
                                <div className="text-xs text-gray-500">(Will not auto-renew)</div>
                            </div>
                        </div>
                        <ArrowRight className="w-5 h-5 text-gray-400" />
                    </button>
                </div>

                <div className="mt-6 text-xs text-gray-500 flex items-start gap-2">
                    <svg className="w-4 h-4 text-gray-400 mt-0.5 flex-shrink-0" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                    </svg>
                    <span>
                        Mobile Money subscriptions require you to renew manually at the end of each subscription period.
                    </span>
                </div>

                <button
                    onClick={onClose}
                    className="mt-4 w-full px-4 py-2 text-gray-500 hover:text-gray-700 transition-colors"
                >
                    Cancel
                </button>
            </div>
        </div>
    );
};

export default PaymentSelectionModal;