import React, { useState, useEffect } from 'react';
import { signInWithEmailAndPassword, signInWithPopup, GoogleAuthProvider } from 'firebase/auth';
import { doc, getDoc } from 'firebase/firestore';
import { Loader2, Lock, AlertCircle, Mail, KeyRound, X } from 'lucide-react';
import { auth, db } from '../../firebase-config';

const AuthorizationModal = ({ isOpen, onClose, onAuthorized }) => {
    const [password, setPassword] = useState('');
    const [error, setError] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [storeOwnerEmail, setStoreOwnerEmail] = useState(null);

    const inputClassName = "mt-1 block w-full px-4 py-3 border border-gray-300 rounded-lg shadow-sm focus:outline-none focus:ring-2 focus:ring-orange-500 focus:border-orange-500 transition-colors duration-200 bg-white text-gray-900 placeholder-gray-400";
    const inputWrapperClassName = "relative flex items-center";
    const iconClassName = "w-5 h-5 text-gray-400 absolute left-3";
    const labelClassName = "block text-sm font-medium text-gray-700 mb-1";

    useEffect(() => {
        const fetchStoreOwner = async () => {
            const storeId = localStorage.getItem('storeId');
            if (!storeId) {
                setError('Store ID not found');
                return;
            }

            try {
                // First, get the ownerId from the Stores document
                const storeDoc = await getDoc(doc(db, 'Stores', storeId));
                if (!storeDoc.exists()) {
                    setError('Store not found');
                    return;
                }

                const ownerId = storeDoc.data().ownerId;
                if (!ownerId) {
                    setError('Store owner information not found');
                    return;
                }

                // Then, get the owner's email from the Users document
                const userDoc = await getDoc(doc(db, 'Users', ownerId));
                if (!userDoc.exists()) {
                    setError('Store owner details not found');
                    return;
                }

                setStoreOwnerEmail(userDoc.data().email);
            } catch (error) {
                console.error('Error fetching store owner:', error);
                setError('Failed to fetch store information');
            }
        };

        if (isOpen) {
            fetchStoreOwner();
        }
    }, [isOpen]);

    const verifyOwnership = (userEmail) => {
        if (!storeOwnerEmail) {
            throw new Error('Store owner information not available');
        }
        if (userEmail !== storeOwnerEmail) {
            throw new Error('Only the store owner can perform this action');
        }
    };

    const handlePasswordAuth = async (e) => {
        e.preventDefault();
        setIsLoading(true);
        setError('');

        try {
            const email = auth.currentUser?.email;
            if (!email) throw new Error('No authenticated user found');

            // Verify store ownership before attempting password authentication
            verifyOwnership(email);

            await signInWithEmailAndPassword(auth, email, password);
            onAuthorized();
            onClose();
        } catch (error) {
            if (error.message.includes('store owner')) {
                setError(error.message);
            } else {
                setError('Invalid password or unauthorized access. Please try again.');
            }
        } finally {
            setIsLoading(false);
        }
    };

    const handleGoogleAuth = async () => {
        setIsLoading(true);
        setError('');
        const provider = new GoogleAuthProvider();

        try {
            const currentEmail = auth.currentUser?.email;
            if (!currentEmail) throw new Error('No authenticated user found');

            // Verify store ownership before attempting Google authentication
            verifyOwnership(currentEmail);

            const result = await signInWithPopup(auth, provider);

            // Verify that the Google account matches the current user's email
            if (result.user.email !== currentEmail) {
                throw new Error('Please use the same Google account you used to sign in');
            }

            onAuthorized();
            onClose();
        } catch (error) {
            if (error.message.includes('store owner') || error.message.includes('same Google account')) {
                setError(error.message);
            } else {
                setError('Google authentication failed or unauthorized access. Please try again.');
            }
        } finally {
            setIsLoading(false);
        }
    };

    if (!isOpen) return null;

    return (
        <div className="fixed inset-0 bg-black/50 flex items-center justify-center p-4 z-50">
            <div className="bg-white rounded-lg w-full max-w-md p-6 relative">
                <button
                    onClick={onClose}
                    className="absolute right-4 top-4 text-gray-400 hover:text-gray-600 transition-colors duration-200"
                >
                    <X className="h-5 w-5" />
                </button>

                <div className="text-center mb-6">
                    <Lock className="mx-auto h-12 w-12 text-orange-600 mb-4" />
                    <h2 className="text-2xl font-semibold text-gray-900">
                        Authorize This Action
                    </h2>
                    <p className="mt-2 text-sm text-gray-600">
                        Please verify your identity to enable this action
                    </p>
                    {storeOwnerEmail && (
                        <p className="mt-1 text-xs text-gray-500">
                            Only the store owner can perform this action
                        </p>
                    )}
                </div>

                {error && (
                    <div className="mb-6 p-4 bg-red-50 rounded-lg flex items-start gap-3">
                        <AlertCircle className="w-5 h-5 text-red-500 mt-0.5" />
                        <p className="text-sm text-red-700">{error}</p>
                    </div>
                )}

                <form onSubmit={handlePasswordAuth} className="space-y-2">
                    <div>
                        <div className={inputWrapperClassName}>
                            <Mail className={iconClassName} />
                            <input
                                type="email"
                                value={auth.currentUser?.email || ''}
                                disabled
                                className={`${inputClassName} pl-10 bg-gray-300 cursor-not-allowed`}
                            />
                        </div>
                    </div>

                    <div>
                        <div className={inputWrapperClassName}>
                            <KeyRound className={iconClassName} />
                            <input
                                id="password"
                                type="password"
                                required
                                value={password}
                                onChange={(e) => setPassword(e.target.value)}
                                className={`${inputClassName} pl-10`}
                                placeholder="Enter your password"
                            />
                        </div>
                    </div>

                    <div className="pt-6">
                        <button
                            type="submit"
                            disabled={isLoading}
                            className={`w-full flex justify-center items-center py-3 px-4 rounded-lg text-white text-sm font-medium transition duration-200 ${isLoading
                                ? 'bg-orange-400 cursor-not-allowed'
                                : 'bg-orange-600 hover:bg-orange-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-orange-500'
                                }`}
                        >
                            {isLoading ? (
                                <>
                                    <Loader2 className="animate-spin -ml-1 mr-3 h-5 w-5" />
                                    Verifying...
                                </>
                            ) : (
                                'Verify with Password'
                            )}
                        </button>
                    </div>
                </form>

                <div className="relative mt-6">
                    <div className="absolute inset-0 flex items-center">
                        <div className="w-full border-t border-gray-300" />
                    </div>
                    <div className="relative flex justify-center text-sm">
                        <span className="px-2 bg-white text-gray-500">Or</span>
                    </div>
                </div>

                <div className="mt-6">
                    <button
                        onClick={handleGoogleAuth}
                        disabled={isLoading}
                        className={`w-full flex items-center justify-center py-3 px-4 rounded-lg text-gray-700 bg-white border border-gray-300 text-sm font-medium transition duration-200 ${isLoading
                            ? 'opacity-50 cursor-not-allowed'
                            : 'hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500'
                            }`}
                    >
                        <img
                            src="https://www.gstatic.com/firebasejs/ui/2.0.0/images/auth/google.svg"
                            alt="Google logo"
                            className="w-5 h-5 mr-2"
                        />
                        Verify with Google
                    </button>
                </div>
            </div>
        </div>
    );
};

export default AuthorizationModal;

