import React, { useState } from 'react';
import { X, Plus, Minus, Search, Loader2, Trash2, AlertTriangle } from 'lucide-react';
import { Timestamp } from 'firebase/firestore';
import InventoryConfirmationModal from './purchase_order_update_confirmation';

const AddItemOptions = ({ onAddInventoryItem, onAddNewItem }) => {
    return (
        <div className="flex gap-2">
            <button
                type="button"
                onClick={onAddInventoryItem}
                className="flex items-center px-3 py-2 text-blue-500 hover:text-blue-700  rounded-lg hover:bg-blue-50"
            >
                <Plus className="h-4 w-4 mr-2" />
                Add Inventory Product
            </button>
            <button
                type="button"
                onClick={onAddNewItem}
                className="flex items-center px-3 py-2 text-blue-500 hover:text-blue-700  rounded-lg hover:bg-blue-50"
            >
                <Plus className="h-4 w-4 mr-2" />
                Add Brand New Product
            </button>
        </div>
    );
};

const ProductSelectionModal = ({ products, onSelect, onClose }) => {
    const [searchTerm, setSearchTerm] = useState('');

    const filteredProducts = products.filter(product =>
        product.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
        (product.sku || '').toLowerCase().includes(searchTerm.toLowerCase())
    );

    const getStockStatus = (product) => {
        if (product.currentStock === 0) {
            return { color: 'bg-red-100 text-red-800', text: 'Out of Stock' };
        } else if (product.currentStock <= product.reorderPoint) {
            return { color: 'bg-yellow-100 text-yellow-800', text: 'Low Stock' };
        }
        return { color: 'bg-green-100 text-green-800', text: 'In Stock' };
    };

    return (
        <div className="fixed inset-0 bg-gray-600 bg-opacity-50 flex items-center justify-center z-50">
            <div className="bg-white rounded-lg p-6 w-[800px] max-h-[80vh] flex flex-col">
                <div className="flex justify-between items-center mb-4">
                    <h3 className="text-xl font-semibold">Select Product from Inventory</h3>
                    <button onClick={onClose} className="text-gray-500 hover:text-gray-700">
                        <X className="h-5 w-5" />
                    </button>
                </div>

                <div className="relative mb-4">
                    <input
                        type="text"
                        placeholder="Search products..."
                        value={searchTerm}
                        onChange={(e) => setSearchTerm(e.target.value)}
                        className="w-full pl-10 pr-4 py-2 border rounded-lg"
                    />
                    <Search className="absolute left-3 top-1/2 transform -translate-y-1/2 h-5 w-5 text-gray-400" />
                </div>

                <div className="flex-1 overflow-y-auto">
                    <div className="grid grid-cols-2 gap-4">
                        {filteredProducts.map((product) => {
                            const status = getStockStatus(product);
                            return (
                                <button
                                    key={product.id}
                                    onClick={() => onSelect(product)}
                                    className="relative p-4 border rounded-lg hover:border-blue-500 text-left flex transition-colors h-[160px]"
                                >
                                    <div className="w-24 h-24 rounded-lg overflow-hidden flex-shrink-0 bg-gray-50 mr-4">
                                        {product.imageUrl ? (
                                            <img
                                                src={product.imageUrl}
                                                alt={product.name}
                                                className="w-full h-full object-cover"
                                            />
                                        ) : (
                                            <div className="w-full h-full flex items-center justify-center text-gray-400">
                                                No Image
                                            </div>
                                        )}
                                    </div>

                                    <div className="flex-1">
                                        <div className="mb-2">
                                            <h4 className="font-medium text-gray-900">{product.name}</h4>
                                            <p className="text-sm text-gray-500">SKU: {product.sku || 'N/A'}</p>
                                        </div>

                                        <div className="text-sm text-gray-600">
                                            <p>Current Stock: {product.currentStock}</p>
                                            {product.lastPurchasePrice && (
                                                <p>Last Purchase: GHS {product.lastPurchasePrice.toFixed(2)}</p>
                                            )}
                                        </div>

                                        <div className="absolute bottom-4 right-4">
                                            <span className={`px-2 py-1 rounded-full text-xs font-medium ${status.color}`}>
                                                {status.text}
                                            </span>
                                        </div>
                                    </div>
                                </button>
                            );
                        })}
                    </div>
                </div>
            </div>
        </div>
    );
};

const OrderItem = ({ item, index, products, handleItemChange, removeItem, isRemovable }) => {
    const [showProductSelection, setShowProductSelection] = useState(false);
    const selectedProduct = item.productId ? products.find(p => p.id === item.productId) : null;
    const isNewItem = item.isNewItem;

    const handleProductSelect = (product) => {
        handleItemChange(index, {
            productId: product.id,
            quantity: item.quantity || 1,
            unitPrice: product.lastPurchasePrice || 0,
            isNewItem: false
        });
        setShowProductSelection(false);
    };

    return (
        <div className="p-3 pb-0 border rounded-lg mb-3">
            <div className="flex gap-3">
                {!isNewItem && selectedProduct?.imageUrl && (
                    <div className="w-12 h-12 rounded-lg overflow-hidden flex-shrink-0 bg-gray-50">
                        <img
                            src={selectedProduct.imageUrl}
                            alt={selectedProduct.name}
                            className="w-full h-full object-cover"
                        />
                    </div>
                )}
                <div className="flex-1">
                    {!isNewItem ? (
                        <button
                            type="button"
                            onClick={() => setShowProductSelection(true)}
                            className="w-full p-2 border rounded-lg text-left hover:border-blue-500 text-sm"
                        >
                            {selectedProduct ? (
                                <>
                                    <div className="font-medium">{selectedProduct.name}</div>
                                    <div className="text-xs text-gray-500">SKU: {selectedProduct.sku || 'N/A'} | Stock: {selectedProduct.currentStock}</div>
                                </>
                            ) : (
                                <div className="text-gray-500">Select product</div>
                            )}
                        </button>
                    ) : (
                        <div className="flex gap-2">
                            <input
                                type="text"
                                value={item.name || ''}
                                onChange={(e) => handleItemChange(index, { ...item, name: e.target.value })}
                                className="flex-1 border rounded-lg p-2 text-sm"
                                placeholder="Item name"
                                required
                            />
                            <input
                                type="text"
                                value={item.sku || ''}
                                onChange={(e) => handleItemChange(index, { ...item, sku: e.target.value })}
                                className="w-1/3 border rounded-lg p-2 text-sm"
                                placeholder="SKU (Optional)"
                            />
                        </div>
                    )}
                </div>
                <div className="flex items-center gap-2">
                    <input
                        type="number"
                        value={item.quantity || ''}
                        onChange={(e) => handleItemChange(index, { ...item, quantity: parseInt(e.target.value) || 0 })}
                        className="w-20 border rounded-lg p-2 text-sm"
                        placeholder="Qty"
                        required
                        min="1"
                    />
                    <input
                        type="number"
                        value={item.unitPrice || ''}
                        onChange={(e) => handleItemChange(index, { ...item, unitPrice: parseFloat(e.target.value) || 0 })}
                        className="w-24 border rounded-lg p-2 text-sm"
                        placeholder="Price"
                        required
                        min="0.01"
                        step="0.01"
                    />
                    {isRemovable && (
                        <button
                            type="button"
                            onClick={() => removeItem(index)}
                            className="p-2 text-red-500 hover:text-red-700"
                        >
                            <Minus className="h-4 w-4" />
                        </button>
                    )}
                </div>
            </div>

            <div className="flex justify-between text-xs text-gray-500 mt-2">
                {!isNewItem && selectedProduct && (
                    <div className="flex justify-between mb-2">
                        {/* <div>Available: {selectedProduct.currentStock}</div> */}
                        {selectedProduct.lastPurchasePrice && (
                            <div>Last price: GHS {selectedProduct.lastPurchasePrice.toFixed(2)}</div>
                        )}
                    </div>
                )}

                {item.quantity > 0 && item.unitPrice > 0 && (
                    <div className="text-right">
                        Total: GHS {(item.quantity * item.unitPrice).toFixed(2)}
                    </div>
                )}
            </div>

            {showProductSelection && (
                <ProductSelectionModal
                    products={products}
                    onSelect={handleProductSelect}
                    onClose={() => setShowProductSelection(false)}
                />
            )}
        </div>
    );
};

export const CreateOrderModal = ({
    suppliers,
    products,
    formData,
    setFormData,
    onSubmit,
    onClose,
    isSubmitting
}) => {
    const handleItemChange = (index, newItemData) => {
        const newItems = [...formData.items];
        if (typeof newItemData === 'object') {
            newItems[index] = { ...newItems[index], ...newItemData };
        }
        setFormData({ ...formData, items: newItems });
    };

    const addInventoryItem = () => {
        setFormData({
            ...formData,
            items: [...formData.items, { productId: '', quantity: 0, unitPrice: 0, isNewItem: false }]
        });
    };

    const addNewItem = () => {
        setFormData({
            ...formData,
            items: [...formData.items, {
                name: '',
                sku: '',
                quantity: 0,
                unitPrice: 0,
                isNewItem: true
            }]
        });
    };

    const removeItem = (index) => {
        const newItems = formData.items.filter((_, i) => i !== index);
        setFormData({ ...formData, items: newItems });
    };

    // Calculate order total
    const orderTotal = formData.items.reduce((sum, item) => {
        return sum + (item.quantity || 0) * (item.unitPrice || 0);
    }, 0);

    return (
        <div className="fixed inset-0 bg-gray-600 bg-opacity-50 flex items-center justify-center">
            <div className="bg-white rounded-lg p-8 max-w-2xl w-full max-h-[90vh] overflow-y-auto">
                <div className="flex justify-between items-center mb-6">
                    <h2 className="text-2xl font-semibold">Create Purchase Order</h2>
                    <button onClick={onClose} className="text-gray-500 hover:text-gray-700">
                        <X className="h-6 w-6" />
                    </button>
                </div>

                <form onSubmit={(e) => {
                    e.preventDefault();
                    onSubmit();
                }}>
                    <div className="space-y-4">
                        {/* Supplier Selection */}
                        <div>
                            <label className="block text-sm font-medium text-gray-700 mb-1">
                                Select Supplier
                            </label>
                            <select
                                value={formData.supplierId}
                                onChange={(e) => setFormData({ ...formData, supplierId: e.target.value })}
                                className="w-full border rounded-lg p-2"
                                required
                            >
                                <option value="">Select a supplier</option>
                                {suppliers.map((supplier) => (
                                    <option key={supplier.id} value={supplier.id}>
                                        {supplier.name}
                                    </option>
                                ))}
                            </select>
                        </div>

                        {/* Expected Delivery Date */}
                        <div>
                            <label className="block text-sm font-medium text-gray-700 mb-1">
                                Expected Delivery Date
                            </label>
                            <input
                                type="date"
                                value={formData.expectedDeliveryDate}
                                onChange={(e) => setFormData({ ...formData, expectedDeliveryDate: e.target.value })}
                                className="w-full border rounded-lg p-2"
                                required
                            />
                        </div>

                        {/* Order Items */}
                        <div>
                            <label className="block text-sm font-medium text-gray-700 mb-1">
                                Order Items
                            </label>
                            {formData.items.map((item, index) => (
                                <OrderItem
                                    key={index}
                                    item={item}
                                    index={index}
                                    products={products}
                                    handleItemChange={handleItemChange}
                                    removeItem={removeItem}
                                    isRemovable={formData.items.length > 1}
                                />
                            ))}
                            <AddItemOptions
                                onAddInventoryItem={addInventoryItem}
                                onAddNewItem={addNewItem}
                            />
                        </div>

                        {/* Order Total */}
                        <div className="border-t pt-4">
                            <div className="flex justify-between items-center text-lg">
                                <span className="font-medium">Order Total:</span>
                                <span className="font-bold">GHS {orderTotal.toFixed(2)}</span>
                            </div>
                        </div>

                        {/* Notes */}
                        <div>
                            <label className="block text-sm font-medium text-gray-700 mb-1">
                                Notes
                            </label>
                            <textarea
                                value={formData.notes}
                                onChange={(e) => setFormData({ ...formData, notes: e.target.value })}
                                className="w-full border rounded-lg p-2"
                                rows="3"
                                placeholder="Add any notes or instructions..."
                            />
                        </div>

                        {/* Updated Submit Button */}
                        <div className="flex justify-end mt-6">
                            <button
                                type="submit"
                                disabled={isSubmitting}
                                className={`flex items-center justify-center px-4 py-2 rounded-lg ${isSubmitting
                                    ? 'bg-blue-400 cursor-not-allowed'
                                    : 'bg-blue-500 hover:bg-blue-600'
                                    } text-white min-w-[120px]`}
                            >
                                {isSubmitting ? (
                                    <>
                                        <Loader2 className="animate-spin h-5 w-5 mr-2" />
                                        Creating...
                                    </>
                                ) : (
                                    'Create Order'
                                )}
                            </button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    );
};

export const OrderDetailsModal = ({ order, suppliers, products, onClose, onUpdateStatus }) => {
    const [isUpdating, setIsUpdating] = useState(false);
    const [showConfirmation, setShowConfirmation] = useState(false);
    const [pendingStatus, setPendingStatus] = useState(null);

    const handleStatusUpdate = async (orderId, newStatus) => {
        // Only show confirmation for receive actions that have inventory products
        if (newStatus === 'received_paid' || newStatus === 'received_unpaid') {
            const hasInventoryItems = order.items.some(item => item.productId);
            if (hasInventoryItems) {
                setShowConfirmation(true);
                setPendingStatus(newStatus);
            } else {
                // If no inventory items, proceed directly
                setIsUpdating(true);
                try {
                    await onUpdateStatus(orderId, newStatus);
                    onClose();
                } catch (error) {
                    console.error('Error updating status:', error);
                } finally {
                    setIsUpdating(false);
                }
            }
        } else {
            // For other status changes (like cancelled), proceed normally
            setIsUpdating(true);
            try {
                await onUpdateStatus(orderId, newStatus);
                onClose();
            } catch (error) {
                console.error('Error updating status:', error);
            } finally {
                setIsUpdating(false);
            }
        }
    };

    const formatModalDate = (timestamp) => {
        if (!timestamp) return 'N/A';
        try {
            if (timestamp instanceof Timestamp) {
                return timestamp.toDate().toLocaleDateString();
            }
            return new Date(timestamp).toLocaleDateString();
        } catch (error) {
            console.error('Error formatting date:', error);
            return 'Invalid Date';
        }
    };

    const handleConfirmUpdate = async () => {
        setIsUpdating(true);
        try {
            await onUpdateStatus(order.id, pendingStatus);
            onClose();
        } catch (error) {
            console.error('Error updating status:', error);
        } finally {
            setIsUpdating(false);
            setShowConfirmation(false);
        }
    };

    const supplier = suppliers.find(s => s.id === order.supplierId);
    const total = order.items.reduce((sum, item) => sum + (item.quantity * item.unitPrice), 0);

    const getStatusBadgeColor = (status) => {
        switch (status) {
            case 'pending': return 'bg-yellow-100 text-yellow-800';
            case 'received_unpaid': return 'bg-blue-100 text-blue-800';
            case 'received_paid': return 'bg-green-100 text-green-800';
            case 'cancelled': return 'bg-red-100 text-red-800';
            default: return 'bg-gray-100 text-gray-800';
        }
    };

    const getStatusDisplay = (status) => {
        switch (status) {
            case 'pending': return 'Pending';
            case 'received_unpaid': return 'Received - Unpaid';
            case 'received_paid': return 'Received & Paid';
            case 'cancelled': return 'Cancelled';
            default: return status;
        }
    };

    return (
        <div className="fixed inset-0 bg-gray-600 bg-opacity-50 flex items-center justify-center z-50">
            <div className="bg-white rounded-lg p-6 max-w-3xl w-full max-h-[90vh] overflow-y-auto">
                <div className="flex justify-between items-center mb-6 sticky top-0 bg-white pb-4 border-b">
                    <div>
                        <h2 className="text-2xl font-semibold">Order Details</h2>
                        <p className="text-sm text-gray-500 mt-1">Order #{order.orderNumber}</p>
                    </div>
                    <button onClick={onClose} className="text-gray-500 hover:text-gray-700 p-2 rounded-lg hover:bg-gray-100">
                        <X className="h-6 w-6" />
                    </button>
                </div>

                <div className="space-y-8">
                    {order.status === 'received_unpaid' && (
                        <div className="bg-blue-50 p-4 rounded-lg space-y-4">
                            <div className="flex items-center gap-2 text-sm text-blue-600">
                                <AlertTriangle className="h-5 w-5" />
                                <span>This order has been received but payment is pending.</span>
                            </div>
                            <button
                                onClick={() => handleStatusUpdate(order.id, 'received_paid')}
                                className="bg-green-500 text-white px-4 py-2 rounded-lg hover:bg-green-600 w-full"
                                disabled={isUpdating}
                            >
                                Mark as Paid
                            </button>
                        </div>
                    )}

                    <div className="grid grid-cols-2 gap-6">
                        <div className="space-y-4">
                            <div>
                                <h3 className="text-sm font-medium text-gray-500">Status</h3>
                                <span className={`mt-1 px-3 py-1 inline-flex text-sm font-medium ${getStatusBadgeColor(order.status)}`}>
                                    {getStatusDisplay(order.status)}
                                </span>
                            </div>
                            <div>
                                <h3 className="text-sm font-medium text-gray-500">Supplier</h3>
                                <p className="mt-1">{supplier?.name}</p>
                            </div>
                        </div>
                        <div className="space-y-4">
                            <div>
                                <h3 className="text-sm font-medium text-gray-500">Order Date</h3>
                                <p className="mt-1">{formatModalDate(order.createdAt)}</p>
                            </div>
                            <div>
                                <h3 className="text-sm font-medium text-gray-500">Expected Delivery</h3>
                                <p className="mt-1">{formatModalDate(order.expectedDeliveryDate)}</p>
                            </div>
                            {order.receivedDate && (
                                <div>
                                    <h3 className="text-sm font-medium text-gray-500">Received Date</h3>
                                    <p className="mt-1">{formatModalDate(order.receivedDate)}</p>
                                </div>
                            )}
                            {order.paidDate && (
                                <div>
                                    <h3 className="text-sm font-medium text-gray-500">Payment Date</h3>
                                    <p className="mt-1">{formatModalDate(order.paidDate)}</p>
                                </div>
                            )}
                        </div>
                    </div>

                    <div>
                        <h3 className="text-lg font-medium mb-4">Order Items</h3>
                        <div className="bg-white border overflow-hidden p-4">
                            <table className="w-full">
                                <thead className="bg-gray-100">
                                    <tr>
                                        <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase">Item</th>
                                        <th className="px-6 py-3 text-right text-xs font-medium text-gray-500 uppercase">Quantity</th>
                                        <th className="px-6 py-3 text-right text-xs font-medium text-gray-500 uppercase">Unit Price</th>
                                        <th className="px-6 py-3 text-right text-xs font-medium text-gray-500 uppercase">Total</th>
                                    </tr>
                                </thead>
                                <tbody className="divide-y divide-gray-200">
                                    {order.items.map((item, index) => {
                                        const product = products.find(p => p.id === item.productId);
                                        return (
                                            <tr key={index}>
                                                <td className="px-6 py-4">
                                                    <div className="font-medium text-gray-900">{product?.name || item.name}</div>
                                                    {product?.sku && <div className="text-sm text-gray-500">SKU: {product.sku}</div>}
                                                </td>
                                                <td className="px-6 py-4 text-right text-gray-500">{item.quantity}</td>
                                                <td className="px-6 py-4 text-right text-gray-500">GHS {item.unitPrice.toFixed(2)}</td>
                                                <td className="px-6 py-4 text-right text-gray-900 font-medium">
                                                    GHS {(item.quantity * item.unitPrice).toFixed(2)}
                                                </td>
                                            </tr>
                                        );
                                    })}
                                </tbody>
                                <tfoot className="bg-gray-50">
                                    <tr>
                                        <td colSpan="3" className="px-6 py-4 text-right font-medium">Total</td>
                                        <td className="px-6 py-4 text-right text-gray-900 font-semibold">
                                            GHS {total.toFixed(2)}
                                        </td>
                                    </tr>
                                </tfoot>
                            </table>
                        </div>
                    </div>

                    {order.notes && (
                        <div>
                            <h3 className="text-lg font-medium mb-2">Notes</h3>
                            <div className="bg-gray-50 rounded-lg p-4">
                                <p className="text-gray-600 whitespace-pre-wrap">{order.notes}</p>
                            </div>
                        </div>
                    )}

                    {order.status === 'pending' && (
                        <div className="flex flex-wrap gap-3">
                            <button
                                onClick={() => handleStatusUpdate(order.id, 'cancelled')}
                                disabled={isUpdating}
                                className="bg-red-500 text-white px-4 py-2.5 rounded-lg hover:bg-red-600 flex-1 min-w-[200px] disabled:opacity-50"
                            >
                                Cancel Order
                            </button>
                            <button
                                onClick={() => handleStatusUpdate(order.id, 'received_unpaid')}
                                disabled={isUpdating}
                                className="bg-blue-500 text-white px-4 py-2.5 rounded-lg hover:bg-blue-600 flex-1 min-w-[200px] disabled:opacity-50"
                            >
                                Items Received (Unpaid)
                            </button>
                            <button
                                onClick={() => handleStatusUpdate(order.id, 'received_paid')}
                                disabled={isUpdating}
                                className="bg-green-500 text-white px-4 py-2.5 rounded-lg hover:bg-green-600 flex-1 min-w-[200px] disabled:opacity-50"
                            >
                                Items Received & Paid
                            </button>
                        </div>
                    )}
                </div>
            </div>

            {showConfirmation && (
                <InventoryConfirmationModal
                    isOpen={showConfirmation}
                    onClose={() => setShowConfirmation(false)}
                    onConfirm={handleConfirmUpdate}
                    order={order}
                    products={products}
                    isPaid={pendingStatus === 'received_paid'}
                    isSubmitting={isUpdating}
                />
            )}
        </div>
    );
};