import React from 'react';
import { ShieldAlert } from 'lucide-react';

const AccessDenied = ({ message }) => {
    return (
        <div className="mt-32 flex flex-col items-center justify-center p-6 text-center">
            <div className="mb-6">
                <ShieldAlert className="h-16 w-16 text-red-500" />
            </div>
            <div className="bg-red-50 border border-red-200 p-6 rounded-lg max-w-md">
                <h2 className="text-lg font-semibold text-red-700 mb-2">Access Denied</h2>
                <div className="text-red-600">
                    {message || "You don't have permission to view this page."}
                </div>
            </div>
        </div>
    );
};

export default AccessDenied;