import React, { useEffect } from 'react';
import { Archive, Trash2, RefreshCw, User2, Package } from 'lucide-react';

const DRAFT_COLORS = [
    { bg: 'bg-green-100 hover:bg-green-200 border-green-300', text: 'text-green-800', shadow: 'shadow-green-100' },
    { bg: 'bg-orange-100 hover:bg-orange-200 border-orange-300', text: 'text-orange-800', shadow: 'shadow-orange-100' },
    { bg: 'bg-purple-100 hover:bg-purple-200 border-purple-300', text: 'text-purple-800', shadow: 'shadow-purple-100' },
    { bg: 'bg-pink-100 hover:bg-pink-200 border-pink-300', text: 'text-pink-800', shadow: 'shadow-pink-100' },
    { bg: 'bg-cyan-100 hover:bg-cyan-200 border-cyan-300', text: 'text-cyan-800', shadow: 'shadow-cyan-100' },
];

const DEFAULT_COLOR = DRAFT_COLORS[0];

const DraftManager = ({
    drafts,
    setDrafts,
    currentSale,
    loadDraft,
    clearCurrentSale,
    customer,
    isCreditSale,
    className
}) => {
    const MAX_DRAFTS = 5;

    // Ensure all drafts have valid color indices
    useEffect(() => {
        const needsColorFix = drafts.some(draft =>
            draft.colorIndex === undefined ||
            draft.colorIndex < 0 ||
            draft.colorIndex >= DRAFT_COLORS.length
        );

        if (needsColorFix) {
            const updatedDrafts = drafts.map((draft, index) => ({
                ...draft,
                colorIndex: index % DRAFT_COLORS.length
            }));
            setDrafts(updatedDrafts);
        }
    }, [drafts, setDrafts]);

    const getNextColorIndex = () => {
        const usedColors = new Set(drafts.map(d => d.colorIndex));
        for (let i = 0; i < DRAFT_COLORS.length; i++) {
            if (!usedColors.has(i)) {
                return i;
            }
        }
        return drafts.length % DRAFT_COLORS.length;
    };

    const getColorSet = (colorIndex) => {
        if (colorIndex === undefined || colorIndex < 0 || colorIndex >= DRAFT_COLORS.length) {
            return DEFAULT_COLOR;
        }
        return DRAFT_COLORS[colorIndex];
    };

    const saveDraft = () => {
        if (drafts.length >= MAX_DRAFTS) {
            alert('Maximum number of drafts reached (5). Please complete or remove existing drafts.');
            return;
        }

        if (!currentSale.length) {
            alert('No items in current sale to save as draft.');
            return;
        }

        const newDraft = {
            id: Date.now(),
            items: currentSale,
            timestamp: new Date(),
            customer: customer,
            isCreditSale: isCreditSale,
            total: currentSale.reduce((sum, item) => sum + (item.sellingPrice * item.quantity), 0),
            colorIndex: getNextColorIndex()
        };

        setDrafts([...drafts, newDraft]);
        clearCurrentSale();
    };

    const deleteDraft = (draftId, event) => {
        event.stopPropagation();
        if (window.confirm('Are you sure you want to delete this draft?')) {
            setDrafts(drafts.filter(draft => draft.id !== draftId));
        }
    };

    const resumeDraft = (draft) => {
        if (currentSale.length > 0) {
            if (!window.confirm('Loading a draft will clear your current sale. Continue?')) {
                return;
            }
        }
        loadDraft(draft);
        setDrafts(drafts.filter(d => d.id !== draft.id));
    };

    if (!drafts.length) return null;

    return (
        <div className={`${className} border-t border-gray-200 pt-3`}>
            <div className="flex items-center gap-2 overflow-x-auto pb-3">
                {drafts.map((draft, index) => {
                    const colorSet = getColorSet(draft.colorIndex);

                    return (
                        <div
                            key={draft.id}
                            onClick={() => resumeDraft(draft)}
                            className={`flex-shrink-0 w-52 p-2 rounded-lg cursor-pointer transition-all 
                                hover:shadow-lg border-2 ${colorSet.bg} ${colorSet.shadow}
                                transform hover:scale-105 transition-transform duration-150`}
                        >
                            <div className="flex items-center justify-between mb-1.5">
                                <span className={`text-sm font-bold ${colorSet.text}`}>
                                    Draft {index + 1}
                                </span>
                                <div className="flex items-center gap-1">
                                    {draft.isCreditSale && (
                                        <span className="text-xs px-1.5 py-0.5 rounded-full bg-yellow-200 text-yellow-800 border border-yellow-300 font-bold">
                                            Credit
                                        </span>
                                    )}
                                    <button
                                        onClick={(e) => deleteDraft(draft.id, e)}
                                        className="p-1 rounded-full text-red-600 hover:bg-red-100"
                                    >
                                        <Trash2 size={14} />
                                    </button>
                                </div>
                            </div>

                            <div className="mb-1.5">
                                {draft.customer ? (
                                    <div className="flex items-center gap-1 text-xs bg-white bg-opacity-70 px-2 py-1 rounded-md border border-gray-200">
                                        <User2 size={12} />
                                        <span className="truncate max-w-[120px] font-semibold">{draft.customer.name}</span>
                                    </div>
                                ) : (
                                    <div className="flex items-center gap-1 text-xs bg-white bg-opacity-70 px-2 py-1 rounded-md border border-gray-200">
                                        <Package size={12} />
                                        <span className="font-semibold">{draft.items.length} items</span>
                                    </div>
                                )}
                            </div>

                            <div className="flex items-center justify-between text-xs">
                                <span className={`font-bold ${colorSet.text}`}>
                                    GHS {draft.total.toFixed(2)}
                                </span>
                                <span className="font-medium text-gray-600 bg-white bg-opacity-50 px-1.5 py-0.5 rounded-md">
                                    {new Date(draft.timestamp).toLocaleTimeString()}
                                </span>
                            </div>
                        </div>
                    );
                })}
            </div>
        </div>
    );
};

export default DraftManager;