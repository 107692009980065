import React, { useState, useCallback, useEffect, useRef } from 'react';
import { useNavigate, useLocation, Link } from 'react-router-dom';
import { auth } from '../../firebase-config';
import { signInWithEmailAndPassword, signInWithPopup, GoogleAuthProvider } from 'firebase/auth';
import { doc, getDoc, getFirestore } from 'firebase/firestore';
import { Loader2, Mail, Lock, AlertCircle, WifiOff } from 'lucide-react';
import { useToast } from '../screens/components/toast';
import businessOwner from '../../assets/business-owner.png';
import logo from '../../assets/hotKioskLogocolor.png';

// Add logging utility
const logEvent = (event, details = {}) => {
    const timestamp = new Date().toISOString();
    const logEntry = {
        timestamp,
        event,
        ...details
    };
    console.log('🔍 Login Flow:', logEntry);
};

function Login() {
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const navigateAttempted = useRef(false);
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [isOnline, setIsOnline] = useState(navigator.onLine);
    const navigate = useNavigate();
    const db = getFirestore();
    const { showToast } = useToast();
    const previousOnlineState = useRef(navigator.onLine);
    const loginAttemptRef = useRef(null);
    const mounted = useRef(true);
    const loginReason = searchParams.get('reason');

    useEffect(() => {
        // Handle unauthorized redirect
        if (loginReason === 'unauthorized') {
            const message = 'Your session has expired. Please sign in again to continue.';
            setError(message);
            showToast(message, 'warning');
        }

        logEvent('Component_Mounted', { loginReason });
        return () => {
            logEvent('Component_Unmounted', {
                navigateAttempted: navigateAttempted.current,
                isLoading: isLoading,
                hasError: !!error,
                loginReason
            });
        };
    }, [loginReason]);

    useEffect(() => {
        logEvent('Component_Mounted');
        return () => {
            logEvent('Component_Unmounted', {
                navigateAttempted: navigateAttempted.current,
                isLoading: isLoading,
                hasError: !!error
            });
        };
    }, []);

    useEffect(() => {
        mounted.current = true;
        return () => {
            mounted.current = false;
            // Clean up any pending login attempts
            if (loginAttemptRef.current) {
                loginAttemptRef.current.abort();
            }
        };
    }, []);

    useEffect(() => {
        const handleOnline = () => {
            setIsOnline(true);
            if (!previousOnlineState.current) {
                showToast('Internet connection restored', 'success');
            }
            previousOnlineState.current = true;
        };

        const handleOffline = () => {
            setIsOnline(false);
            if (previousOnlineState.current) {
                showToast('No internet connection', 'error', 0);
            }
            previousOnlineState.current = false;
        };

        window.addEventListener('online', handleOnline);
        window.addEventListener('offline', handleOffline);

        setIsOnline(navigator.onLine);
        previousOnlineState.current = navigator.onLine;

        return () => {
            window.removeEventListener('online', handleOnline);
            window.removeEventListener('offline', handleOffline);
        };
    }, []);

    const resetForm = useCallback(() => {
        if (!mounted.current) return;
        setIsLoading(false);
        setError('');
    }, []);

    const timeoutPromise = (promise, timeoutMs = 10000, abortController) => {
        const timeoutPromise = new Promise((_, reject) => {
            const timeout = setTimeout(() => {
                clearTimeout(timeout);
                reject(new Error('Request timed out'));
            }, timeoutMs);
        });

        return Promise.race([
            promise,
            timeoutPromise,
            new Promise((_, reject) => {
                if (abortController) {
                    abortController.signal.addEventListener('abort', () => {
                        reject(new Error('Request aborted'));
                    });
                }
            })
        ]);
    };

    const saveUserData = useCallback(async (userId, abortController) => {
        let retries = 3;
        while (retries > 0 && mounted.current) {
            try {
                showToast('Loading your account data...', 'info');
                const userDocRef = doc(db, 'Users', userId);
                const userDoc = await timeoutPromise(getDoc(userDocRef), 10000, abortController);

                if (!mounted.current) return false;

                if (userDoc.exists()) {
                    const userData = userDoc.data();
                    localStorage.setItem('userId', userId);
                    localStorage.setItem('storeId', userData.storeId || '');
                    showToast('Account data loaded successfully', 'success');
                    return true;
                } else {
                    console.warn('User document not found');
                    showToast('Account created without store data', 'info');
                    localStorage.setItem('userId', userId);
                    return true;
                }
            } catch (error) {
                if (!mounted.current) return false;
                retries--;
                console.error(`Error fetching user data (${retries} retries left):`, error);

                if (error.name === 'AbortError' || !mounted.current) {
                    throw new Error('Operation cancelled');
                }

                if (retries > 0) {
                    showToast(`Retrying to load account data... (${retries} attempts left)`, 'info');
                    await new Promise(resolve => setTimeout(resolve, 1000));
                } else {
                    throw error;
                }
            }
        }
        return false;
    }, [db, showToast]);

    // const handleLogin = async (e) => {
    //     e.preventDefault();

    //     if (!isOnline) {
    //         showToast('Cannot log in: No internet connection', 'error');
    //         return;
    //     }

    //     // Clean up any existing login attempt
    //     if (loginAttemptRef.current) {
    //         loginAttemptRef.current.abort();
    //     }

    //     // Create new abort controller for this attempt
    //     loginAttemptRef.current = new AbortController();

    //     try {
    //         setIsLoading(true);
    //         setError('');
    //         showToast('Signing in...', 'info');

    //         const userCredential = await timeoutPromise(
    //             signInWithEmailAndPassword(auth, email, password),
    //             10000,
    //             loginAttemptRef.current
    //         );

    //         if (!mounted.current) return;

    //         try {
    //             const dataSaved = await saveUserData(userCredential.user.uid, loginAttemptRef.current);
    //             if (!mounted.current) return;

    //             if (dataSaved) {
    //                 showToast('Successfully signed in!', 'success');
    //                 navigate('/dashboard');
    //             } else {
    //                 showToast('Signed in with limited access', 'info');
    //                 navigate('/dashboard');
    //             }
    //         } catch (dataError) {
    //             if (!mounted.current) return;
    //             console.error('Error saving user data:', dataError);

    //             if (dataError.message === 'Operation cancelled') {
    //                 return;
    //             }

    //             showToast('Signed in with limited access', 'info');
    //             localStorage.setItem('userId', userCredential.user.uid);
    //             navigate('/dashboard');
    //         }
    //     } catch (error) {
    //         if (!mounted.current) return;
    //         console.error('Login error:', error);

    //         if (error.message === 'Operation cancelled') {
    //             resetForm();
    //             return;
    //         }

    //         const errorMessage = getErrorMessage(error.code || error.message);
    //         setError(errorMessage);
    //         showToast(errorMessage, 'error');

    //         // Reset form on error
    //         setEmail('');
    //         setPassword('');
    //     } finally {
    //         if (mounted.current) {
    //             setIsLoading(false);
    //             loginAttemptRef.current = null;
    //         }
    //     }
    // };

    const handleLogin = async (e) => {
        e.preventDefault();

        if (!isOnline) {
            setError('You are not connected to the internet. Please check your connection and try again.');
            showToast('No internet connection', 'error');
            return;
        }

        if (loginAttemptRef.current) {
            loginAttemptRef.current.abort();
        }

        loginAttemptRef.current = new AbortController();

        try {
            setIsLoading(true);
            setError('');
            showToast('Signing in...', 'info');

            const userCredential = await timeoutPromise(
                signInWithEmailAndPassword(auth, email, password),
                10000,
                loginAttemptRef.current
            );

            if (!mounted.current) return;

            // Get the intended destination from location state
            const intendedDestination = location.state?.from || '/dashboard';

            try {
                let retryCount = 3;
                let dataSaved = false;

                while (retryCount > 0 && !dataSaved && mounted.current) {
                    try {
                        showToast('Loading your account data...', 'info');
                        const userDoc = await timeoutPromise(
                            getDoc(doc(db, 'Users', userCredential.user.uid)),
                            10000,
                            loginAttemptRef.current
                        );

                        if (!mounted.current) return;

                        if (userDoc.exists()) {
                            const userData = userDoc.data();
                            localStorage.setItem('userId', userCredential.user.uid);
                            localStorage.setItem('storeId', userData.storeId || '');

                            showToast('Successfully signed in!', 'success');
                            window.location.href = intendedDestination;
                            return;
                        } else {
                            console.warn('User document not found');
                            showToast('Account created without store data', 'info');
                            localStorage.setItem('userId', userCredential.user.uid);
                            dataSaved = true;
                            navigate('/dashboard', { replace: true });
                            return;
                        }
                    } catch (retryError) {
                        if (!mounted.current) return;
                        retryCount--;

                        if (retryError.name === 'AbortError') {
                            throw new Error('Operation cancelled');
                        }

                        if (retryCount > 0) {
                            showToast(`Retrying to load account data... (${retryCount} attempts left)`, 'info');
                            await new Promise(resolve => setTimeout(resolve, 1000));
                        } else {
                            throw retryError;
                        }
                    }
                }

                if (mounted.current) {
                    showToast('Signed in with limited access', 'info');
                    localStorage.setItem('userId', userCredential.user.uid);
                    navigate('/dashboard', { replace: true });
                }
            } catch (dataError) {
                if (!mounted.current) return;
                console.error('Error saving user data:', dataError);

                if (dataError.message === 'Operation cancelled') {
                    resetForm();
                    return;
                }

                const errorMsg = 'We had trouble loading your account data. You can still sign in with limited access.';
                setError(errorMsg);
                showToast(errorMsg, 'warning');
                localStorage.setItem('userId', userCredential.user.uid);
                navigate('/dashboard', { replace: true });
            }
        } catch (error) {
            if (!mounted.current) return;

            // Debug logging
            console.error('Login error:', {
                code: error.code,
                message: error.message,
                fullError: error
            });

            if (error.message === 'Operation cancelled') {
                resetForm();
                return;
            }

            const errorMessage = getErrorMessage(error.code || error.message);
            setError(errorMessage);
            showToast(errorMessage, 'error');

            // Only clear password field for credential-related errors
            if (error.code === 'auth/wrong-password' ||
                error.code === 'auth/invalid-credential' ||
                error.code === 'auth/user-not-found') {
                setPassword('');
            } else {
                // Clear both fields for other types of errors
                setEmail('');
                setPassword('');
            }
        } finally {
            if (mounted.current) {
                setIsLoading(false);
                loginAttemptRef.current = null;
            }
        }
    };

    // Enhanced error messages for non-technical users
    const getErrorMessage = (errorCode) => {
        console.log('Debug - Error code received:', errorCode); // Debug log

        // Handle specific Firebase Auth error codes
        switch (errorCode) {
            case 'auth/wrong-password':
                return 'Incorrect password. Please try again.';
            case 'auth/user-not-found':
                return 'This email is not registered. Please check your email or sign up for a new account.';
            case 'auth/invalid-credential':  // Firebase sometimes uses this for wrong password
                return 'Invalid credentials. Please check and try again.';
            case 'auth/invalid-email':
                return 'This email address is not valid. Please check for typos.';
            case 'auth/too-many-requests':
                return 'You have tried to log in too many times. Please wait a few minutes before trying again.';
            case 'auth/network-request-failed':
                return 'Unable to connect to our servers. Please check if you are connected to the internet.';
            case 'auth/popup-closed-by-user':
                return 'Google sign-in was cancelled. Please try again.';
            case 'auth/popup-blocked':
                return 'Pop-up blocked by browser. Please allow pop-ups for this site.';
            case 'Request timed out':
                return 'The login is taking too long. Please check your internet connection and try again.';
            case 'Operation cancelled':
                return 'Login was cancelled. Please try again.';
            default:
                console.warn('Unhandled error code:', errorCode); // Debug log
                return `Login failed: ${errorCode}`; // More specific than "Something went wrong"
        }
    };


    const handleGoogleLogin = async () => {
        if (!isOnline) {
            showToast('Cannot log in: No internet connection', 'error');
            return;
        }

        setIsLoading(true);
        setError('');
        showToast('Connecting to Google...', 'info');
        const provider = new GoogleAuthProvider();

        try {
            const result = await timeoutPromise(signInWithPopup(auth, provider));

            try {
                await saveUserData(result.user.uid);
                showToast('Successfully signed in with Google!', 'success');
                navigate('/dashboard');
            } catch (dataError) {
                console.error('Error saving Google user data:', dataError);
                showToast('Signed in with limited access', 'info');
                localStorage.setItem('userId', result.user.uid);
                navigate('/dashboard');
            }
        } catch (error) {
            console.error('Google login error:', error);
            const errorMessage = getErrorMessage(error.code || error.message);
            setError(errorMessage);
            showToast(errorMessage, 'error');
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <div className="min-h-screen bg-white flex">
            {!isOnline && (
                <div className="fixed top-0 left-0 right-0 bg-red-500 text-white p-2 text-center flex items-center justify-center z-50">
                    <WifiOff className="w-4 h-4 mr-2" />
                    No internet connection
                </div>
            )}

            <div className="flex-1 flex flex-col justify-center px-4 sm:px-6 lg:px-8">
                <div className="mx-auto w-full max-w-md">
                    <div className="text-center mb-8">
                        <img
                            src={logo}
                            alt="Hot Kiosk Logo"
                            className="mx-auto h-12 w-auto mb-6"
                        />
                        <h2 className="text-3xl font-semibold text-gray-900">Welcome back</h2>
                        <p className="mt-2 text-sm text-gray-600">
                            Sign in to manage your store
                        </p>
                    </div>

                    {/* Enhanced error display */}
                    {error && (
                        <div className="mb-6 p-4 bg-red-50 border-l-4 border-red-500 rounded-lg">
                            <div className="flex">
                                <AlertCircle className="h-5 w-5 text-red-500 mt-0.5" />
                                <div className="ml-3">
                                    <h3 className="text-sm font-medium text-red-800">
                                        Unable to sign in
                                    </h3>
                                    <p className="mt-1 text-sm text-red-700">
                                        {error}
                                    </p>
                                </div>
                            </div>
                        </div>
                    )}

                    <form onSubmit={handleLogin} className="space-y-6">
                        <div>
                            <label htmlFor="email" className="block text-sm font-medium text-gray-700">
                                Email address
                            </label>
                            <div className="mt-1 relative">
                                <input
                                    id="email"
                                    type="email"
                                    required
                                    value={email}
                                    onChange={(e) => setEmail(e.target.value)}
                                    className="appearance-none block w-full px-3 py-4 pl-10 border border-gray-300 rounded-lg shadow-sm placeholder-gray-400 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                                />
                                <Mail className="w-5 h-5 text-gray-400 absolute left-3 top-4 mt-0.5" />
                            </div>
                        </div>

                        <div>
                            <label htmlFor="password" className="block text-sm font-medium text-gray-700">
                                Password
                            </label>
                            <div className="mt-1 relative">
                                <input
                                    id="password"
                                    type="password"
                                    required
                                    value={password}
                                    onChange={(e) => setPassword(e.target.value)}
                                    className="appearance-none block w-full px-3 py-4 pl-10 border border-gray-300 rounded-lg shadow-sm placeholder-gray-400 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                                />
                                <Lock className="w-5 h-5 text-gray-400 absolute left-3 top-4 mt-0.5" />
                            </div>
                        </div>

                        <div>
                            <button
                                type="submit"
                                disabled={isLoading}
                                className={`w-full flex justify-center items-center py-4 px-4 rounded-lg text-white text-sm font-medium transition duration-150 ease-in-out ${isLoading
                                    ? 'bg-blue-400 cursor-not-allowed'
                                    : 'bg-blue-500 hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500'
                                    }`}
                            >
                                {isLoading ? (
                                    <>
                                        <Loader2 className="animate-spin -ml-1 mr-3 h-5 w-5" />
                                        Signing in...
                                    </>
                                ) : (
                                    'Sign in'
                                )}
                            </button>
                        </div>

                        <div className="relative">
                            <div className="absolute inset-0 flex items-center">
                                <div className="w-full border-t border-gray-300" />
                            </div>
                            <div className="relative flex justify-center text-sm">
                                <span className="px-2 bg-white text-gray-500">Or continue with</span>
                            </div>
                        </div>

                        <button
                            type="button"
                            onClick={handleGoogleLogin}
                            disabled={isLoading}
                            className={`w-full flex items-center justify-center py-2.5 px-4 rounded-lg text-gray-700 bg-white border border-gray-300 text-sm font-medium transition duration-150 ease-in-out ${isLoading
                                ? 'opacity-50 cursor-not-allowed'
                                : 'hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500'
                                }`}
                        >
                            <img
                                src="https://www.gstatic.com/firebasejs/ui/2.0.0/images/auth/google.svg"
                                alt="Google logo"
                                className="w-5 h-5 mr-2"
                            />
                            Sign in with Google
                        </button>

                        <p className="text-center text-sm text-gray-600">
                            Don't have an account?{' '}
                            <Link to="/signup" className="font-medium text-blue-600 hover:text-blue-500">
                                Sign up
                            </Link>
                        </p>
                    </form>
                </div>
            </div>

            <div className="hidden lg:block relative w-0 flex-1 bg-gradient-to-br from-blue-50 to-indigo-50">
                <div className="absolute inset-0 flex items-center justify-center p-12">
                    <div className="max-w-lg">
                        <img
                            src={businessOwner}
                            alt="Store management illustration"
                            className="w-full h-auto rounded-lg shadow-lg"
                        />
                        <div className="mt-8 text-center">
                            <h3 className="text-2xl font-semibold text-gray-900">Manage Your Store</h3>
                            <p className="mt-4 text-gray-600">
                                Complete business management with inventory, sales, POS, advanced financial reports, AI and more.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Login;