import React, { useState } from 'react';
import {
    Loader2,
    AlertCircle,
    Check,
    X,
    Sparkles,
    Brain,
    MoveRight,
    FolderCheck,
    ArrowLeft
} from 'lucide-react';
import { getFunctions, httpsCallable } from 'firebase/functions';
import { updateDoc, doc } from 'firebase/firestore';

const AICategoryAssignment = ({ inventory, categories, storeId, db }) => {
    const [isAnalyzing, setIsAnalyzing] = useState(false);
    const [suggestions, setSuggestions] = useState([]);
    const [selectedSuggestions, setSelectedSuggestions] = useState(new Set());
    const [isSaving, setIsSaving] = useState(false);
    const [error, setError] = useState(null);
    const [searchTerm, setSearchTerm] = useState('');

    const functions = getFunctions();

    const analyzeCategoriesWithAI = async () => {
        setIsAnalyzing(true);
        setError(null);

        try {
            const items = inventory.map(item => ({
                id: item.id,
                name: item.itemName,
                currentCategory: item.categoryId
            }));

            const categoriesMap = categories.reduce((acc, cat) => {
                acc[cat.id] = cat.name;
                return acc;
            }, {});

            const getAICategoryAssignments = httpsCallable(functions, 'getAICategoryAssignments');
            const result = await getAICategoryAssignments({
                items,
                categories: categoriesMap
            });

            setSuggestions(result.data || []);
        } catch (err) {
            setError('Failed to analyze categories. Please try again.');
            console.error('Error analyzing categories:', err);
        } finally {
            setIsAnalyzing(false);
        }
    };

    const toggleSuggestion = (itemId) => {
        setSelectedSuggestions(prev => {
            const newSet = new Set(prev);
            if (newSet.has(itemId)) {
                newSet.delete(itemId);
            } else {
                newSet.add(itemId);
            }
            return newSet;
        });
    };

    const handleApplyChanges = async () => {
        setIsSaving(true);
        setError(null);

        try {
            const updates = suggestions
                .filter(sugg => selectedSuggestions.has(sugg.itemId))
                .map(sugg =>
                    updateDoc(
                        doc(db, 'Stores', storeId, 'Inventory', sugg.itemId),
                        {
                            categoryId: sugg.suggestedCategoryId,
                            updatedAt: new Date()
                        }
                    )
                );

            await Promise.all(updates);
            setSuggestions([]);
            setSelectedSuggestions(new Set());
        } catch (err) {
            setError('Failed to apply changes. Please try again.');
            console.error('Error applying changes:', err);
        } finally {
            setIsSaving(false);
        }
    };

    const handleSelectAll = () => {
        if (selectedSuggestions.size === filteredSuggestions.length) {
            setSelectedSuggestions(new Set());
        } else {
            setSelectedSuggestions(new Set(filteredSuggestions.map(s => s.itemId)));
        }
    };

    const handleReset = () => {
        setSuggestions([]);
        setSelectedSuggestions(new Set());
        setSearchTerm('');
    };

    const getCategoryName = (categoryId) => {
        return categories.find(cat => cat.id === categoryId)?.name || 'Uncategorized';
    };

    const filteredSuggestions = suggestions.filter(item =>
        item.itemName.toLowerCase().includes(searchTerm.toLowerCase())
    );

    if (error) {
        return (
            <div className="flex items-center justify-center p-6 bg-red-50 rounded-xl">
                <AlertCircle className="h-6 w-6 text-red-500 mr-3" />
                <span className="text-red-600 text-lg">{error}</span>
            </div>
        );
    }

    if (suggestions.length === 0) {
        return (
            <div className="min-h-[600px] flex flex-col items-center justify-center px-4">
                <div className="w-full max-w-2xl mx-auto text-center space-y-8">
                    <div className="relative">
                        <div className="absolute inset-0 flex items-center">
                            <div className="w-full border-t border-gray-200"></div>
                        </div>
                        <div className="relative flex justify-center">
                            <div className="px-4 bg-white">
                                <Sparkles className="h-12 w-12 text-purple-400" />
                            </div>
                        </div>
                    </div>

                    <div className="space-y-4">
                        <h2 className="text-2xl font-bold text-gray-900">
                            AI-Powered Category Assignment
                        </h2>
                        <p className="text-sm text-gray-600 max-w-xl mx-auto">
                            Let our AI analyze your inventory and suggest the most appropriate categories
                            based on item names and existing classification patterns.
                        </p>
                    </div>

                    <div className="grid grid-cols-1 md:grid-cols-3 gap-6 mt-12 text-left">
                        <div className="bg-orange-50 p-6 rounded-xl">
                            <Brain className="h-8 w-8 text-purple-600 mb-4" />
                            <h3 className="font-semibold text-gray-900 mb-2">Smart Analysis</h3>
                            <p className="text-sm text-gray-600">
                                Advanced AI algorithms analyze your item names and existing categories
                            </p>
                        </div>
                        <div className="bg-orange-50 p-6 rounded-xl">
                            <FolderCheck className="h-8 w-8 text-purple-600 mb-4" />
                            <h3 className="font-semibold text-gray-900 mb-2">Bulk Categorization</h3>
                            <p className="text-sm text-gray-600">
                                Efficiently categorize multiple items at once with AI suggestions
                            </p>
                        </div>
                        <div className="bg-orange-50 p-6 rounded-xl">
                            <Check className="h-8 w-8 text-purple-600 mb-4" />
                            <h3 className="font-semibold text-gray-900 mb-2">Review & Approve</h3>
                            <p className="text-sm text-gray-600">
                                Review AI suggestions before applying any changes
                            </p>
                        </div>
                    </div>

                    <div className="grid grid-cols-2 gap-8 py-4">
                        <div className="text-center">
                            <div className="text-4xl font-bold text-gray-900 mb-2">
                                {inventory.length}
                            </div>
                            <div className="text-sm text-gray-600">
                                Items to Analyze
                            </div>
                        </div>
                        <div className="text-center">
                            <div className="text-4xl font-bold text-gray-900 mb-2">
                                {categories.length}
                            </div>
                            <div className="text-sm text-gray-600">
                                Available Categories
                            </div>
                        </div>
                    </div>

                    <div className="pt-0">
                        <button
                            onClick={analyzeCategoriesWithAI}
                            className="group relative inline-flex items-center justify-center px-8 py-4 text-lg font-medium text-white bg-orange-600 rounded-xl hover:bg-orange-600 transition-all duration-200 ease-in-out disabled:bg-orange-300"
                            disabled={isAnalyzing}
                        >
                            <span className="flex items-center">
                                {isAnalyzing ? (
                                    <>
                                        <Loader2 className="h-5 w-5 animate-spin mr-2" />
                                        <span>Analyzing Inventory...</span>
                                    </>
                                ) : (
                                    <>
                                        <span>Start AI Analysis</span>
                                        <MoveRight className="ml-2 h-5 w-5 group-hover:translate-x-1 transition-transform" />
                                    </>
                                )}
                            </span>
                        </button>
                    </div>
                </div>
            </div>
        );
    }

    return (
        <div className="space-y-6">
            <div className="flex items-center justify-between bg-white p-4 rounded-lg shadow-sm">
                <div className="flex items-center space-x-4">
                    <button
                        onClick={handleReset}
                        className="text-gray-500 hover:text-gray-700 transition-colors"
                    >
                        <ArrowLeft className="h-6 w-6" />
                    </button>
                    <div>
                        <h3 className="text-lg font-semibold text-gray-900">AI Suggestions</h3>
                        <p className="text-sm text-gray-500">
                            {suggestions.length} items analyzed
                        </p>
                    </div>
                </div>
                <div className="flex items-center space-x-4">
                    <input
                        type="text"
                        placeholder="Search items..."
                        value={searchTerm}
                        onChange={(e) => setSearchTerm(e.target.value)}
                        className="px-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-orange-600"
                    />
                    <button
                        onClick={handleApplyChanges}
                        className="px-4 py-2 bg-orange-600 text-white rounded-lg flex items-center space-x-2 hover:bg-orange-600 disabled:bg-orange-300 transition-colors"
                        disabled={isSaving || selectedSuggestions.size === 0}
                    >
                        {isSaving ? (
                            <>
                                <Loader2 className="h-4 w-4 animate-spin" />
                                <span>Applying...</span>
                            </>
                        ) : (
                            <>
                                <Check className="h-4 w-4" />
                                <span>Apply Selected ({selectedSuggestions.size})</span>
                            </>
                        )}
                    </button>
                </div>
            </div>

            <div className="bg-white rounded-lg shadow-sm border border-gray-200">
                <div className="overflow-x-auto">
                    <table className="w-full">
                        <thead>
                            <tr className="border-b bg-gray-50">
                                <th className="px-4 py-3 text-left">
                                    <input
                                        type="checkbox"
                                        checked={selectedSuggestions.size === filteredSuggestions.length}
                                        onChange={handleSelectAll}
                                        className="rounded border-gray-300 text-orange-600 focus:ring-orange-600"
                                    />
                                </th>
                                <th className="px-4 py-3 text-left text-sm font-medium text-gray-600">Item Name</th>
                                <th className="px-4 py-3 text-left text-sm font-medium text-gray-600">Current Category</th>
                                <th className="px-4 py-3 text-left text-sm font-medium text-gray-600">Suggested Category</th>
                            </tr>
                        </thead>
                        <tbody>
                            {filteredSuggestions.map((item) => (
                                <tr
                                    key={item.itemId}
                                    className="border-b hover:bg-gray-50 transition-colors"
                                >
                                    <td className="px-4 py-3">
                                        <input
                                            type="checkbox"
                                            checked={selectedSuggestions.has(item.itemId)}
                                            onChange={() => toggleSuggestion(item.itemId)}
                                            className="rounded border-gray-300 text-orange-600 focus:ring-orange-600"
                                        />
                                    </td>
                                    <td className="px-4 py-3">{item.itemName}</td>
                                    <td className="px-4 py-3">
                                        <span className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-gray-100 text-gray-800">
                                            {getCategoryName(item.currentCategoryId)}
                                        </span>
                                    </td>
                                    <td className="px-4 py-3">
                                        <span className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-orange-100 text-orange-800">
                                            {getCategoryName(item.suggestedCategoryId)}
                                        </span>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    );
};

export default AICategoryAssignment;