import React, { useState, useEffect } from 'react';
import { Plus, Pencil, Trash2, Loader2, ArrowLeft, Sparkles, X } from 'lucide-react';
import { Link } from 'react-router-dom';
import { PieChart, Pie, Cell, ResponsiveContainer, Legend, Tooltip } from 'recharts';
import { getFunctions, httpsCallable } from 'firebase/functions';
import {
    collection,
    query,
    getDocs,
    addDoc,
    deleteDoc,
    doc,
    getFirestore,
    where,
    updateDoc
} from 'firebase/firestore';
import { getAuth } from 'firebase/auth';
import Sidebar from '../components/sidebar';

const COLORS = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042', '#8884D8', '#FF6B6B'];

const AlertDialog = ({ isOpen, title, description, onConfirm, onCancel }) => {
    if (!isOpen) return null;

    return (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
            <div className="bg-white rounded-lg p-6 max-w-md w-full mx-4">
                <h2 className="text-xl font-semibold mb-2">{title}</h2>
                <p className="text-gray-600 mb-6">{description}</p>
                <div className="flex justify-end space-x-4">
                    <button
                        onClick={onCancel}
                        className="px-4 py-2 text-gray-600 hover:bg-gray-100 rounded-lg"
                    >
                        Cancel
                    </button>
                    <button
                        onClick={onConfirm}
                        className="px-4 py-2 bg-red-500 text-white hover:bg-red-600 rounded-lg"
                    >
                        Confirm
                    </button>
                </div>
            </div>
        </div>
    );
};

const EditCategoryModal = ({ isOpen, onClose, onSave, category }) => {
    const [categoryName, setCategoryName] = useState(category?.name || '');
    const [isSaving, setIsSaving] = useState(false);

    useEffect(() => {
        setCategoryName(category?.name || '');
    }, [category]);

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (!categoryName.trim()) return;

        setIsSaving(true);
        try {
            await onSave(categoryName.trim());
            onClose();
        } catch (error) {
            console.error('Error updating category:', error);
        } finally {
            setIsSaving(false);
        }
    };

    if (!isOpen) return null;

    return (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
            <div className="bg-white rounded-lg p-6 max-w-md w-full mx-4">
                <h2 className="text-xl font-semibold mb-4">Edit Category</h2>
                <form onSubmit={handleSubmit}>
                    <input
                        type="text"
                        value={categoryName}
                        onChange={(e) => setCategoryName(e.target.value)}
                        placeholder="Category Name"
                        className="w-full p-2 border rounded-lg mb-4 focus:outline-none focus:ring-2 focus:ring-orange-500"
                        required
                    />
                    <div className="flex justify-end space-x-4">
                        <button
                            type="button"
                            onClick={onClose}
                            className="px-4 py-2 text-gray-600 hover:bg-gray-100 rounded-lg"
                            disabled={isSaving}
                        >
                            Cancel
                        </button>
                        <button
                            type="submit"
                            className="px-4 py-2 bg-orange-500 text-white hover:bg-orange-600 rounded-lg flex items-center disabled:bg-orange-300"
                            disabled={isSaving}
                        >
                            {isSaving ? (
                                <>
                                    <Loader2 className="h-4 w-4 mr-2 animate-spin" />
                                    Saving...
                                </>
                            ) : (
                                'Save Changes'
                            )}
                        </button>
                    </div>
                </form>
            </div>
        </div>
    );
};

const CategoryRow = ({ category, onEdit, onDelete }) => (
    <tr className="hover:bg-gray-50">
        <td className="px-6 py-4">{category.name}</td>
        <td className="px-6 py-4">{category.itemCount}</td>
        <td className="px-6 py-4">
            <div className="flex space-x-4">
                <button onClick={() => onEdit(category)} className="text-gray-500 hover:text-gray-700">
                    <Pencil className="h-4 w-4" />
                </button>
                <button onClick={() => onDelete(category)} className="text-gray-500 hover:text-red-500">
                    <Trash2 className="h-4 w-4" />
                </button>
            </div>
        </td>
    </tr>
);

const AddCategoryModal = ({ isOpen, onClose, onSave, storeId, inventoryItems, existingCategories }) => {
    const [activeTab, setActiveTab] = useState('manual'); // 'manual' or 'ai'
    const [categoryName, setCategoryName] = useState('');
    const [isSaving, setIsSaving] = useState(false);
    const [isAnalyzing, setIsAnalyzing] = useState(false);
    const [suggestedCategories, setSuggestedCategories] = useState([]);
    const [selectedSuggestions, setSelectedSuggestions] = useState(new Set());
    const db = getFirestore();
    const functions = getFunctions();

    const handleManualSubmit = async (e) => {
        e.preventDefault();
        if (!categoryName.trim() || !storeId) return;

        setIsSaving(true);
        try {
            const newCategory = {
                name: categoryName.trim(),
                createdAt: new Date(),
                updatedAt: new Date()
            };

            const docRef = await addDoc(
                collection(db, 'Stores', storeId, 'InventoryCategories'),
                newCategory
            );

            onSave(docRef.id, categoryName.trim());
            setCategoryName('');
            onClose();
        } catch (error) {
            console.error('Error adding category:', error);
        } finally {
            setIsSaving(false);
        }
    };

    const analyzeCategoriesByAI = async () => {
        setIsAnalyzing(true);
        try {
            // Get items from props
            const itemNames = inventoryItems?.map(item => item.itemName) || [];
            const existingCategoryNames = existingCategories?.map(cat => cat.name) || [];

            // Create the Firebase function call
            const getAICategories = httpsCallable(functions, 'getAICategories');

            // Call the function
            const result = await getAICategories({
                items: itemNames,
                existingCategories: existingCategoryNames
            });

            // Update state with the suggested categories
            setSuggestedCategories(result.data || []);
        } catch (error) {
            console.error('Error analyzing categories:', error);
            // You might want to show an error message to the user
            alert('Failed to analyze categories. Please try again.');
        } finally {
            setIsAnalyzing(false);
        }
    };

    const handleSuggestionSelect = (category) => {
        setSelectedSuggestions(prev => {
            const newSet = new Set(prev);
            if (newSet.has(category)) {
                newSet.delete(category);
            } else {
                newSet.add(category);
            }
            return newSet;
        });
    };

    const handleSaveSuggested = async () => {
        setIsSaving(true);
        try {
            const savedCategories = [];
            for (const categoryName of selectedSuggestions) {
                const newCategory = {
                    name: categoryName,
                    createdAt: new Date(),
                    updatedAt: new Date()
                };

                const docRef = await addDoc(
                    collection(db, 'Stores', storeId, 'InventoryCategories'),
                    newCategory
                );
                savedCategories.push({ id: docRef.id, name: categoryName });
            }

            // Notify parent of all saved categories
            savedCategories.forEach(cat => onSave(cat.id, cat.name));
            onClose();
        } catch (error) {
            console.error('Error saving suggested categories:', error);
        } finally {
            setIsSaving(false);
        }
    };

    const CustomCheckbox = ({ checked, onChange, label }) => (
        <div
            className="flex items-center space-x-3 p-3 rounded-lg transition-colors hover:bg-gray-100 cursor-pointer"
            onClick={onChange}
        >
            <div className="relative flex items-center">
                <input
                    type="checkbox"
                    checked={checked}
                    onChange={onChange}
                    className="peer sr-only"
                />
                <div className={`
                    w-5 h-5 border-2 rounded 
                    transition-colors duration-200 
                    flex items-center justify-center
                    ${checked
                        ? 'bg-orange-500 border-orange-500'
                        : 'border-gray-300 bg-white'
                    }
                `}>
                    {checked && (
                        <svg
                            className="w-3 h-3 text-white"
                            viewBox="0 0 12 12"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                                d="M2 6L5 9L10 3"
                                stroke="currentColor"
                                strokeWidth="2"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                            />
                        </svg>
                    )}
                </div>
            </div>
            <span className="text-sm font-medium select-none">{label}</span>
        </div>
    );

    if (!isOpen) return null;

    return (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
            <div className="bg-white rounded-lg p-6 max-w-md w-full mx-4 relative">
                <button
                    onClick={onClose}
                    className="absolute top-4 right-4 text-gray-400 hover:text-gray-600"
                >
                    <X className="h-5 w-5" />
                </button>

                <h2 className="text-xl font-semibold mb-4">Add Categories</h2>

                <div className="flex mb-6 border-b">
                    <button
                        onClick={() => setActiveTab('manual')}
                        className={`flex items-center justify-center px-4 py-2 flex-1 ${activeTab === 'manual'
                            ? 'border-b-2 border-orange-500 text-orange-500'
                            : 'text-gray-500'}`}
                    >
                        <Pencil className="h-4 w-4 mr-2" />
                        Manual
                    </button>
                    <button
                        onClick={() => setActiveTab('ai')}
                        className={`flex items-center justify-center px-4 py-2 flex-1 ${activeTab === 'ai'
                            ? 'border-b-2 border-orange-500 text-orange-500'
                            : 'text-gray-500'}`}
                    >
                        <Sparkles className="h-4 w-4 mr-2" />
                        AI Assisted
                    </button>
                </div>

                {activeTab === 'manual' ? (
                    <form onSubmit={handleManualSubmit}>
                        <input
                            type="text"
                            value={categoryName}
                            onChange={(e) => setCategoryName(e.target.value)}
                            placeholder="Category Name"
                            className="w-full p-2 border rounded-lg mb-4 focus:outline-none focus:ring-2 focus:ring-orange-500"
                            required
                        />
                        <div className="flex justify-end space-x-4">
                            <button
                                type="button"
                                onClick={onClose}
                                className="px-4 py-2 text-gray-600 hover:bg-gray-100 rounded-lg"
                                disabled={isSaving}
                            >
                                Cancel
                            </button>
                            <button
                                type="submit"
                                className="px-4 py-2 bg-orange-500 text-white hover:bg-orange-600 rounded-lg flex items-center disabled:bg-orange-300"
                                disabled={isSaving}
                            >
                                {isSaving ? (
                                    <>
                                        <Loader2 className="h-4 w-4 mr-2 animate-spin" />
                                        Saving...
                                    </>
                                ) : (
                                    'Add Category'
                                )}
                            </button>
                        </div>
                    </form>
                ) : (
                    <div>
                        {suggestedCategories.length === 0 ? (
                            <div className="text-center">
                                <p className="text-gray-600 mb-4">
                                    HotKiosk AI will analyze your inventory items and suggest relevant categories.
                                </p>
                                <button
                                    onClick={analyzeCategoriesByAI}
                                    className="px-4 py-2 bg-orange-500 text-white hover:bg-orange-600 rounded-lg flex items-center mx-auto disabled:bg-orange-300"
                                    disabled={isAnalyzing}
                                >
                                    {isAnalyzing ? (
                                        <>
                                            <Loader2 className="h-4 w-4 mr-2 animate-spin" />
                                            Analyzing...
                                        </>
                                    ) : (
                                        'Analyze Inventory'
                                    )}
                                </button>
                            </div>
                        ) : (
                            <div>
                                <p className="text-sm text-gray-600 mb-4">
                                    Select categories to add:
                                </p>
                                <div className="max-h-60 overflow-y-auto mb-4 space-y-1">
                                    {suggestedCategories.map((category) => (
                                        <CustomCheckbox
                                            key={category}
                                            checked={selectedSuggestions.has(category)}
                                            onChange={() => handleSuggestionSelect(category)}
                                            label={category}
                                        />
                                    ))}
                                </div>
                                <div className="flex justify-end space-x-4">
                                    <button
                                        onClick={onClose}
                                        className="px-4 py-2 text-gray-600 hover:bg-gray-100 rounded-lg"
                                        disabled={isSaving}
                                    >
                                        Cancel
                                    </button>
                                    <button
                                        onClick={handleSaveSuggested}
                                        className="px-4 py-2 bg-orange-500 text-white hover:bg-orange-600 rounded-lg flex items-center disabled:bg-orange-300"
                                        disabled={isSaving || selectedSuggestions.size === 0}
                                    >
                                        {isSaving ? (
                                            <>
                                                <Loader2 className="h-4 w-4 mr-2 animate-spin" />
                                                Saving...
                                            </>
                                        ) : (
                                            `Add Selected (${selectedSuggestions.size})`
                                        )}
                                    </button>
                                </div>
                            </div>
                        )}
                    </div>
                )}
            </div>
        </div>
    );
};

const InventoryCategories = () => {
    const [categories, setCategories] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [isAddModalOpen, setIsAddModalOpen] = useState(false);
    const [isEditModalOpen, setIsEditModalOpen] = useState(false);
    const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
    const [selectedCategory, setSelectedCategory] = useState(null);
    const [deleteError, setDeleteError] = useState(null);
    const [user, setUser] = useState(null);
    const [error, setError] = useState(null);
    const [inventoryItems, setInventoryItems] = useState([]);

    const db = getFirestore();
    const auth = getAuth();

    useEffect(() => {
        const fetchUserData = async () => {
            try {
                const currentUser = auth.currentUser;
                if (!currentUser) {
                    throw new Error('No authenticated user found');
                }

                const userDoc = await getDocs(
                    query(
                        collection(db, 'Users'),
                        where('email', '==', currentUser.email)
                    )
                );

                if (!userDoc.empty) {
                    setUser(userDoc.docs[0].data());
                } else {
                    throw new Error('User data not found');
                }
            } catch (error) {
                console.error('Error fetching user data:', error);
                setError('Failed to authenticate user');
            }
        };

        if (auth.currentUser) {
            fetchUserData();
        }
    }, [db, auth]);

    useEffect(() => {
        const fetchData = async () => {
            if (!user?.storeId) return;

            try {
                // Fetch categories
                const categoriesQuery = query(collection(db, 'Stores', user.storeId, 'InventoryCategories'));
                const categoriesSnapshot = await getDocs(categoriesQuery);
                const categoriesData = {};

                categoriesSnapshot.forEach(doc => {
                    categoriesData[doc.id] = {
                        name: doc.data().name,
                        id: doc.id,
                        itemCount: 0
                    };
                });

                // Fetch inventory items
                const inventoryQuery = query(collection(db, 'Stores', user.storeId, 'Inventory'));
                const inventorySnapshot = await getDocs(inventoryQuery);

                // Store inventory items
                const items = [];

                // Add "Uncategorized" category only if there are uncategorized items
                let hasUncategorizedItems = false;

                // Count items per category and collect inventory items
                inventorySnapshot.forEach(doc => {
                    const item = doc.data();
                    items.push({ id: doc.id, ...item }); // Add item to array

                    const categoryId = item.categoryId || 'uncategorized';

                    if (categoryId === 'uncategorized') {
                        hasUncategorizedItems = true;
                        if (!categoriesData['uncategorized']) {
                            categoriesData['uncategorized'] = {
                                name: 'Uncategorized',
                                id: 'uncategorized',
                                itemCount: 0
                            };
                        }
                    }

                    if (categoriesData[categoryId]) {
                        categoriesData[categoryId].itemCount++;
                    } else {
                        if (!categoriesData['uncategorized']) {
                            categoriesData['uncategorized'] = {
                                name: 'Uncategorized',
                                id: 'uncategorized',
                                itemCount: 0
                            };
                        }
                        categoriesData['uncategorized'].itemCount++;
                    }
                });

                // Set inventory items
                setInventoryItems(items);

                // Convert to array and include all categories
                const categoriesArray = Object.values(categoriesData)
                    .filter(category => category.id === 'uncategorized' ? hasUncategorizedItems : true);

                setCategories(categoriesArray);
                setIsLoading(false);
            } catch (error) {
                console.error('Error fetching data:', error);
                setError('Failed to load categories and inventory data');
                setIsLoading(false);
            }
        };

        fetchData();
    }, [user, db]);

    const handleAddCategory = async (categoryId, categoryName) => {
        setCategories(prev => [...prev, {
            id: categoryId,
            name: categoryName,
            itemCount: 0
        }]);
    };

    const handleDeleteCategory = async (categoryId) => {
        try {
            await deleteDoc(doc(db, 'Stores', user.storeId, 'Categories', categoryId));
            setCategories(prev => prev.filter(cat => cat.id !== categoryId));
        } catch (error) {
            console.error('Error deleting category:', error);
            setError('Failed to delete category');
        }
    };

    const chartData = categories.map(category => ({
        name: category.name,
        value: category.itemCount
    }));

    const handleEditCategory = (category) => {
        setSelectedCategory(category);
        setIsEditModalOpen(true);
    };

    const handleUpdateCategory = async (newName) => {
        try {
            await updateDoc(
                doc(db, 'Stores', user.storeId, 'InventoryCategories', selectedCategory.id),
                {
                    name: newName,
                    updatedAt: new Date()
                }
            );

            setCategories(prev =>
                prev.map(cat =>
                    cat.id === selectedCategory.id
                        ? { ...cat, name: newName }
                        : cat
                )
            );
        } catch (error) {
            console.error('Error updating category:', error);
            throw error;
        }
    };

    const handleDeleteClick = async (category) => {
        if (!category || !category.id) {
            console.error('Invalid category object');
            return;
        }

        setSelectedCategory(category);

        // Check if category has items
        if (category.itemCount > 0) {
            setDeleteError("One or more inventory items have been assigned to this category. Unassign before you can delete category.");
            setIsDeleteDialogOpen(true);
            return;
        }

        // If we reach here, the category has no items
        setDeleteError(null);
        setIsDeleteDialogOpen(true);
    };

    const handleConfirmDelete = async () => {
        if (deleteError || !selectedCategory) {
            setIsDeleteDialogOpen(false);
            setDeleteError(null);
            setSelectedCategory(null);
            return;
        }

        try {
            await deleteDoc(
                doc(db, 'Stores', user.storeId, 'InventoryCategories', selectedCategory.id)
            );

            setCategories(prev => prev.filter(cat => cat.id !== selectedCategory.id));
            setIsDeleteDialogOpen(false);
            setSelectedCategory(null);
        } catch (error) {
            console.error('Error deleting category:', error);
            setError('Failed to delete category');
        }
    };

    if (isLoading) {
        return (
            <div className="flex h-screen bg-white">
                <Sidebar />
                <div className="flex-1 flex flex-col overflow-hidden">
                    <div className="flex items-center justify-center flex-1">
                        <div className="flex flex-col items-center gap-3">
                            <Loader2 className="h-8 w-8 animate-spin text-gray-600" />
                            <div className="text-lg">Loading categories...</div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    if (error) {
        return (
            <div className="flex h-screen bg-white">
                <Sidebar />
                <div className="flex-1 flex flex-col overflow-hidden">
                    <div className="flex items-center justify-center flex-1">
                        <div className="text-red-500">{error}</div>
                    </div>
                </div>
            </div>
        );
    }

    return (
        <div className="flex h-screen bg-white">
            <Sidebar />
            <div className="flex-1 flex flex-col overflow-hidden">
                <div className="flex-1 overflow-y-auto p-8">
                    <div className="flex justify-between items-center mb-8">
                        <div className="flex items-center space-x-4">
                            <Link
                                to="/inventory"
                                className="text-gray-500 hover:text-gray-700"
                            >
                                <ArrowLeft className="h-6 w-6" />
                            </Link>
                            <h1 className="text-3xl font-semibold">Inventory Categories</h1>
                        </div>
                        <button
                            onClick={() => setIsAddModalOpen(true)}
                            className="bg-orange-500 text-white px-4 py-2 rounded-lg flex items-center hover:bg-orange-600 transition-colors"
                        >
                            <Plus className="h-5 w-5 mr-2" />
                            Add New Category
                        </button>
                    </div>

                    <div className="flex gap-8">
                        <div className="w-96 h-96 p-3">
                            <h2 className="text-lg font-semibold mb-4">Category Distribution</h2>
                            <ResponsiveContainer width="100%" height="100%">
                                <PieChart>
                                    <Pie
                                        data={chartData}
                                        cx="50%"
                                        cy="50%"
                                        innerRadius={60}
                                        outerRadius={100}
                                        fill="#8884d8"
                                        paddingAngle={2}
                                        dataKey="value"
                                    >
                                        {chartData.map((entry, index) => (
                                            <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                                        ))}
                                    </Pie>
                                    <Tooltip />
                                    <Legend />
                                </PieChart>
                            </ResponsiveContainer>
                        </div>

                        <div className="flex-1 p-6">
                            <div className="overflow-x-auto">
                                <table className="w-full">
                                    <thead>
                                        <tr className="bg-gray-100">
                                            <th className="px-6 py-4 text-left text-sm font-medium text-gray-500 uppercase">
                                                Category Name
                                            </th>
                                            <th className="px-6 py-4 text-left text-sm font-medium text-gray-500 uppercase">
                                                Items Count
                                            </th>
                                            <th className="px-6 py-4 text-left text-sm font-medium text-gray-500 uppercase">
                                                Actions
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody className="divide-y">
                                        {categories.length === 0 ? (
                                            <tr>
                                                <td colSpan="3" className="px-6 py-4 text-center text-gray-500">
                                                    No categories found
                                                </td>
                                            </tr>
                                        ) : (
                                            categories.map((category) => (
                                                <CategoryRow
                                                    key={category.id}
                                                    category={category}
                                                    onEdit={handleEditCategory}
                                                    onDelete={handleDeleteClick}
                                                />
                                            ))
                                        )}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <AddCategoryModal
                isOpen={isAddModalOpen}
                onClose={() => setIsAddModalOpen(false)}
                onSave={handleAddCategory}
                storeId={user?.storeId}
                inventoryItems={inventoryItems}
                existingCategories={categories}
            />

            <EditCategoryModal
                isOpen={isEditModalOpen}
                onClose={() => {
                    setIsEditModalOpen(false);
                    setSelectedCategory(null);
                }}
                onSave={handleUpdateCategory}
                category={selectedCategory}
            />

            <AlertDialog
                isOpen={isDeleteDialogOpen}
                title={deleteError ? "Cannot Delete Category" : "Confirm Delete"}
                description={
                    deleteError
                        ? deleteError
                        : "Are you sure you want to delete this category? This action cannot be undone."
                }
                onConfirm={handleConfirmDelete}
                onCancel={() => {
                    setIsDeleteDialogOpen(false);
                    setSelectedCategory(null);
                    setDeleteError(null);
                }}
            />
        </div>
    );
};

export default InventoryCategories;