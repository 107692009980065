import React, { useState, useEffect } from 'react';
import {
    PlusCircle,
    FileText,
    Calendar,
    Package,
    DollarSign,
    AlertCircle,
    Loader2,
    Check,
    X,
    ArrowLeft,
    ClipboardList,
    CrownIcon
} from 'lucide-react';
import {
    collection,
    query,
    where,
    getDocs,
    addDoc,
    updateDoc,
    doc,
    getFirestore,
    orderBy,
    Timestamp,
    serverTimestamp,
    increment,
    setDoc,
} from 'firebase/firestore';
import Sidebar from '../components/sidebar';
import { CreateOrderModal, OrderDetailsModal } from '../components/purchase_order_modal';
import { Link, useNavigate } from 'react-router-dom';
import { useAuth } from '../../auth/AuthContext';

// Helper function to format dates
const formatDate = (timestamp) => {
    if (!timestamp) return 'N/A';

    if (timestamp instanceof Timestamp) {
        return timestamp.toDate().toLocaleDateString(undefined, {
            year: 'numeric',
            month: 'short',
            day: 'numeric'
        });
    }

    return new Date(timestamp).toLocaleDateString(undefined, {
        year: 'numeric',
        month: 'short',
        day: 'numeric'
    });
};

// Restricted Access Component
const RestrictedAccess = () => (
    <div className="flex h-screen bg-white">
        <Sidebar />
        <div className="flex-1 flex items-center justify-center">
            <div className="text-center p-8">
                <CrownIcon className="h-16 w-16 text-yellow-500 mx-auto mb-4" />
                <h1 className="text-2xl font-semibold mb-2">Enterprise Plan Feature</h1>
                <p className="text-gray-600 mb-4">
                    The Purchase Orders feature is only available on the Enterprise plan.
                </p>
                <p className="text-gray-500 text-sm">
                    Please upgrade your plan to access this feature.
                </p>
            </div>
        </div>
    </div>
);

const PurchaseOrders = () => {
    const { hasEnterpriseAccess } = useAuth();
    const navigate = useNavigate();
    const [orders, setOrders] = useState([]);
    const [suppliers, setSuppliers] = useState([]);
    const [products, setProducts] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState(null);
    const [isCreateModalOpen, setIsCreateModalOpen] = useState(false);
    const [currentOrder, setCurrentOrder] = useState(null);
    const [isSubmitting, setIsSubmitting] = useState(false);

    // Form state for new purchase order
    const [formData, setFormData] = useState({
        supplierId: '',
        expectedDeliveryDate: '',
        items: [{ productId: '', quantity: 0, unitPrice: 0 }],
        notes: '',
        status: 'pending' // pending, approved, received, cancelled
    });

    const db = getFirestore();
    const storeId = localStorage.getItem('storeId');

    useEffect(() => {
        if (!storeId) {
            setError('Store ID not found. Please log in again.');
            setIsLoading(false);
        }
    }, [storeId]);

    // Fetch initial data
    useEffect(() => {
        const fetchData = async () => {
            try {
                // Fetch suppliers (unchanged)
                const suppliersQuery = query(
                    collection(db, 'Stores', storeId, 'Suppliers'),
                    where('status', '==', 'active')
                );
                const suppliersSnapshot = await getDocs(suppliersQuery);
                setSuppliers(suppliersSnapshot.docs.map(doc => ({
                    id: doc.id,
                    ...doc.data()
                })));

                // Fetch products (unchanged)
                const inventoryQuery = query(
                    collection(db, 'Stores', storeId, 'Inventory'),
                    orderBy('itemName')
                );
                const inventorySnapshot = await getDocs(inventoryQuery);
                setProducts(inventorySnapshot.docs.map(doc => ({
                    id: doc.id,
                    name: doc.data().itemName,
                    sku: doc.data().sku,
                    imageUrl: doc.data().imageUrl,
                    currentStock: doc.data().quantity,
                    sellingPrice: doc.data().sellingPrice,
                    reorderPoint: doc.data().reorderPoint
                })));

                // Fetch purchase orders
                const ordersQuery = query(
                    collection(db, 'Stores', storeId, 'PurchaseOrders'),
                    orderBy('createdAt', 'desc')
                );
                const ordersSnapshot = await getDocs(ordersQuery);
                setOrders(ordersSnapshot.docs.map(doc => ({
                    id: doc.id,
                    ...doc.data(),
                })));

            } catch (error) {
                console.error('Error fetching data:', error);
            } finally {
                setIsLoading(false);
            }
        };

        fetchData();
    }, [db, storeId]);


    const handleCreateOrder = async () => {
        try {
            setIsSubmitting(true);

            const totalAmount = formData.items.reduce((sum, item) =>
                sum + (item.quantity * item.unitPrice), 0
            );

            // Create order data with server timestamps
            const orderData = {
                ...formData,
                createdAt: serverTimestamp(),
                updatedAt: serverTimestamp(),
                totalAmount,
                orderNumber: `PO-${Date.now()}`,
                status: 'pending'
            };

            const orderRef = await addDoc(
                collection(db, 'Stores', storeId, 'PurchaseOrders'),
                orderData
            );

            const supplierStatsRef = doc(db, 'Stores', storeId, 'SupplierStats', formData.supplierId);

            try {
                await updateDoc(supplierStatsRef, {
                    totalOrders: increment(1),
                    pendingOrders: increment(1),
                    lastOrderDate: serverTimestamp()
                });
            } catch (error) {
                if (error.code === 'not-found') {
                    await setDoc(supplierStatsRef, {
                        totalOrders: 1,
                        pendingOrders: 1,
                        lastOrderDate: serverTimestamp(),
                        createdAt: serverTimestamp()
                    });
                } else {
                    throw error;
                }
            }

            // Create a local version of the order with JavaScript Date objects instead of server timestamps
            const localOrderData = {
                ...orderData,
                id: orderRef.id,
                createdAt: new Date(),  // Use current date for immediate display
                updatedAt: new Date()
            };

            setOrders(prevOrders => [localOrderData, ...prevOrders]);

            setFormData({
                supplierId: '',
                expectedDeliveryDate: '',
                items: [{ productId: '', quantity: 0, unitPrice: 0 }],
                notes: '',
                status: 'pending'
            });
            setIsCreateModalOpen(false);

        } catch (error) {
            console.error('Error creating purchase order:', error);
            alert('Failed to create purchase order. Please try again.');
        } finally {
            setIsSubmitting(false);
        }
    };

    const handleOrderStatusUpdate = async (orderId, newStatus) => {
        try {
            if (!storeId) {
                throw new Error('Store ID not found. Please log in again.');
            }

            const order = orders.find(o => o.id === orderId);
            if (!order) {
                throw new Error('Order not found');
            }

            const orderRef = doc(db, 'Stores', storeId, 'PurchaseOrders', orderId);
            const supplierStatsRef = order.supplierId ?
                doc(db, 'Stores', storeId, 'SupplierStats', order.supplierId) :
                null;

            let updateData = {
                status: newStatus,
                updatedAt: serverTimestamp()
            };

            // Handle supplier stats updates
            if (supplierStatsRef) {
                if (order.status === 'pending' && newStatus === 'cancelled') {
                    await updateDoc(supplierStatsRef, {
                        pendingOrders: increment(-1),
                        cancelledOrders: increment(1)
                    });
                } else if (order.status === 'pending' && (newStatus === 'received_paid' || newStatus === 'received_unpaid')) {
                    await updateDoc(supplierStatsRef, {
                        pendingOrders: increment(-1),
                        completedOrders: increment(1),
                        lastDeliveryDate: serverTimestamp()
                    });
                }
            }

            if (newStatus === 'received_paid' || newStatus === 'received_unpaid') {
                if (newStatus === 'received_paid') {
                    updateData.paymentStatus = 'paid';
                    updateData.paidDate = serverTimestamp();
                }

                updateData.receivedDate = serverTimestamp();

                // Update inventory for each item
                const updatePromises = order.items.map(async item => {
                    if (item.productId) {
                        // Update existing inventory item
                        const productRef = doc(db, 'Stores', storeId, 'Inventory', item.productId);
                        const inventoryItem = products.find(p => p.id === item.productId);

                        if (inventoryItem) {
                            const currentQuantity = parseInt(inventoryItem.quantity) || 0;
                            return updateDoc(productRef, {
                                quantity: currentQuantity + parseInt(item.quantity),
                                lastRestockDate: serverTimestamp(),
                                lastPurchasePrice: parseFloat(item.unitPrice)
                            });
                        }
                    } else if (item.isNewItem) {
                        // Create new inventory item matching AddInventoryItem fields
                        return addDoc(collection(db, 'Stores', storeId, 'Inventory'), {
                            itemName: item.name,
                            sku: item.sku || '',
                            quantity: parseInt(item.quantity),
                            reorderPoint: Math.ceil(parseInt(item.quantity) * 0.2), // 20% of quantity
                            sellingPrice: 0, // Default selling price is 0
                            costPrice: parseFloat(item.unitPrice), // Use order's unit price as cost price
                            imageUrl: '',
                            createdAt: serverTimestamp(),
                            updatedAt: serverTimestamp(),
                            status: 'active'
                        });
                    }
                });

                await Promise.all(updatePromises.filter(Boolean));
            }

            await updateDoc(orderRef, updateData);
            setCurrentOrder(null);

            // Refresh orders list
            const newOrdersQuery = query(
                collection(db, 'Stores', storeId, 'PurchaseOrders'),
                orderBy('createdAt', 'desc')
            );
            const newOrdersSnapshot = await getDocs(newOrdersQuery);
            setOrders(newOrdersSnapshot.docs.map(doc => ({
                id: doc.id,
                ...doc.data()
            })));

        } catch (error) {
            console.error('Error updating order status:', error);
            setError(error.message);
        }
    };

    const getStatusBadgeColor = (status) => {
        switch (status) {
            case 'pending': return 'bg-yellow-100 text-yellow-800';
            case 'received_unpaid': return 'bg-blue-100 text-blue-800';
            case 'received_paid': return 'bg-green-100 text-green-800';
            case 'cancelled': return 'bg-red-100 text-red-800';
            default: return 'bg-gray-100 text-gray-800';
        }
    };

    const getStatusDisplay = (status) => {
        switch (status) {
            case 'pending': return 'Pending';
            case 'received_unpaid': return 'Received - Unpaid';
            case 'received_paid': return 'Received & Paid';
            case 'cancelled': return 'Cancelled';
            default: return status;
        }
    };

    if (isLoading) {
        return (
            <div className="flex h-screen bg-white">
                <Sidebar />
                <div className="flex-1 flex items-center justify-center">
                    <Loader2 className="h-8 w-8 animate-spin text-gray-600" />
                </div>
            </div>
        );
    }

    // Check for Enterprise access before rendering the main component
    if (!hasEnterpriseAccess) {
        return <RestrictedAccess />;
    }

    return (
        <div className="flex h-screen bg-white">
            <Sidebar />
            <div className="flex-1 overflow-auto p-8">
                <div className="flex justify-between items-center mb-8">
                    <div className="flex justify-between items-center">
                        <h1 className="text-3xl font-semibold">Purchase Orders</h1>
                    </div>
                    <button
                        onClick={() => setIsCreateModalOpen(true)}
                        className="bg-indigo-500 text-white px-4 py-2 rounded-lg flex items-center"
                    >
                        <ClipboardList className="h-5 w-5 mr-2" />
                        Create Purchase Order
                    </button>
                </div>

                {/* Purchase Orders List */}
                <div className="bg-white border p-4">
                    <div className="overflow-x-auto">
                        <table className="w-full">
                            <thead className="bg-gray-100">
                                <tr>
                                    <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                        Order Number
                                    </th>
                                    <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                        Supplier
                                    </th>
                                    <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                        Date Created
                                    </th>
                                    <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                        Expected Delivery
                                    </th>
                                    <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                        Total Amount
                                    </th>
                                    <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                        Status
                                    </th>
                                </tr>
                            </thead>
                            <tbody className="bg-white divide-y divide-gray-200">
                                {orders.map((order) => (
                                    <tr
                                        key={order.id}
                                        onClick={() => setCurrentOrder(order)}
                                        className="hover:bg-gray-50 cursor-pointer transition-colors"
                                    >
                                        <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                                            {order.orderNumber}
                                        </td>
                                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                                            {suppliers.find(s => s.id === order.supplierId)?.name}
                                        </td>
                                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                                            {formatDate(order.createdAt)}
                                        </td>
                                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                                            {formatDate(order.expectedDeliveryDate)}
                                        </td>
                                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                                            GHS {order.totalAmount.toFixed(2)}
                                        </td>
                                        <td className="px-6 py-4 whitespace-nowrap">
                                            <span className={`px-2 inline-flex text-xs leading-5 font-semibold rounded-full ${getStatusBadgeColor(order.status)}`}>
                                                {getStatusDisplay(order.status)}
                                            </span>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                </div>

                {/* Create Order Modal */}
                {isCreateModalOpen && (
                    <CreateOrderModal
                        suppliers={suppliers}
                        products={products}
                        formData={formData}
                        setFormData={setFormData}
                        onSubmit={handleCreateOrder}
                        onClose={() => setIsCreateModalOpen(false)}
                        isSubmitting={isSubmitting}
                    />
                )}

                {/* View Order Details Modal */}
                {currentOrder && (
                    <OrderDetailsModal
                        order={currentOrder}
                        suppliers={suppliers}
                        products={products}
                        onClose={() => setCurrentOrder(null)}
                        onUpdateStatus={handleOrderStatusUpdate}
                    />
                )}
            </div>
        </div>
    );
};

export default PurchaseOrders;