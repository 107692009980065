import React, { useState, useEffect } from 'react';
import { ArrowLeft, Loader2, AlertCircle, Plus, ListTree, Layers, Sparkles, CrownIcon } from 'lucide-react';
import { Link } from 'react-router-dom';
import {
    collection,
    query,
    getDocs,
    updateDoc,
    doc,
    getFirestore,
    where
} from 'firebase/firestore';
import { getAuth } from 'firebase/auth';
import Sidebar from '../components/sidebar';
import IndividualStockManagement from '../components/inventory/stock_management_single';
import BulkCategoryAssignment from '../components/inventory/stock_management_bulk';
import AddInventoryCategoryModal from '../components/inventory/add_inventory_category';
import AICategoryAssignment from '../components/inventory/ai_inventory_category_assignment';
import { useAuth } from '../../auth/AuthContext';

const RestrictedAIAccess = () => (
    <div className="flex-1 flex items-center justify-center p-8">
        <div className="text-center">
            <CrownIcon className="h-16 w-16 text-yellow-500 mx-auto mb-4" />
            <h2 className="text-2xl font-semibold mb-2">Enterprise Feature</h2>
            <p className="text-gray-600 mb-4">
                AI Category Management is only available on the Enterprise plan.
            </p>
            <p className="text-gray-500 text-sm">
                Please upgrade your plan to access AI-powered category management.
            </p>
        </div>
    </div>
);

const InventoryStock = () => {
    const { hasEnterpriseAccess } = useAuth();
    const [inventory, setInventory] = useState([]);
    const [categories, setCategories] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [user, setUser] = useState(null);
    const [error, setError] = useState(null);
    const [activeTab, setActiveTab] = useState('individual');
    const [isAddCategoryModalOpen, setIsAddCategoryModalOpen] = useState(false);

    const db = getFirestore();
    const auth = getAuth();

    useEffect(() => {
        const fetchUserData = async () => {
            try {
                const currentUser = auth.currentUser;
                if (!currentUser) throw new Error('No authenticated user found');

                const userDoc = await getDocs(
                    query(collection(db, 'Users'), where('email', '==', currentUser.email))
                );

                if (!userDoc.empty) setUser(userDoc.docs[0].data());
                else throw new Error('User data not found');
            } catch (error) {
                console.error('Error fetching user data:', error);
                setError('Failed to authenticate user');
            }
        };

        if (auth.currentUser) fetchUserData();
    }, [db, auth]);

    useEffect(() => {
        const fetchData = async () => {
            if (!user?.storeId) return;

            try {
                const [inventorySnap, categoriesSnap] = await Promise.all([
                    getDocs(query(collection(db, 'Stores', user.storeId, 'Inventory'))),
                    getDocs(query(collection(db, 'Stores', user.storeId, 'InventoryCategories')))
                ]);

                setInventory(inventorySnap.docs.map(doc => ({ id: doc.id, ...doc.data() })));
                setCategories(categoriesSnap.docs.map(doc => ({ id: doc.id, ...doc.data() })));
                setIsLoading(false);
            } catch (error) {
                console.error('Error fetching data:', error);
                setError('Failed to load inventory data');
                setIsLoading(false);
            }
        };

        fetchData();
    }, [user, db]);

    const handleStockUpdate = async (itemId, newQuantity) => {
        try {
            await updateDoc(doc(db, 'Stores', user.storeId, 'Inventory', itemId), {
                quantity: newQuantity,
                updatedAt: new Date()
            });

            setInventory(prev =>
                prev.map(item =>
                    item.id === itemId
                        ? { ...item, quantity: newQuantity }
                        : item
                )
            );
        } catch (error) {
            console.error('Error updating stock:', error);
        }
    };

    const handleCategorySelect = async (itemId, categoryId) => {
        try {
            await updateDoc(doc(db, 'Stores', user.storeId, 'Inventory', itemId), {
                categoryId,
                updatedAt: new Date()
            });

            setInventory(prev =>
                prev.map(item =>
                    item.id === itemId
                        ? { ...item, categoryId }
                        : item
                )
            );
        } catch (error) {
            console.error('Error updating category:', error);
        }
    };

    const handleAddCategory = async (categoryId, categoryName) => {
        setCategories(prev => [...prev, {
            id: categoryId,
            name: categoryName,
            itemCount: 0
        }]);
    };

    if (isLoading) {
        return (
            <div className="flex h-screen bg-white">
                <Sidebar />
                <div className="flex-1 flex items-center justify-center">
                    <div className="flex flex-col items-center gap-3">
                        <Loader2 className="h-8 w-8 animate-spin text-gray-600" />
                        <div className="text-lg">Loading stock data...</div>
                    </div>
                </div>
            </div>
        );
    }

    if (error) {
        return (
            <div className="flex h-screen bg-white">
                <Sidebar />
                <div className="flex-1 flex items-center justify-center">
                    <div className="flex items-center gap-2 text-red-500">
                        <AlertCircle className="h-5 w-5" />
                        <span>{error}</span>
                    </div>
                </div>
            </div>
        );
    }

    return (
        <div className="flex h-screen bg-white">
            <Sidebar />
            <div className="flex-1 p-8 overflow-y-auto">
                <div className="flex items-center justify-between mb-8">
                    <div className="flex items-center space-x-4">
                        <h1 className="text-3xl font-semibold">Stock Management</h1>
                    </div>
                    <button
                        onClick={() => setIsAddCategoryModalOpen(true)}
                        className="px-4 py-2 bg-orange-600 text-white rounded-lg flex items-center space-x-2 hover:bg-orange-600 transition-colors"
                    >
                        <Plus className="h-5 w-5" />
                        <span>Add Category</span>
                    </button>
                </div>

                <div className="flex border-b mb-6">
                    <button
                        onClick={() => setActiveTab('individual')}
                        className={`px-6 py-3 font-medium flex items-center space-x-2 ${activeTab === 'individual'
                            ? 'border-b-2 border-orange-600 text-orange-700'
                            : 'text-gray-500 hover:text-gray-700'
                            }`}
                    >
                        <ListTree className="h-5 w-5" />
                        <span>Manage Stock</span>
                    </button>
                    <button
                        onClick={() => setActiveTab('bulk')}
                        className={`px-6 py-3 font-medium flex items-center space-x-2 ${activeTab === 'bulk'
                            ? 'border-b-2 border-orange-600 text-orange-700'
                            : 'text-gray-500 hover:text-gray-700'
                            }`}
                    >
                        <Layers className="h-5 w-5" />
                        <span>Bulk Category Assignment</span>
                    </button>
                    <button
                        onClick={() => setActiveTab('ai')}
                        className={`px-6 py-3 font-medium flex items-center space-x-2 ${activeTab === 'ai'
                            ? 'border-b-2 border-orange-600 text-orange-700'
                            : 'text-gray-500 hover:text-gray-700'
                            }`}
                    >
                        <Sparkles className="h-5 w-5" />
                        <span>AI Category Management</span>
                    </button>
                </div>

                {activeTab === 'individual' ? (
                    <IndividualStockManagement
                        inventory={inventory}
                        categories={categories}
                        onStockUpdate={handleStockUpdate}
                        onCategorySelect={handleCategorySelect}
                        onAddCategory={handleAddCategory}
                        user={user}
                    />
                ) : activeTab === 'bulk' ? (
                    <BulkCategoryAssignment
                        inventory={inventory}
                        categories={categories}
                        storeId={user?.storeId}
                        db={db}
                    />
                ) : activeTab === 'ai' && !hasEnterpriseAccess ? (
                    <RestrictedAIAccess />
                ) : (
                    <AICategoryAssignment
                        inventory={inventory}
                        categories={categories}
                        storeId={user?.storeId}
                        db={db}
                    />
                )}

                {isAddCategoryModalOpen && (
                    <AddInventoryCategoryModal
                        isOpen={isAddCategoryModalOpen}
                        onClose={() => setIsAddCategoryModalOpen(false)}
                        onSave={handleAddCategory}
                        storeId={user?.storeId}
                        inventoryItems={inventory}
                        existingCategories={categories}
                    />
                )}
            </div>
        </div>
    );
};

export default InventoryStock;