import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Search, UserPlus, Users, ChevronDown, ChevronLeft, ChevronRight, Phone, FileText, Loader2, ArrowLeft, Crown } from 'lucide-react';
import { getFirestore, collection, query, getDocs, orderBy, Timestamp, where } from 'firebase/firestore';
import Sidebar from '../components/sidebar';
import AddCustomerModal from '../components/customer/add_customer';
import { useAuth } from '../../auth/AuthContext';

const db = getFirestore();

const formatTimeAgo = (timestamp) => {
    if (!timestamp) return 'Never';

    // Convert Firestore Timestamp to Date if needed
    const date = timestamp instanceof Timestamp ?
        timestamp.toDate() :
        new Date(timestamp);

    const seconds = Math.floor((new Date() - date) / 1000);
    const intervals = {
        year: 31536000,
        month: 2592000,
        week: 604800,
        day: 86400,
        hour: 3600,
        minute: 60
    };

    for (const [unit, secondsInUnit] of Object.entries(intervals)) {
        const value = Math.floor(seconds / secondsInUnit);
        if (value >= 1) {
            return `${value} ${unit}${value === 1 ? '' : 's'} ago`;
        }
    }
    return 'Just now';
};

// Helper function to format dates consistently
const formatDate = (timestamp) => {
    if (!timestamp) return 'N/A';
    const date = timestamp instanceof Timestamp ?
        timestamp.toDate() :
        new Date(timestamp);
    return date.toLocaleDateString(undefined, {
        year: 'numeric',
        month: 'short',
        day: 'numeric'
    });
};

// Restricted Access Component
const RestrictedAccess = () => (
    <div className="flex h-screen bg-white">
        <Sidebar />
        <div className="flex-1 flex items-center justify-center">
            <div className="text-center p-8">
                <Crown className="h-16 w-16 text-yellow-500 mx-auto mb-4" />
                <h1 className="text-2xl font-semibold mb-2">Pro Feature</h1>
                <p className="text-gray-600 mb-4">
                    The Customers management feature is only available on Pro and Enterprise plans.
                </p>
                <p className="text-gray-500 text-sm">
                    Please upgrade your plan to access this feature.
                </p>
            </div>
        </div>
    </div>
);

const CustomerDetails = ({ customer }) => {
    const [activeTab, setActiveTab] = useState('details');
    const [quotes, setQuotes] = useState([]);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);

    useEffect(() => {
        if (customer?.id) {
            fetchCustomerQuotes(customer.id);
        }
    }, [customer]);

    const fetchCustomerQuotes = async (customerId) => {
        try {
            setLoading(true);
            setError(null);
            const storeId = localStorage.getItem('storeId');

            const quotesRef = collection(db, 'Stores', storeId, 'SalesQuotes');
            const q = query(
                quotesRef,
                where('customerDetails.phone', '==', customerId),
                orderBy('createdAt', 'desc')
            );

            const querySnapshot = await getDocs(q);

            const fetchedQuotes = querySnapshot.docs.map(doc => {
                const data = doc.data();
                return {
                    id: doc.id,
                    quoteNumber: data.quoteNumber,
                    date: data.createdAt,
                    total: data.total,
                    itemCount: data.items?.length || 0
                };
            });

            setQuotes(fetchedQuotes);
        } catch (err) {
            console.error('Error fetching quotes:', err);
            setError(err.message || 'Error loading quotes');
        } finally {
            setLoading(false);
        }
    };

    if (!customer) {
        return (
            <div className="flex items-center justify-center h-full text-gray-500">
                Select a customer to view details
            </div>
        );
    }

    return (
        <div className="h-full flex flex-col">
            {/* Customer Name Header */}
            <div className="p-4 border-b">
                <h2 className="text-lg font-semibold">{customer.name}</h2>
            </div>

            {/* Tabs */}
            <div className="border-b">
                <div className="flex">
                    <button
                        onClick={() => setActiveTab('details')}
                        className={`px-6 py-3 text-sm font-medium border-b-2 ${activeTab === 'details'
                            ? 'border-blue-500 text-blue-600'
                            : 'border-transparent text-gray-500 hover:text-gray-700'
                            }`}
                    >
                        Details
                    </button>
                    <button
                        onClick={() => setActiveTab('sales')}
                        className={`px-6 py-3 text-sm font-medium border-b-2 ${activeTab === 'sales'
                            ? 'border-blue-500 text-blue-600'
                            : 'border-transparent text-gray-500 hover:text-gray-700'
                            }`}
                    >
                        Order History
                    </button>
                    <button
                        onClick={() => setActiveTab('quotes')}
                        className={`px-6 py-3 text-sm font-medium border-b-2 ${activeTab === 'quotes'
                            ? 'border-blue-500 text-blue-600'
                            : 'border-transparent text-gray-500 hover:text-gray-700'
                            }`}
                    >
                        Quotes
                    </button>
                </div>
            </div>

            {/* Tab Content */}
            <div className="flex-1 overflow-y-auto">
                {activeTab === 'details' && (
                    <div className="p-6 space-y-4 text-sm">
                        <div className="flex justify-between items-center">
                            <span className="text-gray-500">Phone Number</span>
                            <span className="font-medium">{customer.phone || 'N/A'}</span>
                        </div>
                        <div className="flex justify-between items-center">
                            <span className="text-gray-500">Total Quotes</span>
                            <span className="font-medium">{customer.totalQuotes || 0}</span>
                        </div>
                        <div className="flex justify-between items-center">
                            <span className="text-gray-500">Last Quote</span>
                            <div className="text-right flex ">

                                <div className="text-sm text-gray-400 mr-1">( {formatDate(customer.lastQuoteDate)} )</div>
                                <div>{formatTimeAgo(customer.lastQuoteDate)}</div>
                            </div>
                        </div>
                        <div className="flex justify-between items-center">
                            <span className="text-gray-500">Total Revenue</span>
                            <span className="font-medium">
                                <span className="text-sm mr-1">GHS</span>
                                {(customer.totalRevenue || 0).toFixed(2)}
                            </span>
                        </div>
                    </div>
                )}

                {activeTab === 'sales' && (
                    <div className="p-4 text-sm">
                        <table className="w-full">
                            <thead>
                                <tr className="text-sm text-gray-500">
                                    <th className="text-left pb-2">Date</th>
                                    <th className="text-left pb-2">Items</th>
                                    <th className="text-right pb-2">Amount</th>
                                </tr>
                            </thead>
                            <tbody className="divide-y">
                                {(customer.sales || []).length === 0 ? (
                                    <tr>
                                        <td colSpan="3" className="text-center py-4 text-gray-500">
                                            No sales records found
                                        </td>
                                    </tr>
                                ) : (
                                    (customer.sales || []).map((sale, index) => (
                                        <tr key={index} className="hover:bg-gray-50">
                                            <td className="py-2">
                                                <div className="text-sm">{formatDate(sale.date)}</div>
                                                <div className="text-xs text-gray-400">{formatTimeAgo(sale.date)}</div>
                                            </td>
                                            <td className="py-2 text-sm">{sale.items || 0} items</td>
                                            <td className="py-2 text-right text-sm">
                                                <span className="text-xs mr-1">GHS</span>
                                                {(sale.amount || 0).toFixed(2)}
                                            </td>
                                        </tr>
                                    ))
                                )}
                            </tbody>
                        </table>
                    </div>
                )}

                {activeTab === 'quotes' && (
                    <div className="p-4 text-sm">
                        {loading ? (
                            <div className="flex justify-center py-8">
                                <Loader2 className="h-6 w-6 animate-spin text-gray-400" />
                            </div>
                        ) : error ? (
                            <div className="text-center py-4 text-red-500">
                                {error}
                            </div>
                        ) : (
                            <table className="w-full">
                                <thead>
                                    <tr className="text-sm text-gray-500">
                                        <th className="text-left pb-2">Quote #</th>
                                        <th className="text-left pb-2">Date</th>
                                        <th className="text-left pb-2">Items</th>
                                        <th className="text-right pb-2">Amount</th>
                                    </tr>
                                </thead>
                                <tbody className="divide-y">
                                    {quotes.length === 0 ? (
                                        <tr>
                                            <td colSpan="4" className="text-center py-4 text-gray-500">
                                                No quotes found
                                            </td>
                                        </tr>
                                    ) : (
                                        quotes.map((quote) => (
                                            <tr key={quote.id} className="hover:bg-gray-50">
                                                <td className="py-2">
                                                    <div className="text-sm font-medium">{quote.quoteNumber}</div>
                                                </td>
                                                <td className="py-2">
                                                    <div className="text-sm">{formatDate(quote.date)}</div>
                                                    {/* <div className="text-xs text-gray-400">{formatTimeAgo(quote.date)}</div> */}
                                                </td>
                                                <td className="py-2 text-sm">{quote.itemCount} items</td>
                                                <td className="py-2 text-right text-sm">
                                                    <span className="text-xs mr-1">GHS</span>
                                                    <span className="font-medium">{quote.total.toFixed(2)}</span>
                                                </td>
                                            </tr>
                                        ))
                                    )}
                                </tbody>
                            </table>
                        )}
                    </div>
                )}
            </div>
        </div>
    );
};

const Customers = () => {
    const navigate = useNavigate();
    const { hasProAccess } = useAuth();
    const [customers, setCustomers] = useState([]);
    const [loading, setLoading] = useState(true);
    const [searchTerm, setSearchTerm] = useState('');
    const [currentPage, setCurrentPage] = useState(1);
    const [selectedCustomer, setSelectedCustomer] = useState(null);
    const [isAddModalOpen, setIsAddModalOpen] = useState(false);
    const [stats, setStats] = useState({
        totalCustomers: 0,
        totalQuotes: 0,
        totalRevenue: 0
    });
    const itemsPerPage = 10;

    const storeId = localStorage.getItem('storeId');

    useEffect(() => {
        fetchCustomers();
    }, [storeId]);

    const fetchCustomers = async () => {
        try {
            setLoading(true);
            const customersRef = collection(db, 'Stores', storeId, 'Customers');
            const q = query(customersRef, orderBy('createdAt', 'desc'));
            const querySnapshot = await getDocs(q);

            const fetchedCustomers = [];
            let totalQuotes = 0;
            let totalRevenue = 0;

            querySnapshot.forEach((doc) => {
                const customerData = {
                    id: doc.id,
                    ...doc.data(),
                };
                fetchedCustomers.push(customerData);
                totalQuotes += customerData.totalQuotes || 0;
                totalRevenue += customerData.totalRevenue || 0;
            });

            setCustomers(fetchedCustomers);
            setStats({
                totalCustomers: fetchedCustomers.length,
                totalQuotes: totalQuotes,
                totalRevenue: totalRevenue
            });
        } catch (error) {
            console.error('Error fetching customers:', error);
        } finally {
            setLoading(false);
        }
    };

    const handleCustomerAdded = () => {
        fetchCustomers(); // Refresh the customer list
    };
    const filteredCustomers = customers.filter(customer =>
    (customer.name?.toLowerCase().includes(searchTerm.toLowerCase()) ||
        customer.phone?.toLowerCase().includes(searchTerm.toLowerCase()))
    );

    const totalPages = Math.ceil(filteredCustomers.length / itemsPerPage);
    const startIndex = (currentPage - 1) * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;
    const currentCustomers = filteredCustomers.slice(startIndex, endIndex);

    const StatCard = ({ title, value, icon }) => (
        <div className="bg-white border p-6">
            <div className="flex items-center justify-between mb-2">
                <h3 className="text-sm text-gray-500">{title}</h3>
                <div className="text-gray-400">{icon}</div>
            </div>
            {title === "Total Customer Revenue" ? (
                <p className="text-2xl font-bold">
                    <span className="mr-1">GHS</span>
                    <span>{(value || 0).toFixed(2)}</span>
                </p>
            ) : (
                <p className="text-2xl font-bold">{value}</p>
            )}
        </div>
    );

    if (loading) {
        return (
            <div className="flex h-screen bg-white">
                <Sidebar />
                <div className="flex-1 flex items-center justify-center">
                    <div className="flex flex-col items-center gap-3">
                        <Loader2 className="h-8 w-8 animate-spin text-gray-600" />
                        <div className="text-lg">Loading customers...</div>
                    </div>
                </div>
            </div>
        );
    }

    if (!hasProAccess) {
        return <RestrictedAccess />;
    }

    return (
        <div className="flex h-screen bg-white overflow-hidden">
            <Sidebar />
            <div className="flex-1 flex flex-col overflow-hidden">
                <div className="flex-1 overflow-y-auto p-8">
                    <div className="flex justify-between items-center mb-8">
                        <div className="flex justify-between items-center">
                            <h1 className="text-3xl font-semibold">Customers</h1>
                        </div>
                        <button
                            className="bg-blue-500 text-white px-4 py-2 rounded-lg flex items-center hover:bg-blue-600 transition-colors"
                            onClick={() => setIsAddModalOpen(true)}
                        >
                            <UserPlus className="h-5 w-5 mr-2" />
                            Add Customer
                        </button>
                    </div>

                    <div className="grid grid-cols-1 md:grid-cols-3 gap-4 mb-8">
                        <StatCard
                            title="Total Customers"
                            value={stats.totalCustomers}
                            icon={<Users className="h-6 w-6" />}
                        />
                        <StatCard
                            title="Total Quotes"
                            value={stats.totalQuotes}
                            icon={<FileText className="h-6 w-6" />}
                        />
                        <StatCard
                            title="Total Customer Revenue"
                            value={stats.totalRevenue}
                            icon={<FileText className="h-6 w-6" />}
                        />
                    </div>

                    <div className="flex gap-6">
                        {/* Customer List Section */}
                        <div className="flex-1">
                            <div className="mb-4 flex justify-between items-center">
                                <h2 className="text-xl font-semibold">Customer List</h2>
                                <div className="flex items-center gap-2">
                                    {/* <div className="text-sm text-gray-500">
                                        {filteredCustomers.length} customers
                                    </div> */}
                                    <div className="relative">
                                        <input
                                            type="text"
                                            placeholder="Search customers..."
                                            value={searchTerm}
                                            onChange={(e) => setSearchTerm(e.target.value)}
                                            className="pl-10 pr-4 py-2 rounded-lg border border-gray-300 focus:outline-none focus:ring-2 focus:ring-blue-500"
                                        />
                                        <div className="absolute left-3 top-1/2 transform -translate-y-1/2">
                                            <Search className="h-5 w-5 text-gray-400" />
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="border overflow-hidden">
                                <div className="overflow-x-auto">
                                    <table className="w-full p-4">
                                        <thead>
                                            <tr className="bg-gray-100">
                                                <th className="px-6 py-4 text-left text-sm font-medium text-gray-500">Customer Name</th>
                                                <th className="px-6 py-4 text-left text-sm font-medium text-gray-500">Customer Since</th>
                                                <th className="px-6 py-4 text-right text-sm font-medium text-gray-500">Total Revenue</th>
                                            </tr>
                                        </thead>
                                        <tbody className="divide-y">
                                            {filteredCustomers.map((customer) => (
                                                <tr
                                                    key={customer.id}
                                                    onClick={() => setSelectedCustomer(customer)}
                                                    className={`hover:bg-gray-50 cursor-pointer ${selectedCustomer?.id === customer.id ? 'bg-blue-50' : ''
                                                        }`}
                                                >
                                                    <td className="px-6 py-4 text-sm text-gray-900">
                                                        {customer.name}
                                                    </td>
                                                    <td className="px-6 py-4 text-sm text-gray-500">
                                                        <div className="flex flex-col">
                                                            <span>{formatTimeAgo(customer.createdAt)}</span>
                                                            <span className="text-xs text-gray-400">
                                                                {formatDate(customer.createdAt)}
                                                            </span>
                                                        </div>
                                                    </td>
                                                    <td className="px-6 py-4 text-sm text-right">
                                                        <span className="text-xs text-gray-500 mr-1">GHS</span>
                                                        <span className="text-gray-900 font-medium">
                                                            {(customer.totalRevenue || 0).toFixed(2)}
                                                        </span>
                                                    </td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>

                        {/* Customer Details Section */}
                        <div className="flex-1 border bg-white">
                            <CustomerDetails customer={selectedCustomer} />
                        </div>
                    </div>
                </div>
            </div>
            <AddCustomerModal
                isOpen={isAddModalOpen}
                onClose={() => setIsAddModalOpen(false)}
                onSuccess={handleCustomerAdded}
            />
        </div>
    );
};

export default Customers;