import React, { useState, useMemo } from 'react';
import {
    LineChart, Line, XAxis, YAxis, ResponsiveContainer, Tooltip, CartesianGrid,
    BarChart, Bar, PieChart, Pie, Cell, RadarChart, Radar, PolarGrid,
    PolarAngleAxis, PolarRadiusAxis, Scatter, ScatterChart
} from 'recharts';
import { Calendar, Users, Clock, ShoppingBag } from 'lucide-react';
import Sidebar from '../components/sidebar';

const ReportsAnalytics = () => {
    const [timeRange, setTimeRange] = useState('7days');

    // Sample data structure focusing on customer behavior and product analytics
    const sampleData = {
        customerBehavior: [
            { hour: '06-09', orders: 25, avgBasketSize: 3.2, conversionRate: 2.8 },
            { hour: '09-12', orders: 85, avgBasketSize: 2.8, conversionRate: 3.5 },
            { hour: '12-15', orders: 120, avgBasketSize: 3.0, conversionRate: 4.2 },
            { hour: '15-18', orders: 95, avgBasketSize: 2.5, conversionRate: 3.8 },
            { hour: '18-21', orders: 75, avgBasketSize: 2.2, conversionRate: 3.0 },
            { hour: '21-00', orders: 45, avgBasketSize: 2.0, conversionRate: 2.5 }
        ],
        customerSegments: [
            { segment: 'New', value: 30, retention: 25 },
            { segment: 'Occasional', value: 45, retention: 40 },
            { segment: 'Regular', value: 15, retention: 75 },
            { segment: 'Loyal', value: 10, retention: 85 }
        ],
        productMetrics: [
            { name: 'Product A', stockTurnover: 8.5, margin: 35, reorderFrequency: 15 },
            { name: 'Product B', stockTurnover: 6.2, margin: 42, reorderFrequency: 20 },
            { name: 'Product C', stockTurnover: 4.8, margin: 28, reorderFrequency: 25 },
            { name: 'Product D', stockTurnover: 9.1, margin: 31, reorderFrequency: 12 }
        ],
        locationData: [
            { region: 'North', salesDensity: 85, marketPenetration: 3.2 },
            { region: 'South', salesDensity: 65, marketPenetration: 2.8 },
            { region: 'East', salesDensity: 75, marketPenetration: 3.5 },
            { region: 'West', salesDensity: 90, marketPenetration: 3.8 }
        ]
    };

    const processedData = useMemo(() => {
        // Calculate metrics
        const totalOrders = sampleData.customerBehavior.reduce((sum, item) => sum + item.orders, 0);
        const avgConversion = sampleData.customerBehavior.reduce((sum, item) => sum + item.conversionRate, 0) /
            sampleData.customerBehavior.length;
        const avgBasketSize = sampleData.customerBehavior.reduce((sum, item) => sum + item.avgBasketSize, 0) /
            sampleData.customerBehavior.length;

        // Customer lifetime value calculation
        const customerLifetimeValue = sampleData.customerSegments.reduce((sum, segment) => {
            return sum + (segment.value * segment.retention / 100);
        }, 0);

        return {
            metrics: {
                totalOrders,
                avgConversion,
                avgBasketSize,
                customerLifetimeValue
            }
        };
    }, []);

    const COLORS = ['#FF8042', '#00C49F', '#FFBB28', '#0088FE'];

    return (
        <div className="flex h-screen bg-white overflow-hidden">
            <Sidebar />
            <div className="flex-1 flex flex-col overflow-hidden">
                <div className="flex-1 overflow-y-auto p-8">
                    <div className="flex justify-between items-center my-10">
                        <h1 className="text-5xl font-semibold">Advanced Analytics</h1>
                        <div className="flex items-center gap-2 bg-white border rounded-lg px-4 py-2">
                            <Calendar className="h-5 w-5 text-gray-500" />
                            <select
                                className="border-none bg-transparent focus:outline-none text-gray-700"
                                value={timeRange}
                                onChange={(e) => setTimeRange(e.target.value)}
                            >
                                <option value="7days">Last 7 days</option>
                                <option value="30days">Last 30 days</option>
                                <option value="90days">Last 90 days</option>
                            </select>
                        </div>
                    </div>

                    {/* Customer Behavior Metrics */}
                    <div className="grid grid-cols-1 md:grid-cols-4 gap-4 mb-8">
                        <MetricCard
                            title="Daily Orders"
                            value={processedData.metrics.totalOrders}
                            icon={<ShoppingBag />}
                            subtitle="Average daily orders"
                        />
                        <MetricCard
                            title="Conversion Rate"
                            value={`${processedData.metrics.avgConversion.toFixed(1)}%`}
                            icon={<Users />}
                            subtitle="Visitor to customer"
                        />
                        <MetricCard
                            title="Avg Basket Size"
                            value={processedData.metrics.avgBasketSize.toFixed(1)}
                            icon={<ShoppingBag />}
                            subtitle="Items per order"
                        />
                        <MetricCard
                            title="Customer LTV"
                            value={`GHS ${processedData.metrics.customerLifetimeValue.toFixed(2)}`}
                            icon={<Users />}
                            subtitle="Lifetime value"
                        />
                    </div>

                    {/* Charts Grid */}
                    <div className="grid grid-cols-1 lg:grid-cols-2 gap-6 mb-8">
                        {/* Hourly Order Pattern */}
                        <div className="bg-white border rounded-lg p-6">
                            <h2 className="text-xl font-semibold mb-4">Hourly Order Pattern</h2>
                            <div className="h-[400px]">
                                <ResponsiveContainer width="100%" height="100%">
                                    <BarChart data={sampleData.customerBehavior}>
                                        <CartesianGrid strokeDasharray="3 3" />
                                        <XAxis dataKey="hour" />
                                        <YAxis />
                                        <Tooltip />
                                        <Bar dataKey="orders" fill="#8884d8" />
                                    </BarChart>
                                </ResponsiveContainer>
                            </div>
                        </div>

                        {/* Product Performance Radar */}
                        <div className="bg-white border rounded-lg p-6">
                            <h2 className="text-xl font-semibold mb-4">Product Metrics</h2>
                            <div className="h-[400px]">
                                <ResponsiveContainer width="100%" height="100%">
                                    <RadarChart data={sampleData.productMetrics}>
                                        <PolarGrid />
                                        <PolarAngleAxis dataKey="name" />
                                        <PolarRadiusAxis />
                                        <Radar
                                            name="Turnover"
                                            dataKey="stockTurnover"
                                            stroke="#8884d8"
                                            fill="#8884d8"
                                            fillOpacity={0.6}
                                        />
                                        <Radar
                                            name="Margin"
                                            dataKey="margin"
                                            stroke="#82ca9d"
                                            fill="#82ca9d"
                                            fillOpacity={0.6}
                                        />
                                        <Tooltip />
                                    </RadarChart>
                                </ResponsiveContainer>
                            </div>
                        </div>

                        {/* Customer Segments */}
                        <div className="bg-white border rounded-lg p-6">
                            <h2 className="text-xl font-semibold mb-4">Customer Segments</h2>
                            <div className="h-[400px]">
                                <ResponsiveContainer width="100%" height="100%">
                                    <PieChart>
                                        <Pie
                                            data={sampleData.customerSegments}
                                            dataKey="value"
                                            nameKey="segment"
                                            cx="50%"
                                            cy="50%"
                                            outerRadius={150}
                                            label
                                        >
                                            {sampleData.customerSegments.map((entry, index) => (
                                                <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                                            ))}
                                        </Pie>
                                        <Tooltip />
                                    </PieChart>
                                </ResponsiveContainer>
                            </div>
                        </div>

                        {/* Market Penetration */}
                        <div className="bg-white border rounded-lg p-6">
                            <h2 className="text-xl font-semibold mb-4">Regional Performance</h2>
                            <div className="h-[400px]">
                                <ResponsiveContainer width="100%" height="100%">
                                    <ScatterChart>
                                        <CartesianGrid />
                                        <XAxis type="number" dataKey="salesDensity" name="Sales Density" />
                                        <YAxis type="number" dataKey="marketPenetration" name="Market Penetration" />
                                        <Tooltip cursor={{ strokeDasharray: '3 3' }} />
                                        <Scatter name="Regions" data={sampleData.locationData} fill="#8884d8">
                                            {sampleData.locationData.map((entry, index) => (
                                                <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                                            ))}
                                        </Scatter>
                                    </ScatterChart>
                                </ResponsiveContainer>
                            </div>
                        </div>
                    </div>

                    {/* Customer Retention Matrix */}
                    <div className="bg-white border rounded-lg p-6">
                        <h2 className="text-xl font-semibold mb-4">Customer Retention by Segment</h2>
                        <div className="overflow-x-auto">
                            <table className="min-w-full">
                                <thead>
                                    <tr className="border-b">
                                        <th className="text-left p-4">Segment</th>
                                        <th className="text-right p-4">Distribution (%)</th>
                                        <th className="text-right p-4">Retention Rate (%)</th>
                                        <th className="text-right p-4">Value Contribution (%)</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {sampleData.customerSegments.map((segment, index) => (
                                        <tr key={index} className="border-b">
                                            <td className="p-4">{segment.segment}</td>
                                            <td className="text-right p-4">{segment.value}</td>
                                            <td className="text-right p-4">{segment.retention}</td>
                                            <td className="text-right p-4">
                                                {((segment.value * segment.retention) / 100).toFixed(1)}
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

const MetricCard = ({ title, value, icon, subtitle }) => (
    <div className="bg-white border rounded-lg p-6">
        <div className="flex justify-between items-start">
            <div>
                <h3 className="text-sm text-gray-500 mb-1">{title}</h3>
                <p className="text-2xl font-bold mb-2">{value}</p>
                <p className="text-xs text-gray-500">{subtitle}</p>
            </div>
            <div className="p-2 bg-gray-50 rounded-lg">
                {icon}
            </div>
        </div>
    </div>
);

export default ReportsAnalytics;