import React, { useState, useEffect } from 'react';
import { Search, ArrowLeft, Download, Grid, List, CrownIcon } from 'lucide-react';
import {
    collection,
    query,
    where,
    orderBy,
    getDocs,
    getFirestore,
    Timestamp
} from 'firebase/firestore';
import { getAuth } from 'firebase/auth';
import Sidebar from '../components/sidebar';
import { Link } from 'react-router-dom';
import TimePeriodPicker from '../components/time_period_picker';
import SalesDetailsModal from '../components/sales/sales_detail_modal';
import { useAuth } from '../../auth/AuthContext';

const RestrictedCreditSales = () => (
    <div className="flex-1 flex items-center justify-center p-8 bg-gray-50 rounded-lg">
        <div className="text-center">
            <CrownIcon className="h-16 w-16 text-yellow-500 mx-auto mb-4" />
            <h2 className="text-2xl font-semibold mb-2">Pro Feature</h2>
            <p className="text-gray-600 mb-4">
                Credit Sales management is only available on Pro and Enterprise plans.
            </p>
            <p className="text-gray-500 text-sm">
                Please upgrade your plan to manage credit sales.
            </p>
        </div>
    </div>
);

const SalesList = () => {
    const { hasProAccess } = useAuth();
    const [salesData, setSalesData] = useState({
        sales: [],
        isLoading: true,
        error: null
    });
    const [viewMode, setViewMode] = useState('orders');
    const [searchTerm, setSearchTerm] = useState('');
    const [salesType, setSalesType] = useState('regular');
    const [currentPage, setCurrentPage] = useState(1);
    const [selectedTimeLabel, setSelectedTimeLabel] = useState('Today');
    const [dateRange, setDateRange] = useState(() => {
        const today = new Date();
        today.setHours(0, 0, 0, 0);
        return {
            startDate: today,
            endDate: new Date()
        };
    });

    const [modalData, setModalData] = useState({
        isOpen: false,
        data: null,
        mode: null
    });

    const salesPerPage = 20;
    const db = getFirestore();
    const auth = getAuth();

    const handleSalesTypeChange = (type) => {
        if (type === 'credit' && !hasProAccess) {
            return; // Do nothing if user tries to access credit sales without pro access
        }
        setSalesType(type);
        setCurrentPage(1);
    };

    const handleDateRangeChange = (start, end, label) => {
        setDateRange({
            startDate: start,
            endDate: end
        });
        setSelectedTimeLabel(label);
    };

    const getFilteredData = () => {
        if (viewMode === 'orders') {
            return salesData.sales.filter(sale =>
                sale.id.toLowerCase().includes(searchTerm.toLowerCase()) ||
                (sale.orderNumber && sale.orderNumber.toLowerCase().includes(searchTerm.toLowerCase()))
            );
        } else {
            const allItems = salesData.sales.flatMap(sale =>
                sale.items.map(item => ({
                    ...item,
                    saleId: sale.id,
                    orderNumber: sale.orderNumber,
                    saleDate: sale.saleDate
                }))
            );
            return allItems.filter(item =>
                item.itemName.toLowerCase().includes(searchTerm.toLowerCase()) ||
                (item.orderNumber && item.orderNumber.toLowerCase().includes(searchTerm.toLowerCase()))
            );
        }
    };

    const getDateRange = (startDate, endDate) => {
        // Create start of day for start date
        const start = new Date(startDate);
        start.setHours(0, 0, 0, 0);

        // Create end of day for end date
        const end = new Date(endDate);
        end.setHours(23, 59, 59, 999);

        return { start, end };
    };

    useEffect(() => {
        const fetchSalesData = async () => {
            try {
                const currentUser = auth.currentUser;
                if (!currentUser) {
                    throw new Error('No authenticated user found');
                }

                const userDoc = await getDocs(
                    query(
                        collection(db, 'Users'),
                        where('email', '==', currentUser.email)
                    )
                );

                if (userDoc.empty) {
                    throw new Error('User data not found');
                }

                const userData = userDoc.docs[0].data();

                // Determine collection path based on salesType
                const collectionPath = salesType === 'regular'
                    ? ['Stores', userData.storeId, 'Sales']
                    : ['Stores', userData.storeId, 'CreditSales'];

                // Get proper date range with start of day and end of day
                const { start, end } = getDateRange(dateRange.startDate, dateRange.endDate);

                const salesQuery = query(
                    collection(db, ...collectionPath),
                    where('saleDate', '>=', Timestamp.fromDate(start)),
                    where('saleDate', '<=', Timestamp.fromDate(end)),
                    orderBy('saleDate', 'desc')
                );

                console.log('Query dates:', {
                    start: start.toISOString(),
                    end: end.toISOString()
                });

                const salesSnapshot = await getDocs(salesQuery);
                const sales = salesSnapshot.docs.map(doc => {
                    const saleData = doc.data();
                    const totalAmount = saleData.items.reduce(
                        (sum, item) => sum + (item.price * item.quantity),
                        0
                    );
                    return {
                        id: doc.id,
                        ...saleData,
                        saleDate: saleData.saleDate.toDate(),
                        totalAmount
                    };
                });

                console.log(`Found ${sales.length} ${salesType} sales`);

                setSalesData({
                    sales,
                    isLoading: false,
                    error: null
                });
            } catch (error) {
                console.error('Error fetching sales data:', error);
                setSalesData(prev => ({
                    ...prev,
                    isLoading: false,
                    error: 'Failed to load sales data'
                }));
            }
        };

        fetchSalesData();
    }, [db, dateRange, salesType]);


    const exportToCSV = () => {
        let headers, csvContent;

        if (viewMode === 'orders') {
            headers = ['Order No.', 'Date', 'Amount', 'Items'];
            csvContent = [
                headers.join(','),
                ...filteredData.map(sale => [
                    sale.orderNumber || `ORD${String(parseInt(sale.id.slice(-6), 16)).padStart(6, '0')}`,
                    sale.saleDate.toLocaleString(),
                    sale.totalAmount.toFixed(2),
                    sale.items.length
                ].join(','))
            ].join('\n');
        } else {
            headers = ['Order No.', 'Date', 'Item Name', 'Quantity', 'Price', 'Total'];
            csvContent = [
                headers.join(','),
                ...filteredData.map(item => [
                    item.orderNumber || `ORD${String(parseInt(item.saleId.slice(-6), 16)).padStart(6, '0')}`,
                    item.saleDate.toLocaleString(),
                    item.itemName,
                    item.quantity,
                    item.price.toFixed(2),
                    (item.price * item.quantity).toFixed(2)
                ].join(','))
            ].join('\n');
        }

        const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
        const link = document.createElement('a');
        link.href = URL.createObjectURL(blob);
        link.download = `sales_${viewMode}_report.csv`;
        link.click();
    };

    const handleRowClick = (data, mode) => {
        setModalData({
            isOpen: true,
            data,
            mode
        });
    };

    const closeModal = () => {
        setModalData({
            isOpen: false,
            data: null,
            mode: null
        });
    };

    const filteredData = getFilteredData();
    const totalPages = Math.ceil(filteredData.length / salesPerPage);
    const indexOfLastItem = currentPage * salesPerPage;
    const indexOfFirstItem = indexOfLastItem - salesPerPage;
    const currentItems = filteredData.slice(indexOfFirstItem, indexOfLastItem);

    if (salesData.isLoading) {
        return (
            <div className="flex h-screen bg-white">
                <Sidebar />
                <div className="flex-1 flex items-center justify-center">
                    <div className="text-lg">Loading sales data...</div>
                </div>
            </div>
        );
    }

    if (salesData.error) {
        return (
            <div className="flex h-screen bg-white">
                <Sidebar />
                <div className="flex-1 flex items-center justify-center text-red-500">
                    <div>{salesData.error}</div>
                </div>
            </div>
        );
    }

    return (
        <div className="flex h-screen bg-white">
            <Sidebar />
            <div className="flex-1 p-8 overflow-y-auto">
                <div className="flex items-center justify-between mb-8">
                    <div className="flex items-center">
                        <h1 className="text-3xl font-semibold">All Sales</h1>
                    </div>
                    <div className="flex items-center space-x-4">
                        {/* Sales Type Tabs */}
                        <div className="inline-flex rounded-lg border border-gray-200 bg-white p-1">
                            <button
                                onClick={() => handleSalesTypeChange('regular')}
                                className={`px-4 py-2 rounded-md text-sm font-medium transition-colors ${salesType === 'regular'
                                    ? 'bg-blue-50 text-blue-600'
                                    : 'text-gray-600 hover:bg-gray-50'
                                    }`}
                            >
                                Regular Sales
                            </button>
                            <div className="relative">
                                <button
                                    onClick={() => handleSalesTypeChange('credit')}
                                    className={`px-4 py-2 rounded-md text-sm font-medium transition-colors ${salesType === 'credit'
                                        ? 'bg-blue-50 text-blue-600'
                                        : 'text-gray-600 hover:bg-gray-50'
                                        } ${!hasProAccess ? 'cursor-not-allowed' : ''}`}
                                    disabled={!hasProAccess}
                                >
                                    <div className="flex items-center">
                                        Credit Sales
                                        {!hasProAccess && (
                                            <span className="ml-2 inline-flex items-center rounded-md bg-blue-50 px-2 py-0.5 text-xs font-medium text-blue-600 ring-1 ring-inset ring-blue-600/20">
                                                Pro
                                            </span>
                                        )}
                                    </div>
                                </button>
                            </div>
                        </div>

                        {/* View Toggle */}
                        <TimePeriodPicker
                            onDateRangeChange={handleDateRangeChange}
                            selectedLabel={selectedTimeLabel}
                            onLabelChange={setSelectedTimeLabel}
                        />
                    </div>
                </div>

                {/* Search and Filter */}

                {salesType === 'credit' && !hasProAccess ? (
                    <RestrictedCreditSales />
                ) : (
                    <>
                        <div className="flex items-center justify-between mb-5">
                            <div className="relative">
                                <input
                                    type="text"
                                    placeholder={`Search ${viewMode}...`}
                                    value={searchTerm}
                                    onChange={(e) => setSearchTerm(e.target.value)}
                                    className="pl-10 pr-4 py-2 rounded-lg border border-gray-300 focus:outline-none focus:ring-2 focus:ring-blue-500"
                                />
                                <div className="absolute left-3 top-1/2 transform -translate-y-1/2">
                                    <Search className="h-5 w-5 text-gray-400" />
                                </div>
                            </div>
                            <div className="flex items-center">
                                <div className="flex items-center bg-gray-100 rounded-lg p-1 mr-2">
                                    <button
                                        onClick={() => setViewMode('orders')}
                                        className={`flex items-center px-3 py-1 rounded-md ${viewMode === 'orders'
                                            ? 'bg-white text-blue-600 shadow'
                                            : 'text-gray-600'
                                            }`}
                                    >
                                        <List className="h-4 w-4 mr-2" />
                                        Orders
                                    </button>
                                    <button
                                        onClick={() => setViewMode('items')}
                                        className={`flex items-center px-3 py-1 rounded-md ${viewMode === 'items'
                                            ? 'bg-white text-blue-600 shadow'
                                            : 'text-gray-600'
                                            }`}
                                    >
                                        <Grid className="h-4 w-4 mr-2" />
                                        Items
                                    </button>
                                </div>

                                <button
                                    onClick={exportToCSV}
                                    className="inline-flex items-center px-4 py-2 border rounded-lg text-sm text-gray-700 hover:bg-gray-50"
                                >
                                    <Download className="h-4 w-4 mr-2" />
                                    Export
                                </button>
                            </div>
                        </div>

                        <div className="bg-white overflow-hidden border p-5">
                            <table className="w-full">
                                <thead>
                                    <tr className="bg-gray-100">
                                        {viewMode === 'orders' ? (
                                            <>
                                                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                                    Order No.
                                                </th>
                                                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                                    Date
                                                </th>
                                                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                                    Amount
                                                </th>
                                                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                                    Items
                                                </th>
                                            </>
                                        ) : (
                                            <>
                                                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                                    Order No.
                                                </th>
                                                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                                    Date
                                                </th>
                                                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                                    Item Name
                                                </th>
                                                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                                    Quantity
                                                </th>
                                                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                                    Price
                                                </th>
                                                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                                    Total
                                                </th>
                                            </>
                                        )}
                                    </tr>
                                </thead>
                                <tbody className="bg-white divide-y divide-gray-200">
                                    {currentItems.length === 0 ? (
                                        <tr>
                                            <td colSpan={viewMode === 'orders' ? 4 : 6} className="px-6 py-4 text-center text-gray-500">
                                                No {viewMode} found
                                            </td>
                                        </tr>
                                    ) : viewMode === 'orders' ? (
                                        currentItems.map((sale) => (
                                            <tr
                                                key={sale.id}
                                                className="hover:bg-gray-50 cursor-pointer"
                                                onClick={() => handleRowClick(sale, 'order')}
                                            >
                                                <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                                                    {sale.orderNumber || `ORD${String(parseInt(sale.id.slice(-6), 16)).padStart(6, '0')}`}
                                                </td>
                                                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                                                    {sale.saleDate.toLocaleString()}
                                                </td>
                                                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                                                    <span className="text-xs mr-1">GHS</span>
                                                    <span className="text-gray-900">{sale.totalAmount.toFixed(2)}</span>
                                                </td>
                                                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                                                    {sale.items.length} items
                                                </td>
                                            </tr>
                                        ))
                                    ) : (
                                        currentItems.map((item, index) => (
                                            <tr
                                                key={`${item.saleId}-${index}`}
                                                className="hover:bg-gray-50 cursor-pointer"
                                                onClick={() => handleRowClick(item, 'item')}
                                            >
                                                <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                                                    {item.orderNumber || `ORD${String(parseInt(item.saleId.slice(-6), 16)).padStart(6, '0')}`}
                                                </td>
                                                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                                                    {item.saleDate.toLocaleString()}
                                                </td>
                                                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                                                    {item.itemName}
                                                </td>
                                                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                                                    {item.quantity}
                                                </td>
                                                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                                                    <span className="text-xs mr-1">GHS</span>
                                                    <span className="text-gray-900">{item.price.toFixed(2)}</span>
                                                </td>
                                                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                                                    <span className="text-xs mr-1">GHS</span>
                                                    <span className="text-gray-900">{(item.price * item.quantity).toFixed(2)}</span>
                                                </td>
                                            </tr>
                                        ))
                                    )}
                                </tbody>
                            </table>

                            {/* Pagination */}
                            <div className="px-6 py-4 bg-gray-50 border-t border-gray-200">
                                <div className="flex items-center justify-between">
                                    <div className="text-sm text-gray-700">
                                        Showing {indexOfFirstItem + 1} to {Math.min(indexOfLastItem, filteredData.length)} of{' '}
                                        {filteredData.length} results
                                    </div>
                                    <div className="flex space-x-2">
                                        <button
                                            onClick={() => setCurrentPage(prev => Math.max(prev - 1, 1))}
                                            disabled={currentPage === 1}
                                            className="px-4 py-2 border rounded-lg text-sm font-medium text-gray-700 hover:bg-gray-50 disabled:opacity-50 disabled:cursor-not-allowed"
                                        >
                                            Previous
                                        </button>
                                        <button
                                            onClick={() => setCurrentPage(prev => Math.min(prev + 1, totalPages))}
                                            disabled={currentPage === totalPages}
                                            className="px-4 py-2 border rounded-lg text-sm font-medium text-gray-700 hover:bg-gray-50 disabled:opacity-50 disabled:cursor-not-allowed"
                                        >
                                            Next
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </>
                )}

            </div>

            <SalesDetailsModal
                isOpen={modalData.isOpen}
                onClose={closeModal}
                data={modalData.data}
                mode={modalData.mode}
            />
        </div>
    );
};

export default SalesList;