import React, { useState, useEffect, useRef } from 'react';
import { X, Check, ChevronsUpDown } from 'lucide-react';
import { getFirestore, collection, addDoc, getDocs } from 'firebase/firestore';

const AddCategoryModal = ({ isOpen, onClose, onCategoryAdded }) => {
    const [categoryName, setCategoryName] = useState('');
    const [description, setDescription] = useState('');
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');
    const [suggestions, setSuggestions] = useState([]);
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);
    const [commonCategories] = useState([
        'Rent',
        'Restocking Products',
        'Utilities',
        'Internet',
        'Salaries',
        'Software',
        'Marketing',
        'Supplies',
        'Insurance',
        'Maintenance',
        'Transportation',
        'Professional Services',
        'Technology',
        'Training',
        'Travel',
        'Miscellaneous'
    ]);

    const inputRef = useRef(null);
    const suggestionsRef = useRef(null);

    const db = getFirestore();
    const storeId = localStorage.getItem('storeId');

    useEffect(() => {
        // Close suggestions on click outside
        const handleClickOutside = (event) => {
            if (suggestionsRef.current && !suggestionsRef.current.contains(event.target) &&
                inputRef.current && !inputRef.current.contains(event.target)) {
                setIsDropdownOpen(false);
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => document.removeEventListener('mousedown', handleClickOutside);
    }, []);

    useEffect(() => {
        // Filter suggestions based on input
        const filtered = commonCategories.filter(category =>
            category.toLowerCase().includes(categoryName.toLowerCase())
        );
        setSuggestions(filtered);
    }, [categoryName]);

    const handleInputChange = (e) => {
        setCategoryName(e.target.value);
        setIsDropdownOpen(true);
    };

    const handleSelectSuggestion = (suggestion) => {
        setCategoryName(suggestion);
        setIsDropdownOpen(false);
        inputRef.current?.focus();
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setError('');

        if (!categoryName.trim()) {
            setError('Category name is required');
            return;
        }

        try {
            setLoading(true);
            const categoryData = {
                name: categoryName.trim().toLowerCase(),
                description: description.trim(),
                createdAt: new Date(),
                storeId
            };

            const categoriesRef = collection(db, 'Stores', storeId, 'ExpenseCategories');
            const docRef = await addDoc(categoriesRef, categoryData);

            onCategoryAdded({ id: docRef.id, ...categoryData });
            setCategoryName('');
            setDescription('');
            onClose();
        } catch (err) {
            setError('Failed to add category. Please try again.');
            console.error('Error adding category:', err);
        } finally {
            setLoading(false);
        }
    };

    if (!isOpen) return null;

    return (
        <div className="fixed inset-0 z-50 flex items-center justify-center">
            <div className="fixed inset-0 bg-black bg-opacity-50" onClick={onClose} />
            <div className="relative bg-white rounded-lg shadow-lg w-full max-w-md p-6">
                <div className="flex justify-between items-center mb-6">
                    <h2 className="text-xl font-semibold">Add Expense Category</h2>
                    <button
                        onClick={onClose}
                        className="text-gray-500 hover:text-gray-700"
                    >
                        <X className="h-5 w-5" />
                    </button>
                </div>

                <form onSubmit={handleSubmit}>
                    {error && (
                        <div className="mb-4 p-3 bg-red-50 text-red-700 rounded-md text-sm">
                            {error}
                        </div>
                    )}

                    <div className="mb-4 relative">
                        <label
                            htmlFor="categoryName"
                            className="block text-sm font-medium text-gray-700 mb-1"
                        >
                            Category Name*
                        </label>
                        <div className="relative">
                            <input
                                ref={inputRef}
                                id="categoryName"
                                type="text"
                                value={categoryName}
                                onChange={handleInputChange}
                                onFocus={() => setIsDropdownOpen(true)}
                                className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                                placeholder="Enter or select category name"
                            />
                            <button
                                type="button"
                                onClick={() => setIsDropdownOpen(!isDropdownOpen)}
                                className="absolute right-2 top-1/2 -translate-y-1/2"
                            >
                                <ChevronsUpDown className="h-4 w-4 text-gray-500" />
                            </button>
                        </div>

                        {isDropdownOpen && suggestions.length > 0 && (
                            <div
                                ref={suggestionsRef}
                                className="absolute z-10 mt-1 w-full bg-white shadow-lg max-h-60 rounded-md py-1 text-base ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none sm:text-sm"
                            >
                                {suggestions.map((suggestion) => (
                                    <button
                                        key={suggestion}
                                        type="button"
                                        className={`
                      w-full text-left px-4 py-2 flex items-center justify-between
                      ${categoryName.toLowerCase() === suggestion.toLowerCase()
                                                ? 'bg-blue-50 text-blue-900'
                                                : 'text-gray-900 hover:bg-gray-100'
                                            }
                    `}
                                        onClick={() => handleSelectSuggestion(suggestion)}
                                    >
                                        <span>{suggestion}</span>
                                        {categoryName.toLowerCase() === suggestion.toLowerCase() && (
                                            <Check className="h-4 w-4 text-blue-600" />
                                        )}
                                    </button>
                                ))}
                            </div>
                        )}
                    </div>

                    <div className="mb-6">
                        <label
                            htmlFor="description"
                            className="block text-sm font-medium text-gray-700 mb-1"
                        >
                            Description
                        </label>
                        <textarea
                            id="description"
                            value={description}
                            onChange={(e) => setDescription(e.target.value)}
                            rows={3}
                            className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                            placeholder="Enter category description (optional)"
                        />
                    </div>

                    <div className="flex justify-end gap-3">
                        <button
                            type="button"
                            onClick={onClose}
                            className="px-4 py-2 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded-md hover:bg-gray-50"
                        >
                            Cancel
                        </button>
                        <button
                            type="submit"
                            disabled={loading}
                            className="px-4 py-2 text-sm font-medium text-white bg-blue-600 rounded-md hover:bg-blue-700 disabled:opacity-50 disabled:cursor-not-allowed"
                        >
                            {loading ? 'Adding...' : 'Add Category'}
                        </button>
                    </div>
                </form>
            </div>
        </div>
    );
};

export default AddCategoryModal;