import React, { useState, useEffect } from 'react';
import { Settings as SettingsIcon, Store, Users, Printer, CreditCard, PlaySquareIcon } from 'lucide-react';
import { useNavigate, useLocation } from 'react-router-dom';
import Sidebar from '../components/sidebar';

// Import components
import AccountSettings from '../components/settings/account_settings';
import StoreManagement from '../components/settings/store_management';
import UserPermissions from '../components/settings/user_permissions';
import PrinterSettings from '../components/settings/printer_settings';
import SubscriptionSettings from '../components/settings/subscription';
import PaystackPlansFetcher from '../components/settings/plans';
import PaystackProductsDisplay from '../components/settings/products';

const Settings = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const [selectedTab, setSelectedTab] = useState('Account');

    const tabs = [
        { id: 'account', name: 'Account', icon: SettingsIcon, component: <AccountSettings /> },
        { id: 'store_management', name: 'Store Management', icon: Store, component: <StoreManagement /> },
        { id: 'user_permissions', name: 'User Permissions', icon: Users, component: <UserPermissions /> },
        { id: 'printer_settings', name: 'Printer Settings', icon: Printer, component: <PrinterSettings /> },
        { id: 'subscription', name: 'Subscription', icon: CreditCard, component: <SubscriptionSettings /> },
        // { id: 'plans', name: 'Plans', icon: CreditCard, component: <PaystackPlansFetcher /> },
        // { id: 'products', name: 'Products', icon: CreditCard, component: <PaystackProductsDisplay /> },
    ];

    // Initialize the selected tab based on URL on component mount
    useEffect(() => {
        const tabFromUrl = location.hash.slice(1); // Remove the # from the hash
        const validTab = tabs.find(tab => tab.id === tabFromUrl);
        if (validTab) {
            setSelectedTab(validTab.id);
        } else if (location.hash) {
            // If there's an invalid hash, navigate to default tab
            navigate('#account', { replace: true });
        } else {
            // If no hash, add the default
            navigate('#account', { replace: true });
        }
    }, [location.hash, navigate]);

    const handleTabChange = (tabId) => {
        setSelectedTab(tabId);
        navigate(`#${tabId}`);
    };

    return (
        <div className="flex h-screen bg-white overflow-hidden">
            <Sidebar />
            <div className="flex-1 flex flex-col overflow-hidden">
                <div className="flex-1 overflow-y-auto p-8">
                    <div className="flex items-center space-x-4 mb-8">
                        <h1 className="text-3xl font-semibold">Stock Management</h1>
                    </div>

                    <div className="flex space-x-6 mb-8">
                        {tabs.map((tab) => (
                            <button
                                key={tab.id}
                                className={`text-lg font-medium ${selectedTab === tab.id
                                    ? 'text-orange-600 border-b-2 border-orange-600'
                                    : 'text-gray-500 hover:text-gray-700'
                                    }`}
                                onClick={() => handleTabChange(tab.id)}
                            >
                                {tab.name}
                            </button>
                        ))}
                    </div>

                    {tabs.find(tab => tab.id === selectedTab)?.component}
                </div>
            </div>
        </div>
    );
};

export default Settings;