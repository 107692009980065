// Service to handle cash drawer operations
const cashDrawerService = {
    // Opens the cash drawer through the receipt printer
    openDrawer: async () => {
        try {
            // ESC/POS commands for opening cash drawer
            // Pulse to pin 2 (0x40) and pin 5 (0x41)
            const commands = [
                '\x1B\x70\x00\x19\x19', // Send pulse to pin 2
                '\x1B\x70\x01\x19\x19'  // Send pulse to pin 5
            ];

            // If using a web-based printer solution (like WebUSB)
            if ('usb' in navigator) {
                const printer = await navigator.usb.requestDevice({
                    filters: [{ vendorId: 0x0483 }] // Replace with your printer's vendor ID
                });

                await printer.open();
                await printer.selectConfiguration(1);
                await printer.claimInterface(0);

                // Send commands to printer
                for (const command of commands) {
                    const data = new TextEncoder().encode(command);
                    await printer.transferOut(1, data);
                }

                await printer.close();
                return true;
            }

            // Fallback for network printers
            // Replace URL with your printer's network address
            const response = await fetch('http://printer-ip/drawer', {
                method: 'POST',
                body: JSON.stringify({ commands }),
            });

            if (!response.ok) throw new Error('Failed to open cash drawer');
            return true;

        } catch (error) {
            console.error('Error opening cash drawer:', error);
            return false;
        }
    }
};

export default cashDrawerService;