import React, { useState, useEffect } from 'react';
import { Check, AlertCircle, Loader2, Filter } from 'lucide-react';
import { updateDoc, doc, writeBatch } from 'firebase/firestore';

const BulkCategoryAssignment = ({ inventory: initialInventory, categories, storeId, db }) => {
    const [inventory, setInventory] = useState(initialInventory);
    const [selectedItems, setSelectedItems] = useState(new Set());
    const [selectedCategory, setSelectedCategory] = useState('');
    const [filterCategory, setFilterCategory] = useState('all');
    const [isUpdating, setIsUpdating] = useState(false);
    const [updateStatus, setUpdateStatus] = useState(null);

    useEffect(() => {
        if (updateStatus) {
            const timer = setTimeout(() => {
                setUpdateStatus(null);
            }, 3000);
            return () => clearTimeout(timer);
        }
    }, [updateStatus]);

    const handleSelectAll = (checked) => {
        if (checked) {
            const filteredIds = filteredInventory
                .map(item => item.id);
            setSelectedItems(new Set(filteredIds));
        } else {
            setSelectedItems(new Set());
        }
    };

    const handleItemSelect = (itemId) => {
        setSelectedItems(prev => {
            const newSet = new Set(prev);
            if (newSet.has(itemId)) {
                newSet.delete(itemId);
            } else {
                newSet.add(itemId);
            }
            return newSet;
        });
    };

    const handleBulkUpdate = async () => {
        if (!selectedCategory || selectedItems.size === 0) return;

        setIsUpdating(true);
        setUpdateStatus(null);

        try {
            const batch = writeBatch(db);
            const updatedInventory = [...inventory];

            selectedItems.forEach(itemId => {
                const itemRef = doc(db, 'Stores', storeId, 'Inventory', itemId);
                batch.update(itemRef, {
                    categoryId: selectedCategory,
                    updatedAt: new Date()
                });

                const itemIndex = updatedInventory.findIndex(item => item.id === itemId);
                if (itemIndex !== -1) {
                    updatedInventory[itemIndex] = {
                        ...updatedInventory[itemIndex],
                        categoryId: selectedCategory
                    };
                }
            });

            await batch.commit();
            setInventory(updatedInventory);
            setUpdateStatus('success');
            setSelectedItems(new Set());
            setSelectedCategory('');
        } catch (error) {
            console.error('Error updating categories:', error);
            setUpdateStatus('error');
        } finally {
            setIsUpdating(false);
        }
    };

    const filteredInventory = inventory.filter(item => {
        if (filterCategory === 'all') return true;
        if (filterCategory === 'uncategorized') return !item.categoryId;
        return item.categoryId === filterCategory;
    });

    return (
        <div className="-mt-6">
            <div className="sticky -top-8 bg-white z-10 pb-4 space-y-4 shadow-sm">
                <div className="bg-white flex items-center justify-between pt-6">
                    <div className="bg-white flex items-center space-x-4">
                        <div className="bg-white flex items-center space-x-2">
                            <Filter className="h-4 w-4 text-gray-400" />
                            <select
                                value={filterCategory}
                                onChange={(e) => {
                                    setFilterCategory(e.target.value);
                                    setSelectedItems(new Set());
                                }}
                                className="px-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-orange-600"
                            >
                                <option value="all">All Categories</option>
                                <option value="uncategorized">Uncategorized</option>
                                {categories.map(category => (
                                    <option key={category.id} value={category.id}>
                                        {category.name}
                                    </option>
                                ))}
                            </select>
                        </div>

                        <span className="text-gray-300">|</span>

                        <div className="flex items-center space-x-2">
                            <input
                                type="checkbox"
                                checked={selectedItems.size === filteredInventory.length && filteredInventory.length > 0}
                                onChange={(e) => handleSelectAll(e.target.checked)}
                                className="w-4 h-4 rounded border-gray-300"
                            />
                            <span className="text-sm text-gray-600">
                                {selectedItems.size} items selected
                            </span>
                        </div>
                    </div>

                    <div className="flex items-center space-x-4">
                        <select
                            value={selectedCategory}
                            onChange={(e) => setSelectedCategory(e.target.value)}
                            className="px-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-orange-600"
                        >
                            <option value="" className='text-sm'>Change category to...</option>
                            {categories.map(category => (
                                <option key={category.id} value={category.id}>
                                    {category.name}
                                </option>
                            ))}
                        </select>

                        <button
                            onClick={handleBulkUpdate}
                            disabled={!selectedCategory || selectedItems.size === 0 || isUpdating}
                            className="px-4 py-2 bg-gray-600 text-white rounded-lg flex items-center space-x-2 hover:bg-gray-800 transition-colors disabled:bg-gray-300"
                        >
                            {isUpdating ? (
                                <>
                                    <Loader2 className="h-4 w-4 animate-spin" />
                                    <span>Updating...</span>
                                </>
                            ) : (
                                <>
                                    <Check className="h-4 w-4" />
                                    <span>Apply to Selected</span>
                                </>
                            )}
                        </button>
                    </div>
                </div>

                {updateStatus && (
                    <div
                        className={`p-4 rounded-lg ${updateStatus === 'success' ? 'bg-green-50 text-green-700' : 'bg-red-50 text-red-700'
                            } flex items-center space-x-2 transition-opacity duration-300`}
                    >
                        {updateStatus === 'success' ? (
                            <>
                                <Check className="h-5 w-5" />
                                <span>Categories updated successfully</span>
                            </>
                        ) : (
                            <>
                                <AlertCircle className="h-5 w-5" />
                                <span>Failed to update categories. Please try again.</span>
                            </>
                        )}
                    </div>
                )}
            </div>

            <div className="mt-6 space-y-1">
                {filteredInventory.map(item => {
                    const isSelected = selectedItems.has(item.id);
                    return (
                        <div
                            key={item.id}
                            onClick={() => handleItemSelect(item.id)}
                            className="flex items-center py-2 px-4 border rounded-lg bg-white hover:ring-1 hover:ring-orange-600 cursor-pointer"
                        >
                            <div
                                onClick={(e) => e.stopPropagation()}
                                className="mr-4"
                            >
                                <input
                                    type="checkbox"
                                    checked={isSelected}
                                    onChange={() => handleItemSelect(item.id)}
                                    className="w-4 h-4 rounded border-gray-300"
                                />
                            </div>
                            <div className="flex-1 flex items-center justify-between">
                                <div className="flex items-center space-x-2">
                                    <span className="font-medium">{item.itemName}</span>
                                    {item.sku && <span className="text-gray-300">|</span>}
                                    <span className="text-sm text-gray-500">{item.sku}</span>
                                </div>
                                <div className="text-sm text-gray-500">
                                    {categories.find(c => c.id === item.categoryId)?.name || 'Uncategorized'}
                                </div>
                            </div>
                        </div>
                    );
                })}
            </div>
        </div>
    );
};

export default BulkCategoryAssignment;