// SalesDetailsModal.jsx
import React from 'react';
import { X, Download, Printer } from 'lucide-react';
import { generateSalesPDF } from '../generate_sales_receipt_pdf';

const SalesDetailsModal = ({ isOpen, onClose, data, mode }) => {
    if (!isOpen || !data) return null;

    const downloadPDF = async () => {
        try {
            const pdf = generateSalesPDF(data);
            pdf.save(`receipt-${data.orderNumber || data.id}.pdf`);
        } catch (error) {
            console.error('Error downloading PDF:', error);
        }
    };

    const printReceipt = async () => {
        try {
            console.log('Printing receipt for sale:', data);
            // Implementation for printing receipt
        } catch (error) {
            console.error('Error printing receipt:', error);
        }
    };

    return (
        <div className="fixed inset-0 bg-black bg-opacity-50 z-50 flex items-center justify-center">
            <div className="bg-white rounded-lg w-full max-w-2xl mx-4">
                <div className="flex items-center justify-between p-4 border-b">
                    <h2 className="text-xl font-semibold text-gray-800">
                        {mode === 'order' ? 'Order Details' : 'Item Details'}
                    </h2>
                    <div className="flex items-center space-x-4">
                        <button
                            onClick={downloadPDF}
                            className="flex items-center space-x-1 px-3 py-1.5 text-sm font-medium text-red-600 hover:text-red-700 hover:bg-red-50 rounded-lg transition-colors"
                            title="Download PDF"
                        >
                            <Download className="h-4 w-4" />
                            <span>PDF</span>
                        </button>
                        <button
                            onClick={printReceipt}
                            className="flex items-center space-x-1 px-3 py-1.5 text-sm font-medium text-green-600 hover:text-green-700 hover:bg-green-50 rounded-lg transition-colors"
                            title="Print Receipt"
                        >
                            <Printer className="h-4 w-4" />
                            <span>Print</span>
                        </button>
                        <button
                            onClick={onClose}
                            className="p-1 hover:bg-gray-100 rounded-full transition-colors"
                        >
                            <X className="h-5 w-5 text-gray-500" />
                        </button>
                    </div>
                </div>

                <div className="p-6">
                    {mode === 'order' && data ? (
                        <OrderDetails data={data} />
                    ) : mode === 'item' && data ? (
                        <ItemDetails data={data} />
                    ) : (
                        <div className="text-center text-gray-500">No data available</div>
                    )}
                </div>

                <div className="flex justify-end p-4 border-t bg-gray-50 rounded-b-lg">
                    <button
                        onClick={onClose}
                        className="px-4 py-2 text-sm font-medium text-gray-600 hover:text-gray-800"
                    >
                        Close
                    </button>
                </div>
            </div>
        </div>
    );
};

const OrderDetails = ({ data }) => (
    <div className="space-y-6">
        <div className="grid grid-cols-2 gap-4">
            <div className="space-y-1">
                <p className="text-sm text-gray-500">Order Number</p>
                <p className="font-medium">
                    {data.orderNumber || `SR${String(parseInt(data.id.slice(-6), 16)).padStart(6, '0')}`}
                </p>
            </div>
            <div className="space-y-1">
                <p className="text-sm text-gray-500">Date</p>
                <p className="font-medium">{data.saleDate.toLocaleString()}</p>
            </div>
            <div className="space-y-1">
                <p className="text-sm text-gray-500">Total Amount</p>
                <p className="font-medium">
                    <span className="text-xs mr-1">GHS</span>
                    {data.totalAmount.toFixed(2)}
                </p>
            </div>
            <div className="space-y-1">
                <p className="text-sm text-gray-500">Items Count</p>
                <p className="font-medium">{data.items.length} items</p>
            </div>
        </div>

        <div className="mt-6">
            <h3 className="text-lg font-medium mb-3">Order Items</h3>
            <div className="border rounded-lg overflow-hidden">
                <table className="w-full">
                    <thead className="bg-gray-50">
                        <tr>
                            <th className="px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase">Item</th>
                            <th className="px-4 py-3 text-right text-xs font-medium text-gray-500 uppercase">Quantity</th>
                            <th className="px-4 py-3 text-right text-xs font-medium text-gray-500 uppercase">Price</th>
                            <th className="px-4 py-3 text-right text-xs font-medium text-gray-500 uppercase">Total</th>
                        </tr>
                    </thead>
                    <tbody className="divide-y divide-gray-200">
                        {data.items.map((item, index) => (
                            <tr key={index} className="hover:bg-gray-50">
                                <td className="px-4 py-3 text-sm text-gray-900">{item.itemName}</td>
                                <td className="px-4 py-3 text-sm text-gray-900 text-right">{item.quantity}</td>
                                <td className="px-4 py-3 text-sm text-gray-900 text-right">
                                    <span className="text-xs mr-1">GHS</span>
                                    {item.price.toFixed(2)}
                                </td>
                                <td className="px-4 py-3 text-sm text-gray-900 text-right">
                                    <span className="text-xs mr-1">GHS</span>
                                    {(item.price * item.quantity).toFixed(2)}
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        </div>
    </div>
);

const ItemDetails = ({ data }) => {
    if (!data) return null;

    return (
        <div className="space-y-6">
            <div className="grid grid-cols-2 gap-4">
                <div className="space-y-1">
                    <p className="text-sm text-gray-500">Order Number</p>
                    <p className="font-medium">
                        {data.orderNumber || data.id || 'N/A'}
                    </p>
                </div>
                <div className="space-y-1">
                    <p className="text-sm text-gray-500">Date</p>
                    <p className="font-medium">
                        {data.saleDate ? data.saleDate.toLocaleString() : 'N/A'}
                    </p>
                </div>
            </div>

            <div className="bg-gray-50 p-4 rounded-lg space-y-4">
                <h3 className="text-lg font-medium">Item Details</h3>
                <div className="grid grid-cols-2 gap-4">
                    <div className="space-y-1">
                        <p className="text-sm text-gray-500">Item Name</p>
                        <p className="font-medium">{data.itemName || 'N/A'}</p>
                    </div>
                    <div className="space-y-1">
                        <p className="text-sm text-gray-500">Quantity</p>
                        <p className="font-medium">{data.quantity || 0}</p>
                    </div>
                    <div className="space-y-1">
                        <p className="text-sm text-gray-500">Unit Price</p>
                        <p className="font-medium">
                            <span className="text-xs mr-1">GHS</span>
                            {(data.price || 0).toFixed(2)}
                        </p>
                    </div>
                    <div className="space-y-1">
                        <p className="text-sm text-gray-500">Total Amount</p>
                        <p className="font-medium">
                            <span className="text-xs mr-1">GHS</span>
                            {((data.price || 0) * (data.quantity || 0)).toFixed(2)}
                        </p>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default SalesDetailsModal;