import { useState } from 'react';
import { Pencil, Loader2, ShoppingCart, X, Minus, Plus, Lock } from 'lucide-react';
import { getFirestore, doc, updateDoc } from 'firebase/firestore';
import { useAuth } from '../../../auth/AuthContext';

const PriceEditModal = ({ isOpen, onClose, item, onSave, storeId }) => {
    const [newPrice, setNewPrice] = useState(item.sellingPrice);
    const [newCostPrice, setNewCostPrice] = useState(item.costPrice);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [error, setError] = useState(null);
    const db = getFirestore();

    const handleSubmit = async (e) => {
        e.preventDefault();
        setIsSubmitting(true);
        setError(null);

        if (parseFloat(newCostPrice) > parseFloat(newPrice)) {
            setError('Cost price cannot be greater than selling price');
            setIsSubmitting(false);
            return;
        }

        try {
            // Update Firestore inventory
            const inventoryRef = doc(db, 'Stores', storeId, 'Inventory', item.id);
            await updateDoc(inventoryRef, {
                sellingPrice: parseFloat(newPrice),
                costPrice: parseFloat(newCostPrice)
            });

            // Update local cart state
            onSave(item.id, parseFloat(newPrice), parseFloat(newCostPrice));
            onClose();
        } catch (err) {
            console.error('Error updating prices:', err);
            setError('Failed to update prices. Please try again.');
        } finally {
            setIsSubmitting(false);
        }
    };

    if (!isOpen) return null;

    return (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center p-4 z-50">
            <div className="bg-white rounded-lg p-6 max-w-sm w-full">
                <h3 className="text-lg font-semibold mb-2">Edit Prices</h3>
                <h3 className="text-sm font-semibold mb-4">{item.itemName}</h3>

                {error && (
                    <div className="mb-4 p-3 bg-red-50 border border-red-200 rounded-lg text-red-600 text-sm">
                        {error}
                    </div>
                )}

                <form onSubmit={handleSubmit}>
                    <div className="space-y-4">
                        <div>
                            <label className="block text-sm font-medium text-gray-700 mb-1">
                                Cost Price (GHS)
                            </label>
                            <input
                                type="number"
                                step="0.01"
                                min="0"
                                value={newCostPrice}
                                onChange={(e) => setNewCostPrice(e.target.value)}
                                className="w-full p-2 border border-gray-300 rounded-md focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                                disabled={isSubmitting}
                            />
                        </div>

                        <div>
                            <label className="block text-sm font-medium text-gray-700 mb-1">
                                Selling Price (GHS)
                            </label>
                            <input
                                type="number"
                                step="0.01"
                                min="0"
                                value={newPrice}
                                onChange={(e) => setNewPrice(e.target.value)}
                                className="w-full p-2 border border-gray-300 rounded-md focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                                disabled={isSubmitting}
                            />
                        </div>
                    </div>

                    <div className="flex justify-end space-x-3 mt-6">
                        <button
                            type="button"
                            onClick={onClose}
                            className="px-4 py-2 text-gray-700 border border-gray-300 rounded-md hover:bg-gray-50"
                            disabled={isSubmitting}
                        >
                            Cancel
                        </button>
                        <button
                            type="submit"
                            disabled={isSubmitting}
                            className="px-4 py-2 bg-blue-500 text-white rounded-md hover:bg-blue-600 disabled:bg-blue-300 flex items-center justify-center min-w-[5rem]"
                        >
                            {isSubmitting ? (
                                <Loader2 className="w-5 h-5 animate-spin" />
                            ) : (
                                'Save'
                            )}
                        </button>
                    </div>
                </form>
            </div>
        </div>
    );
};

const CartItems = ({ saleItems, updateQuantity, removeFromSale, updateItemPrice, storeId }) => {
    const [editingItem, setEditingItem] = useState(null);
    const { hasFullAccess } = useAuth();

    const handlePriceUpdate = (itemId, newPrice) => {
        updateItemPrice(itemId, newPrice);
    };

    const PriceDisplay = ({ item }) => {
        if (hasFullAccess) {
            return (
                <div className="flex items-center space-x-2 text-sm text-gray-500">
                    <span>GHS {item.sellingPrice.toFixed(2)} each</span>
                    <button
                        onClick={() => setEditingItem(item)}
                        className="p-1 text-gray-400 hover:text-blue-500 rounded-full hover:bg-blue-50 transition-colors"
                    >
                        <Pencil size={14} />
                    </button>
                </div>
            );
        }

        return (
            <div className="flex items-center space-x-2 text-sm text-gray-500">
                <span>GHS {item.sellingPrice.toFixed(2)} each</span>
                <span className="p-1 text-gray-400" title="Only administrators can edit prices">
                    <Lock size={14} />
                </span>
            </div>
        );
    };

    return (
        <div className="flex-1 overflow-y-auto mb-4 px-0 pr-5">
            {saleItems.length === 0 ? (
                <div className="text-center text-gray-500 py-8">
                    <ShoppingCart size={48} className="mx-auto mb-4" />
                    <p className="text-lg mb-2">No items in cart</p>
                    <p className="text-sm text-gray-400">
                        Add items from the inventory or scan barcode
                    </p>
                </div>
            ) : (
                <div className="space-y-5 py-5">
                    {saleItems.map(item => (
                        <div key={item.id} className="relative">
                            <button
                                onClick={(e) => removeFromSale(item.id, e)}
                                className="absolute -top-3 -right-2 p-1 ring-1 ring-gray-300 bg-white hover:bg-gray-300 text-gray-900 rounded-full shadow-md transition-colors z-10"
                            >
                                <X size={14} />
                            </button>
                            <div className="flex items-center justify-between p-3 bg-gray-50 rounded-lg border border-gray-200">
                                <div className="flex-1 pr-8">
                                    <h4 className="font-medium">{item.itemName}</h4>
                                    <PriceDisplay item={item} />
                                </div>

                                <div className="flex items-center space-x-3 mr-5">
                                    <button
                                        onClick={(e) => updateQuantity(item.id, item.quantity - 1, e)}
                                        className="p-1 rounded-full hover:bg-gray-200 transition-colors"
                                    >
                                        <Minus size={16} />
                                    </button>
                                    <span className="w-8 text-center">{item.quantity}</span>
                                    <button
                                        onClick={(e) => updateQuantity(item.id, item.quantity + 1, e)}
                                        className="p-1 rounded-full hover:bg-gray-200 transition-colors"
                                    >
                                        <Plus size={16} />
                                    </button>
                                </div>
                            </div>
                        </div>
                    ))}

                    {editingItem && hasFullAccess && (
                        <PriceEditModal
                            isOpen={!!editingItem}
                            onClose={() => setEditingItem(null)}
                            item={editingItem}
                            onSave={handlePriceUpdate}
                            storeId={storeId}
                        />
                    )}
                </div>
            )}
        </div>
    );
};

export default CartItems;