import React, { useState, useEffect, useMemo } from 'react';
import { LineChart, Line, XAxis, YAxis, ResponsiveContainer, Tooltip, CartesianGrid, Area } from 'recharts';
import { getFirestore, collection, query, where, getDocs, Timestamp, orderBy, getDoc, doc } from 'firebase/firestore';
import { Info, Loader2, ArrowRight, Plus, Package, CrownIcon } from 'lucide-react';
import { useAuth } from '../../../auth/AuthContext';

// Restricted Access Component
const RestrictedAccess = () => (
    <div className="flex pt-16 bg-white">
        <div className="flex-1 flex items-center justify-center">
            <div className="text-center p-8">
                <CrownIcon className="h-16 w-16 text-yellow-500 mx-auto mb-4" />
                <h1 className="text-2xl font-semibold mb-2">Pro Feature</h1>
                <p className="text-gray-600 mb-4">
                    The Sales Analytics feature is only available on Pro and Enterprise plans.
                </p>
                <p className="text-gray-500 text-sm">
                    Please upgrade your plan to access detailed sales analytics and insights.
                </p>
            </div>
        </div>
    </div>
);

const MetricBox = ({ value, label, comparison, onClick, isSelected }) => (
    <div
        className={`text-center border-r last:border-r-0 cursor-pointer transition-colors duration-200 ${isSelected ? 'bg-blue-50' : 'hover:bg-gray-50'
            }`}
        onClick={onClick}
    >
        <div className="space-y-2 py-4">
            <div className="text-sm text-gray-500">{label}</div>
            <div className="text-2xl font-semibold">{value}</div>
            {comparison && <div className="text-xs text-gray-400">{comparison}</div>}
        </div>
    </div>
);

const InfoTooltip = ({ text, bulletPoints }) => {
    return (
        <div className="group relative inline-block">
            <Info className="h-4 w-4 text-gray-400 hover:text-gray-600 cursor-help" />
            <div className="opacity-0 invisible group-hover:opacity-100 group-hover:visible transition-opacity duration-200 absolute z-10 w-64 right-0 mt-2 p-4 bg-white border rounded-lg shadow-lg text-sm">
                <p className="text-gray-600 mb-2">{text}</p>
                {bulletPoints && (
                    <ul className="list-disc pl-4 text-gray-600 space-y-1">
                        {bulletPoints.map((point, index) => (
                            <li key={index}>{point}</li>
                        ))}
                    </ul>
                )}
            </div>
        </div>
    );
};

const ProductImage = ({ imageUrl, altText }) => {
    const [hasError, setHasError] = useState(false);

    return (
        <div className="w-8 h-8 bg-gray-100 rounded flex-shrink-0 flex items-center justify-center">
            {!hasError && imageUrl ? (
                <img
                    src={imageUrl}
                    alt={altText}
                    className="w-full h-full object-cover rounded"
                    onError={() => setHasError(true)}
                />
            ) : (
                <Package className="w-5 h-5 text-gray-400" />
            )}
        </div>
    );
};

const SectionHeader = ({ title, description, bulletPoints, timeRange }) => (
    <div>
        <div className="flex justify-between items-start mb-1">
            <h3 className="text-lg font-semibold">{title}</h3>
            <InfoTooltip text={description} bulletPoints={bulletPoints} />
        </div>
        {timeRange && (
            <div className="text-xs text-gray-500 mb-4">{timeRange}</div>
        )}
    </div>
);

const BasketAnalysis = ({ data, storeId, timeRange }) => {
    const [itemImages, setItemImages] = useState({});
    const [isLoadingImages, setIsLoadingImages] = useState(true);

    const processBasketAnalysis = (sales) => {
        if (!sales || sales.length === 0) return [];

        const itemPairs = {};

        sales.forEach(sale => {
            if (!sale.items || !Array.isArray(sale.items)) return;

            const uniqueItems = Array.from(new Set(
                sale.items.map(item => ({
                    name: item.itemName || item.name,
                    inventoryItemId: item.inventoryItemId
                }))
            )).filter(item => item.name);

            for (let i = 0; i < uniqueItems.length; i++) {
                for (let j = i + 1; j < uniqueItems.length; j++) {
                    const pairKey = [uniqueItems[i].name, uniqueItems[j].name].sort().join('___');

                    if (!itemPairs[pairKey]) {
                        itemPairs[pairKey] = {
                            items: [
                                {
                                    name: uniqueItems[i].name,
                                    inventoryItemId: uniqueItems[i].inventoryItemId
                                },
                                {
                                    name: uniqueItems[j].name,
                                    inventoryItemId: uniqueItems[j].inventoryItemId
                                }
                            ],
                            count: 0
                        };
                    }

                    itemPairs[pairKey].count++;
                }
            }
        });

        return Object.values(itemPairs)
            .sort((a, b) => b.count - a.count)
            .slice(0, 5);
    };

    const topPairs = useMemo(() => processBasketAnalysis(data.currentPeriod), [data.currentPeriod]);

    useEffect(() => {
        const fetchInventoryImages = async () => {
            if (!topPairs || topPairs.length === 0) {
                setIsLoadingImages(false);
                return;
            }

            const db = getFirestore();
            setIsLoadingImages(true);

            try {
                const inventoryIds = new Set(
                    topPairs.flatMap(pair =>
                        pair.items.map(item => item.inventoryItemId)
                    ).filter(Boolean)
                );

                if (inventoryIds.size === 0) {
                    setIsLoadingImages(false);
                    return;
                }

                const imagePromises = Array.from(inventoryIds).map(async (inventoryId) => {
                    const inventoryRef = doc(db, 'Stores', storeId, 'Inventory', inventoryId);
                    const inventoryDoc = await getDoc(inventoryRef);

                    if (inventoryDoc.exists()) {
                        return [inventoryId, inventoryDoc.data().imageUrl];
                    }
                    return [inventoryId, null];
                });

                const imageResults = await Promise.all(imagePromises);
                const newImageUrls = Object.fromEntries(imageResults);

                setItemImages(newImageUrls);
            } catch (error) {
                console.error('Error fetching inventory images:', error);
            } finally {
                setIsLoadingImages(false);
            }
        };

        fetchInventoryImages();
    }, [topPairs, storeId]);

    return (
        <div className="bg-white p-6 shadow-sm border">
            <SectionHeader
                title="Products customers are buying together"
                description="Shows which items are frequently purchased together, helping with:"
                bulletPoints={[
                    "Store layout optimization",
                    "Cross-promotion opportunities",
                    "Bundle deal suggestions"
                ]}
                timeRange={`Last ${Math.ceil((new Date(timeRange.end) - new Date(timeRange.start)) / (1000 * 60 * 60 * 24))} days`}
            />
            <div className="mt-4">
                <div className="text-sm font-medium text-gray-600 mb-4 pt-2">Most Frequent Combinations</div>
                <div className="space-y-3">
                    {isLoadingImages ? (
                        <div className="flex items-center justify-center py-8">
                            <Loader2 className="w-6 h-6 animate-spin text-gray-400" />
                        </div>
                    ) : topPairs.length > 0 ? (
                        topPairs.map((pair, index) => (
                            <div
                                key={index}
                                className="p-4 bg-gray-50 rounded-lg hover:bg-gray-100 transition-colors duration-200"
                            >
                                <div className="flex items-center gap-3">
                                    {/* Left product */}
                                    <div className="flex-1 flex gap-2 items-center bg-white rounded p-2">
                                        <ProductImage
                                            imageUrl={itemImages[pair.items[0].inventoryItemId]}
                                            altText={pair.items[0].name}
                                        />
                                        <span className="text-xs text-gray-900 font-medium overflow-hidden whitespace-nowrap overflow-ellipsis max-w-[150px]">
                                            {pair.items[0].name}
                                        </span>
                                    </div>

                                    {/* Plus sign */}
                                    <div className="flex-shrink-0">
                                        <Plus className="h-4 w-4 text-gray-400" />
                                    </div>

                                    {/* Right product */}
                                    <div className="flex-1 flex gap-2 items-center bg-white rounded p-2">
                                        <ProductImage
                                            imageUrl={itemImages[pair.items[1].inventoryItemId]}
                                            altText={pair.items[1].name}
                                        />
                                        <span className="text-xs text-gray-900 font-medium overflow-hidden whitespace-nowrap overflow-ellipsis max-w-[150px]">
                                            {pair.items[1].name}
                                        </span>
                                    </div>
                                </div>

                                <div className="text-xs text-gray-500 mt-3 text-center">
                                    Purchased together in {pair.count} {pair.count === 1 ? 'order' : 'orders'}
                                </div>
                            </div>
                        ))
                    ) : (
                        <div className="text-gray-500 text-center py-8 bg-gray-50 rounded-lg">
                            No co-purchase patterns found in this period
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};

const SalesReports = ({ timeRange, storeId }) => {
    const { hasProAccess } = useAuth();
    const [selectedMetric, setSelectedMetric] = useState('grossSales');
    const [isLoading, setIsLoading] = useState(true);
    const [sixMonthData, setSixMonthData] = useState([]);
    const [sixMonthLoading, setSixMonthLoading] = useState(true);
    const [sixMonthError, setSixMonthError] = useState(null);
    const [error, setError] = useState(null);
    const [data, setData] = useState({
        currentPeriod: [],
        previousPeriod: []
    });

    const db = getFirestore();

    useEffect(() => {
        const fetchData = async () => {
            if (!storeId || !timeRange.start || !timeRange.end) return;

            setIsLoading(true);
            try {
                // Calculate current period dates
                const currentStartDate = new Date(timeRange.start);
                currentStartDate.setHours(0, 0, 0, 0);
                const currentEndDate = new Date(timeRange.end);
                currentEndDate.setHours(23, 59, 59, 999);

                // Calculate previous period dates
                const daysDifference = Math.ceil((currentEndDate - currentStartDate) / (1000 * 60 * 60 * 24));
                const previousEndDate = new Date(currentStartDate);
                previousEndDate.setDate(previousEndDate.getDate() - 1);
                const previousStartDate = new Date(previousEndDate);
                previousStartDate.setDate(previousStartDate.getDate() - daysDifference + 1);

                // Query for current period
                const currentPeriodQuery = query(
                    collection(db, 'Stores', storeId, 'Sales'),
                    where('saleDate', '>=', Timestamp.fromDate(currentStartDate)),
                    where('saleDate', '<=', Timestamp.fromDate(currentEndDate))
                );

                // Query for previous period
                const previousPeriodQuery = query(
                    collection(db, 'Stores', storeId, 'Sales'),
                    where('saleDate', '>=', Timestamp.fromDate(previousStartDate)),
                    where('saleDate', '<=', Timestamp.fromDate(previousEndDate))
                );

                // Fetch both periods
                const [currentPeriodDocs, previousPeriodDocs] = await Promise.all([
                    getDocs(currentPeriodQuery),
                    getDocs(previousPeriodQuery)
                ]);

                setData({
                    currentPeriod: currentPeriodDocs.docs.map(doc => ({
                        ...doc.data(),
                        id: doc.id
                    })),
                    previousPeriod: previousPeriodDocs.docs.map(doc => ({
                        ...doc.data(),
                        id: doc.id
                    }))
                });
            } catch (err) {
                setError('Failed to load sales data');
                console.error('Error loading sales data:', err);
            } finally {
                setIsLoading(false);
            }
        };

        fetchData();
    }, [timeRange, storeId, db]);

    const processedData = useMemo(() => {
        const processPeriodData = (sales) => {
            let totalGrossSales = 0;
            let totalItemsSold = 0;
            let totalCogs = 0;
            const dailyTotals = {};

            sales.forEach(sale => {
                const saleDate = sale.saleDate.toDate();
                const dateKey = saleDate.toISOString().split('T')[0];

                if (!dailyTotals[dateKey]) {
                    dailyTotals[dateKey] = {
                        grossSales: 0,
                        itemsSold: 0,
                        grossProfit: 0
                    };
                }

                sale.items.forEach(item => {
                    const revenue = item.price * item.quantity;
                    const cogs = (item.costPrice || 0) * item.quantity;
                    const quantity = item.quantity;

                    dailyTotals[dateKey].grossSales += revenue;
                    dailyTotals[dateKey].itemsSold += quantity;
                    dailyTotals[dateKey].grossProfit += (revenue - cogs);

                    totalGrossSales += revenue;
                    totalItemsSold += quantity;
                    totalCogs += cogs;
                });
            });

            return {
                totalGrossSales,
                totalItemsSold,
                totalGrossProfit: totalGrossSales - totalCogs,
                dailyTotals
            };
        };

        const currentPeriodData = processPeriodData(data.currentPeriod);
        const previousPeriodData = processPeriodData(data.previousPeriod);

        // Calculate growth rates
        const calculateGrowth = (current, previous) => {
            return previous === 0 ? 100 : ((current - previous) / previous) * 100;
        };

        const salesGrowth = calculateGrowth(
            currentPeriodData.totalGrossSales,
            previousPeriodData.totalGrossSales
        );

        const itemsGrowth = calculateGrowth(
            currentPeriodData.totalItemsSold,
            previousPeriodData.totalItemsSold
        );

        const profitGrowth = calculateGrowth(
            currentPeriodData.totalGrossProfit,
            previousPeriodData.totalGrossProfit
        );

        // Create chart data array
        const startDate = new Date(timeRange.start);
        const endDate = new Date(timeRange.end);
        const salesData = [];
        let currentDate = new Date(startDate);

        while (currentDate <= endDate) {
            const dateKey = currentDate.toISOString().split('T')[0];
            salesData.push({
                date: dateKey,
                grossSales: currentPeriodData.dailyTotals[dateKey]?.grossSales || 0,
                itemsSold: currentPeriodData.dailyTotals[dateKey]?.itemsSold || 0,
                grossProfit: currentPeriodData.dailyTotals[dateKey]?.grossProfit || 0,
                formattedDate: currentDate.toLocaleDateString('en-US', {
                    month: 'short',
                    day: 'numeric'
                })
            });

            currentDate.setDate(currentDate.getDate() + 1);
        }

        return {
            metrics: {
                grossSales: currentPeriodData.totalGrossSales,
                itemsSold: currentPeriodData.totalItemsSold,
                grossProfit: currentPeriodData.totalGrossProfit,
                salesGrowth,
                itemsGrowth,
                profitGrowth,
                previousPeriodSales: previousPeriodData.totalGrossSales,
                previousPeriodItems: previousPeriodData.totalItemsSold,
                previousPeriodProfit: previousPeriodData.totalGrossProfit
            },
            salesData
        };
    }, [data, timeRange]);

    useEffect(() => {
        const fetchSixMonthData = async () => {
            if (!storeId) return;

            setSixMonthLoading(true);
            try {
                const db = getFirestore();
                const now = new Date();
                const sixMonthsAgo = new Date(now.getFullYear(), now.getMonth() - 5, 1);
                sixMonthsAgo.setHours(0, 0, 0, 0);

                const salesQuery = query(
                    collection(db, 'Stores', storeId, 'Sales'),
                    where('saleDate', '>=', Timestamp.fromDate(sixMonthsAgo)),
                    orderBy('saleDate', 'desc')
                );

                const salesDocs = await getDocs(salesQuery);
                setSixMonthData(salesDocs.docs.map(doc => ({
                    ...doc.data(),
                    id: doc.id
                })));
            } catch (err) {
                setSixMonthError('Failed to load 6-month sales data');
                console.error('Error loading 6-month sales data:', err);
            } finally {
                setSixMonthLoading(false);
            }
        };

        fetchSixMonthData();
    }, [storeId]);

    const getChartData = (metric) => {
        switch (metric) {
            case 'grossSales':
                return {
                    data: processedData.salesData,
                    dataKey: 'grossSales',
                    label: 'Gross Sales',
                    color: '#4299E1'
                };
            case 'itemsSold':
                return {
                    data: processedData.salesData,
                    dataKey: 'itemsSold',
                    label: 'Items Sold',
                    color: '#48BB78'
                };
            case 'grossProfit':
                return {
                    data: processedData.salesData,
                    dataKey: 'grossProfit',
                    label: 'Gross Profit',
                    color: '#ED8936'
                };
            case 'salesGrowth':
                return {
                    data: processedData.salesData,
                    dataKey: 'grossSales',
                    label: 'Sales Growth',
                    color: '#F56565'
                };
            default:
                return {
                    data: processedData.salesData,
                    dataKey: 'grossSales',
                    label: 'Gross Sales',
                    color: '#4299E1'
                };
        }
    };

    const processBasketAnalysis = (sales) => {
        const itemPairs = {};

        sales.forEach(sale => {
            if (!sale.items || !Array.isArray(sale.items)) return;

            const uniqueItems = Array.from(new Set(
                sale.items.map(item => ({
                    name: item.itemName || item.name,
                    imageUrl: item.imageUrl
                }))
            )).filter(item => item.name); // Filter out any undefined/null names

            for (let i = 0; i < uniqueItems.length; i++) {
                for (let j = i + 1; j < uniqueItems.length; j++) {
                    const pairKey = [uniqueItems[i].name, uniqueItems[j].name].sort().join('___');

                    if (!itemPairs[pairKey]) {
                        itemPairs[pairKey] = {
                            items: [
                                { name: uniqueItems[i].name, imageUrl: uniqueItems[i].imageUrl },
                                { name: uniqueItems[j].name, imageUrl: uniqueItems[j].imageUrl }
                            ],
                            count: 0
                        };
                    }

                    itemPairs[pairKey].count++;
                }
            }
        });

        return Object.values(itemPairs)
            .sort((a, b) => b.count - a.count)
            .slice(0, 5);
    };

    const processSeasonalAnalysis = (sales) => {
        const monthlyData = {};

        sales.forEach(sale => {
            const month = sale.saleDate.toDate().getMonth();
            monthlyData[month] = monthlyData[month] || { total: 0, count: 0 };

            sale.items.forEach(item => {
                monthlyData[month].total += item.price * item.quantity;
                monthlyData[month].count += item.quantity;
            });
        });

        return monthlyData;
    };

    const processCustomerBehavior = (sales) => {
        const hourlyData = {};
        const weeklyData = {};

        // Initialize weekday data
        ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'].forEach(day => {
            weeklyData[day] = { total: 0, transactions: 0 };
        });

        sales.forEach(sale => {
            const saleDate = sale.saleDate.toDate();

            // Skip if sale is from today or yesterday
            const today = new Date();
            const yesterday = new Date(today);
            yesterday.setDate(yesterday.getDate() - 1);

            if (saleDate.toDateString() === today.toDateString() ||
                saleDate.toDateString() === yesterday.toDateString()) {
                return;
            }

            // Process hourly data
            const hour = saleDate.getHours();
            hourlyData[hour] = hourlyData[hour] || { total: 0, transactions: 0 };
            hourlyData[hour].total += 1;
            hourlyData[hour].transactions += 1;

            // Process weekly data
            const dayName = saleDate.toLocaleString('en-US', { weekday: 'long' });
            weeklyData[dayName].total += 1;
            weeklyData[dayName].transactions += 1;
        });

        return { hourlyData, weeklyData };
    };

    const process6MonthPerformance = useMemo(() => {
        const monthlyData = {};
        const now = new Date();

        // Initialize last 6 months
        for (let i = 5; i >= 0; i--) {
            const date = new Date(now.getFullYear(), now.getMonth() - i, 1);
            const monthKey = date.toLocaleString('default', { month: 'short' });
            monthlyData[monthKey] = 0;
        }

        // Fill in actual data
        sixMonthData.forEach(sale => {
            const date = sale.saleDate.toDate();
            const monthKey = date.toLocaleString('default', { month: 'short' });
            if (monthlyData.hasOwnProperty(monthKey)) {
                sale.items.forEach(item => {
                    monthlyData[monthKey] += item.price * item.quantity;
                });
            }
        });

        return monthlyData;
    }, [sixMonthData]);

    const renderCustomerBehavior = () => {
        const behaviorData = processCustomerBehavior(data.currentPeriod);
        const { hourlyData, weeklyData } = behaviorData;

        // Find max transactions for scaling
        const maxHourlyTransactions = Math.max(...Object.values(hourlyData).map(data => data.transactions));
        const maxWeeklyTransactions = Math.max(...Object.values(weeklyData).map(data => data.transactions));

        // Create array of all hours
        const hours = Array.from({ length: 24 }, (_, i) => i);

        // Week days in order
        const weekDays = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'];

        // Function to get color intensity based on transaction count
        const getIntensity = (transactions, maxTransactions) => {
            if (!transactions) return 'bg-gray-100';
            const ratio = transactions / maxTransactions;
            if (ratio > 0.75) return 'bg-blue-500';
            if (ratio > 0.5) return 'bg-blue-400';
            if (ratio > 0.25) return 'bg-blue-300';
            return 'bg-blue-200';
        };

        // Function to format hour for display
        const formatHour = (hour) => {
            const period = hour >= 12 ? 'PM' : 'AM';
            const displayHour = hour % 12 || 12;
            return `${displayHour}${period}`;
        };

        // Skip rendering weekly data if it's empty (meaning only today/yesterday data exists)
        const hasHistoricalData = Object.values(weeklyData).some(data => data.transactions > 0);

        return (
            <div className="bg-white p-6 shadow-sm border">
                <SectionHeader
                    title="When customers are buying products"
                    description="Analyzes shopping patterns like:"
                    bulletPoints={[
                        "Peak shopping hours and days",
                        "Periodic buying patterns",
                    ]}
                    timeRange={`Last ${Math.ceil((new Date(timeRange.end) - new Date(timeRange.start)) / (1000 * 60 * 60 * 24))} days`}
                />

                <div className="mt-6">
                    <div className="text-sm font-medium mb-3 text-gray-600">Transaction Activity by Hour</div>

                    {/* Hourly view */}
                    <div className="flex flex-col gap-1 w-full">
                        {/* AM Hours */}
                        <div className="flex items-center w-full">
                            <div className="w-8 text-xs text-gray-500 text-center">AM</div>
                            <div className="flex-1 grid grid-cols-12 gap-1 ml-2">
                                {hours.slice(0, 12).map(hour => (
                                    <div key={hour} className="relative group">
                                        <div
                                            className={`h-8 rounded-sm ${getIntensity(hourlyData[hour]?.transactions, maxHourlyTransactions)} 
                                                transition-colors duration-200`}
                                        />
                                        <div className="opacity-0 group-hover:opacity-100 transition-opacity absolute bottom-full left-1/2 transform -translate-x-1/2 mb-2 px-2 py-1 text-xs text-white bg-gray-800 rounded pointer-events-none whitespace-nowrap z-10">
                                            {formatHour(hour)}: {hourlyData[hour]?.transactions || 0} transactions
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>

                        {/* PM Hours */}
                        <div className="flex items-center w-full">
                            <div className="w-8 text-xs text-gray-500 text-center">PM</div>
                            <div className="flex-1 grid grid-cols-12 gap-1 ml-2">
                                {hours.slice(12, 24).map(hour => (
                                    <div key={hour} className="relative group">
                                        <div
                                            className={`h-8 rounded-sm ${getIntensity(hourlyData[hour]?.transactions, maxHourlyTransactions)} 
                                                transition-colors duration-200`}
                                        />
                                        <div className="opacity-0 group-hover:opacity-100 transition-opacity absolute bottom-full left-1/2 transform -translate-x-1/2 mb-2 px-2 py-1 text-xs text-white bg-gray-800 rounded pointer-events-none whitespace-nowrap z-10">
                                            {formatHour(hour)}: {hourlyData[hour]?.transactions || 0} transactions
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>


                        {/* Weekly view - only show if there's historical data */}
                        {hasHistoricalData && (
                            <>
                                <div className="text-sm font-medium mt-7 text-gray-600">Transaction Activity by Day</div>
                                <div className="flex items-center w-full mt-1">
                                    <div className="w-8 text-xs text-gray-500 text-center -mt-1">Day</div>
                                    <div className="flex-1 grid grid-cols-7 gap-1 ml-2">
                                        {weekDays.map(day => (
                                            <div key={day} className="relative group">
                                                <div
                                                    className={`h-8 rounded-sm ${getIntensity(weeklyData[day]?.transactions, maxWeeklyTransactions)} 
                                                    transition-colors duration-200`}
                                                />
                                                <div className="mt-1 text-xs text-center text-gray-500">{day.slice(0, 3)}</div>
                                                <div className="opacity-0 group-hover:opacity-100 transition-opacity absolute bottom-full left-1/2 transform -translate-x-1/2 mb-2 px-2 py-1 text-xs text-white bg-gray-800 rounded pointer-events-none whitespace-nowrap z-10">
                                                    {day}: {weeklyData[day]?.transactions || 0} transactions
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            </>

                        )}
                    </div>

                    {/* Legend */}
                    <div className="mt-6 flex items-center justify-end text-xs text-gray-500">
                        <span className="mr-2">Less</span>
                        <div className="flex gap-1">
                            <div className="w-3 h-3 rounded-sm bg-gray-100"></div>
                            <div className="w-3 h-3 rounded-sm bg-blue-200"></div>
                            <div className="w-3 h-3 rounded-sm bg-blue-300"></div>
                            <div className="w-3 h-3 rounded-sm bg-blue-400"></div>
                            <div className="w-3 h-3 rounded-sm bg-blue-500"></div>
                        </div>
                        <span className="ml-2">More</span>
                    </div>

                    {/* Peak hours and days summary */}
                    <div className="mt-4 text-sm text-gray-600">
                        {maxHourlyTransactions > 0 && (
                            <div>
                                <span className="font-medium">Peak Hours: </span>
                                {Object.entries(hourlyData)
                                    .filter(([, data]) => data.transactions > maxHourlyTransactions * 0.75)
                                    .map(([hour]) => formatHour(parseInt(hour)))
                                    .join(', ')}
                            </div>
                        )}
                        {hasHistoricalData && maxWeeklyTransactions > 0 && (
                            <div className="mt-2">
                                <span className="font-medium">Busiest Days: </span>
                                {Object.entries(weeklyData)
                                    .filter(([, data]) => data.transactions > maxWeeklyTransactions * 0.75)
                                    .map(([day]) => day)
                                    .join(', ')}
                            </div>
                        )}
                    </div>
                </div>
            </div>
        );
    };

    const render6MonthPerformance = () => {
        if (sixMonthLoading) {
            return (
                <div className="bg-white p-6 shadow-sm border">
                    <div className="flex items-center justify-center h-64">
                        <Loader2 className="h-6 w-6 animate-spin text-gray-600" />
                    </div>
                </div>
            );
        }

        if (sixMonthError) {
            return (
                <div className="bg-white p-6 shadow-sm border">
                    <div className="flex items-center justify-center h-64 text-red-500">
                        {sixMonthError}
                    </div>
                </div>
            );
        }

        const maxValue = Math.max(...Object.values(process6MonthPerformance));

        return (
            <div className="bg-white p-6 shadow-sm border">
                <SectionHeader
                    title="How much sales you're earning"
                    description="Overview of sales performance over the past 6 months"
                    timeRange="Last 6 months"
                />
                <div className="space-y-4">
                    {Object.entries(process6MonthPerformance).reverse().map(([month, value]) => {
                        const fullMonth = new Date(Date.parse(month + " 1 2024")).toLocaleString('default', { month: 'long' });
                        const width = value === 0 ? 5 : Math.max(5, (value / maxValue) * 100);

                        return (
                            <div key={month} className="flex items-center">
                                {/* Month label - fixed width */}
                                <div className="w-56 shrink-0">
                                    <span className="text-sm">
                                        {fullMonth} {month === Object.keys(process6MonthPerformance)[Object.keys(process6MonthPerformance).length - 1] && "(ongoing)"}
                                    </span>
                                </div>

                                {/* Spacer to push content to the right */}
                                <div className="flex-grow" />

                                {/* Progress bar container - fixed width */}
                                <div className="w-48 mx-4 flex justify-end">
                                    <div className="h-2 rounded-full overflow-hidden relative w-full">
                                        <div
                                            className="h-full bg-blue-500 rounded-full transition-all duration-300 absolute right-0"
                                            style={{
                                                width: `${width}%`
                                            }}
                                        />
                                    </div>
                                </div>

                                {/* Amount section - flexible width */}
                                <div className="min-w-[7rem] text-right shrink-0">
                                    <span className="text-sm">GHS {value.toFixed(2)}</span>
                                </div>
                            </div>
                        );
                    })}
                </div>
            </div>
        );
    };

    if (isLoading) {
        return (
            <div className="flex items-center justify-center h-96">
                <div className="flex flex-col items-center gap-3">
                    <Loader2 className="h-8 w-8 animate-spin text-gray-600" />
                    <div className="text-lg text-gray-600">Loading sales data...</div>
                </div>
            </div>
        );
    }

    if (error) {
        return (
            <div className="flex items-center justify-center h-96 text-red-500">
                {error}
            </div>
        );
    }

    if (!hasProAccess) {
        return <RestrictedAccess />;
    }

    return (
        <div className="bg-white">
            <div className="">

                <div className="border">
                    <div className="grid grid-cols-4 mb-4">
                        <MetricBox
                            value={`GHS ${processedData.metrics.grossSales.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}`}
                            label="Gross Sales Revenue"
                            comparison="Total sales this period"
                            onClick={() => setSelectedMetric('grossSales')}
                            isSelected={selectedMetric === 'grossSales'}
                        />
                        <MetricBox
                            value={processedData.metrics.itemsSold}
                            label="Number of Items Sold"
                            comparison="Total units sold"
                            onClick={() => setSelectedMetric('itemsSold')}
                            isSelected={selectedMetric === 'itemsSold'}
                        />
                        <MetricBox
                            value={`GHS ${processedData.metrics.grossProfit.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}`}
                            label="Gross Profit"
                            comparison="Before expenses"
                            onClick={() => setSelectedMetric('grossProfit')}
                            isSelected={selectedMetric === 'grossProfit'}
                        />
                        <MetricBox
                            value={`${processedData.metrics.salesGrowth.toFixed(1)}%`}
                            label="Sales Growth"
                            comparison="vs previous period"
                            onClick={() => setSelectedMetric('salesGrowth')}
                            isSelected={selectedMetric === 'salesGrowth'}
                        />
                    </div>


                    <div className="h-72 p-8">
                        <ResponsiveContainer width="100%" height="100%">
                            <LineChart data={getChartData(selectedMetric).data}>
                                <XAxis
                                    dataKey="formattedDate"
                                    axisLine={false}
                                    tickLine={false}
                                    dy={10}
                                    tick={{ fontSize: 12 }}
                                />
                                <YAxis
                                    axisLine={false}
                                    tickLine={false}
                                    dx={-10}
                                    tick={{ fontSize: 12 }}
                                />
                                <CartesianGrid
                                    horizontal={true}
                                    vertical={false}
                                    stroke="#e2e8f0"
                                    strokeWidth={1}
                                />
                                <Tooltip
                                    formatter={(value) => [
                                        `${selectedMetric === 'itemsSold' ? value : `GHS ${value.toFixed(2)}`}`,
                                        getChartData(selectedMetric).label
                                    ]}
                                    contentStyle={{
                                        backgroundColor: 'white',
                                        border: '1px solid #f0f0f0',
                                        borderRadius: '8px',
                                        padding: '8px'
                                    }}
                                />
                                <Area
                                    type="linear"
                                    dataKey={getChartData(selectedMetric).dataKey}
                                    fill={getChartData(selectedMetric).color}
                                    fillOpacity={0.2}
                                    strokeWidth={0}
                                    baseLine={0}
                                />
                                <Line
                                    type="linear"
                                    dataKey={getChartData(selectedMetric).dataKey}
                                    stroke={getChartData(selectedMetric).color}
                                    strokeWidth={3}
                                    dot={false}
                                    activeDot={{ r: 4 }}
                                />
                            </LineChart>
                        </ResponsiveContainer>
                    </div>
                </div>

                {/* Divider */}
                <div className="flex flex-col items-center justify-center gap-4 py-8">
                    <div className="h-[2px] mt-12 rounded-lg w-32 bg-gray-200"></div>
                </div>

                {/* Placeholder for the 4 sections you'll provide later */}
                <div className="grid grid-cols-2 gap-6 mt-8">
                    {/* Left column */}
                    <div className="space-y-6">
                        <BasketAnalysis data={data} storeId={storeId} timeRange={timeRange} />
                    </div>

                    {/* Right column */}
                    <div className="space-y-6">
                        {renderCustomerBehavior()}
                        {render6MonthPerformance()}
                    </div>
                </div>
            </div>
        </div>
    );
};

export { SalesReports };