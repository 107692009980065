import React, { useState, useEffect } from 'react';
import { collection, query, orderBy, getDocs, getFirestore } from 'firebase/firestore';
import { Loader2, TrendingUp, TrendingDown, DollarSign, PackageX, Search } from 'lucide-react';

const PriceHistory = () => {
    const [priceHistory, setPriceHistory] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [searchTerm, setSearchTerm] = useState('');
    const [filterType, setFilterType] = useState('all');
    const db = getFirestore();

    const storeId = localStorage.getItem('storeId');

    const priceTypes = {
        all: 'All Changes',
        selling_price: 'Selling Price',
        cost_price: 'Cost Price'
    };

    useEffect(() => {
        const fetchPriceHistory = async () => {
            if (!storeId) {
                setError('Store ID not found');
                setLoading(false);
                return;
            }

            try {
                const priceHistoryQuery = query(
                    collection(db, 'Stores', storeId, 'PriceHistory'),
                    orderBy('timestamp', 'desc')
                );

                const historySnapshot = await getDocs(priceHistoryQuery);

                if (historySnapshot.empty) {
                    setPriceHistory([]);
                    setLoading(false);
                    return;
                }

                const history = historySnapshot.docs.map(doc => ({
                    id: doc.id,
                    ...doc.data()
                }));

                setPriceHistory(history);
                setLoading(false);
            } catch (error) {
                console.error('Error fetching price history:', error);
                setError('Failed to load price history');
                setLoading(false);
            }
        };

        fetchPriceHistory();
    }, [db, storeId]);

    const filteredHistory = priceHistory.filter(record => {
        const matchesSearch =
            record.itemName?.toLowerCase().includes(searchTerm.toLowerCase()) ||
            record.sku?.toLowerCase().includes(searchTerm.toLowerCase()) ||
            record.notes?.toLowerCase().includes(searchTerm.toLowerCase());

        const matchesType = filterType === 'all' || record.priceType === filterType;

        return matchesSearch && matchesType;
    });

    if (loading) {
        return (
            <div className="flex items-center justify-center p-8">
                <Loader2 className="h-8 w-8 animate-spin text-gray-600" />
            </div>
        );
    }

    if (error) {
        return (
            <div className="flex flex-col items-center justify-center p-8 text-red-500">
                <PackageX className="h-12 w-12 mb-4" />
                <p className="text-lg font-medium">{error}</p>
                <p className="text-sm text-gray-500 mt-2">Please try again later or contact support if the issue persists.</p>
            </div>
        );
    }

    if (!priceHistory.length) {
        return (
            <div className="flex flex-col items-center justify-center p-8">
                <DollarSign className="h-12 w-12 text-gray-400 mb-4" />
                <h3 className="text-lg font-medium text-gray-900">No Price History Found</h3>
                <p className="text-sm text-gray-500 mt-2">Price changes will appear here once inventory prices are updated.</p>
            </div>
        );
    }

    return (
        <div className="space-y-6">
            <div className="flex items-center justify-between">
                <div className="flex gap-4 items-center">
                    <div className="relative flex-1 max-w-md">
                        <Search className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400 h-5 w-5" />
                        <input
                            type="text"
                            placeholder="Search by item name or SKU..."
                            className="pl-10 pr-4 py-2 border rounded-lg w-full min-w-96 focus:outline-none focus:ring-2 focus:ring-blue-500"
                            value={searchTerm}
                            onChange={(e) => setSearchTerm(e.target.value)}
                        />
                    </div>
                    <select
                        className="border rounded-lg px-4 py-2 bg-white focus:outline-none focus:ring-2 focus:ring-blue-500"
                        value={filterType}
                        onChange={(e) => setFilterType(e.target.value)}
                    >
                        {Object.entries(priceTypes).map(([value, label]) => (
                            <option key={value} value={value}>{label}</option>
                        ))}
                    </select>
                </div>
                <span className="text-sm text-gray-500">
                    {filteredHistory.length} {filteredHistory.length === 1 ? 'record' : 'records'} found
                </span>
            </div>

            <div className="border rounded-lg overflow-hidden">
                <div className="overflow-x-auto">
                    <table className="w-full">
                        <thead>
                            <tr className="bg-gray-100">
                                <th className="px-6 py-3 text-left text-sm font-medium text-gray-500">Date</th>
                                <th className="px-6 py-3 text-left text-sm font-medium text-gray-500">Item</th>
                                <th className="px-6 py-3 text-left text-sm font-medium text-gray-500">SKU</th>
                                <th className="px-6 py-3 text-left text-sm font-medium text-gray-500">Price Type</th>
                                <th className="px-6 py-3 text-left text-sm font-medium text-gray-500">Previous Price</th>
                                <th className="px-6 py-3 text-left text-sm font-medium text-gray-500">New Price</th>
                                <th className="px-6 py-3 text-left text-sm font-medium text-gray-500">Change %</th>
                            </tr>
                        </thead>
                        <tbody className="divide-y divide-gray-200 bg-white">
                            {filteredHistory.map((record) => {
                                const priceChange = record.currentPrice - record.previousPrice;
                                const changePercent = ((priceChange / record.previousPrice) * 100).toFixed(1);
                                const changeDirection = priceChange >= 0 ? 'increase' : 'decrease';

                                return (
                                    <tr key={record.id} className="hover:bg-gray-50">
                                        <td className="px-6 py-4 text-sm text-gray-500 whitespace-nowrap">
                                            {record.timestamp?.toDate().toLocaleString()}
                                        </td>
                                        <td className="px-6 py-4 text-sm text-gray-900">
                                            {record.itemName}
                                        </td>
                                        <td className="px-6 py-4 text-sm text-gray-500">
                                            {record.sku || '-'}
                                        </td>
                                        <td className="px-6 py-4 text-sm">
                                            <span className={`px-2 py-1 rounded-full text-xs font-medium ${record.priceType === 'selling_price'
                                                    ? 'bg-blue-100 text-blue-800'
                                                    : 'bg-purple-100 text-purple-800'
                                                }`}>
                                                {priceTypes[record.priceType]}
                                            </span>
                                        </td>
                                        <td className="px-6 py-4 text-sm text-gray-500">
                                            GHS {record.previousPrice?.toFixed(2)}
                                        </td>
                                        <td className="px-6 py-4 text-sm text-gray-500">
                                            GHS {record.currentPrice?.toFixed(2)}
                                        </td>
                                        <td className="px-6 py-4 text-sm">
                                            <div className={`flex items-center ${changeDirection === 'increase'
                                                    ? 'text-green-600'
                                                    : 'text-red-600'
                                                }`}>
                                                {changeDirection === 'increase' ? (
                                                    <TrendingUp className="h-4 w-4 mr-1" />
                                                ) : (
                                                    <TrendingDown className="h-4 w-4 mr-1" />
                                                )}
                                                {changePercent}%
                                            </div>
                                        </td>
                                    </tr>
                                );
                            })}
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    );
};

export default PriceHistory;