import React from 'react';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { AuthProvider } from './app/auth/AuthContext';
import { PrivateRoute } from './app/auth/PrivateRoute';
import Home from './app/pages/Home';
import Terms from './app/pages/Terms';
import Privacy from './app/pages/Privacy';
import DeleteAccount from './app/pages/DeleteAccount';
import Inventory from './app/screens/views/Inventory';
import Lobby from './app/screens/views/Lobby';
import Reports from './app/screens/views/Reports';
import Sales from './app/screens/views/Sales';
import AddSale from './app/screens/views/AddSale';
import Login from './app/auth/Login';
import Signup from './app/auth/Signup';
import Support from './app/pages/Support';
import Subscription from './app/screens/views/Subscription';
import SalesList from './app/screens/views/SalesList';
import Settings from './app/screens/views/Settings';

// Import all new views (these files need to be created)
import SalesQuotes from './app/screens/views/SalesQuotes';
import SalesReturns from './app/screens/views/SalesReturns';
import SalesInvoices from './app/screens/views/SalesInvoices';
import InventoryCategories from './app/screens/views/InventoryCategories';
import InventoryStock from './app/screens/views/InventoryStockManagement';
import ExpensesList from './app/screens/views/ExpensesList';
import ExpensesCategories from './app/screens/views/ExpensesCategories';
import ExpensesRecurring from './app/screens/views/ExpensesRecurring';
import ReportsClients from './app/screens/views/ReportsClients';
import ReportsFinancials from './app/screens/views/ReportsFinancials';
import Clients from './app/screens/views/Clients';
import Customers from './app/screens/views/Customers';
import Suppliers from './app/screens/views/Suppliers';
import Expenses from './app/screens/views/Expenses';
import ReportsAnalytics from './app/screens/views/ReportsAnalytics';
import PurchaseOrders from './app/screens/views/PurchaseOrders';
import InventoryItems from './app/screens/components/inventory/Inventory_items';
import SalesReceipts from './app/screens/views/SalesReceipts';
import Unauthorized from './app/auth/Unauthorized';
import RoleSelection from './app/auth/RoleSelection';
import JoinStore from './app/auth/JoinStore';
import AddStore from './app/auth/AddStore';
import Payments from './app/screens/views/Payments';
import AccountDowngraded from './app/screens/views/AccountDowngraded';
import { ToastProvider } from './app/screens/components/toast';
import AddInventoryItem from './app/screens/views/InventoryAdd';

function App() {
  return (
    <AuthProvider>
      <BrowserRouter>
        <ToastProvider>
          <div className="bg-white">
            <Routes>
              {/* Public Routes */}
              <Route path="/" element={<Home />} />
              <Route path="/privacy" element={<Privacy />} />
              <Route path="/terms" element={<Terms />} />
              <Route path="/delete" element={<DeleteAccount />} />
              <Route path="/support" element={<Support />} />
              <Route path="/login" element={<Login />} />
              <Route path="/signup" element={<Signup />} />

              {/* Private Routes */}
              <Route path="/dashboard" element={<PrivateRoute><Lobby /></PrivateRoute>} />
              <Route path="/account-downgraded" element={<PrivateRoute><AccountDowngraded /></PrivateRoute>} />
              <Route path="/roleselection" element={<PrivateRoute><RoleSelection /></PrivateRoute>} />
              <Route path="/unauthorized" element={<PrivateRoute><Unauthorized /></PrivateRoute>} />
              <Route path="/joinstore" element={<PrivateRoute><JoinStore /></PrivateRoute>} />
              <Route path="/addstore" element={<PrivateRoute><AddStore /></PrivateRoute>} />

              {/* Sales Routes */}
              <Route path="/sales" element={<PrivateRoute><Sales /></PrivateRoute>} />
              <Route path="/sales/add" element={<PrivateRoute><AddSale /></PrivateRoute>} />
              <Route path="/sales/list" element={<PrivateRoute><SalesList /></PrivateRoute>} />
              <Route path="/sales/quotes" element={<PrivateRoute><SalesQuotes /></PrivateRoute>} />
              <Route path="/sales/receipts" element={<PrivateRoute><SalesReceipts /></PrivateRoute>} />
              <Route path="/sales/returns" element={<PrivateRoute><SalesReturns /></PrivateRoute>} />
              <Route path="/sales/invoices" element={<PrivateRoute><SalesInvoices /></PrivateRoute>} />

              {/* Inventory Routes */}
              <Route path="/inventory" element={<PrivateRoute><Inventory /></PrivateRoute>} />
              <Route path="/inventory/list" element={<PrivateRoute><InventoryItems /></PrivateRoute>} />
              <Route path="//inventory/add" element={<PrivateRoute><AddInventoryItem /></PrivateRoute>} />
              <Route path="/inventory/categories" element={<PrivateRoute><InventoryCategories /></PrivateRoute>} />
              <Route path="/inventory/stock" element={<PrivateRoute><InventoryStock /></PrivateRoute>} />

              {/* Clients Routes */}
              <Route path="/clients" element={<PrivateRoute><Clients /></PrivateRoute>} />
              <Route path="/clients/customers" element={<PrivateRoute><Customers /></PrivateRoute>} />
              <Route path="/clients/suppliers" element={<PrivateRoute><Suppliers /></PrivateRoute>} />
              <Route path="/clients/suppliers/purchase-orders" element={<PrivateRoute><PurchaseOrders /></PrivateRoute>} />

              {/* Settings Routes */}
              <Route path="/payments" element={<PrivateRoute><Payments /></PrivateRoute>} />

              {/* Expenses Routes */}
              <Route path="/expenses" element={<PrivateRoute><Expenses /></PrivateRoute>} />
              <Route path="/expenses/list" element={<PrivateRoute><ExpensesList /></PrivateRoute>} />
              <Route path="/expenses/categories" element={<PrivateRoute><ExpensesCategories /></PrivateRoute>} />
              <Route path="/expenses/recurring" element={<PrivateRoute><ExpensesRecurring /></PrivateRoute>} />

              {/* Reports Routes */}
              <Route path="/reports" element={<PrivateRoute><Reports /></PrivateRoute>} />
              <Route path="/reports/analytics" element={<PrivateRoute><ReportsAnalytics /></PrivateRoute>} />
              <Route path="/reports/finance" element={<PrivateRoute><ReportsFinancials /></PrivateRoute>} />
              <Route path="/reports/clients" element={<PrivateRoute><ReportsClients /></PrivateRoute>} />

              {/* Settings Routes */}
              <Route path="/settings" element={<PrivateRoute><Settings /></PrivateRoute>} />

              {/* Other Routes */}
              <Route path="/subscription" element={<PrivateRoute><Subscription /></PrivateRoute>} />
              <Route path="/home" element={<PrivateRoute><Lobby /></PrivateRoute>} />
            </Routes>
          </div>
        </ToastProvider>
      </BrowserRouter>
    </AuthProvider>
  );
}

export default App;