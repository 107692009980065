import React, { useState, useEffect, useRef } from 'react';
import { X, ImageOff, BarcodeIcon, AlertTriangle, Check, Loader2 } from 'lucide-react';
import {
    doc,
    updateDoc,
    getFirestore,
    serverTimestamp
} from 'firebase/firestore';

export const ProductEditModal = ({ item: initialItem, isOpen, onClose, storeId }) => {
    const [item, setItem] = useState(initialItem);
    const [formData, setFormData] = useState({
        itemName: '',
        sku: '',
        quantity: 0,
        reorderPoint: 0,
        sellingPrice: 0,
        costPrice: 0,
        imageUrl: ''
    });

    const [isScanning, setIsScanning] = useState(false);
    const [scanBuffer, setScanBuffer] = useState('');
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [successMessage, setSuccessMessage] = useState('');

    const scanTimeoutRef = useRef(null);
    const skuInputRef = useRef(null);
    const db = getFirestore();

    useEffect(() => {
        if (isOpen && initialItem) {
            setItem(initialItem);
            setFormData({
                itemName: initialItem.itemName || '',
                sku: initialItem.sku || '',
                quantity: initialItem.quantity || 0,
                reorderPoint: initialItem.reorderPoint || 0,
                sellingPrice: initialItem.sellingPrice || 0,
                costPrice: initialItem.costPrice || 0,
                imageUrl: initialItem.imageUrl || ''
            });
        }
    }, [isOpen, initialItem]);

    useEffect(() => {
        if (successMessage) {
            const timer = setTimeout(() => {
                setSuccessMessage('');
                onClose();
            }, 1500);
            return () => clearTimeout(timer);
        }
    }, [successMessage, onClose]);

    // Handle escape key
    useEffect(() => {
        const handleEscape = (e) => {
            if (e.key === 'Escape') onClose();
        };

        if (isOpen) {
            document.addEventListener('keydown', handleEscape);
            document.body.style.overflow = 'hidden';
        }

        return () => {
            document.removeEventListener('keydown', handleEscape);
            document.body.style.overflow = 'unset';
        };
    }, [isOpen, onClose]);


    useEffect(() => {
        console.log('Scanner enabled:', isScanning);
        console.log('Scan buffer:', scanBuffer);

        if (!isScanning) return;

        const handleKeyPress = (e) => {
            // Handle Enter key
            if (e.key === 'Enter' && isScanning) {
                e.preventDefault();

                // Process the complete barcode
                if (scanBuffer) {
                    console.log('Processing barcode:', scanBuffer);
                    setFormData(prev => ({
                        ...prev,
                        sku: scanBuffer
                    }));
                    setScanBuffer('');
                }
                return;
            }

            // Log keypress
            console.log('Key pressed:', e.key);
            console.log('Current scan buffer:', scanBuffer);

            // Accumulate barcode characters
            if (isScanning) {
                setScanBuffer(prev => prev + e.key);

                // Reset scan buffer after delay
                if (scanTimeoutRef.current) {
                    clearTimeout(scanTimeoutRef.current);
                }

                scanTimeoutRef.current = setTimeout(() => {
                    setScanBuffer('');
                }, 100);
            }
        };

        window.addEventListener('keypress', handleKeyPress);
        return () => {
            window.removeEventListener('keypress', handleKeyPress);
            if (scanTimeoutRef.current) {
                clearTimeout(scanTimeoutRef.current);
            }
        };
    }, [isScanning, scanBuffer]);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData(prev => ({
            ...prev,
            [name]: ['quantity', 'sellingPrice', 'costPrice', 'reorderPoint'].includes(name)
                ? parseFloat(value) || 0
                : value
        }));
        setErrorMessage('');
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setIsSubmitting(true);
        setErrorMessage('');

        try {
            if (!storeId || !item?.id) {
                throw new Error('Missing required data');
            }

            const itemRef = doc(db, 'Stores', storeId, 'Inventory', item.id);

            await updateDoc(itemRef, {
                ...formData,
                updatedAt: serverTimestamp()
            });

            setItem(prev => ({
                ...prev,
                ...formData
            }));

            setSuccessMessage('Product successfully updated');
        } catch (error) {
            console.error('Error updating item:', error);
            setErrorMessage('Failed to update item. Please try again.');
        } finally {
            setIsSubmitting(false);
        }
    };

    const toggleScanner = () => {
        setIsScanning(!isScanning);
        if (!isScanning) {
            skuInputRef.current?.focus();
        }
        // Clear scan buffer when toggling scanner
        setScanBuffer('');
    };

    if (!isOpen) return null;

    return (
        <div className="fixed inset-0 z-50 overflow-y-auto">
            <div className="fixed inset-0 bg-black bg-opacity-50" onClick={onClose} />

            <div className="flex min-h-full items-center justify-center p-4">
                <div className="relative bg-white rounded-lg shadow-xl w-full max-w-4xl mx-auto">
                    <div className="flex items-center justify-between p-4 border-b">
                        <h2 className="text-lg font-semibold">Edit Product</h2>
                        <button
                            onClick={onClose}
                            className="p-1 hover:bg-gray-100 rounded-full"
                        >
                            <X className="h-5 w-5" />
                        </button>
                    </div>

                    {successMessage && (
                        <div className="mx-6 mt-4 p-3 bg-green-50 border border-green-200 rounded-lg flex items-center gap-2 text-green-700">
                            <Check className="h-5 w-5" />
                            <span>{successMessage}</span>
                        </div>
                    )}

                    {errorMessage && (
                        <div className="mx-6 mt-4 p-3 bg-red-50 border border-red-200 rounded-lg flex items-center gap-2 text-red-700">
                            <AlertTriangle className="h-5 w-5" />
                            <span>{errorMessage}</span>
                        </div>
                    )}

                    <div className="flex">
                        <div className="w-2/5 p-6 bg-gray-50 border-r">
                            <div className="flex flex-col items-center justify-center h-full">
                                <div className="w-64 h-64 rounded-lg overflow-hidden bg-white flex items-center justify-center border">
                                    {formData.imageUrl ? (
                                        <img
                                            src={formData.imageUrl}
                                            alt={formData.itemName}
                                            className="w-full h-full object-contain"
                                        />
                                    ) : (
                                        <ImageOff className="h-12 w-12 text-gray-400" />
                                    )}
                                </div>
                                <button
                                    type="button"
                                    className="mt-4 px-4 py-2 border rounded-lg text-gray-700 hover:bg-gray-100"
                                >
                                    Change Image
                                </button>
                            </div>
                        </div>

                        <form onSubmit={handleSubmit} className="flex-1 p-6">
                            <div className="space-y-4">
                                <div>
                                    <label className="block text-sm font-medium text-gray-700 mb-1">
                                        Product Name
                                    </label>
                                    <input
                                        name="itemName"
                                        value={formData.itemName}
                                        onChange={handleChange}
                                        className="w-full px-3 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
                                    />
                                </div>

                                <div>
                                    <label className="block text-sm font-medium text-gray-700 mb-1">
                                        SKU
                                    </label>
                                    <div className="flex gap-2">
                                        <input
                                            ref={skuInputRef}
                                            name="sku"
                                            value={formData.sku}
                                            onChange={handleChange}
                                            className="flex-1 px-3 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
                                            readOnly={isScanning}
                                        />
                                        <button
                                            type="button"
                                            onClick={toggleScanner}
                                            className={`px-3 py-2 border rounded-lg flex items-center justify-center transition-colors ${isScanning
                                                ? 'bg-blue-100 border-blue-300 text-blue-600 hover:bg-blue-200'
                                                : 'bg-gray-100 hover:bg-gray-200 text-gray-600'
                                                }`}
                                            title={isScanning ? 'Scanner Active' : 'Enable Scanner'}
                                        >
                                            <BarcodeIcon className="h-5 w-5" />
                                        </button>
                                    </div>
                                    {isScanning && (
                                        <p className="mt-1 text-sm text-blue-600">
                                            Scanner active. Waiting for barcode...
                                        </p>
                                    )}
                                </div>

                                <div className="grid grid-cols-2 gap-4">
                                    <div>
                                        <label className="block text-sm font-medium text-gray-700 mb-1">
                                            Current Stock
                                        </label>
                                        <input
                                            type="number"
                                            name="quantity"
                                            value={formData.quantity}
                                            onChange={handleChange}
                                            className="w-full px-3 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
                                        />
                                    </div>

                                    <div>
                                        <label className="block text-sm font-medium text-gray-700 mb-1">
                                            Low Stock Alert
                                        </label>
                                        <input
                                            type="number"
                                            name="reorderPoint"
                                            value={formData.reorderPoint}
                                            onChange={handleChange}
                                            className="w-full px-3 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
                                        />
                                    </div>
                                </div>

                                <div className="grid grid-cols-2 gap-4">
                                    <div>
                                        <label className="block text-sm font-medium text-gray-700 mb-1">
                                            Selling Price (GHS)
                                        </label>
                                        <input
                                            type="number"
                                            step="0.01"
                                            name="sellingPrice"
                                            value={formData.sellingPrice}
                                            onChange={handleChange}
                                            className="w-full px-3 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500" />
                                    </div>

                                    <div>
                                        <label className="block text-sm font-medium text-gray-700 mb-1">
                                            Cost Price (GHS)
                                        </label>
                                        <input
                                            type="number"
                                            step="0.01"
                                            name="costPrice"
                                            value={formData.costPrice}
                                            onChange={handleChange}
                                            className="w-full px-3 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
                                        />
                                    </div>
                                </div>

                                {formData.quantity <= formData.reorderPoint && (
                                    <div className="p-3 bg-yellow-50 border border-yellow-200 rounded-lg">
                                        <p className="text-sm text-yellow-700">
                                            {formData.quantity === 0
                                                ? "This item is out of stock."
                                                : "Stock level is below the alert threshold."}
                                            {formData.quantity > 0 && ` Consider restocking soon.`}
                                        </p>
                                    </div>
                                )}

                                {formData.sellingPrice < formData.costPrice && (
                                    <div className="p-3 bg-red-50 border border-red-200 rounded-lg">
                                        <p className="text-sm text-red-700">
                                            Warning: Selling price is lower than cost price.
                                        </p>
                                    </div>
                                )}
                            </div>

                            <div className="flex justify-end gap-3 mt-6">
                                <button
                                    type="button"
                                    onClick={onClose}
                                    className="px-4 py-2 border rounded-lg text-gray-700 hover:bg-gray-50"
                                >
                                    Cancel
                                </button>
                                <button
                                    type="submit"
                                    disabled={isSubmitting}
                                    className="px-4 py-2 bg-blue-500 text-white rounded-lg hover:bg-blue-600 disabled:bg-blue-300 disabled:cursor-not-allowed flex items-center gap-2"
                                >
                                    {isSubmitting ? (
                                        <>
                                            <Loader2 className="h-4 w-4 animate-spin" />
                                            <span>Saving...</span>
                                        </>
                                    ) : (
                                        <span>Save Changes</span>
                                    )}
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ProductEditModal;