import React from 'react';
import { Crown, LogOut } from 'lucide-react';

export default function AccountDowngraded() {
    return (
        <div className="min-h-screen bg-gray-50 flex flex-col items-center justify-center p-4">
            <div className="max-w-md w-full bg-white rounded-xl shadow-lg p-8 text-center">
                <div className="mx-auto w-16 h-16 bg-yellow-100 rounded-full flex items-center justify-center mb-6">
                    <Crown className="w-8 h-8 text-yellow-600" />
                </div>

                <h1 className="text-2xl font-bold text-gray-900 mb-2">
                    Upgrade for full access
                </h1>

                <p className="text-gray-600 mb-8">
                    You currently don't have an active subscription. <br />
                    To continue enjoying premium features, please renew your subscription.
                </p>

                <a
                    href="/settings#subscription"
                    className="inline-flex items-center justify-center px-6 py-3 border border-transparent text-base font-medium rounded-lg text-white bg-blue-600 hover:bg-blue-700 transition-colors duration-150 ease-in-out"
                >
                    Renew Subscription
                </a>

                <div className="mt-6 text-sm text-gray-500">
                    Need help? <a href="/support" className="text-blue-600 hover:text-blue-700">Contact support</a>
                </div>
            </div>
        </div>
    );
}