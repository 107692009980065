import React, { useState } from 'react';
import { ArrowDown, ArrowUp, ChevronDown, PlusCircle, ArrowRight, Crown } from 'lucide-react';
import { getAuth } from 'firebase/auth';
import { collection, query, where, getDocs, addDoc, getFirestore, Timestamp } from 'firebase/firestore';
import Sidebar from '../components/sidebar';
import PaymentsTable from '../components/payments/payments_table';
import TimePeriodPicker from '../components/time_period_picker';
import { useAuth } from '../../auth/AuthContext';

// Restricted Access Component
const RestrictedAccess = () => (
    <div className="flex h-screen bg-white">
        <Sidebar />
        <div className="flex-1 flex items-center justify-center">
            <div className="text-center p-8">
                <Crown className="h-16 w-16 text-yellow-500 mx-auto mb-4" />
                <h1 className="text-2xl font-semibold mb-2">Pro Feature</h1>
                <p className="text-gray-600 mb-4">
                    The Payments management feature is only available on Pro and Enterprise plans.
                </p>
                <p className="text-gray-500 text-sm">
                    Please upgrade your plan to access this feature.
                </p>
            </div>
        </div>
    </div>
);

const CategoryCard = ({ category, icon, description, selected, onClick }) => (
    <div
        onClick={onClick}
        className={`p-4 border rounded-lg cursor-pointer transition-all
            ${selected
                ? 'border-blue-500 bg-blue-50 shadow-md'
                : 'border-gray-200 hover:border-blue-300 hover:bg-blue-50/50'}
        `}
    >
        <div className="flex items-start space-x-3">
            <div className={`p-2 rounded-lg ${selected ? 'bg-blue-100' : 'bg-gray-100'}`}>
                {icon}
            </div>
            <div>
                <h3 className={`font-medium ${selected ? 'text-blue-700' : 'text-gray-900'}`}>
                    {category}
                </h3>
                <p className="text-sm text-gray-500 mt-1">{description}</p>
            </div>
        </div>
    </div>
);

const Payments = () => {
    const { hasProAccess } = useAuth();
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);
    const [currentStep, setCurrentStep] = useState(1);
    const [timeRange, setTimeRange] = useState({
        start: new Date(new Date().setDate(new Date().getDate() - 7)),
        end: new Date(),
        label: 'Last 7 Days'
    });
    const [newPayment, setNewPayment] = useState({
        amount: '',
        description: '',
        date: new Date().toISOString().slice(0, 16),
        paymentType: 'manual',
        direction: 'in',
        category: '',
        paymentMethod: 'cash',
        reference: '',
        status: 'completed'
    });

    const db = getFirestore();
    const auth = getAuth();

    const incomingCategories = [
        { id: 'sales', name: 'Sales', description: 'Revenue from goods and services', icon: '💰' },
        { id: 'refund', name: 'Refund', description: 'Money returned from purchases', icon: '↩️' },
        { id: 'investment', name: 'Investment', description: 'Capital injections and funding', icon: '📈' },
        { id: 'loan', name: 'Loan', description: 'Borrowed money and credit', icon: '🏦' },
        { id: 'cash_on_hand', name: 'Cash On Hand', description: 'Current Cash Reserve', icon: '💰' },
        { id: 'other_income', name: 'Other Income', description: 'Miscellaneous incoming payments', icon: '📥' }
    ];

    const outgoingCategories = [
        { id: 'inventory', name: 'Inventory', description: 'Stock purchases and supplies', icon: '📦' },
        { id: 'salary', name: 'Salary', description: 'Employee wages and benefits', icon: '👥' },
        { id: 'utilities', name: 'Utilities', description: 'Electric, water, and other services', icon: '💡' },
        { id: 'rent', name: 'Rent', description: 'Property and equipment rental', icon: '🏢' },
        { id: 'marketing', name: 'Marketing', description: 'Advertising and promotions', icon: '📢' },
        { id: 'equipment', name: 'Equipment', description: 'Tools and machinery', icon: '🔧' },
        { id: 'other_expenses', name: 'Other Expenses', description: 'Miscellaneous outgoing payments', icon: '📤' }
    ];

    const handleDateRangeChange = (start, end, label) => {
        setTimeRange({ start, end, label });
    };

    const handleCategorySelect = (category) => {
        setNewPayment(prev => ({
            ...prev,
            category: category.name
        }));
    };

    const resetForm = () => {
        setCurrentStep(1);
        setNewPayment({
            amount: '',
            description: '',
            date: new Date().toISOString().slice(0, 16),
            paymentType: 'manual',
            direction: 'in',
            category: '',
            paymentMethod: 'cash',
            reference: '',
            status: 'completed'
        });
    };

    const getPaymentType = (category) => {
        const incomingTypes = {
            'Sales': 'Sale',
            'Refund': 'Refund',
            'Investment': 'Investment',
            'Loan': 'Loan',
            'Other Income': 'Manual'
        };

        const outgoingTypes = {
            'Inventory': 'Purchase',
            'Salary': 'Payroll',
            'Utilities': 'Bill',
            'Rent': 'Bill',
            'Marketing': 'Expense',
            'Equipment': 'Purchase',
            'Other Expenses': 'Manual'
        };

        return newPayment.direction === 'in'
            ? (incomingTypes[category] || 'Manual')
            : (outgoingTypes[category] || 'Manual');
    };

    const handleAddPayment = async (e) => {
        e.preventDefault();
        try {
            const currentUser = auth.currentUser;
            if (!currentUser) throw new Error('No authenticated user found');

            const userDoc = await getDocs(
                query(collection(db, 'Users'), where('email', '==', currentUser.email))
            );

            if (userDoc.empty) throw new Error('User data not found');

            const userData = userDoc.docs[0].data();
            const paymentDate = newPayment.date ? new Date(newPayment.date) : new Date();
            const amount = parseFloat(newPayment.amount);

            const paymentData = {
                amount: newPayment.direction === 'in' ? amount : -amount,
                description: newPayment.description,
                date: Timestamp.fromDate(paymentDate),
                paymentType: 'manual',
                direction: newPayment.direction,
                category: newPayment.category,
                paymentMethod: newPayment.paymentMethod,
                reference: newPayment.reference,
                status: newPayment.status,
                createdAt: Timestamp.now(),
                createdBy: currentUser.email
            };

            await addDoc(collection(db, 'Stores', userData.storeId, 'Payments'), paymentData);

            setIsModalOpen(false);
            resetForm();
            window.location.reload();
        } catch (error) {
            console.error('Error adding payment:', error);
        }
    };

    const renderStepContent = () => {
        if (currentStep === 1) {
            const categories = newPayment.direction === 'in' ? incomingCategories : outgoingCategories;
            return (
                <div className="p-6">
                    <h3 className="text-lg font-medium text-gray-900 mb-4">
                        Select Payment Category
                    </h3>
                    <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                        {categories.map(category => (
                            <CategoryCard
                                key={category.id}
                                category={category.name}
                                icon={<span className="text-2xl">{category.icon}</span>}
                                description={category.description}
                                selected={newPayment.category === category.name}
                                onClick={() => handleCategorySelect(category)}
                            />
                        ))}
                    </div>
                </div>
            );
        }

        return (
            <div className="p-6 space-y-6">
                <div className="space-y-2">
                    <label className="text-sm font-medium text-gray-900">Amount (GHS)</label>
                    <div className="relative">
                        <span className="absolute left-3 top-1/2 -translate-y-1/2 text-gray-500 text-sm">
                            GHS
                        </span>
                        <input
                            type="number"
                            step="0.01"
                            required
                            value={newPayment.amount}
                            onChange={(e) => setNewPayment(prev => ({
                                ...prev,
                                amount: e.target.value
                            }))}
                            placeholder="0.00"
                            className={`pl-12 pr-4 py-2.5 block w-full rounded-lg border focus:ring-1 
                            ${newPayment.direction === 'in'
                                    ? 'border-green-200 bg-green-50 focus:border-green-500 focus:ring-green-500'
                                    : 'border-red-200 bg-red-50 focus:border-red-500 focus:ring-red-500'
                                }`}
                        />
                    </div>
                </div>

                <div className="space-y-2">
                    <label className="text-sm font-medium text-gray-900">Payment Method</label>
                    <select
                        required
                        value={newPayment.paymentMethod}
                        onChange={(e) => setNewPayment(prev => ({
                            ...prev,
                            paymentMethod: e.target.value
                        }))}
                        className="block w-full rounded-lg border border-gray-200 bg-gray-50 px-4 py-2.5 text-gray-900 focus:border-blue-500 focus:ring-1 focus:ring-blue-500"
                    >
                        <option value="cash">Cash</option>
                        <option value="momo">Mobile Money</option>
                        <option value="card">Card</option>
                        <option value="bank">Bank Transfer</option>
                        <option value="cheque">Cheque</option>
                    </select>
                </div>

                <div className="space-y-2">
                    <label className="text-sm font-medium text-gray-900">Description</label>
                    <input
                        type="text"
                        required
                        value={newPayment.description}
                        onChange={(e) => setNewPayment(prev => ({
                            ...prev,
                            description: e.target.value
                        }))}
                        placeholder="Enter payment description"
                        className="block w-full rounded-lg border border-gray-200 bg-gray-50 px-4 py-2.5 text-gray-900 focus:border-blue-500 focus:ring-1 focus:ring-blue-500"
                    />
                </div>

                <div className="space-y-2">
                    <label className="text-sm font-medium text-gray-900">Reference Number (Optional)</label>
                    <input
                        type="text"
                        value={newPayment.reference}
                        onChange={(e) => setNewPayment(prev => ({
                            ...prev,
                            reference: e.target.value
                        }))}
                        placeholder="Enter reference number"
                        className="block w-full rounded-lg border border-gray-200 bg-gray-50 px-4 py-2.5 text-gray-900 focus:border-blue-500 focus:ring-1 focus:ring-blue-500"
                    />
                </div>

                <div className="space-y-2">
                    <label className="text-sm font-medium text-gray-900">Date</label>
                    <input
                        type="datetime-local"
                        value={newPayment.date}
                        onChange={(e) => setNewPayment(prev => ({
                            ...prev,
                            date: e.target.value
                        }))}
                        className="block w-full rounded-lg border border-gray-200 bg-gray-50 px-4 py-2.5 text-gray-900 focus:border-blue-500 focus:ring-1 focus:ring-blue-500"
                    />
                </div>
            </div>
        );
    };

    if (!hasProAccess) {
        return <RestrictedAccess />;
    }

    return (
        <div className="flex h-screen bg-white overflow-hidden">
            <Sidebar />
            <div className="flex-1 flex flex-col overflow-hidden">
                <div className="flex-1 overflow-y-auto p-8">
                    <div className="flex justify-between items-center mb-10">
                        <h1 className="text-3xl font-semibold">Payments Management</h1>
                        <div className="flex items-center space-x-4">
                            <TimePeriodPicker
                                onDateRangeChange={handleDateRangeChange}
                                selectedLabel={timeRange.label}
                                onLabelChange={(label) => setTimeRange(prev => ({ ...prev, label }))}
                            />
                            <div className="relative">
                                <button
                                    onClick={() => setIsDropdownOpen(!isDropdownOpen)}
                                    className="inline-flex items-center px-4 py-2 bg-blue-600 text-white rounded-lg hover:bg-blue-700"
                                >
                                    <PlusCircle className="h-5 w-5 mr-2" />
                                    Add Payment
                                    <ChevronDown className="h-4 w-4 ml-2" />
                                </button>

                                {isDropdownOpen && (
                                    <div className="absolute right-0 mt-2 w-48 bg-white rounded-lg shadow-lg z-50">
                                        <button
                                            onClick={() => {
                                                setIsModalOpen(true);
                                                setIsDropdownOpen(false);
                                                resetForm();
                                                setNewPayment(prev => ({
                                                    ...prev,
                                                    direction: 'in'
                                                }));
                                            }}
                                            className="w-full px-4 py-2 text-left text-sm text-gray-700 hover:bg-gray-100 rounded-t-lg flex items-center"
                                        >
                                            <ArrowDown className="h-4 w-4 mr-2 text-green-500" />
                                            Add Payment In
                                        </button>
                                        <button
                                            onClick={() => {
                                                setIsModalOpen(true);
                                                setIsDropdownOpen(false);
                                                resetForm();
                                                setNewPayment(prev => ({
                                                    ...prev,
                                                    direction: 'out'
                                                }));
                                            }}
                                            className="w-full px-4 py-2 text-left text-sm text-gray-700 hover:bg-gray-100 rounded-b-lg flex items-center"
                                        >
                                            <ArrowUp className="h-4 w-4 mr-2 text-red-500" />
                                            Add Payment Out
                                        </button>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>

                    <PaymentsTable timeRange={timeRange} />

                    {isModalOpen && (
                        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center p-4 z-50">
                            <div className="bg-white rounded-xl w-full max-w-3xl">
                                <div className="p-6 border-b">
                                    <h2 className="text-xl font-semibold text-gray-900 flex items-center">
                                        {newPayment.direction === 'in' ? (
                                            <>
                                                <ArrowDown className="h-5 w-5 mr-2 text-green-500" />
                                                Add Payment In
                                            </>
                                        ) : (
                                            <>
                                                <ArrowUp className="h-5 w-5 mr-2 text-red-500" />
                                                Add Payment Out
                                            </>
                                        )}
                                    </h2>
                                    <div className="flex items-center mt-4">
                                        <div className="flex items-center">
                                            <div className={`w-8 h-8 rounded-full flex items-center justify-center ${currentStep === 1 ? 'bg-blue-600 text-white' : 'bg-gray-200'
                                                }`}>
                                                1
                                            </div>
                                            <span className={`ml-2 text-sm ${currentStep === 1 ? 'text-blue-600 font-medium' : 'text-gray-500'
                                                }`}>Category</span>
                                        </div>
                                        <div className="w-16 h-px bg-gray-200 mx-2" />
                                        <div className="flex items-center">
                                            <div className={`w-8 h-8 rounded-full flex items-center justify-center ${currentStep === 2 ? 'bg-blue-600 text-white' : 'bg-gray-200'
                                                }`}>
                                                2
                                            </div>
                                            <span className={`ml-2 text-sm ${currentStep === 2 ? 'text-blue-600 font-medium' : 'text-gray-500'
                                                }`}>Details</span>
                                        </div>
                                    </div>
                                </div>

                                <form onSubmit={handleAddPayment}>
                                    {renderStepContent()}

                                    <div className="p-6 bg-gray-50 rounded-b-xl border-t flex justify-between">
                                        <button
                                            type="button"
                                            onClick={() => {
                                                if (currentStep === 1) {
                                                    setIsModalOpen(false);
                                                    resetForm();
                                                } else {
                                                    setCurrentStep(1);
                                                }
                                            }}
                                            className="px-4 py-2.5 border border-gray-200 rounded-lg text-sm font-medium text-gray-700 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200"
                                        >
                                            {currentStep === 1 ? 'Cancel' : 'Back'}
                                        </button>

                                        {currentStep === 1 ? (
                                            <button
                                                type="button"
                                                onClick={() => {
                                                    if (newPayment.category) {
                                                        setCurrentStep(2);
                                                    }
                                                }}
                                                disabled={!newPayment.category}
                                                className={`px-4 py-2.5 rounded-lg text-sm font-medium inline-flex items-center space-x-2
                                                    ${newPayment.category
                                                        ? 'bg-blue-600 text-white hover:bg-blue-700'
                                                        : 'bg-gray-100 text-gray-400 cursor-not-allowed'
                                                    }`}
                                            >
                                                <span>Next</span>
                                                <ArrowRight className="h-4 w-4" />
                                            </button>
                                        ) : (
                                            <button
                                                type="submit"
                                                className={`px-4 py-2.5 text-white rounded-lg text-sm font-medium focus:outline-none focus:ring-2 
                                                ${newPayment.direction === 'in'
                                                        ? 'bg-green-600 hover:bg-green-700 focus:ring-green-500'
                                                        : 'bg-red-600 hover:bg-red-700 focus:ring-red-500'
                                                    }`}
                                            >
                                                {newPayment.direction === 'in' ? 'Add Payment In' : 'Add Payment Out'}
                                            </button>
                                        )}
                                    </div>
                                </form>
                            </div>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};

export default Payments;
