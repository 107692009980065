import React from 'react';
import { Check, X, AlertCircle, Loader2 } from 'lucide-react';

const InventoryConfirmationModal = ({
    isOpen,
    onClose,
    onConfirm,
    order,
    products,
    isPaid = false,
    isSubmitting = false
}) => {
    if (!isOpen) return null;

    // Get updates that will be made to inventory
    const inventoryUpdates = order.items
        .filter(item => item.productId)
        .map(item => {
            const product = products.find(p => p.id === item.productId);
            if (!product) return null;

            const currentStock = product.currentStock || 0;
            const newStock = currentStock + item.quantity;
            const priceChange = product.lastPurchasePrice !== item.unitPrice;

            return {
                product,
                currentStock,
                newStock,
                quantity: item.quantity,
                oldPrice: product.lastPurchasePrice,
                newPrice: item.unitPrice,
                priceChange
            };
        })
        .filter(Boolean);

    // If no inventory updates needed, don't show modal
    if (inventoryUpdates.length === 0) {
        onConfirm();
        return null;
    }

    return (
        <div className="fixed inset-0 bg-gray-600 bg-opacity-50 flex items-center justify-center z-50">
            <div className="bg-white rounded-lg p-6 max-w-2xl w-full max-h-[90vh] overflow-y-auto">
                <div className="flex justify-between items-center mb-6">
                    <h2 className="text-2xl font-semibold">Confirm Inventory Updates</h2>
                    <button
                        onClick={onClose}
                        disabled={isSubmitting}
                        className="text-gray-500 hover:text-gray-700 disabled:opacity-50"
                    >
                        <X className="h-6 w-6" />
                    </button>
                </div>

                <div className="bg-blue-50 border border-blue-200 rounded-lg p-4 mb-6 flex items-start gap-3">
                    <AlertCircle className="h-5 w-5 text-blue-500 mt-0.5" />
                    <p className="text-blue-700">
                        {isPaid ?
                            "This will mark the order as received and paid, and update your inventory quantities and prices." :
                            "This will mark the order as received (unpaid) and update your inventory quantities and prices."
                        }
                    </p>
                </div>

                <div className="space-y-6">
                    <div className="border rounded-lg overflow-hidden">
                        <table className="w-full">
                            <thead className="bg-gray-50">
                                <tr>
                                    <th className="px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase">Product</th>
                                    <th className="px-4 py-3 text-right text-xs font-medium text-gray-500 uppercase">Current Stock</th>
                                    <th className="px-4 py-3 text-right text-xs font-medium text-gray-500 uppercase">Adding</th>
                                    <th className="px-4 py-3 text-right text-xs font-medium text-gray-500 uppercase">New Stock</th>
                                    <th className="px-4 py-3 text-right text-xs font-medium text-gray-500 uppercase">Price Update</th>
                                </tr>
                            </thead>
                            <tbody className="divide-y divide-gray-200">
                                {inventoryUpdates.map((update, index) => (
                                    <tr key={index} className="hover:bg-gray-50">
                                        <td className="px-4 py-3">
                                            <div className="font-medium text-gray-900">{update.product.name}</div>
                                            <div className="text-sm text-gray-500">SKU: {update.product.sku || 'N/A'}</div>
                                        </td>
                                        <td className="px-4 py-3 text-right text-gray-500">{update.currentStock}</td>
                                        <td className="px-4 py-3 text-right text-green-600">+{update.quantity}</td>
                                        <td className="px-4 py-3 text-right font-medium text-gray-900">{update.newStock}</td>
                                        <td className="px-4 py-3 text-right">
                                            {update.priceChange ? (
                                                <div>
                                                    <div className="text-gray-500 line-through">
                                                        GHS {update.oldPrice?.toFixed(2) || '0.00'}
                                                    </div>
                                                    <div className="text-blue-600 font-medium">
                                                        GHS {update.newPrice.toFixed(2)}
                                                    </div>
                                                </div>
                                            ) : (
                                                <div className="text-gray-500">
                                                    GHS {update.newPrice.toFixed(2)}
                                                    <span className="text-gray-400 text-xs"> (unchanged)</span>
                                                </div>
                                            )}
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>

                    <div className="flex gap-3 justify-end">
                        <button
                            onClick={onClose}
                            disabled={isSubmitting}
                            className="px-4 py-2 text-gray-700 border rounded-lg hover:bg-gray-50 disabled:opacity-50"
                        >
                            Cancel
                        </button>
                        <button
                            onClick={onConfirm}
                            disabled={isSubmitting}
                            className="px-4 py-2 bg-blue-500 text-white rounded-lg hover:bg-blue-600 flex items-center disabled:opacity-50"
                        >
                            {isSubmitting ? (
                                <>
                                    <Loader2 className="h-4 w-4 mr-2 animate-spin" />
                                    Updating...
                                </>
                            ) : (
                                <>
                                    <Check className="h-4 w-4 mr-2" />
                                    Confirm Updates
                                </>
                            )}
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default InventoryConfirmationModal;