import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Search, UserPlus, Building, ChevronDown, ChevronLeft, ChevronRight, Truck, ShoppingBag, Loader2, ClipboardList, ArrowLeft, TruckIcon, AlertTriangle, CrownIcon } from 'lucide-react';
import {
    collection,
    query,
    where,
    getDocs,
    getFirestore,
    orderBy,
    Timestamp
} from 'firebase/firestore';
import { getAuth } from 'firebase/auth';
import Sidebar from '../components/sidebar';
import AddSupplierModal from '../components/add_supplier';
import EditSupplierModal from '../components/edit_supplier';
import { useAuth } from '../../auth/AuthContext';

const formatDate = (timestamp) => {
    if (!timestamp) return 'N/A';

    if (timestamp instanceof Timestamp) {
        return timestamp.toDate().toLocaleDateString(undefined, {
            year: 'numeric',
            month: 'short',
            day: 'numeric'
        });
    }

    return new Date(timestamp).toLocaleDateString(undefined, {
        year: 'numeric',
        month: 'short',
        day: 'numeric'
    });
};

// Restricted Access Component
const RestrictedAccess = () => (
    <div className="flex h-screen bg-white">
        <Sidebar />
        <div className="flex-1 flex items-center justify-center">
            <div className="text-center p-8">
                <CrownIcon className="h-16 w-16 text-yellow-500 mx-auto mb-4" />
                <h1 className="text-2xl font-semibold mb-2">Enterprise Plan Feature</h1>
                <p className="text-gray-600 mb-4">
                    The Suppliers management feature is only available on the Enterprise plan.
                </p>
                <p className="text-gray-500 text-sm">
                    Please upgrade your plan to access this feature.
                </p>
            </div>
        </div>
    </div>
);

const Suppliers = () => {
    const navigate = useNavigate();
    const { hasEnterpriseAccess } = useAuth();
    const [pendingOrders, setPendingOrders] = useState(0);
    const [searchTerm, setSearchTerm] = useState('');
    const [filterStatus, setFilterStatus] = useState('all');
    const [isStatusDropdownOpen, setIsStatusDropdownOpen] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const [isAddModalOpen, setIsAddModalOpen] = useState(false);
    const [suppliers, setSuppliers] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState(null);
    const [user, setUser] = useState(null);
    const [isEditModalOpen, setIsEditModalOpen] = useState(false);
    const [selectedSupplier, setSelectedSupplier] = useState(null);
    const itemsPerPage = 10;

    const db = getFirestore();
    const auth = getAuth();
    const storeId = localStorage.getItem('storeId');

    // Fetch user data
    useEffect(() => {
        const fetchUserData = async () => {
            try {
                const currentUser = auth.currentUser;
                if (!currentUser) {
                    throw new Error('No authenticated user found');
                }

                const userDoc = await getDocs(
                    query(
                        collection(db, 'Users'),
                        where('email', '==', currentUser.email)
                    )
                );

                if (!userDoc.empty) {
                    setUser(userDoc.docs[0].data());
                } else {
                    throw new Error('User data not found');
                }
            } catch (error) {
                console.error('Error fetching user data:', error);
                setError('Failed to authenticate user');
            }
        };

        if (auth.currentUser) {
            fetchUserData();
        }
    }, [db, auth]);

    // Fetch suppliers data
    useEffect(() => {
        const fetchSuppliers = async () => {
            if (!user?.storeId) return;

            setIsLoading(true);
            setError(null);

            try {
                // Get all suppliers
                const suppliersQuery = query(
                    collection(db, 'Stores', user.storeId, 'Suppliers'),
                    orderBy('name')
                );
                const suppliersSnapshot = await getDocs(suppliersQuery);

                // Get all purchase orders to calculate stats
                const ordersQuery = query(
                    collection(db, 'Stores', user.storeId, 'PurchaseOrders'),
                    orderBy('createdAt', 'desc')
                );
                const ordersSnapshot = await getDocs(ordersQuery);

                const suppliersData = suppliersSnapshot.docs.map(doc => {
                    const supplierData = doc.data();
                    const supplierOrders = ordersSnapshot.docs
                        .filter(orderDoc => orderDoc.data().supplierId === doc.id);

                    const stats = {
                        totalOrders: supplierOrders.length,
                        totalPurchased: supplierOrders.reduce((sum, order) =>
                            sum + (order.data().totalAmount || 0), 0),
                        lastDelivery: supplierOrders
                            .filter(order =>
                                order.data().status === 'received_paid' ||
                                order.data().status === 'received_unpaid')
                            .sort((a, b) => b.data().receivedDate - a.data().receivedDate)[0]?.data().receivedDate || null
                    };

                    return {
                        id: doc.id,
                        ...supplierData,
                        ...stats
                    };
                });

                setSuppliers(suppliersData);
            } catch (error) {
                console.error('Error fetching suppliers:', error);
                setError('Failed to load suppliers data');
            } finally {
                setIsLoading(false);
            }
        };

        fetchSuppliers();
    }, [user, db]);

    // Fetch pending orders count
    useEffect(() => {
        const fetchPendingOrders = async () => {
            if (!user?.storeId) return;

            try {
                const ordersQuery = query(
                    collection(db, 'Stores', user.storeId, 'PurchaseOrders'),
                    where('status', '==', 'pending')
                );
                const ordersSnapshot = await getDocs(ordersQuery);
                setPendingOrders(ordersSnapshot.size);
            } catch (error) {
                console.error('Error fetching pending orders:', error);
            }
        };

        fetchPendingOrders();
    }, [user, db]);

    useEffect(() => {
        const fetchPendingOrders = async () => {
            if (!user?.storeId) return;

            try {
                const ordersQuery = query(
                    collection(db, 'Stores', user.storeId, 'PurchaseOrders'),
                    where('status', '==', 'pending')
                );
                const ordersSnapshot = await getDocs(ordersQuery);
                setPendingOrders(ordersSnapshot.size);
            } catch (error) {
                console.error('Error fetching pending orders:', error);
            }
        };

        fetchPendingOrders();
    }, [user, db]);

    const handleAddSupplier = async (supplierData) => {
        try {
            // Refresh the suppliers list after adding
            const suppliersQuery = query(
                collection(db, 'Stores', user.storeId, 'Suppliers'),
                orderBy('name')
            );
            const suppliersSnapshot = await getDocs(suppliersQuery);
            const suppliersData = [];

            // Get each supplier's stats, just like in the original fetch
            for (const doc of suppliersSnapshot.docs) {
                const supplierData = doc.data();
                const statsQuery = query(
                    collection(db, 'Stores', user.storeId, 'SupplierStats'),
                    where('supplierId', '==', doc.id)
                );
                const statsDoc = await getDocs(statsQuery);

                if (!statsDoc.empty) {
                    const stats = statsDoc.docs[0].data();
                    suppliersData.push({
                        id: doc.id,
                        ...supplierData,
                        totalOrders: stats.totalOrders,
                        lastDelivery: stats.lastDelivery,
                        totalPurchased: stats.totalPurchased
                    });
                } else {
                    suppliersData.push({
                        id: doc.id,
                        ...supplierData,
                        totalOrders: 0,
                        lastDelivery: null,
                        totalPurchased: 0
                    });
                }
            }

            setSuppliers(suppliersData);
            setIsAddModalOpen(false);
        } catch (error) {
            console.error('Error refreshing suppliers:', error);
        }
    };

    const handleUpdateSupplier = async (updatedData) => {
        try {
            const updatedSuppliers = suppliers.map(supplier =>
                supplier.id === updatedData.id
                    ? { ...supplier, ...updatedData }
                    : supplier
            );
            setSuppliers(updatedSuppliers);
            setIsEditModalOpen(false);
            setSelectedSupplier(null);
        } catch (error) {
            console.error('Error updating supplier:', error);
        }
    };

    const statusOptions = [
        { value: 'all', label: 'All Suppliers' },
        { value: 'active', label: 'Active' },
        { value: 'inactive', label: 'Inactive' }
    ];

    const filteredSuppliers = suppliers.filter(supplier =>
        (supplier.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
            supplier.phone.includes(searchTerm)) &&
        (filterStatus === 'all' || supplier.status === filterStatus)
    );

    // Pagination calculations
    const totalPages = Math.ceil(filteredSuppliers.length / itemsPerPage);
    const startIndex = (currentPage - 1) * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;
    const currentSuppliers = filteredSuppliers.slice(startIndex, endIndex);

    const StatCard = ({ title, value, icon }) => (
        <div className="bg-white border p-6">
            <div className="flex items-center justify-between mb-2">
                <h3 className="text-sm text-gray-500">{title}</h3>
                <div className="text-gray-400">{icon}</div>
            </div>
            <p className="text-2xl font-bold">{value}</p>
        </div>
    );

    if (isLoading) {
        return (
            <div className="flex h-screen bg-white">
                <Sidebar />
                <div className="flex-1 flex items-center justify-center">
                    <div className="flex flex-col items-center gap-3">
                        <Loader2 className="h-8 w-8 animate-spin text-gray-600" />
                        <div className="text-lg">Loading suppliers...</div>
                    </div>
                </div>
            </div>
        );
    }

    if (error) {
        return (
            <div className="flex h-screen bg-white">
                <Sidebar />
                <div className="flex-1 flex items-center justify-center">
                    <div className="text-red-500 text-center">
                        <p className="text-lg">{error}</p>
                        <p className="text-sm">Please try refreshing the page</p>
                    </div>
                </div>
            </div>
        );
    }

    // Check for Enterprise access before rendering the main component
    if (!hasEnterpriseAccess) {
        return <RestrictedAccess />;
    }

    return (
        <div className="flex h-screen bg-white overflow-hidden">
            <Sidebar />
            <div className="flex-1 flex flex-col overflow-hidden">
                <div className="flex-1 overflow-y-auto p-8">
                    <div className="flex justify-between items-center mb-8">
                        <div className="flex justify-between items-center">
                            {/* <button
                                onClick={() => navigate(-1)}
                                className="mr-4 text-gray-500 hover:text-gray-700 transition-colors"
                            >
                                <ArrowLeft className="h-6 w-6" />
                            </button> */}
                            <h1 className="text-3xl font-semibold">Suppliers</h1>
                        </div>
                        <div className="flex space-x-4">

                            <button
                                onClick={() => setIsAddModalOpen(true)}
                                className="bg-blue-500 text-white px-4 py-2 rounded-lg flex items-center hover:bg-blue-600"
                            >
                                <TruckIcon className="h-5 w-5 mr-2" />
                                Add Supplier
                            </button>
                        </div>
                    </div>

                    {/* Statistics Cards */}
                    <div className="grid grid-cols-1 md:grid-cols-4 gap-4 mb-8">
                        <StatCard
                            title="Total Suppliers"
                            value={suppliers.length}
                            icon={<Building className="h-6 w-6" />}
                        />
                        <StatCard
                            title="Active Suppliers"
                            value={suppliers.filter(s => s.status === 'active').length}
                            icon={<Truck className="h-6 w-6" />}
                        />
                        <StatCard
                            title="Pending Orders"
                            value={pendingOrders}
                            icon={<ClipboardList className="h-6 w-6" />}
                        />
                        <StatCard
                            title="Total Purchased"
                            value={`GHS ${suppliers.reduce((acc, curr) => acc + (curr.totalPurchased || 0), 0).toLocaleString()}`}
                            icon={<ShoppingBag className="h-6 w-6" />}
                        />
                    </div>

                    <div className="mt-8 mb-4 flex justify-between items-center">
                        <h2 className="text-xl font-semibold">Supplier List</h2>
                        <div className="flex space-x-2">
                            <div className="relative">
                                <input
                                    type="text"
                                    placeholder="Search suppliers..."
                                    value={searchTerm}
                                    onChange={(e) => setSearchTerm(e.target.value)}
                                    className="pl-10 pr-4 py-2 rounded-lg border border-gray-300 focus:outline-none focus:ring-2 focus:ring-blue-500"
                                />
                                <div className="absolute left-3 top-1/2 transform -translate-y-1/2">
                                    <Search className="h-5 w-5 text-gray-400" />
                                </div>
                            </div>

                            <div className="relative">
                                <button
                                    onClick={() => setIsStatusDropdownOpen(!isStatusDropdownOpen)}
                                    className="flex items-center space-x-2 px-4 py-2 bg-white border rounded-lg text-gray-700 hover:bg-gray-50"
                                >
                                    <span>{statusOptions.find(opt => opt.value === filterStatus).label}</span>
                                    <ChevronDown className="h-4 w-4" />
                                </button>
                                {isStatusDropdownOpen && (
                                    <div className="absolute right-0 mt-2 py-2 w-48 bg-white rounded-lg shadow-lg border z-10">
                                        {statusOptions.map((option) => (
                                            <button
                                                key={option.value}
                                                className="block w-full text-left px-4 py-2 text-gray-700 hover:bg-gray-100"
                                                onClick={() => {
                                                    setFilterStatus(option.value);
                                                    setIsStatusDropdownOpen(false);
                                                }}
                                            >
                                                {option.label}
                                            </button>
                                        ))}
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>

                    {/* Suppliers Table */}
                    <div className="border p-5 pb-0">
                        <div className="overflow-x-auto">
                            <table className="w-full">
                                <thead>
                                    <tr className="bg-gray-100">
                                        <th className="px-6 py-4 text-left text-sm font-medium text-gray-500">Supplier Name</th>
                                        <th className="px-6 py-4 text-left text-sm font-medium text-gray-500">Category</th>
                                        <th className="px-6 py-4 text-left text-sm font-medium text-gray-500">Phone</th>
                                        <th className="px-6 py-4 text-left text-sm font-medium text-gray-500">Orders</th>
                                        <th className="px-6 py-4 text-left text-sm font-medium text-gray-500">Last Delivery</th>
                                        <th className="px-6 py-4 text-left text-sm font-medium text-gray-500">Total Purchased</th>
                                        <th className="px-6 py-4 text-left text-sm font-medium text-gray-500">Actions</th>
                                    </tr>
                                </thead>
                                <tbody className="divide-y">
                                    {currentSuppliers.length === 0 ? (
                                        <tr>
                                            <td colSpan="6" className="px-6 py-4 text-center text-gray-500">
                                                No suppliers found
                                            </td>
                                        </tr>
                                    ) : (
                                        currentSuppliers.map((supplier) => (
                                            <tr key={supplier.id} className="hover:bg-gray-50">
                                                <td className="px-6 py-4 text-sm text-gray-900">
                                                    {supplier.name}
                                                </td>
                                                <td className="px-6 py-4 text-sm text-gray-500">
                                                    {supplier.category}
                                                </td>
                                                <td className="px-6 py-4 text-sm text-gray-500">
                                                    {supplier.phone}
                                                </td>
                                                <td className="px-6 py-4 text-sm text-gray-500">
                                                    {supplier.totalOrders || 0}
                                                </td>
                                                <td className="px-6 py-4 text-sm text-gray-500">
                                                    {formatDate(supplier.lastDelivery)}
                                                </td>
                                                <td className="px-6 py-4 text-sm text-gray-500">
                                                    <span className="text-xs mr-1">GHS</span>
                                                    <span className="text-gray-900">{(supplier.totalPurchased || 0).toFixed(2)}</span>
                                                </td>
                                                <td className="px-6 py-4 text-sm text-gray-500">
                                                    <button
                                                        onClick={() => {
                                                            setSelectedSupplier(supplier);
                                                            setIsEditModalOpen(true);
                                                        }}
                                                        className="text-blue-500 hover:text-blue-700"
                                                    >
                                                        Edit
                                                    </button>
                                                </td>
                                            </tr>
                                        ))
                                    )}
                                </tbody>
                            </table>
                        </div>

                        <div className="px-6 py-4 border-t flex items-center justify-between">
                            <div className="text-sm text-gray-500">
                                Showing {startIndex + 1} to {Math.min(endIndex, filteredSuppliers.length)} of {filteredSuppliers.length} suppliers
                            </div>
                            <div className="flex items-center space-x-2">
                                <button
                                    onClick={() => setCurrentPage(prev => Math.max(prev - 1, 1))}
                                    disabled={currentPage === 1}
                                    className="p-2 border rounded hover:bg-gray-50 disabled:opacity-50 disabled:cursor-not-allowed"
                                >
                                    <ChevronLeft className="h-4 w-4" />
                                </button>
                                <div className="text-sm text-gray-700">
                                    Page {currentPage} of {totalPages}
                                </div>
                                <button
                                    onClick={() => setCurrentPage(prev => Math.min(prev + 1, totalPages))}
                                    disabled={currentPage === totalPages}
                                    className="p-2 border rounded hover:bg-gray-50 disabled:opacity-50 disabled:cursor-not-allowed"
                                >
                                    <ChevronRight className="h-4 w-4" />
                                </button>
                            </div>
                        </div>
                    </div>

                    {isAddModalOpen && (
                        <AddSupplierModal
                            onClose={() => setIsAddModalOpen(false)}
                            onSuccess={handleAddSupplier}
                        />
                    )}

                    {isEditModalOpen && selectedSupplier && (
                        <EditSupplierModal
                            supplier={selectedSupplier}
                            onClose={() => {
                                setIsEditModalOpen(false);
                                setSelectedSupplier(null);
                            }}
                            onSuccess={handleUpdateSupplier}
                        />
                    )}
                </div>
            </div>
        </div>
    );
};

export default Suppliers;