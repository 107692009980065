import React, { useState, useEffect } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import {
    Home,
    BarChart2,
    Package,
    ShoppingCart,
    DollarSign,
    Settings,
    Users,
    ChevronLeft,
    ChevronRight,
    Plus,
    LogOut,
    CreditCard,
    UserCircle,
    FileText,
    Archive,
    ReceiptText,
    LucideReceiptCent,
    ShoppingBag,
    UsersRound,
    Truck,
    Boxes,
    LayoutDashboard,
    ClipboardList,
    Download,
    Upload,
    ArrowDownToDot,
    ArrowUpFromDot,
    Crown
} from 'lucide-react';
import { auth } from '../../../firebase-config';
import { signOut } from 'firebase/auth';
import logo from '../../../assets/hotKioskLogocolor.png';
import { useAuth, PLAN_LEVELS } from '../../auth/AuthContext';

// Logout Modal Component
const LogoutModal = ({ isOpen, onClose, onConfirm }) => {
    if (!isOpen) return null;

    return (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
            <div className="bg-white rounded-lg p-6 max-w-sm w-full mx-4">
                <h3 className="text-lg font-semibold text-gray-900 mb-2">
                    Confirm Logout
                </h3>
                <p className="text-gray-600 mb-6">
                    Are you sure you want to log out? This will end your current session.
                </p>
                <div className="flex justify-end space-x-3">
                    <button
                        onClick={onClose}
                        className="px-4 py-2 text-gray-600 hover:bg-gray-100 rounded transition-colors duration-200"
                    >
                        Cancel
                    </button>
                    <button
                        onClick={onConfirm}
                        className="px-4 py-2 bg-orange-600 text-white rounded hover:bg-orange-700 transition-colors duration-200"
                    >
                        Logout
                    </button>
                </div>
            </div>
        </div>
    );
};

// Menu Heading Component
const MenuHeading = ({ icon: Icon, title }) => (
    <div className="flex items-center px-2 py-1 mt-2 mb-1 text-gray-400 text-xs font-medium">
        {/* <Icon size={12} className="mr-2" /> */}
        <span>{title}</span>
    </div>
);

// Sidebar Link Component
const SidebarLink = ({
    to,
    icon,
    text,
    isActive,
    isCollapsed,
    requiredPlanLevel = PLAN_LEVELS.TRIAL // Default to TRIAL level
}) => {
    const { planLevel } = useAuth();
    const isPremiumFeature = planLevel < requiredPlanLevel;

    return (
        <Link
            to={to}
            className={`group relative flex items-center py-1 px-2 rounded transition-colors duration-200 
                ${isActive ? 'text-orange-500' : 'text-gray-300 hover:text-orange-400'} 
                ${isCollapsed ? 'justify-center' : ''}`}
            title={isCollapsed ? text : ''}
        >
            <span className="flex items-center">
                {icon}
            </span>
            {!isCollapsed && (
                <span className="ml-2 text-sm flex-grow">
                    {text}
                </span>
            )}
            {isPremiumFeature && (
                <Crown
                    size={14}
                    className={`text-yellow-400 ${isCollapsed ? 'absolute -top-1 -right-1' : 'ml-2'}`}
                />
            )}
        </Link>
    );
};

const Sidebar = () => {
    const [isCollapsed, setIsCollapsed] = useState(() => {
        return localStorage.getItem('sidebarCollapsed') === 'true';
    });
    const [showLogoutModal, setShowLogoutModal] = useState(false);
    const location = useLocation();
    const navigate = useNavigate();
    const storeId = localStorage.getItem('storeId');

    useEffect(() => {
        localStorage.setItem('sidebarCollapsed', isCollapsed);
    }, [isCollapsed]);

    const toggleSidebar = () => {
        setIsCollapsed(prevState => !prevState);
    };

    const handleNewSale = () => {
        navigate('/sales/add');
    };

    const handleLogoutClick = () => {
        setShowLogoutModal(true);
    };

    const handleLogoutConfirm = async () => {
        try {
            localStorage.clear();
            await signOut(auth);
            setShowLogoutModal(false);
            window.location.href = '/login';
        } catch (error) {
            console.error("Error signing out: ", error);
        }
    };

    return (
        <div className={`bg-black text-white ${isCollapsed ? 'w-16' : 'w-64'} min-h-screen p-2 transition-all duration-300 relative flex flex-col`}>
            {/* Logo */}
            <div className={`flex items-center mb-4 ${isCollapsed ? 'justify-center' : ''}`}>
                <img
                    src={logo}
                    alt="HotKiosk Logo"
                    className={`h-12 ${isCollapsed ? 'mr-0' : 'mr-1'}`}
                />
            </div>

            {/* New Sale Button */}
            <button
                onClick={handleNewSale}
                className={`bg-orange-600 text-white text-base font-semibold w-full py-4 rounded mb-3 flex items-center justify-center hover:bg-orange-700 transition-colors duration-200 ${isCollapsed ? 'p-1' : ''}`}
            >
                {isCollapsed ? <Plus size={16} /> : "Make Sales / POS"}
            </button>

            {/* Navigation Menu */}
            <nav className="flex-grow overflow-y-auto space-y-0.5">
                {/* Dashboard */}
                <SidebarLink
                    to="/dashboard"
                    icon={<Home size={16} />}
                    text="Home"
                    isCollapsed={isCollapsed}
                    isActive={location.pathname === '/dashboard'}
                />

                {/* Sales Section */}
                {!isCollapsed && <MenuHeading icon={ShoppingCart} title="Sales" />}
                {/* <SidebarLink
                    to="/sales"
                    icon={<LayoutDashboard size={16} />}
                    text="Sales Stats"
                    isCollapsed={isCollapsed}
                    isActive={location.pathname === '/sales'}
                /> */}
                <SidebarLink
                    to="/sales/list"
                    icon={<ShoppingCart size={16} />}
                    text="All Sales"
                    isCollapsed={isCollapsed}
                    isActive={location.pathname === '/sales/list'}
                />
                <SidebarLink
                    to="/sales/quotes"
                    icon={<FileText size={16} />}
                    text="Sales Quotes"
                    isCollapsed={isCollapsed}
                    isActive={location.pathname === '/sales/quotes'}
                    requiredPlanLevel={PLAN_LEVELS.ENTERPRISE}
                />
                <SidebarLink
                    to="/sales/receipts"
                    icon={<ReceiptText size={16} />}
                    text="Receipts"
                    isCollapsed={isCollapsed}
                    isActive={location.pathname === '/sales/receipts'}
                />
                <SidebarLink
                    to="/sales/returns"
                    icon={<CreditCard size={16} />}
                    text="Returns"
                    isCollapsed={isCollapsed}
                    isActive={location.pathname === '/sales/returns'}
                    requiredPlanLevel={PLAN_LEVELS.ENTERPRISE}
                />

                {/* Inventory Section */}
                {!isCollapsed && <MenuHeading icon={Package} title="Inventory" />}
                <SidebarLink
                    to="/inventory"
                    icon={<Boxes size={16} />}
                    text="Inventory"
                    isCollapsed={isCollapsed}
                    isActive={location.pathname === '/inventory'}
                />
                {/* <SidebarLink
                    to="/inventory/list"
                    icon={<Package size={16} />}
                    text="Inventory Items"
                    isCollapsed={isCollapsed}
                    isActive={location.pathname === '/inventory/list'}
                />
                <SidebarLink
                    to="/inventory/history"
                    icon={<Package size={16} />}
                    text="Stock History"
                    isCollapsed={isCollapsed}
                    isActive={location.pathname === '/inventory/history'}
                /> */}
                <SidebarLink
                    to="/inventory/stock"
                    icon={<Package size={16} />}
                    text="Stock Management"
                    isCollapsed={isCollapsed}
                    isActive={location.pathname === '/inventory/stock'}
                />
                <SidebarLink
                    to="/inventory/categories"
                    icon={<Archive size={16} />}
                    text="Categories"
                    isCollapsed={isCollapsed}
                    isActive={location.pathname === '/inventory/categories'}
                />

                {!isCollapsed && <MenuHeading icon={Users} title="Cash In & Out" />}
                <SidebarLink
                    to="/payments"
                    icon={<ArrowDownToDot size={16} />}
                    text="Payments"
                    isCollapsed={isCollapsed}
                    isActive={location.pathname === '/payments'}
                    requiredPlanLevel={PLAN_LEVELS.PRO}
                />
                <SidebarLink
                    to="/expenses"
                    icon={<ArrowUpFromDot size={16} />}
                    text="Expenses"
                    isCollapsed={isCollapsed}
                    isActive={location.pathname === '/expenses'}
                    requiredPlanLevel={PLAN_LEVELS.PRO}
                />

                {/* Clients Section */}
                {!isCollapsed && <MenuHeading icon={Users} title="Clients" />}

                <SidebarLink
                    to="/clients/customers"
                    icon={<UserCircle size={16} />}
                    text="Customers"
                    isCollapsed={isCollapsed}
                    isActive={location.pathname === '/clients/customers'}
                    requiredPlanLevel={PLAN_LEVELS.PRO}
                />
                <SidebarLink
                    to="/clients/suppliers"
                    icon={<Truck size={16} />}
                    text="Suppliers"
                    isCollapsed={isCollapsed}
                    isActive={location.pathname === '/clients/suppliers'}
                    requiredPlanLevel={PLAN_LEVELS.ENTERPRISE}
                />
                <SidebarLink
                    to="/clients/suppliers/purchase-orders"
                    icon={<ClipboardList size={16} />}
                    text="Purchase Orders"
                    isCollapsed={isCollapsed}
                    isActive={location.pathname === '/clients/suppliers/purchase-orders'}
                    requiredPlanLevel={PLAN_LEVELS.ENTERPRISE}
                />

                {/* Reports Section */}
                {!isCollapsed && <MenuHeading icon={BarChart2} title="Reports" />}
                <SidebarLink
                    to="/reports"
                    icon={<BarChart2 size={16} />}
                    text="Reports & Analytics"
                    isCollapsed={isCollapsed}
                    isActive={location.pathname === '/reports'}
                />
                {/* <SidebarLink
                    to="/reports/analytics"
                    icon={<BarChart2 size={16} />}
                    text="Analytic Reports"
                    isCollapsed={isCollapsed}
                    isActive={location.pathname === '/reports/analytics'}
                /> */}
                {/* <SidebarLink
                    to="/reports/finance"
                    icon={<DollarSign size={16} />}
                    text="Financial Reports"
                    isCollapsed={isCollapsed}
                    isActive={location.pathname === '/reports/finance'}
                />
                <SidebarLink
                    to="/reports/clients"
                    icon={<Users size={16} />}
                    text="Client Reports"
                    isCollapsed={isCollapsed}
                    isActive={location.pathname === '/reports/clients'}
                /> */}

                {/* Settings Section */}
                {!isCollapsed && <MenuHeading icon={Settings} title="Settings" />}
                <SidebarLink
                    to="/settings"
                    icon={<Settings size={16} />}
                    text="Settings"
                    isCollapsed={isCollapsed}
                    isActive={location.pathname === '/settings'}
                />
            </nav>

            {/* Logout Button */}
            <button
                onClick={handleLogoutClick}
                className={`mt-auto flex items-center justify-center w-full py-2 px-3 rounded 
                    ring-orange-600 hover:ring-orange-700 hover:bg-orange-600/30 text-white transition-colors duration-200 ring-1
                    ${isCollapsed ? 'mx-auto' : ''}`}
                title={isCollapsed ? "Logout" : ''}
            >
                <LogOut size={16} className="flex-shrink-0" />
                {!isCollapsed && (
                    <span className="ml-2 text-sm font-medium">Logout</span>
                )}
            </button>

            {/* Collapse Toggle Button */}
            <button
                onClick={toggleSidebar}
                className="absolute -right-2 top-1/2 bg-black text-gray-300 hover:text-orange-400 p-0.5 rounded-full transition-colors duration-200"
            >
                {isCollapsed ? <ChevronRight size={14} /> : <ChevronLeft size={14} />}
            </button>

            {/* Logout Confirmation Modal */}
            <LogoutModal
                isOpen={showLogoutModal}
                onClose={() => setShowLogoutModal(false)}
                onConfirm={handleLogoutConfirm}
            />
        </div>
    );
};

export default Sidebar;