import React, { useState, useEffect, useCallback } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { useAuth, ACCESS_LEVELS } from './AuthContext';
import { getFirestore, doc, onSnapshot, getDoc } from 'firebase/firestore';

// Constants
const CONSTANTS = {
    RETRY_ATTEMPTS: 3,
    RETRY_DELAY: 1000,
    SUBSCRIPTION_EXEMPT_PATHS: [
        '/account-downgraded',
        '/settings'
    ],
    RESTRICTED_URLS: [
        '/inventory',
        '/inventory/list',
        '/inventory/categories',
        '/inventory/stock',
        '/sales',
        '/sales/list',
        '/sales/quotes',
        '/sales/returns',
        '/sales/invoices',
        '/clients',
        '/clients/customers',
        '/clients/suppliers',
        '/clients/suppliers/purchase-orders',
        '/expenses',
        '/expenses/list',
        '/expenses/categories',
        '/expenses/recurring',
        '/reports',
        '/reports/analytics',
        '/reports/finance',
        '/reports/clients'
    ]
};

const wait = (ms) => new Promise(resolve => setTimeout(resolve, ms));

const createDefaultAccess = () => {
    const defaultAccess = {};
    CONSTANTS.RESTRICTED_URLS.forEach(url => {
        defaultAccess[url] = false;
    });
    return defaultAccess;
};

export function PrivateRoute({ children, requireFullAccess = false }) {
    const {
        user,
        loading,
        hasFullAccess,
        accessLevel,
        temporaryAccessLevel, // Add this
    } = useAuth();
    const [state, setState] = useState({
        pageAccess: null,
        loadingPermissions: true,
        subscriptionValid: true,
        error: null,
        retryCount: 0
    });
    const location = useLocation();

    // Add helper to get effective access level
    const effectiveAccessLevel = temporaryAccessLevel || accessLevel;

    const validateSubscription = useCallback((storeData) => {
        const isTrialActive = storeData.trialStatus !== 'ended';
        if (isTrialActive) return true;

        const hasActiveSubscription =
            storeData.subscription?.status === 'active' ||
            storeData.subscription?.subscriptionStatus === 'active';

        return hasActiveSubscription;
    }, []);

    // const processPageAccess = useCallback((pageAccessData) => {
    //     if (!pageAccessData) return createDefaultAccess();

    //     if (Array.isArray(pageAccessData[accessLevel])) {
    //         const converted = {};
    //         CONSTANTS.RESTRICTED_URLS.forEach(url => {
    //             converted[url] = pageAccessData[accessLevel].includes(url.replace(/^\//, ''));
    //         });
    //         return converted;
    //     }
    //     return pageAccessData[accessLevel] || createDefaultAccess();
    // }, [accessLevel]);

    // Update processPageAccess to use effectiveAccessLevel

    const processPageAccess = useCallback((pageAccessData) => {
        if (!pageAccessData) return createDefaultAccess();

        if (Array.isArray(pageAccessData[effectiveAccessLevel])) {
            const converted = {};
            CONSTANTS.RESTRICTED_URLS.forEach(url => {
                converted[url] = pageAccessData[effectiveAccessLevel].includes(url.replace(/^\//, ''));
            });
            return converted;
        }
        return pageAccessData[effectiveAccessLevel] || createDefaultAccess();
    }, [effectiveAccessLevel]);

    const fetchDataWithRetry = useCallback(async (storeId) => {
        for (let i = 0; i < CONSTANTS.RETRY_ATTEMPTS; i++) {
            try {
                const db = getFirestore();
                const storeDoc = await getDoc(doc(db, 'Stores', storeId));
                if (storeDoc.exists()) {
                    return storeDoc.data();
                }
                throw new Error('Store document does not exist');
            } catch (error) {
                if (i === CONSTANTS.RETRY_ATTEMPTS - 1) throw error;
                await wait(CONSTANTS.RETRY_DELAY * (i + 1));
            }
        }
    }, []);

    useEffect(() => {
        if (!user?.storeId) {
            setState(prev => ({ ...prev, loadingPermissions: false }));
            return;
        }

        let isActive = true;
        let snapshotUnsubscribe = () => { };

        const setupListener = async () => {
            const db = getFirestore();
            const storeRef = doc(db, 'Stores', user.storeId);

            try {
                const initialData = await fetchDataWithRetry(user.storeId);
                if (isActive) {
                    setState(prev => ({
                        ...prev,
                        subscriptionValid: validateSubscription(initialData),
                        pageAccess: processPageAccess(initialData.pageAccess),
                        loadingPermissions: false,
                        error: null
                    }));
                }

                snapshotUnsubscribe = onSnapshot(
                    storeRef,
                    (snapshot) => {
                        if (snapshot.exists() && isActive) {
                            const storeData = snapshot.data();
                            setState(prev => ({
                                ...prev,
                                subscriptionValid: validateSubscription(storeData),
                                pageAccess: processPageAccess(storeData.pageAccess),
                                loadingPermissions: false,
                                error: null
                            }));
                        }
                    },
                    async (error) => {
                        if (isActive) {
                            setState(prev => ({
                                ...prev,
                                error: error.message,
                                retryCount: prev.retryCount + 1
                            }));

                            try {
                                const fallbackData = await fetchDataWithRetry(user.storeId);
                                if (isActive) {
                                    setState(prev => ({
                                        ...prev,
                                        subscriptionValid: validateSubscription(fallbackData),
                                        pageAccess: processPageAccess(fallbackData.pageAccess),
                                        loadingPermissions: false,
                                        error: null
                                    }));
                                }
                            } catch (fallbackError) {
                                if (isActive) {
                                    setState(prev => ({
                                        ...prev,
                                        error: 'Failed to fetch data after multiple attempts',
                                        loadingPermissions: false
                                    }));
                                }
                            }
                        }
                    }
                );
            } catch (error) {
                if (isActive) {
                    setState(prev => ({
                        ...prev,
                        error: error.message,
                        loadingPermissions: false
                    }));
                }
            }
        };

        setupListener();

        return () => {
            isActive = false;
            snapshotUnsubscribe();
        };
    }, [user?.storeId, accessLevel, validateSubscription, processPageAccess, fetchDataWithRetry]);

    // Handle loading states first
    if (loading || state.loadingPermissions) {
        return (
            <div className="flex items-center justify-center min-h-screen">
                <div className="flex flex-col items-center gap-4">
                    <div className="w-12 h-12 border-4 border-gray-200 border-t-orange-500 rounded-full animate-spin"></div>
                    <div className="text-gray-600">Getting things ready...</div>
                </div>
            </div>
        );
    }

    // Handle error states
    if (state.error) {
        return (
            <div className="flex flex-col items-center justify-center min-h-screen p-4">
                <div className="text-red-600 mb-4">Error: {state.error}</div>
                <button
                    className="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600"
                    onClick={() => window.location.reload()}
                >
                    Retry
                </button>
            </div>
        );
    }

    // Handle authentication
    if (!loading && !user) {
        // Store the attempted location
        return <Navigate to="/login?reason=unauthorized" state={{ from: location.pathname }} replace />;
    }

    const currentPath = location.pathname;
    const currentHash = location.hash;

    // Handle subscription validation
    if (!state.subscriptionValid) {
        const isExemptPath = CONSTANTS.SUBSCRIPTION_EXEMPT_PATHS.includes(currentPath);
        const isSubscriptionSettings = currentPath === '/settings' && currentHash === '#subscription';

        if (!isExemptPath && !isSubscriptionSettings) {
            return <Navigate to="/account-downgraded" replace />;
        }

        return children;
    }
    // Handle access level requirements based on effective access level only
    if (requireFullAccess) {
        const hasEffectiveFullAccess = effectiveAccessLevel === ACCESS_LEVELS.OWNER ||
            effectiveAccessLevel === ACCESS_LEVELS.ADMIN;
        if (!hasEffectiveFullAccess) {
            return <Navigate to="/unauthorized" replace />;
        }
    }

    console.log('Effective Access level:', effectiveAccessLevel);
    // Check if user has unrestricted access based on effective level only
    const hasUnrestrictedAccess = effectiveAccessLevel === ACCESS_LEVELS.OWNER ||
        effectiveAccessLevel === ACCESS_LEVELS.ADMIN;

    if (hasUnrestrictedAccess) {
        return children;
    }

    // Check restricted URLs for all other access levels
    if (CONSTANTS.RESTRICTED_URLS.includes(currentPath)) {
        const isAllowed = state.pageAccess && state.pageAccess[currentPath] === true;
        if (!isAllowed) {
            return <Navigate to="/unauthorized" replace />;
        }
    }

    // All checks passed, render the protected content
    return children;
}

//------------------------------------------------------------------------
//------------------------------------------------------------------------

// import React, { useState, useEffect, useCallback } from 'react';
// import { Navigate, useLocation } from 'react-router-dom';
// import { useAuth } from './AuthContext';
// import { getFirestore, doc, onSnapshot, getDoc } from 'firebase/firestore';

// // Constants moved to a separate section for better maintainability
// const CONSTANTS = {
//     RETRY_ATTEMPTS: 3,
//     RETRY_DELAY: 1000,
//     SUBSCRIPTION_EXEMPT_PATHS: [
//         '/account-downgraded',
//         '/settings'
//     ],
//     RESTRICTED_URLS: [
//         '/inventory',
//         '/inventory/list',
//         '/inventory/categories',
//         '/inventory/stock',
//         '/sales',
//         '/sales/list',
//         '/sales/quotes',
//         '/sales/returns',
//         '/sales/invoices',
//         '/clients',
//         '/clients/customers',
//         '/clients/suppliers',
//         '/clients/suppliers/purchase-orders',
//         '/expenses',
//         '/expenses/list',
//         '/expenses/categories',
//         '/expenses/recurring',
//         '/reports',
//         '/reports/analytics',
//         '/reports/finance',
//         '/reports/clients'
//     ]
// };

// // Utility functions
// const wait = (ms) => new Promise(resolve => setTimeout(resolve, ms));

// const createDefaultAccess = () => {
//     const defaultAccess = {};
//     CONSTANTS.RESTRICTED_URLS.forEach(url => {
//         defaultAccess[url] = false;
//     });
//     return defaultAccess;
// };

// export function PrivateRoute({ children, requireFullAccess = false }) {
//     const { user, loading, hasFullAccess, accessLevel } = useAuth();
//     const [state, setState] = useState({
//         pageAccess: null,
//         loadingPermissions: true,
//         subscriptionValid: true,
//         error: null,
//         retryCount: 0
//     });
//     const location = useLocation();

//     // Function to validate subscription status
//     const validateSubscription = useCallback((storeData) => {
//         // Check if trial is active (not ended)
//         const isTrialActive = storeData.trialStatus !== 'ended';

//         // If trial is active, user has full access
//         if (isTrialActive) {
//             return true;
//         }

//         // If trial has ended, check subscription status
//         const hasActiveSubscription =
//             storeData.subscription?.status === 'active' ||
//             storeData.subscription?.subscriptionStatus === 'active';

//         return hasActiveSubscription;
//     }, []);

//     // Function to handle page access data
//     const processPageAccess = useCallback((pageAccessData) => {
//         if (!pageAccessData) return createDefaultAccess();

//         if (Array.isArray(pageAccessData[accessLevel])) {
//             const converted = {};
//             CONSTANTS.RESTRICTED_URLS.forEach(url => {
//                 converted[url] = pageAccessData[accessLevel].includes(url.replace(/^\//, ''));
//             });
//             return converted;
//         }
//         return pageAccessData[accessLevel] || createDefaultAccess();
//     }, [accessLevel]);

//     // Fallback data fetching mechanism
//     const fetchDataWithRetry = useCallback(async (storeId) => {
//         for (let i = 0; i < CONSTANTS.RETRY_ATTEMPTS; i++) {
//             try {
//                 const db = getFirestore();
//                 const storeDoc = await getDoc(doc(db, 'Stores', storeId));
//                 if (storeDoc.exists()) {
//                     return storeDoc.data();
//                 }
//                 throw new Error('Store document does not exist');
//             } catch (error) {
//                 if (i === CONSTANTS.RETRY_ATTEMPTS - 1) throw error;
//                 await wait(CONSTANTS.RETRY_DELAY * (i + 1)); // Exponential backoff
//             }
//         }
//     }, []);

//     // Combined effect for subscription and page access
//     useEffect(() => {
//         if (!user?.storeId) {
//             setState(prev => ({ ...prev, loadingPermissions: false }));
//             return;
//         }

//         let isActive = true;
//         let snapshotUnsubscribe = () => { };

//         const setupListener = async () => {
//             const db = getFirestore();
//             const storeRef = doc(db, 'Stores', user.storeId);

//             try {
//                 // Initial data fetch with retry
//                 const initialData = await fetchDataWithRetry(user.storeId);
//                 if (isActive) {
//                     setState(prev => ({
//                         ...prev,
//                         subscriptionValid: validateSubscription(initialData),
//                         pageAccess: processPageAccess(initialData.pageAccess),
//                         loadingPermissions: false,
//                         error: null
//                     }));
//                 }

//                 // Set up real-time listener
//                 snapshotUnsubscribe = onSnapshot(
//                     storeRef,
//                     (snapshot) => {
//                         if (snapshot.exists() && isActive) {
//                             const storeData = snapshot.data();
//                             setState(prev => ({
//                                 ...prev,
//                                 subscriptionValid: validateSubscription(storeData),
//                                 pageAccess: processPageAccess(storeData.pageAccess),
//                                 loadingPermissions: false,
//                                 error: null
//                             }));
//                         }
//                     },
//                     async (error) => {
//                         // console.error('Snapshot listener error:', error);
//                         if (isActive) {
//                             setState(prev => ({
//                                 ...prev,
//                                 error: error.message,
//                                 retryCount: prev.retryCount + 1
//                             }));

//                             // Attempt to recover using regular fetch
//                             try {
//                                 const fallbackData = await fetchDataWithRetry(user.storeId);
//                                 if (isActive) {
//                                     setState(prev => ({
//                                         ...prev,
//                                         subscriptionValid: validateSubscription(fallbackData),
//                                         pageAccess: processPageAccess(fallbackData.pageAccess),
//                                         loadingPermissions: false,
//                                         error: null
//                                     }));
//                                 }
//                             } catch (fallbackError) {
//                                 if (isActive) {
//                                     setState(prev => ({
//                                         ...prev,
//                                         error: 'Failed to fetch data after multiple attempts',
//                                         loadingPermissions: false
//                                     }));
//                                 }
//                             }
//                         }
//                     }
//                 );
//             } catch (error) {
//                 if (isActive) {
//                     setState(prev => ({
//                         ...prev,
//                         error: error.message,
//                         loadingPermissions: false
//                     }));
//                 }
//             }
//         };

//         setupListener();

//         return () => {
//             isActive = false;
//             snapshotUnsubscribe();
//         };
//     }, [user?.storeId, accessLevel, validateSubscription, processPageAccess, fetchDataWithRetry]);

//     // Error state handling
//     if (state.error) {
//         return (
//             <div className="flex flex-col items-center justify-center min-h-screen p-4">
//                 <div className="text-red-600 mb-4">Error: {state.error}</div>
//                 <button
//                     className="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600"
//                     onClick={() => window.location.reload()}
//                 >
//                     Retry
//                 </button>
//             </div>
//         );
//     }

//     if (loading || state.loadingPermissions) {
//         return (
//             <div className="flex items-center justify-center min-h-screen">
//                 <div className="text-gray-600">Loading...</div>
//             </div>
//         );
//     }

//     if (!user) {
//         return <Navigate to="/login" state={{ from: location }} replace />;
//     }

//     const currentPath = location.pathname;
//     const currentHash = location.hash;

//     if (!state.subscriptionValid) {
//         const isExemptPath = CONSTANTS.SUBSCRIPTION_EXEMPT_PATHS.includes(currentPath);
//         const isSubscriptionSettings = currentPath === '/settings' && currentHash === '#subscription';

//         if (!isExemptPath && !isSubscriptionSettings) {
//             return <Navigate to="/account-downgraded" replace />;
//         }

//         return children;
//     }

//     if (requireFullAccess && !hasFullAccess) {
//         return <Navigate to="/unauthorized" replace />;
//     }

//     if (accessLevel === 1 || accessLevel === 3) {
//         return children;
//     }

//     if (CONSTANTS.RESTRICTED_URLS.includes(currentPath)) {
//         const isAllowed = state.pageAccess && state.pageAccess[currentPath] === true;
//         if (!isAllowed) {
//             return <Navigate to="/unauthorized" replace />;
//         }
//     }

//     return children;
// }

