import React, { useState, useEffect, useMemo } from 'react';
import { BarChart, Bar, XAxis, YAxis, ResponsiveContainer, Tooltip, CartesianGrid, Area, LineChart, Line, } from 'recharts';
import { ShoppingCart, Package, Loader2, AlertTriangle, Store, Users, FileText, Settings, Printer, Lock, Unlock, User } from 'lucide-react';
import {
    collection,
    query,
    where,
    orderBy,
    getDocs,
    getFirestore,
    Timestamp,
    doc,
    getDoc,
    updateDoc
} from 'firebase/firestore';
import { getAuth } from 'firebase/auth';
import { useNavigate } from 'react-router-dom';
import { useAuth, ACCESS_LEVELS } from '../../auth/AuthContext'; // Add this import
import AuthorizationModal from '../../auth/AuthorizationModal'; // Add this import if not already present

const LOCKDOWN_STORAGE_KEY = 'store_lockdown_status';

// Utility function to get date ranges
const getDateRanges = () => {
    const now = new Date();
    const startOfMonth = new Date(now.getFullYear(), now.getMonth(), 1);
    const startOfYear = new Date(now.getFullYear(), 0, 1);
    return {
        monthStart: startOfMonth,
        yearStart: startOfYear
    };
};

// Utility function to format currency
const formatCurrency = (amount) => {
    return amount.toLocaleString('en-GH', {
        style: 'currency',
        currency: 'GHS',
        minimumFractionDigits: 2
    });
};

const LoadingSpinner = () => (
    <div className="flex items-center justify-center flex-1">
        <div className="flex flex-col items-center gap-3">
            <Loader2 className="h-8 w-8 animate-spin text-gray-600" />
            <div className="text-lg text-gray-600">Loading dashboard data...</div>
        </div>
    </div>
);

const ErrorMessage = ({ message }) => (
    <div className="flex items-center justify-center flex-1 p-4 bg-red-50 text-red-500 rounded-lg">
        <AlertTriangle className="mr-2 h-5 w-5" />
        <div>{message}</div>
    </div>
);

const LockdownToggle = ({ isLockdown, onToggle, isToggling }) => (
    <button
        onClick={onToggle}
        disabled={isToggling}
        className={`flex items-center px-4 py-2 rounded-lg transition-colors duration-200 ${isLockdown
            ? 'bg-red-100 text-red-700 hover:bg-red-200'
            : 'bg-green-100 text-green-700 hover:bg-green-200'
            } ${isToggling ? 'opacity-50 cursor-not-allowed' : ''}`}
        style={{ border: '1px solid currentColor' }} // Add border to make it more visible
    >
        {isToggling ? (
            <Loader2 className="h-5 w-5 animate-spin mr-2" />
        ) : isLockdown ? (
            <>
                <Lock className="h-5 w-5 mr-2" />
                <span>Restricted Mode</span>
            </>
        ) : (
            <>
                <Unlock className="h-5 w-5 mr-2" />
                <span>Full Access Mode</span>
            </>
        )}
    </button>
);

const TabButton = ({ active, children, onClick, disabled }) => (
    <button
        onClick={onClick}
        disabled={disabled}
        className={`px-4 py-2 font-medium rounded-lg transition-colors duration-200 
            ${active ? 'bg-blue-100 text-blue-700' : 'text-gray-600 hover:bg-gray-100'} 
            ${disabled ? 'opacity-50 cursor-not-allowed hover:bg-transparent' : ''}`}
    >
        {children}
    </button>
);

const ActionButton = ({ icon: Icon, title, description, onClick }) => (
    <button
        onClick={onClick}
        className="flex items-center gap-4 w-full p-6 bg-white border rounded-lg hover:bg-gray-50 transition-all duration-200 group"
    >
        <div className="p-3 bg-blue-50 rounded-lg text-blue-600 group-hover:bg-blue-100 transition-colors duration-200">
            <Icon size={24} />
        </div>
        <div className="text-left">
            <h3 className="text-lg font-semibold mb-1">{title}</h3>
            <p className="text-gray-500">{description}</p>
        </div>
    </button>
);

// const MetricCard = ({ title, value, subtitle }) => (
//     <div className="bg-white border p-4">
//         <h3 className="text-sm text-gray-500 mb-1">{title}</h3>
//         <p className="text-2xl font-bold mb-1">{value}</p>
//         <p className="text-xs text-gray-500">{subtitle}</p>
//     </div>
// );

const MetricBox = ({ value, label, comparison, onClick, isSelected }) => (
    <div
        className={`text-center border-r last:border-r-0 cursor-pointer transition-colors duration-200 ${isSelected ? 'bg-blue-50' : 'hover:bg-gray-50'}`}
        onClick={onClick}
    >
        <div className="space-y-2 py-4">
            <div className="text-sm text-gray-500">{label}</div>
            <div className="text-2xl font-semibold">{value}</div>
            <div className="text-xs text-gray-400">{comparison}</div>
        </div>
    </div>
);

const DashboardMetrics = ({ lobbyData = {} }) => {
    const currentMonthData = useMemo(() => {
        if (!lobbyData.dailySales || !Array.isArray(lobbyData.dailySales)) {
            return [];
        }

        return lobbyData.dailySales
            .sort((a, b) => a.date.getTime() - b.date.getTime())
            .map(day => ({
                date: day.date.toLocaleDateString('en-US', { month: 'short', day: 'numeric' }),
                sales: day.totalSales
            }));
    }, [lobbyData.dailySales]);

    return (
        <div className="space-y-6">
            <div className="border">
                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4">
                    <MetricBox
                        value={`GHS ${(lobbyData.monthlySales || 0).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}`}
                        label="Monthly Sales"
                        comparison={`vs. GHS ${(lobbyData.lastMonthSales || 0).toLocaleString('en-US', { minimumFractionDigits: 2 })}`}
                        isSelected={true}
                    />
                    <MetricBox
                        value={lobbyData.itemsSold || 0}
                        label="Items Sold"
                        comparison="This month"
                    />
                    <MetricBox
                        value={lobbyData.lowStockCount || 0}
                        label="Low Stock Items"
                        comparison="Need attention"
                    />
                    <MetricBox
                        value={`GHS ${(lobbyData.yearToDateSales || 0).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}`}
                        label="Total Revenue"
                        comparison="Year to date"
                    />
                </div>

                <div className="h-96 p-8 pb-12">
                    <div className="mb-4 flex justify-between items-center">
                        <div className="text-sm font-medium text-gray-600">
                            Daily Sales Revenue
                        </div>
                        <div className="text-sm text-gray-500">
                            {new Date().toLocaleDateString('en-US', { month: 'long', year: 'numeric' })}
                        </div>
                    </div>
                    <ResponsiveContainer width="100%" height="100%">
                        <LineChart data={currentMonthData}>
                            <XAxis
                                dataKey="date"
                                axisLine={false}
                                tickLine={false}
                                dy={10}
                                tick={{ fontSize: 12 }}
                            />
                            <YAxis
                                axisLine={false}
                                tickLine={false}
                                dx={-10}
                                tick={{ fontSize: 12 }}
                                tickFormatter={(value) => `${value.toFixed(0)}`}
                            />
                            <CartesianGrid
                                horizontal={true}
                                vertical={false}
                                stroke="#e2e8f0"
                            />
                            <Tooltip
                                formatter={(value) => [`GHS ${value.toFixed(2)}`, 'Sales']}
                                labelFormatter={(label) => label}
                                contentStyle={{
                                    backgroundColor: 'white',
                                    border: '1px solid #f0f0f0',
                                    borderRadius: '8px',
                                    padding: '8px'
                                }}
                            />
                            <Area
                                type="monochrome"
                                dataKey="sales"
                                fill="#4299E1"
                                fillOpacity={0.2}
                                stroke="none"
                            />
                            <Line
                                type="linear"
                                dataKey="sales"
                                stroke="#4299E1"
                                strokeWidth={2}
                                dot={false}
                                activeDot={{ r: 4 }}
                            />
                        </LineChart>
                    </ResponsiveContainer>
                </div>
            </div>
        </div>
    );
};

const TabContent = ({ activeTab, navigate, lobbyData = {} }) => {
    if (activeTab === 'actions') {
        return (
            <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                <ActionButton
                    icon={ShoppingCart}
                    title="New Sale / POS"
                    description="Process a new sales transaction"
                    onClick={() => navigate('/sales/add')}
                />
                <ActionButton
                    icon={Package}
                    title="Manage Inventory"
                    description="Update your stock levels and products"
                    onClick={() => navigate('/inventory')}
                />
                <ActionButton
                    icon={Users}
                    title="Manage Staff"
                    description="Handle employee accounts and permissions"
                    onClick={() => navigate('/staff')}
                />
                <ActionButton
                    icon={FileText}
                    title="View Reports"
                    description="Access detailed sales and inventory reports"
                    onClick={() => navigate('/reports')}
                />
                <ActionButton
                    icon={Settings}
                    title="Store Settings"
                    description="Configure your store preferences"
                    onClick={() => navigate('/settings')}
                />
                <ActionButton
                    icon={Printer}
                    title="Print Receipt"
                    description="Quickly print a sales receipt"
                    onClick={() => navigate('/sales/receipts')}
                />
            </div>
        );
    }

    return <DashboardMetrics lobbyData={lobbyData} />;
};

const UserProfile = ({ userData }) => {
    const getRoleLabel = (accessLevel) => {
        switch (accessLevel) {
            case 1:
                return 'Owner';
            case 2:
                return 'Cashier';
            default:
                return 'Staff';
        }
    };

    return (
        <div className="flex items-center gap-3 bg-white p-3">
            <div className="relative">
                {userData.photoURL ? (
                    <img
                        src={userData.photoURL}
                        alt={userData.displayName}
                        className="w-10 h-10 rounded-full object-cover"
                    />
                ) : (
                    <div className="w-10 h-10 rounded-full bg-blue-100 flex items-center justify-center">
                        <User className="w-6 h-6 text-blue-600" />
                    </div>
                )}
                <div className="absolute -bottom-1 -right-1 w-4 h-4 bg-green-400 border-2 border-white rounded-full" />
            </div>
            <div className="flex-1">
                <div className="inline-flex">
                    <div className="font-medium">{userData.displayName}</div>
                    <div className="px-2 py-1 text-xs font-medium bg-blue-50 text-blue-700 rounded">
                        {getRoleLabel(userData.accessLevel)}
                    </div></div>
                <div className="text-sm text-gray-500">{userData.email}</div>
            </div>

        </div>
    );
};

const Dashboard = () => {
    const [lobbyData, setLobbyData] = useState({
        monthlySales: 0,
        itemsSold: 0,
        lowStockItems: 0,
        yearToDateRevenue: 0,
        salesChart: [],
        isLoading: true,
        error: null
    });
    const [storeData, setStoreData] = useState(null);
    const [activeTab, setActiveTab] = useState('actions');
    const [isToggling, setIsToggling] = useState(false);
    const [showAuthModal, setShowAuthModal] = useState(false);

    const navigate = useNavigate();
    const db = getFirestore();
    const auth = getAuth();
    const storeId = localStorage.getItem('storeId');
    const [userData, setUserData] = useState(null);

    const {
        hasOwnerPrivileges,
        setTemporaryAccess,
        temporaryAccessLevel,
    } = useAuth();

    // Check if user is in cashier mode based on temporaryAccessLevel
    const isCashierMode = temporaryAccessLevel === ACCESS_LEVELS.CASHIER;

    // Initialize isLockdown based on localStorage
    const [isLockdown, setIsLockdown] = useState(() => {
        const savedLockdown = localStorage.getItem(LOCKDOWN_STORAGE_KEY);
        return savedLockdown ? JSON.parse(savedLockdown) : false;
    });

    useEffect(() => {
        const fetchUserData = async () => {
            if (!auth.currentUser) return;

            try {
                const userDoc = await getDoc(doc(db, 'Users', auth.currentUser.uid));
                if (userDoc.exists()) {
                    setUserData({
                        ...userDoc.data(),
                        id: auth.currentUser.uid
                    });
                }
            } catch (error) {
                console.error('Error fetching user data:', error);
            }
        };

        fetchUserData();
    }, [auth.currentUser, db]);

    // Update effect to properly handle lockdown state
    useEffect(() => {
        const savedLockdown = localStorage.getItem(LOCKDOWN_STORAGE_KEY);
        const currentLockdown = savedLockdown ? JSON.parse(savedLockdown) : false;

        // When toggling lockdown state
        if (isLockdown !== currentLockdown) {
            localStorage.setItem(LOCKDOWN_STORAGE_KEY, JSON.stringify(isLockdown));
            setTemporaryAccess(isLockdown ? ACCESS_LEVELS.CASHIER : null);
        }
        // When temporary access changes externally
        else if (temporaryAccessLevel === ACCESS_LEVELS.CASHIER && !isLockdown) {
            setIsLockdown(true);
            localStorage.setItem(LOCKDOWN_STORAGE_KEY, 'true');
        } else if (temporaryAccessLevel === null && isLockdown) {
            setIsLockdown(false);
            localStorage.setItem(LOCKDOWN_STORAGE_KEY, 'false');
        }
    }, [isLockdown, temporaryAccessLevel, setTemporaryAccess]);

    // Effect to handle tab state when in cashier mode
    useEffect(() => {
        if (isCashierMode && activeTab === 'stats') {
            setActiveTab('actions');
        }
    }, [isCashierMode]);

    useEffect(() => {
        const fetchStoreData = async () => {
            if (!storeId) {
                setLobbyData(prev => ({
                    ...prev,
                    error: 'Store ID not found'
                }));
                return;
            }

            try {
                const storeDoc = await getDoc(doc(db, 'Stores', storeId));
                if (storeDoc.exists()) {
                    const data = storeDoc.data();
                    setStoreData(data);
                } else {
                    throw new Error('Store not found');
                }
            } catch (error) {
                console.error('Error fetching store data:', error);
                setLobbyData(prev => ({
                    ...prev,
                    error: 'Failed to load store data'
                }));
            }
        };

        fetchStoreData();
    }, [db, storeId]);

    useEffect(() => {
        const fetchDashboardData = async () => {
            if (!storeId) {
                setLobbyData(prev => ({
                    ...prev,
                    error: 'Store ID not found',
                    isLoading: false
                }));
                return;
            }

            try {
                const { monthStart, yearStart } = getDateRanges();
                const lastMonthStart = new Date(monthStart);
                lastMonthStart.setMonth(lastMonthStart.getMonth() - 1);
                const lastMonthEnd = new Date(monthStart);
                lastMonthEnd.setSeconds(lastMonthEnd.getSeconds() - 1);

                // Fetch all relevant data in parallel
                const [currentMonthSales, lastMonthSales, yearSales, inventoryItems] = await Promise.all([
                    getDocs(query(
                        collection(db, 'Stores', storeId, 'Sales'),
                        where('saleDate', '>=', Timestamp.fromDate(monthStart))
                    )),
                    getDocs(query(
                        collection(db, 'Stores', storeId, 'Sales'),
                        where('saleDate', '>=', Timestamp.fromDate(lastMonthStart)),
                        where('saleDate', '<=', Timestamp.fromDate(lastMonthEnd))
                    )),
                    getDocs(query(
                        collection(db, 'Stores', storeId, 'Sales'),
                        where('saleDate', '>=', Timestamp.fromDate(yearStart))
                    )),
                    getDocs(query(collection(db, 'Stores', storeId, 'Inventory')))
                ]);

                // Process monthly sales data
                const dailySalesMap = new Map();
                let currentMonthTotal = 0;
                let totalItemsSold = 0;

                currentMonthSales.forEach(doc => {
                    const sale = doc.data();
                    const saleDate = sale.saleDate.toDate();
                    const dateKey = saleDate.toDateString();
                    const saleTotal = sale.items.reduce((sum, item) =>
                        sum + (item.price * item.quantity), 0);

                    currentMonthTotal += saleTotal;
                    totalItemsSold += sale.items.reduce((sum, item) => sum + item.quantity, 0);

                    const existingTotal = dailySalesMap.get(dateKey)?.totalSales || 0;
                    dailySalesMap.set(dateKey, {
                        date: saleDate,
                        totalSales: existingTotal + saleTotal
                    });
                });

                // Calculate last month's total
                let lastMonthTotal = 0;
                lastMonthSales.forEach(doc => {
                    const sale = doc.data();
                    lastMonthTotal += sale.items.reduce((sum, item) =>
                        sum + (item.price * item.quantity), 0);
                });

                // Calculate year-to-date total
                let yearToDateTotal = 0;
                yearSales.forEach(doc => {
                    const sale = doc.data();
                    yearToDateTotal += sale.items.reduce((sum, item) =>
                        sum + (item.price * item.quantity), 0);
                });

                // Count low stock items
                const lowStockCount = inventoryItems.docs.reduce((count, doc) => {
                    const item = doc.data();
                    return count + (item.quantity <= item.reorderPoint ? 1 : 0);
                }, 0);

                setLobbyData({
                    monthlySales: currentMonthTotal,
                    lastMonthSales: lastMonthTotal,
                    itemsSold: totalItemsSold,
                    lowStockCount,
                    yearToDateSales: yearToDateTotal,
                    dailySales: Array.from(dailySalesMap.values()),
                    isLoading: false,
                    error: null
                });

            } catch (error) {
                console.error('Error fetching dashboard data:', error);
                setLobbyData(prev => ({
                    ...prev,
                    isLoading: false,
                    error: 'Failed to load dashboard data'
                }));
            }
        };

        fetchDashboardData();
    }, [db, storeId]);

    const handleLockdownToggle = async () => {
        if (isToggling) return;

        if (isLockdown) {
            // If currently in lockdown, show auth modal to disable it
            setShowAuthModal(true);
        } else {
            // If not in lockdown, enable it directly
            setIsToggling(true);
            try {
                setIsLockdown(true);
            } finally {
                setIsToggling(false);
            }
        }
    };

    const handleAuthorizationSuccess = async () => {
        setIsToggling(true);
        try {
            setIsLockdown(false);
            setShowAuthModal(false);
        } finally {
            setIsToggling(false);
        }
    };
    if (lobbyData.isLoading) {
        return (
            <div className="flex h-screen bg-white overflow-hidden">
                <LoadingSpinner />
            </div>
        );
    }

    if (lobbyData.error) {
        return (
            <div className="flex h-screen bg-white overflow-hidden">
                <ErrorMessage message={lobbyData.error} />
            </div>
        );
    }

    const handleTabClick = (tab) => {
        if (tab === 'stats' && isCashierMode) {
            return;
        }
        setActiveTab(tab);
    };
    return (
        <div className="flex h-screen bg-white overflow-hidden">
            <div className="flex-1 flex flex-col overflow-hidden">
                <div className="flex-1 overflow-y-auto p-8">
                    <div className="flex flex-col">
                        <div className="flex justify-between items-start">
                            <div className="flex items-center gap-4">
                                <div className="p-4 bg-blue-50 rounded-full">
                                    <Store className="h-12 w-12 text-blue-600" />
                                </div>
                                <div>
                                    <h1 className="text-4xl font-semibold">
                                        {storeData?.name || 'Loading store...'}
                                    </h1>
                                    {storeData?.location && (
                                        <p className="text-gray-500 mt-1">
                                            {storeData.location}
                                        </p>
                                    )}
                                </div>
                            </div>
                            {hasOwnerPrivileges && (
                                <LockdownToggle
                                    isLockdown={isLockdown}
                                    onToggle={handleLockdownToggle}
                                    isToggling={isToggling}
                                />
                            )}
                        </div>
                        <div className="flex justify-end">{userData && <UserProfile userData={userData} />}</div>

                    </div>

                    <div className="flex gap-2 mb-6">
                        <TabButton
                            active={activeTab === 'actions'}
                            onClick={() => handleTabClick('actions')}
                        >
                            Quick Actions
                        </TabButton>
                        <TabButton
                            active={activeTab === 'stats'}
                            onClick={() => handleTabClick('stats')}
                            disabled={isCashierMode}
                        >
                            Quick Stats
                        </TabButton>
                    </div>

                    <TabContent
                        activeTab={activeTab}
                        navigate={navigate}
                        lobbyData={lobbyData}
                    />


                </div>
            </div>

            <AuthorizationModal
                isOpen={showAuthModal}
                onClose={() => setShowAuthModal(false)}
                onAuthorized={handleAuthorizationSuccess}
            />
        </div>
    );
};

export default Dashboard;