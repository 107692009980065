import React, { useState, useRef, useEffect, useCallback } from 'react';
import { ArrowLeft, BarcodeIcon, Calculator, X, Search, ImageOff, Loader2 } from 'lucide-react';
import { useNavigate } from 'react-router-dom';
import { collection, query, where, getDocs, getFirestore, addDoc } from 'firebase/firestore';
import { useAuth } from '../../auth/AuthContext';
import Sidebar from '../components/sidebar';
import ImageUploadDropzone from '../components/inventory/image_upload_dropzone';
import CostCalculatorModal from '../components/inventory/cost_calculator';
import ProductMatchModal from '../components/inventory/matched_product_showcase';
import MatchedProductShowcase from '../components/inventory/matched_product_showcase';

const AddInventoryItem = () => {
    const navigate = useNavigate();
    const { user } = useAuth();
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [formData, setFormData] = useState({
        itemName: '',
        sku: '',
        quantity: 0,
        reorderPoint: 0,
        sellingPrice: 0,
        costPrice: 0,
        imageUrl: ''
    });
    const [showSuccess, setShowSuccess] = useState(false);
    const [isScannerEnabled, setIsScannerEnabled] = useState(false);
    const scanTimeout = useRef(null);
    const [isScanning, setIsScanning] = useState(false);
    const [scanBuffer, setScanBuffer] = useState('');
    const [skuError, setSkuError] = useState('');
    const [isValidating, setIsValidating] = useState(false);
    const [isCostCalculatorOpen, setIsCostCalculatorOpen] = useState(false);
    const [imageUrl, setImageUrl] = useState(null);
    const scanTimeoutRef = useRef(null);
    const skuInputRef = useRef(null);
    const db = getFirestore();
    // Replace matchedProducts with matchedProduct
    const [matchedProduct, setMatchedProduct] = useState(null);
    const [isProductMatchModalOpen, setIsProductMatchModalOpen] = useState(false);
    const [isSearchingProducts, setIsSearchingProducts] = useState(false);
    const [products, setProducts] = useState([]);
    const [loadingProducts, setLoadingProducts] = useState(true);
    const [productError, setProductError] = useState(null);
    const [productSearchTerm, setProductSearchTerm] = useState('');
    const [loadingMore, setLoadingMore] = useState(false);
    const [pagination, setPagination] = useState({
        hasMore: false,
        nextCursor: null
    });
    const observer = useRef();
    const API_BASE_URL = 'https://us-central1-hot-kiosk.cloudfunctions.net/adminApi';


    const clearForm = () => {
        setFormData({
            itemName: '',
            sku: '',
            quantity: 0,
            reorderPoint: 0,
            sellingPrice: 0,
            costPrice: 0,
            imageUrl: ''
        });
        setImageUrl(null);
    };

    useEffect(() => {
        const quantity = parseFloat(formData.quantity) || 0;
        const reorderPoint = Math.ceil(quantity * 0.2); // 20% of quantity, rounded up
        setFormData(prev => ({
            ...prev,
            reorderPoint
        }));
    }, [formData.quantity]);

    useEffect(() => {
        if (!isScannerEnabled) return;

        const handleKeyPress = async (e) => {
            if (e.key === 'Enter' && scanBuffer) {
                e.preventDefault();
                setFormData(prev => ({
                    ...prev,
                    sku: scanBuffer
                }));

                // Use the same logic as handleChange
                setIsValidating(true);
                try {
                    // Check local inventory
                    const inventoryRef = collection(db, 'Stores', user.storeId, 'Inventory');
                    const q = query(inventoryRef, where('sku', '==', scanBuffer.trim()));
                    const querySnapshot = await getDocs(q);

                    if (!querySnapshot.empty) {
                        setSkuError('This SKU already exists in your inventory');
                        return;
                    }

                    // Check products database
                    const url = new URL(`${API_BASE_URL}/products`);
                    url.searchParams.append('sku', scanBuffer.trim());
                    url.searchParams.append('limit', '1');

                    const response = await fetch(url);
                    if (response.ok) {
                        const data = await response.json();
                        if (data.products && data.products.length === 1) {
                            setMatchedProduct(data.products[0]);
                            setIsProductMatchModalOpen(true);
                        }
                    }
                } catch (error) {
                    console.error('Error checking SKU:', error);
                    setSkuError('Error validating SKU');
                } finally {
                    setIsValidating(false);
                    setScanBuffer('');
                }
            } else {
                setScanBuffer(prev => prev + e.key);
            }
        };

        window.addEventListener('keypress', handleKeyPress);
        return () => window.removeEventListener('keypress', handleKeyPress);
    }, [isScannerEnabled, scanBuffer]);

    const validateSku = async (skuValue) => {
        if (!skuValue) {
            setSkuError('');
            return true;
        }

        setIsValidating(true);
        try {
            // Only check local inventory for duplicates
            const inventoryRef = collection(db, 'Stores', user.storeId, 'Inventory');
            const q = query(inventoryRef, where('sku', '==', skuValue.trim()));
            const querySnapshot = await getDocs(q);

            if (!querySnapshot.empty) {
                setSkuError('This SKU already exists in your inventory');
                return false;
            }

            setSkuError('');
            return true;
        } catch (error) {
            console.error('Error validating SKU:', error);
            setSkuError('Error validating SKU');
            return false;
        } finally {
            setIsValidating(false);
        }
    };

    const handleChange = async (e) => {
        const { name, value } = e.target;
        setFormData(prev => ({
            ...prev,
            [name]: ['quantity', 'sellingPrice', 'costPrice'].includes(name)
                ? Number(parseFloat(value || 0).toFixed(2))
                : value
        }));

        if (name === 'sku') {
            setSkuError('');
            setMatchedProduct(null);

            if (value.trim()) {
                setIsValidating(true);
                try {
                    // First check local inventory
                    const inventoryRef = collection(db, 'Stores', user.storeId, 'Inventory');
                    const q = query(inventoryRef, where('sku', '==', value.trim()));
                    const querySnapshot = await getDocs(q);

                    if (!querySnapshot.empty) {
                        setSkuError('This SKU already exists in your inventory');
                        return;
                    }

                    // Check products database
                    console.log('Searching for SKU:', value.trim()); // Debug log
                    const productsRef = collection(db, 'Products');
                    const productQuery = query(productsRef, where('sku', '==', value.trim()));
                    const productSnapshot = await getDocs(productQuery);

                    console.log('Query results:', productSnapshot.docs.map(doc => ({
                        id: doc.id,
                        ...doc.data()
                    }))); // Debug log

                    if (!productSnapshot.empty) {
                        const matchedProductData = productSnapshot.docs[0].data();
                        console.log('Matched product:', matchedProductData); // Debug log
                        setMatchedProduct(matchedProductData);
                    } else {
                        console.log('No product found with this SKU'); // Debug log
                    }
                } catch (error) {
                    console.error('Error checking SKU:', error);
                    setSkuError('Error validating SKU');
                } finally {
                    setIsValidating(false);
                }
            }
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (!formData.itemName.trim()) {
            return;
        }

        setIsSubmitting(true);
        try {
            const newItem = {
                ...formData,
                sku: formData.sku.trim(),
                createdAt: new Date(),
                updatedAt: new Date()
            };

            await addDoc(collection(db, 'Stores', user.storeId, 'Inventory'), newItem);

            // Show success message
            setShowSuccess(true);

            // Clear the form
            clearForm();

            // Hide success message after 3 seconds
            setTimeout(() => {
                setShowSuccess(false);
            }, 3000);

        } catch (error) {
            console.error('Error adding item:', error);
            // You might want to show an error message here
        } finally {
            setIsSubmitting(false);
        }
    };

    const toggleScanner = () => {
        setIsScannerEnabled(!isScannerEnabled);
        if (!isScannerEnabled) {
            skuInputRef.current?.focus();
        }
    };

    const handleApplyCost = (calculatedCost) => {
        setFormData(prev => ({
            ...prev,
            costPrice: calculatedCost
        }));
    };

    useEffect(() => {
        // Add logging before the scanner useEffect
        console.log('Scanner enabled:', isScannerEnabled);
        console.log('Scan buffer:', scanBuffer);

        if (!isScannerEnabled) return;

        const handleKeyPress = (e) => {
            // Prevent default for Enter key when scanner is enabled
            if (e.key === 'Enter' && isScannerEnabled) {
                e.preventDefault();

                // Process the complete barcode
                if (scanBuffer) {
                    setFormData(prev => ({
                        ...prev,
                        sku: scanBuffer
                    }));
                    validateSku(scanBuffer);
                    setScanBuffer('');
                }
                return;
            }

            // Add logging for each keypress
            console.log('Key pressed:', e.key);
            console.log('Current scan buffer:', scanBuffer);

            // Accumulate barcode characters
            if (isScannerEnabled) {
                setScanBuffer(prev => prev + e.key);

                // Reset scan buffer after delay
                if (scanTimeout.current) {
                    clearTimeout(scanTimeout.current);
                }

                scanTimeout.current = setTimeout(() => {
                    setScanBuffer('');
                }, 100);
            }
        };

        window.addEventListener('keypress', handleKeyPress);
        return () => {
            window.removeEventListener('keypress', handleKeyPress);
            if (scanTimeout.current) clearTimeout(scanTimeout.current);
        };
    }, [isScannerEnabled, scanBuffer]);

    // Add useEffect to fetch products on mount
    useEffect(() => {
        fetchProducts();
    }, []);

    const handleImageError = (e) => {
        e.target.style.display = 'none';
        e.target.nextElementSibling.style.display = 'flex';
    };

    const renderSkuInput = () => (
        <div>
            <label className="block text-sm font-medium text-gray-700 mb-1">
                SKU (Optional)
            </label>
            <div className="flex gap-2">
                <input
                    ref={skuInputRef}
                    name="sku"
                    value={formData.sku}
                    onChange={handleChange}
                    className={`flex-1 px-3 py-2 border rounded-lg focus:outline-none focus:ring-2 ${isScannerEnabled ? 'focus:ring-indigo-600' : 'focus:ring-orange-600'} ${skuError ? 'border-red-500' : ''
                        }`}
                    readOnly={isScannerEnabled}
                />
                <button
                    type="button"
                    onClick={toggleScanner}
                    className={`px-3 py-2 border rounded-lg flex items-center justify-center transition-colors ${isScannerEnabled
                        ? 'bg-indigo-100 border-indigo-300 text-indigo-600 hover:bg-indigo-200'
                        : 'bg-gray-100 hover:bg-gray-200 text-gray-600'
                        }`}
                    title={isScannerEnabled ? 'Scanner Active' : 'Enable Scanner'}
                >
                    <BarcodeIcon className="h-5 w-5" />
                </button>
            </div>
            {skuError && (
                <p className="mt-1 text-sm text-red-600">{skuError}</p>
            )}
            {isScannerEnabled && (
                <p className="mt-1 text-sm text-indigo-600">
                    Scanner active. Please scan barcode...
                </p>
            )}
        </div>
    );

    const fetchProducts = async () => {
        setLoadingProducts(true);
        setProducts([]);
        setPagination({
            hasMore: false,
            nextCursor: null
        });

        try {
            const url = new URL(`${API_BASE_URL}/products`);
            url.searchParams.append('limit', '15');
            if (productSearchTerm) {
                url.searchParams.append('search', productSearchTerm);
            }
            url.searchParams.append('hasImage', 'true'); // Add filter for products with images

            const response = await fetch(url);
            if (!response.ok) throw new Error('Failed to fetch products');
            const data = await response.json();

            // Additional client-side filter to ensure we only get products with images
            const productsWithImages = data.products.filter(product =>
                product.imgUrl &&
                product.imgUrl.trim() !== '' &&
                !product.imgUrl.includes('undefined')
            );

            setProducts(productsWithImages);
            setPagination({
                hasMore: data.pagination.hasMore,
                nextCursor: data.pagination.nextCursor
            });
        } catch (err) {
            setProductError(err.message);
        } finally {
            setLoadingProducts(false);
        }
    };

    // Update loadMore function as well
    const loadMore = async () => {
        if (!pagination.hasMore || loadingMore) return;

        setLoadingMore(true);
        try {
            const url = new URL(`${API_BASE_URL}/products`);
            url.searchParams.append('limit', '15');
            url.searchParams.append('lastDocId', pagination.nextCursor);
            url.searchParams.append('hasImage', 'true'); // Add filter for products with images
            if (productSearchTerm) {
                url.searchParams.append('search', productSearchTerm);
            }

            const response = await fetch(url);
            if (!response.ok) throw new Error('Failed to fetch more products');
            const data = await response.json();

            // Additional client-side filter to ensure we only get products with images
            const productsWithImages = data.products.filter(product =>
                product.imgUrl &&
                product.imgUrl.trim() !== '' &&
                !product.imgUrl.includes('undefined')
            );

            setProducts(prev => [...prev, ...productsWithImages]);
            setPagination({
                hasMore: data.pagination.hasMore,
                nextCursor: data.pagination.nextCursor
            });
        } catch (err) {
            setProductError(err.message);
        } finally {
            setLoadingMore(false);
        }
    };

    // Last element ref callback for infinite scroll
    const lastProductElementRef = useCallback(node => {
        if (loadingProducts || loadingMore) return;
        if (observer.current) observer.current.disconnect();
        observer.current = new IntersectionObserver(entries => {
            if (entries[0].isIntersecting && pagination.hasMore) {
                loadMore();
            }
        });
        if (node) observer.current.observe(node);
    }, [loadingProducts, loadingMore, pagination.hasMore]);

    const handleProductSearch = (e) => {
        e.preventDefault();
        fetchProducts();
    };

    const handleProductImport = async (product) => {
        // Reset any validation errors and validation state
        setSkuError('');
        setIsValidating(false);

        // Update form data with new product
        setFormData({
            ...formData,
            itemName: product.itemName,
            sku: product.sku || '',
            sellingPrice: product.price || 0,
            imageUrl: product.imgUrl || ''
        });
        setImageUrl(product.imgUrl);

        // If there's a SKU, validate it after setting the form
        if (product.sku) {
            await validateSku(product.sku);
        }
    };

    const renderProductDatabase = () => (
        <div className="w-2/5">
            {matchedProduct ? (
                <MatchedProductShowcase
                    product={matchedProduct}
                    onImport={handleProductImport}
                    onClose={() => setMatchedProduct(null)}
                />
            ) : (
                <div className=" bg-white rounded-lg shadow-sm p-6">
                    <h2 className="text-lg font-semibold mb-6">Import from Hotkiosk Product Database</h2>

                    {/* Search Form */}
                    <form onSubmit={handleProductSearch} className="mb-6">
                        <div className="relative">
                            <Search className="absolute left-3 top-1/2 transform -translate-y-1/2 h-5 w-5 text-gray-400" />
                            <input
                                type="text"
                                placeholder="Search products by name or SKU..."
                                value={productSearchTerm}
                                onChange={(e) => setProductSearchTerm(e.target.value)}
                                className="w-full pl-10 pr-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-orange-500 focus:border-orange-500"
                            />
                        </div>
                    </form>

                    {/* Products List */}
                    <div className="overflow-y-auto max-h-[600px]">
                        {loadingProducts ? (
                            // Loading skeleton
                            <div className="space-y-4">
                                {[...Array(3)].map((_, idx) => (
                                    <div key={idx} className="bg-gray-50 rounded-lg p-4 animate-pulse">
                                        <div className="flex items-center">
                                            <div className="w-16 h-16 bg-gray-200 rounded-lg flex-shrink-0"></div>
                                            <div className="ml-4 flex-1">
                                                <div className="h-4 bg-gray-200 rounded w-3/4 mb-2"></div>
                                                <div className="h-3 bg-gray-200 rounded w-1/2"></div>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        ) : productError ? (
                            <div className="text-red-500 text-center py-4">{productError}</div>
                        ) : products.length === 0 ? (
                            <div className="text-center py-8 text-gray-500">No products found</div>
                        ) : (
                            <div className="space-y-4">
                                {products.map((product, index) => (
                                    <div
                                        key={product.id}
                                        ref={index === products.length - 1 ? lastProductElementRef : null}
                                        className="bg-gray-50 rounded-lg p-4 hover:bg-gray-100 cursor-pointer transition-colors"
                                        onClick={() => handleProductImport(product)}
                                    >
                                        <div className="flex items-center">
                                            <div className="w-16 h-16 bg-white rounded-lg flex-shrink-0 overflow-hidden">
                                                {product.imgUrl ? (
                                                    <>
                                                        <img
                                                            src={product.imgUrl}
                                                            alt={product.itemName}
                                                            onError={handleImageError}
                                                            className="w-full h-full object-contain"
                                                        />
                                                        <div className="hidden items-center justify-center w-full h-full">
                                                            <ImageOff className="h-4 w-4 text-gray-400" />
                                                        </div>
                                                    </>
                                                ) : (
                                                    <div className="flex items-center justify-center w-full h-full">
                                                        <ImageOff className="h-4 w-4 text-gray-400" />
                                                    </div>
                                                )}
                                            </div>
                                            <div className="ml-4 flex-1">
                                                <h3 className="font-medium text-gray-900 line-clamp-2">
                                                    {product.itemName}
                                                </h3>
                                                <div className="text-sm text-gray-500 mt-1">
                                                    SKU: {product.sku || 'N/A'}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        )}

                        {loadingMore && (
                            <div className="text-center py-4">
                                <Loader2 className="h-6 w-6 text-gray-400 animate-spin mx-auto" />
                            </div>
                        )}
                    </div>
                </div>
            )}
        </div>
    );

    // const renderProductDatabase = () => (
    //     <div className="w-2/5 bg-white rounded-lg shadow-sm p-6">
    //         <h2 className="text-lg font-semibold mb-6">Import from Hotkiosk Product Database</h2>

    //         {/* Search Form */}
    //         <form onSubmit={handleProductSearch} className="mb-6">
    //             <div className="relative">
    //                 <Search className="absolute left-3 top-1/2 transform -translate-y-1/2 h-5 w-5 text-gray-400" />
    //                 <input
    //                     type="text"
    //                     placeholder="Search products by name or SKU..."
    //                     value={productSearchTerm}
    //                     onChange={(e) => setProductSearchTerm(e.target.value)}
    //                     className="w-full pl-10 pr-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-orange-500 focus:border-orange-500"
    //                 />
    //             </div>
    //         </form>

    //         {/* Products List */}
    //         <div className="overflow-y-auto max-h-[600px]">
    //             {loadingProducts ? (
    //                 // Loading skeleton
    //                 <div className="space-y-4">
    //                     {[...Array(3)].map((_, idx) => (
    //                         <div key={idx} className="bg-gray-50 rounded-lg p-4 animate-pulse">
    //                             <div className="flex items-center">
    //                                 <div className="w-16 h-16 bg-gray-200 rounded-lg flex-shrink-0"></div>
    //                                 <div className="ml-4 flex-1">
    //                                     <div className="h-4 bg-gray-200 rounded w-3/4 mb-2"></div>
    //                                     <div className="h-3 bg-gray-200 rounded w-1/2"></div>
    //                                 </div>
    //                             </div>
    //                         </div>
    //                     ))}
    //                 </div>
    //             ) : productError ? (
    //                 <div className="text-red-500 text-center py-4">{productError}</div>
    //             ) : products.length === 0 ? (
    //                 <div className="text-center py-8 text-gray-500">No products found</div>
    //             ) : (
    //                 <div className="space-y-4">
    //                     {products.map((product, index) => (
    //                         <div
    //                             key={product.id}
    //                             ref={index === products.length - 1 ? lastProductElementRef : null}
    //                             className="bg-gray-50 rounded-lg p-4 hover:bg-gray-100 cursor-pointer transition-colors"
    //                             onClick={() => handleProductImport(product)}
    //                         >
    //                             <div className="flex items-center">
    //                                 <div className="w-16 h-16 bg-white rounded-lg flex-shrink-0 overflow-hidden">
    //                                     {product.imgUrl ? (
    //                                         <>
    //                                             <img
    //                                                 src={product.imgUrl}
    //                                                 alt={product.itemName}
    //                                                 onError={handleImageError}
    //                                                 className="w-full h-full object-contain"
    //                                             />
    //                                             <div className="hidden items-center justify-center w-full h-full">
    //                                                 <ImageOff className="h-4 w-4 text-gray-400" />
    //                                             </div>
    //                                         </>
    //                                     ) : (
    //                                         <div className="flex items-center justify-center w-full h-full">
    //                                             <ImageOff className="h-4 w-4 text-gray-400" />
    //                                         </div>
    //                                     )}
    //                                 </div>
    //                                 <div className="ml-4 flex-1">
    //                                     <h3 className="font-medium text-gray-900 line-clamp-2">
    //                                         {product.itemName}
    //                                     </h3>
    //                                     <div className="text-sm text-gray-500 mt-1">
    //                                         SKU: {product.sku || 'N/A'}
    //                                     </div>
    //                                 </div>
    //                             </div>
    //                         </div>
    //                     ))}
    //                 </div>
    //             )}

    //             {loadingMore && (
    //                 <div className="text-center py-4">
    //                     <Loader2 className="h-6 w-6 text-gray-400 animate-spin mx-auto" />
    //                 </div>
    //             )}
    //         </div>
    //     </div>
    // );

    const renderSubmitButton = () => (
        <button
            type="submit"
            disabled={isSubmitting}
            className="px-4 py-2 bg-orange-600 text-white rounded-lg hover:bg-orange-700 disabled:opacity-50 disabled:cursor-not-allowed"
        >
            {isSubmitting ? 'Adding...' : 'Add Product'}
        </button>
    );
    return (
        <div className="flex h-screen w-full bg-white overflow-hidden">
            <Sidebar />
            <div className="flex-1 flex flex-col overflow-hidden">
                <div className="flex-1 overflow-y-auto bg-gray-50">
                    <div className="p-8">
                        <div className="mb-6">
                            <button
                                onClick={() => navigate('/inventory#items')}
                                className="flex items-center text-gray-600 hover:text-gray-900"
                            >
                                <ArrowLeft className="h-5 w-5 mr-2" />
                                Back to Inventory
                            </button>
                            {showSuccess && (
                                <div className="bg-green-100 text-green-800 px-4 py-4 mt-2 ring-1 ring-green-300 rounded-lg flex items-center">
                                    Item added successfully!
                                </div>
                            )}
                        </div>

                        <div className="flex gap-4">
                            {/* Left Side - Form */}
                            <div className="flex-1 bg-white rounded-lg shadow-sm p-6">
                                <h2 className="text-xl font-semibold mb-6">Add New Product</h2>
                                <div className="mb-6">
                                    <ImageUploadDropzone
                                        onImageUploaded={setImageUrl}
                                        currentImageUrl={imageUrl}
                                        storeId={user?.storeId}
                                        className="w-full"
                                    />
                                </div>

                                <form onSubmit={handleSubmit} className="space-y-4">
                                    <div>
                                        <label className="block text-sm font-medium text-gray-700 mb-1">
                                            Product Name *
                                        </label>
                                        <input
                                            required
                                            name="itemName"
                                            value={formData.itemName}
                                            onChange={handleChange}
                                            className="w-full px-3 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-orange-600"
                                        />
                                    </div>

                                    {renderSkuInput()}

                                    <div className="grid grid-cols-2 gap-4">
                                        <div>
                                            <label className="block text-sm font-medium text-gray-700 mb-1">
                                                Quantity
                                            </label>
                                            <input
                                                type="number"
                                                name="quantity"
                                                value={formData.quantity}
                                                onChange={handleChange}
                                                className="w-full px-3 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-orange-600"
                                            />
                                        </div>

                                        <div>
                                            <label className="block text-sm font-medium text-gray-700 mb-1">
                                                Low Stock Point (20% of quantity)
                                            </label>
                                            <input
                                                type="number"
                                                name="reorderPoint"
                                                value={formData.reorderPoint}
                                                readOnly
                                                className="w-full px-3 py-2 border rounded-lg bg-gray-50"
                                            />
                                        </div>
                                    </div>

                                    <div className="grid grid-cols-2 gap-4">
                                        <div>
                                            <label className="block text-sm font-medium text-gray-700 mb-1">
                                                Selling Price (GHS)
                                            </label>
                                            <input
                                                type="number"
                                                step="0.01"
                                                name="sellingPrice"
                                                value={formData.sellingPrice}
                                                onChange={handleChange}
                                                className="w-full px-3 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-orange-600"
                                            />
                                        </div>

                                        <div>
                                            <label className="block text-sm font-medium text-gray-700 mb-1">
                                                Cost Price (GHS)
                                            </label>
                                            <div className="flex gap-2">
                                                <input
                                                    type="number"
                                                    step="0.01"
                                                    name="costPrice"
                                                    value={formData.costPrice}
                                                    onChange={handleChange}
                                                    className="w-full px-3 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-orange-600"
                                                />
                                                <button
                                                    type="button"
                                                    onClick={() => setIsCostCalculatorOpen(true)}
                                                    className="px-3 py-2 bg-gray-100 border rounded-lg hover:bg-gray-200 text-gray-600"
                                                    title="Calculate Cost"
                                                >
                                                    <Calculator className="h-5 w-5" />
                                                </button>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="flex justify-end gap-3 mt-6">
                                        <button
                                            type="button"
                                            onClick={() => navigate('/inventory#items')}
                                            className="px-4 py-2 border rounded-lg text-gray-700 hover:bg-gray-50"
                                        >
                                            Cancel
                                        </button>
                                        {renderSubmitButton()}
                                    </div>
                                </form>
                            </div>

                            {/* Right Side - Additional Content */}
                            {renderProductDatabase()}
                        </div>
                    </div>
                </div>
            </div>

            {/* <ProductMatchModal
                isOpen={isProductMatchModalOpen}
                onClose={() => setIsProductMatchModalOpen(false)}
                product={matchedProduct}
                onImport={handleProductImport}
            /> */}


            <CostCalculatorModal
                isOpen={isCostCalculatorOpen}
                onClose={() => setIsCostCalculatorOpen(false)}
                onApplyCost={handleApplyCost}
            />
        </div>
    );
};

export default AddInventoryItem;