import React from 'react';
import { X, ImageOff } from 'lucide-react';

const MatchedProductShowcase = ({ product, onImport, onClose }) => {
    if (!product) return null;

    const handleImageError = (e) => {
        e.target.style.display = 'none';
        e.target.nextElementSibling.style.display = 'flex';
    };

    return (
        <div className="bg-white rounded-lg shadow-sm p-6">
            <div className="flex justify-between items-start mb-6">
                <h2 className="text-lg font-semibold">Matched Product Found</h2>
                <button
                    onClick={onClose}
                    className="p-1 hover:bg-gray-100 rounded-full"
                >
                    <X className="h-5 w-5 text-gray-500" />
                </button>
            </div>

            <div className="bg-gray-50 rounded-lg p-6">
                <div className="flex gap-6">
                    <div className="w-32 h-32 bg-white rounded-lg flex-shrink-0 overflow-hidden">
                        {product.imgUrl ? (
                            <>
                                <img
                                    src={product.imgUrl}
                                    alt={product.itemName}
                                    onError={handleImageError}
                                    className="w-full h-full object-contain"
                                />
                                <div className="hidden items-center justify-center w-full h-full">
                                    <ImageOff className="h-6 w-6 text-gray-400" />
                                </div>
                            </>
                        ) : (
                            <div className="flex items-center justify-center w-full h-full">
                                <ImageOff className="h-6 w-6 text-gray-400" />
                            </div>
                        )}
                    </div>

                    <div className="flex-1">
                        <h3 className="text-xl font-medium text-gray-900 mb-2">
                            {product.itemName}
                        </h3>
                        <div className="space-y-2 text-gray-600">
                            <p>SKU: {product.sku || 'N/A'}</p>
                            <p>Price: GHS {product.price?.toFixed(2) || '0.00'}</p>
                        </div>

                        <button
                            onClick={() => onImport(product)}
                            className="mt-4 px-4 py-2 bg-orange-600 text-white rounded-lg hover:bg-orange-700"
                        >
                            Use These Details
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default MatchedProductShowcase;