import React, { useState, useEffect } from 'react';
import { X, Download, FileText, Printer, Loader2 } from 'lucide-react';
import {
    collection,
    query,
    orderBy,
    getDocs,
    limit,
    where,
    getFirestore
} from 'firebase/firestore';
import { getAuth } from 'firebase/auth';
import { generateSalesPDF } from '../generate_sales_receipt_pdf';
import SalesDetailsModal from './sales_detail_modal';

const RecentReceiptsModal = ({ isOpen, onClose }) => {
    const [sales, setSales] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const db = getFirestore();
    const auth = getAuth();

    // State for sales details modal
    const [salesDetailsModal, setSalesDetailsModal] = useState({
        isOpen: false,
        data: null,
        mode: null
    });

    useEffect(() => {
        if (!isOpen) return;

        const fetchRecentSales = async () => {
            try {
                setLoading(true);
                const currentUser = auth.currentUser;
                if (!currentUser) {
                    throw new Error('No authenticated user found');
                }

                const userDoc = await getDocs(
                    query(
                        collection(db, 'Users'),
                        where('email', '==', currentUser.email)
                    )
                );

                if (userDoc.empty) {
                    throw new Error('User data not found');
                }

                const userData = userDoc.docs[0].data();
                const salesQuery = query(
                    collection(db, 'Stores', userData.storeId, 'Sales'),
                    orderBy('saleDate', 'desc'),
                    limit(10)
                );

                const salesSnapshot = await getDocs(salesQuery);
                const salesData = salesSnapshot.docs.map(doc => {
                    const saleData = doc.data();
                    const totalAmount = saleData.items.reduce(
                        (sum, item) => sum + (item.price * item.quantity),
                        0
                    );
                    return {
                        id: doc.id,
                        ...saleData,
                        saleDate: saleData.saleDate.toDate(),
                        totalAmount
                    };
                });

                setSales(salesData);
            } catch (error) {
                console.error('Error fetching sales:', error);
                setError('Failed to load recent sales');
            } finally {
                setLoading(false);
            }
        };

        fetchRecentSales();
    }, [isOpen]);

    const downloadPDF = async (sale, e) => {
        e.stopPropagation(); // Prevent row click when clicking download
        try {
            const pdf = generateSalesPDF(sale);
            pdf.save(`receipt-${sale.orderNumber || sale.id}.pdf`);
        } catch (error) {
            console.error('Error downloading PDF:', error);
        }
    };

    const printReceipt = async (sale, e) => {
        e.stopPropagation(); // Prevent row click when clicking print
        try {
            console.log('Printing receipt for sale:', sale);
            // Implementation for printing receipt
        } catch (error) {
            console.error('Error printing receipt:', error);
        }
    };

    const handleRowClick = (sale) => {
        setSalesDetailsModal({
            isOpen: true,
            data: sale,
            mode: 'order'
        });
    };

    const closeSalesDetailsModal = () => {
        setSalesDetailsModal({
            isOpen: false,
            data: null,
            mode: null
        });
    };

    if (!isOpen) return null;

    return (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center p-4 z-50">
            <div className="bg-white rounded-lg w-full max-w-4xl">
                {/* Header */}
                <div className="flex items-center justify-between p-6 border-b">
                    <h2 className="text-xl font-semibold">Recent Sales Receipts</h2>
                    <button
                        onClick={onClose}
                        className="text-gray-500 hover:text-gray-700 rounded-full p-1 hover:bg-gray-100"
                    >
                        <X size={24} />
                    </button>
                </div>

                {/* Content */}
                <div className="p-6">
                    {loading ? (
                        <div className="flex items-center justify-center py-8">
                            <Loader2 className="h-8 w-8 animate-spin text-indigo-500" />
                        </div>
                    ) : error ? (
                        <div className="text-center text-red-500 py-8">{error}</div>
                    ) : (
                        <div className="overflow-x-auto">
                            <table className="w-full">
                                <thead>
                                    <tr className="bg-gray-50">
                                        <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                            Sale No.
                                        </th>
                                        <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                            Date
                                        </th>
                                        <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                            Items
                                        </th>
                                        <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                            Total Amount
                                        </th>
                                        <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                            Actions
                                        </th>
                                    </tr>
                                </thead>
                                <tbody className="bg-white divide-y divide-gray-200">
                                    {sales.map((sale) => (
                                        <tr
                                            key={sale.id}
                                            onClick={() => handleRowClick(sale)}
                                            className="hover:bg-gray-50 cursor-pointer"
                                        >
                                            <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                                                {sale.orderNumber || `SR${String(parseInt(sale.id.slice(-6), 16)).padStart(6, '0')}`}
                                            </td>
                                            <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                                                {sale.saleDate.toLocaleString()}
                                            </td>
                                            <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                                                {sale.items.length} items
                                            </td>
                                            <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                                                <span className="text-xs mr-1">GHS</span>
                                                <span className="text-gray-900">{sale.totalAmount.toFixed(2)}</span>
                                            </td>
                                            <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                                                <div className="flex space-x-4">
                                                    <button
                                                        onClick={(e) => downloadPDF(sale, e)}
                                                        className="text-red-600 hover:text-red-800"
                                                        title="Download PDF"
                                                    >
                                                        <Download className="h-5 w-5" />
                                                    </button>
                                                    <button
                                                        onClick={(e) => printReceipt(sale, e)}
                                                        className="text-green-600 hover:text-green-800"
                                                        title="Print Receipt"
                                                    >
                                                        <Printer className="h-5 w-5" />
                                                    </button>
                                                </div>
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    )}
                </div>

                {/* Footer */}
                <div className="border-t p-6 flex justify-end">
                    <button
                        onClick={onClose}
                        className="px-4 py-2 bg-gray-100 text-gray-700 rounded-lg hover:bg-gray-200 transition-colors"
                    >
                        Close
                    </button>
                </div>
            </div>

            {/* Sales Details Modal */}
            <SalesDetailsModal
                isOpen={salesDetailsModal.isOpen}
                onClose={closeSalesDetailsModal}
                data={salesDetailsModal.data}
                mode={salesDetailsModal.mode}
            />
        </div>
    );
};

export default RecentReceiptsModal;