import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { LineChart, Line, XAxis, YAxis, ResponsiveContainer, Tooltip } from 'recharts';
import { Search, Plus, FileText, Clock, CheckCircle, AlertTriangle, MoreVertical, Filter, Download, ArrowLeft, CrownIcon } from 'lucide-react';
import {
    collection,
    query,
    where,
    orderBy,
    getDocs,
    getFirestore,
    onSnapshot,
    Timestamp
} from 'firebase/firestore';
import Sidebar from '../components/sidebar';
import CreateQuoteForm from '../components/sales_quote_form';
import QuoteDetails from '../components/quote_details';
import QuoteActionsDropdown from '../components/sales_quote_actions';
import { useAuth } from '../../auth/AuthContext';

// Restricted Access Component
const RestrictedAccess = () => (
    <div className="flex h-screen bg-white">
        <Sidebar />
        <div className="flex-1 flex items-center justify-center">
            <div className="text-center p-8">
                <CrownIcon className="h-16 w-16 text-yellow-500 mx-auto mb-4" />
                <h1 className="text-2xl font-semibold mb-2">Enterprise Plan Feature</h1>
                <p className="text-gray-600 mb-4">
                    The Sales Quotes feature is only available on the Enterprise plan.
                </p>
                <p className="text-gray-500 text-sm">
                    Please upgrade your plan to access this feature.
                </p>
            </div>
        </div>
    </div>
);


const SalesQuotes = () => {
    const { hasEnterpriseAccess } = useAuth();
    const navigate = useNavigate();
    const [searchTerm, setSearchTerm] = useState('');
    const [statusFilter, setStatusFilter] = useState('all');
    const [sortBy, setSortBy] = useState('date');
    const [isFilterOpen, setIsFilterOpen] = useState(false);
    const [showCreateQuote, setShowCreateQuote] = useState(false);
    const [quotes, setQuotes] = useState([]);
    const [loading, setLoading] = useState(true);
    const [stats, setStats] = useState({
        activeQuotes: 0,
        purchasedQuotes: 0,
        totalValue: 0,
        expiredQuotes: 0
    });
    const [selectedQuote, setSelectedQuote] = useState(null);

    const db = getFirestore();
    const storeId = localStorage.getItem('storeId');

    // Fetch quotes from Firestore
    useEffect(() => {
        if (!storeId) return;

        const quotesRef = collection(db, 'Stores', storeId, 'SalesQuotes');
        const q = query(quotesRef, orderBy('createdAt', 'desc'));

        const unsubscribe = onSnapshot(q, (snapshot) => {
            const quotesData = [];
            snapshot.forEach((doc) => {
                const data = doc.data();

                // Handle Firestore Timestamp conversion
                const createdAt = data.createdAt instanceof Timestamp ?
                    data.createdAt.toDate() :
                    new Date();

                const quoteData = {
                    id: doc.id,
                    ...data,
                    createdAt, // Store as Date object
                    expiryDate: new Date(data.terms.validUntil)
                };

                // Check if quote is expired
                const now = new Date();
                const validUntil = new Date(quoteData.terms.validUntil);
                if (quoteData.status !== 'purchased' && now > validUntil) {
                    quoteData.status = 'expired';
                }

                quotesData.push(quoteData);
            });
            setQuotes(quotesData);
            setLoading(false);
            calculateStats(quotesData);
        }, (error) => {
            console.error("Error fetching quotes:", error);
            setLoading(false);
        });

        return () => unsubscribe();
    }, [db, storeId]);

    const calculateStats = (quotesData) => {
        const now = new Date();

        const stats = quotesData.reduce((acc, quote) => {
            const validUntil = new Date(quote.terms.validUntil);

            if (quote.status === 'purchased') {
                acc.purchasedQuotes++;
                acc.totalValue += quote.total;
            } else if (validUntil > now) {
                acc.activeQuotes++;
            } else {
                acc.expiredQuotes++;
            }

            return acc;
        }, {
            activeQuotes: 0,
            purchasedQuotes: 0,
            totalValue: 0,
            expiredQuotes: 0
        });

        setStats(stats);
    };

    // Format date helper function
    const formatDate = (date) => {
        if (!date) return 'N/A';
        return date instanceof Date ?
            date.toLocaleDateString(undefined, {
                year: 'numeric',
                month: 'short',
                day: 'numeric'
            }) : 'N/A';
    };

    const handleQuoteSubmit = (quoteData) => {
        setShowCreateQuote(false);
    };

    const filteredQuotes = quotes
        .filter(quote =>
            (quote.customerDetails?.name?.toLowerCase().includes(searchTerm.toLowerCase()) ||
                quote.id.toLowerCase().includes(searchTerm.toLowerCase())) &&
            (statusFilter === 'all' || quote.status === statusFilter)
        );

    const getStatusColor = (status) => {
        switch (status) {
            case 'active': return 'text-blue-600 bg-blue-50';
            case 'purchased': return 'text-green-600 bg-green-50';
            case 'expired': return 'text-red-600 bg-red-50';
            default: return 'text-gray-600 bg-gray-50';
        }
    };

    // Filter options for the dropdown
    const filterOptions = [
        { value: 'all', label: 'All Quotes' },
        { value: 'active', label: 'Active' },
        { value: 'purchased', label: 'Purchased' },
        { value: 'expired', label: 'Expired' }
    ];

    // Check for Enterprise access before rendering the main component
    if (!hasEnterpriseAccess) {
        return <RestrictedAccess />;
    }

    return (
        <div className="flex h-screen bg-white overflow-hidden">
            <Sidebar />
            <div className="flex-1 flex flex-col overflow-hidden">
                <div className="flex-1 overflow-y-auto p-8">
                    <div className="flex justify-between items-center mb-10">
                        <div className="flex justify-between items-center">
                            {/* <button
                                onClick={() => navigate(-1)}
                                className="mr-4 text-gray-500 hover:text-gray-700 transition-colors"
                            >
                                <ArrowLeft className="h-6 w-6" />
                            </button> */}
                            <h1 className="text-3xl font-semibold">Sales Quotes</h1>
                        </div>
                        <div className="relative flex">
                            <button
                                onClick={() => setShowCreateQuote(true)}
                                className="bg-blue-500 text-white px-4 py-2 rounded-lg flex items-center hover:bg-blue-600 transition-colors"
                            >
                                <Plus className="h-5 w-5 mr-2" />
                                Create Quote
                            </button>
                        </div>
                    </div>

                    {/* Updated Stats Cards */}
                    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4 mb-8">
                        <div className="bg-white p-6 border">
                            <div className="flex items-center justify-between mb-4">
                                <h3 className="text-gray-500">Active Quotes</h3>
                                <Clock className="h-6 w-6 text-blue-500" />
                            </div>
                            <p className="text-2xl font-bold">{stats.activeQuotes}</p>
                            <p className="text-sm text-gray-500">Currently valid</p>
                        </div>

                        <div className="bg-white p-6 border">
                            <div className="flex items-center justify-between mb-4">
                                <h3 className="text-gray-500">Purchased</h3>
                                <CheckCircle className="h-6 w-6 text-green-500" />
                            </div>
                            <p className="text-2xl font-bold">{stats.purchasedQuotes}</p>
                            <p className="text-sm text-gray-500">Successfully converted</p>
                        </div>

                        <div className="bg-white p-6 border">
                            <div className="flex items-center justify-between mb-4">
                                <h3 className="text-gray-500">Total Value</h3>
                                <FileText className="h-6 w-6 text-blue-500" />
                            </div>
                            <p className="text-2xl font-bold">GHS {stats.totalValue.toFixed(2)}</p>
                            <p className="text-sm text-gray-500">From purchased quotes</p>
                        </div>

                        <div className="bg-white p-6 border">
                            <div className="flex items-center justify-between mb-4">
                                <h3 className="text-gray-500">Expired</h3>
                                <AlertTriangle className="h-6 w-6 text-red-500" />
                            </div>
                            <p className="text-2xl font-bold">{stats.expiredQuotes}</p>
                            <p className="text-sm text-gray-500">Past validity date</p>
                        </div>
                    </div>

                    {/* Search and Filter Bar */}
                    <div className="bg-white border">
                        <div className="p-4 border-b">
                            <div className="flex justify-between items-center">
                                <div className="relative">
                                    <input
                                        type="text"
                                        placeholder="Search quotes..."
                                        value={searchTerm}
                                        onChange={(e) => setSearchTerm(e.target.value)}
                                        className="pl-10 pr-4 py-2 rounded-lg border border-gray-300 focus:outline-none focus:ring-2 focus:ring-blue-500"
                                    />
                                    <Search className="h-5 w-5 text-gray-400 absolute left-3 top-1/2 transform -translate-y-1/2" />
                                </div>
                                <div className="flex space-x-2">
                                    <select
                                        value={statusFilter}
                                        onChange={(e) => setStatusFilter(e.target.value)}
                                        className="px-4 py-2 border rounded-lg text-gray-600 focus:outline-none focus:ring-2 focus:ring-blue-500"
                                    >
                                        {filterOptions.map(option => (
                                            <option key={option.value} value={option.value}>
                                                {option.label}
                                            </option>
                                        ))}
                                    </select>
                                    <button className="px-4 py-2 border rounded-lg flex items-center text-gray-600 hover:bg-gray-50">
                                        <Download className="h-4 w-4 mr-2" />
                                        Export
                                    </button>
                                </div>
                            </div>
                        </div>

                        {/* Quotes Table */}
                        <table className="w-full">
                            <thead>
                                <tr className="bg-gray-50">
                                    <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase">Quote #</th>
                                    <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase">Customer</th>
                                    <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase">Created Date</th>
                                    <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase">Valid Until</th>
                                    <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase">Amount</th>
                                    <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase">Status</th>
                                    <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase"></th>
                                </tr>
                            </thead>
                            <tbody className="divide-y divide-gray-200">
                                {filteredQuotes.map((quote) => (
                                    <tr
                                        key={quote.id}
                                        className="hover:bg-gray-50 cursor-pointer"
                                        onClick={() => setSelectedQuote(quote)}
                                    >
                                        <td className="px-6 py-4 text-sm font-medium text-gray-900">
                                            {quote.quoteNumber}
                                        </td>
                                        <td className="px-6 py-4 text-sm text-gray-500">
                                            {quote.customerDetails.name}
                                        </td>
                                        <td className="px-6 py-4 text-sm text-gray-500">
                                            {formatDate(quote.createdAt)}
                                        </td>
                                        <td className="px-6 py-4 text-sm text-gray-500">
                                            {formatDate(quote.expiryDate)}
                                        </td>
                                        <td className="px-6 py-4 text-sm text-gray-900">
                                            <span className="text-xs mr-1">GHS</span>
                                            {quote.total.toFixed(2)}
                                        </td>
                                        <td className="px-6 py-4 text-sm">
                                            <span className={`px-2 py-1 rounded-full capitalize ${getStatusColor(quote.status)}`}>
                                                {quote.status}
                                            </span>
                                        </td>
                                        <td className="px-6 py-4 text-sm text-gray-500">
                                            <QuoteActionsDropdown
                                                quote={quote}
                                                onEdit={(quote) => {
                                                    setSelectedQuote(quote);
                                                }}
                                            />
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>

                    {/* Modals */}
                    {selectedQuote && (
                        <QuoteDetails
                            quote={selectedQuote}
                            onClose={() => setSelectedQuote(null)}
                        />
                    )}

                    {showCreateQuote && (
                        <CreateQuoteForm
                            onClose={() => setShowCreateQuote(false)}
                            onSuccess={() => {
                                setShowCreateQuote(false);
                                // Quotes will automatically update via the onSnapshot listener
                            }}
                        />
                    )}
                </div>
            </div>
        </div>
    );
};

export default SalesQuotes;