import React, { useState, useEffect, useRef, useCallback } from 'react';
import { Loader2, AlertCircle } from 'lucide-react';
import {
    collection,
    query,
    where,
    getDocs,
    doc,
    updateDoc,
    getFirestore,
    orderBy,
    limit,
    startAfter
} from 'firebase/firestore';
import { getAuth } from 'firebase/auth';

const PriceDigest = () => {
    const [items, setItems] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState(null);
    const [editingId, setEditingId] = useState(null);
    const [editValues, setEditValues] = useState({});
    const [user, setUser] = useState(null);
    const [lastVisible, setLastVisible] = useState(null);
    const [hasMore, setHasMore] = useState(true);
    const [isLoadingMore, setIsLoadingMore] = useState(false);

    const observerTarget = useRef(null);
    const db = getFirestore();
    const auth = getAuth();
    const ITEMS_PER_PAGE = 20;

    useEffect(() => {
        const fetchUserData = async () => {
            try {
                const currentUser = auth.currentUser;
                if (!currentUser) {
                    throw new Error('No authenticated user found');
                }

                const userDoc = await getDocs(
                    query(
                        collection(db, 'Users'),
                        where('email', '==', currentUser.email)
                    )
                );

                if (!userDoc.empty) {
                    setUser(userDoc.docs[0].data());
                } else {
                    throw new Error('User data not found');
                }
            } catch (error) {
                setError('Failed to authenticate user');
                setIsLoading(false);
            }
        };

        if (auth.currentUser) {
            fetchUserData();
        }
    }, [db, auth]);

    const fetchItems = async (lastDoc = null) => {
        if (!user?.storeId) return;

        try {
            let itemsQuery;
            if (lastDoc) {
                itemsQuery = query(
                    collection(db, 'Stores', user.storeId, 'Inventory'),
                    orderBy('updatedAt', 'desc'),
                    startAfter(lastDoc),
                    limit(ITEMS_PER_PAGE)
                );
            } else {
                itemsQuery = query(
                    collection(db, 'Stores', user.storeId, 'Inventory'),
                    orderBy('updatedAt', 'desc'),
                    limit(ITEMS_PER_PAGE)
                );
            }

            const itemsSnapshot = await getDocs(itemsQuery);

            if (itemsSnapshot.empty) {
                setHasMore(false);
                return;
            }

            const newItems = itemsSnapshot.docs
                .map(doc => ({
                    id: doc.id,
                    ...doc.data()
                }))
                .filter(item =>
                    !item.sellingPrice ||
                    !item.costPrice ||
                    item.sellingPrice === 0 ||
                    item.costPrice === 0
                );

            setLastVisible(itemsSnapshot.docs[itemsSnapshot.docs.length - 1]);

            if (lastDoc) {
                setItems(prev => [...prev, ...newItems]);
            } else {
                setItems(newItems);
            }

            setHasMore(itemsSnapshot.docs.length === ITEMS_PER_PAGE);
        } catch (err) {
            setError('Failed to load items');
        } finally {
            setIsLoading(false);
            setIsLoadingMore(false);
        }
    };

    useEffect(() => {
        if (user?.storeId) {
            fetchItems();
        }
    }, [user]);

    const loadMore = useCallback(() => {
        if (!isLoadingMore && hasMore && lastVisible) {
            setIsLoadingMore(true);
            fetchItems(lastVisible);
        }
    }, [isLoadingMore, hasMore, lastVisible]);

    useEffect(() => {
        const observer = new IntersectionObserver(
            entries => {
                if (entries[0].isIntersecting) {
                    loadMore();
                }
            },
            { threshold: 0.1 }
        );

        if (observerTarget.current) {
            observer.observe(observerTarget.current);
        }

        return () => {
            if (observerTarget.current) {
                observer.unobserve(observerTarget.current);
            }
        };
    }, [loadMore]);

    const handleEdit = (item) => {
        setEditingId(item.id);
        setEditValues({
            sellingPrice: item.sellingPrice || 0,
            costPrice: item.costPrice || 0
        });
    };

    const handleSave = async (itemId) => {
        try {
            const itemRef = doc(db, 'Stores', user.storeId, 'Inventory', itemId);
            await updateDoc(itemRef, {
                sellingPrice: Number(editValues.sellingPrice) || 0,
                costPrice: Number(editValues.costPrice) || 0,
                updatedAt: new Date()
            });

            setItems(items.map(item =>
                item.id === itemId
                    ? {
                        ...item,
                        sellingPrice: Number(editValues.sellingPrice) || 0,
                        costPrice: Number(editValues.costPrice) || 0
                    }
                    : item
            ));
            setEditingId(null);
        } catch (err) {
            setError('Failed to update prices');
        }
    };

    const costPriceRef = useRef(null);
    const sellingPriceRef = useRef(null);

    const handleRowClick = (item) => {
        if (editingId === item.id) return;

        setEditingId(item.id);
        setEditValues({
            sellingPrice: item.sellingPrice || 0,
            costPrice: item.costPrice || 0
        });

        // Schedule focus for after the input renders
        setTimeout(() => {
            if (!item.costPrice || item.costPrice === 0) {
                costPriceRef.current?.focus();
            } else if (!item.sellingPrice || item.sellingPrice === 0) {
                sellingPriceRef.current?.focus();
            }
        }, 0);
    };


    // Add keyboard navigation
    useEffect(() => {
        const handleKeyPress = (e) => {
            if (e.key === 'Escape' && editingId) {
                setEditingId(null);
            }
        };

        window.addEventListener('keydown', handleKeyPress);
        return () => window.removeEventListener('keydown', handleKeyPress);
    }, [editingId]);

    if (isLoading) {
        return (
            <div className="flex items-center justify-center h-64">
                <Loader2 className="h-8 w-8 animate-spin text-gray-600" />
            </div>
        );
    }

    if (error) {
        return (
            <div className="flex items-center justify-center h-64 text-red-500">
                <AlertCircle className="h-6 w-6 mr-2" />
                {error}
            </div>
        );
    }

    if (items.length === 0) {
        return (
            <div className="text-center py-8 text-gray-500">
                All items have their prices set correctly.
            </div>
        );
    }

    return (
        <div className="container mx-auto">
            <div className="mb-6 flex items-center justify-center">
                <p className="text-gray-600 text-sm text-center w-full">
                    {items.length} items found with missing or zero prices
                </p>
            </div>

            <div className="bg-white border p-6">
                <div className="overflow-x-auto">
                    <table className="w-full">
                        <thead>
                            <tr className="bg-gray-100">
                                <th className="px-6 py-3 text-left text-sm font-medium text-gray-500">
                                    Product
                                </th>
                                <th className="px-6 py-3 text-left text-sm font-medium text-gray-500">
                                    SKU
                                </th>
                                <th className="px-6 py-3 text-left text-sm font-medium text-gray-500">
                                    Selling Price
                                </th>
                                <th className="px-6 py-3 text-left text-sm font-medium text-gray-500">
                                    Cost Price
                                </th>
                                <th className="px-6 py-3 text-left text-sm font-medium text-gray-500">
                                    Actions
                                </th>
                            </tr>
                        </thead>
                        <tbody className="divide-y divide-gray-200">
                            {items.map((item) => (
                                <tr
                                    key={item.id}
                                    onClick={() => handleRowClick(item)}
                                    className={`hover:bg-gray-50  cursor-pointer ${editingId === item.id ? 'bg-blue-50' : ''}`}
                                >
                                    <td className="px-6 py-4 text-sm h-12 text-gray-900">
                                        {item.itemName}
                                    </td>
                                    <td className="px-6 py-4 text-sm text-gray-500">
                                        {item.sku || 'N/A'}
                                    </td>
                                    <td className="px-6 py-4 text-sm">
                                        {editingId === item.id ? (
                                            <input
                                                ref={costPriceRef}
                                                type="number"
                                                step="0.01"
                                                min="0"
                                                value={editValues.costPrice}
                                                onChange={(e) => setEditValues({
                                                    ...editValues,
                                                    costPrice: e.target.value
                                                })}
                                                className={`border rounded px-2 py-1 w-24 ${(!item.costPrice || item.costPrice === 0) ? 'border-red-500' : ''}`}
                                                onClick={(e) => e.stopPropagation()}
                                            />
                                        ) : (
                                            <span className={!item.costPrice || item.costPrice === 0 ? 'text-red-500' : 'text-gray-900'}>
                                                GHS {(item.costPrice || 0).toFixed(2)}
                                            </span>
                                        )}
                                    </td>
                                    <td className="px-6 py-4 text-sm">
                                        {editingId === item.id ? (
                                            <input
                                                ref={sellingPriceRef}
                                                type="number"
                                                step="0.01"
                                                min="0"
                                                value={editValues.sellingPrice}
                                                onChange={(e) => setEditValues({
                                                    ...editValues,
                                                    sellingPrice: e.target.value
                                                })}
                                                className={`border rounded px-2 py-1 w-24 ${(!item.sellingPrice || item.sellingPrice === 0) ? 'border-red-500' : ''}`}
                                                onClick={(e) => e.stopPropagation()}
                                            />
                                        ) : (
                                            <span className={!item.sellingPrice || item.sellingPrice === 0 ? 'text-red-500' : 'text-gray-900'}>
                                                GHS {(item.sellingPrice || 0).toFixed(2)}
                                            </span>
                                        )}
                                    </td>
                                    <td className="px-6 py-4 text-sm">
                                        {editingId === item.id ? (
                                            <div
                                                className="flex space-x-2"
                                                onClick={(e) => e.stopPropagation()}
                                            >
                                                <button
                                                    onClick={() => handleSave(item.id)}
                                                    className="text-green-600 hover:text-green-800"
                                                >
                                                    Save
                                                </button>
                                                <button
                                                    onClick={() => setEditingId(null)}
                                                    className="text-gray-600 hover:text-gray-800"
                                                >
                                                    Cancel
                                                </button>
                                            </div>
                                        ) :
                                            <div onClick={() => handleRowClick(item)}>
                                                Edit
                                            </div>
                                        }
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>

                <div ref={observerTarget} className="py-4 text-center">
                    {isLoadingMore && (
                        <Loader2 className="h-6 w-6 animate-spin mx-auto text-gray-600" />
                    )}
                    {!hasMore && items.length > 0 && (
                        <p className="text-gray-500 text-sm">No more items to load</p>
                    )}
                </div>
            </div>
        </div>
    );
};

export default PriceDigest;