import React, { useState } from 'react';
import { ArrowUpRight, ArrowDownRight, Trash2, X, AlertCircle, Info } from 'lucide-react';
import {
    collection,
    doc,
    deleteDoc,
    getFirestore,
} from 'firebase/firestore';

const PaymentTypeBadge = ({ type, direction }) => {
    const getBadgeColors = () => {
        if (direction === 'out') {
            return 'bg-orange-100 text-orange-800';
        }
        return type === 'sale' ? 'bg-green-100 text-green-800' : 'bg-blue-100 text-blue-800';
    };

    return (
        <div className="flex space-x-2">
            <span className={`px-2 py-1 rounded-full text-xs ${getBadgeColors()}`}>
                {type === 'sale' ? 'Sale' : 'Manual'}
            </span>
            <span className={`px-2 py-1 rounded-full text-xs ${direction === 'out' ? 'bg-orange-100 text-orange-800' : 'bg-green-100 text-green-800'
                }`}>
                {direction === 'out' ? 'Payment Out' : 'Payment In'}
            </span>
        </div>
    );
};

const PaymentDetailModal = ({ payment, onClose, onDelete, storeId }) => {
    const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);
    const db = getFirestore();

    const handleDelete = async () => {
        try {
            await deleteDoc(doc(db, 'Stores', storeId, 'Payments', payment.id));
            onDelete(payment.id);
            onClose();
        } catch (error) {
            console.error('Error deleting payment:', error);
        }
    };

    return (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center p-4 z-50">
            <div className="bg-white rounded-xl w-full max-w-2xl">
                {/* Header */}
                <div className="flex justify-between items-start p-6 border-b">
                    <div>
                        <h2 className="text-xl font-semibold text-gray-900">Payment Details</h2>
                        <p className="mt-1 text-sm text-gray-500">
                            {payment.paymentType === 'sale' ? 'Sale Payment' : 'Manual Payment'}
                        </p>
                    </div>
                    <button
                        onClick={onClose}
                        className="text-gray-400 hover:text-gray-500"
                    >
                        <X className="h-5 w-5" />
                    </button>
                </div>

                {/* Content */}
                <div className="p-6 space-y-6">
                    {/* Sale Warning Message */}
                    {payment.paymentType === 'sale' && (
                        <div className="flex items-start space-x-3 p-4 bg-blue-50 text-blue-800 rounded-lg">
                            <Info className="h-5 w-5 flex-shrink-0 mt-0.5" />
                            <div className="text-sm">
                                <p className="font-medium">This is a sale-related payment</p>
                                <p className="mt-1">To modify or delete this payment, please use the Sales Management page where you can manage the entire sale record.</p>
                            </div>
                        </div>
                    )}

                    {/* Amount Section */}
                    <div className="flex items-center justify-between p-4 bg-gray-50 rounded-lg">
                        <div className="space-y-1">
                            <p className="text-sm text-gray-500">Amount</p>
                            <div className="flex items-center space-x-2">
                                {payment.amount < 0 ? (
                                    <ArrowUpRight className="h-5 w-5 text-orange-500" />
                                ) : (
                                    <ArrowDownRight className="h-5 w-5 text-green-500" />
                                )}
                                <span className={`text-2xl font-semibold ${payment.amount < 0 ? 'text-orange-600' : 'text-green-600'
                                    }`}>
                                    GHS {Math.abs(payment.amount).toFixed(2)}
                                </span>
                            </div>
                        </div>
                        <PaymentTypeBadge
                            type={payment.paymentType}
                            direction={payment.amount < 0 ? 'out' : 'in'}
                        />
                    </div>

                    {/* Details Grid */}
                    <div className="grid grid-cols-2 gap-6">
                        <div>
                            <p className="text-sm font-medium text-gray-500">Date</p>
                            <p className="mt-1 text-sm text-gray-900">
                                {new Date(payment.date).toLocaleString('en-GB', {
                                    day: '2-digit',
                                    month: 'short',
                                    year: 'numeric',
                                    hour: '2-digit',
                                    minute: '2-digit',
                                    hour12: true
                                })}
                            </p>
                        </div>
                        <div>
                            <p className="text-sm font-medium text-gray-500">Payment Method</p>
                            <p className="mt-1 text-sm text-gray-900 capitalize">
                                {payment.paymentMethod || 'Not specified'}
                            </p>
                        </div>
                        <div className="col-span-2">
                            <p className="text-sm font-medium text-gray-500">Description</p>
                            <p className="mt-1 text-sm text-gray-900">
                                {payment.description}
                            </p>
                        </div>
                        {payment.reference && (
                            <div className="col-span-2">
                                <p className="text-sm font-medium text-gray-500">Reference Number</p>
                                <p className="mt-1 text-sm text-gray-900">
                                    {payment.reference}
                                </p>
                            </div>
                        )}
                        {payment.category && (
                            <div>
                                <p className="text-sm font-medium text-gray-500">Category</p>
                                <p className="mt-1 text-sm text-gray-900">
                                    {payment.category}
                                </p>
                            </div>
                        )}
                    </div>
                </div>

                {/* Footer */}
                <div className="px-6 py-4 bg-gray-50 rounded-b-xl border-t flex justify-between items-center">
                    {payment.paymentType !== 'sale' && (
                        <button
                            onClick={() => setShowDeleteConfirm(true)}
                            className="flex items-center px-4 py-2 text-sm font-medium text-red-600 hover:text-red-700"
                        >
                            <Trash2 className="h-4 w-4 mr-2" />
                            Delete Payment
                        </button>
                    )}
                    <button
                        onClick={onClose}
                        className="px-4 py-2 bg-gray-100 text-gray-700 rounded-lg text-sm font-medium hover:bg-gray-200"
                    >
                        Close
                    </button>
                </div>
            </div>

            {/* Delete Confirmation Modal */}
            {showDeleteConfirm && !payment.paymentType === 'sale' && (
                <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center p-4 z-50">
                    <div className="bg-white rounded-lg p-6 max-w-sm w-full">
                        <div className="flex items-center space-x-3 text-red-600 mb-4">
                            <AlertCircle className="h-6 w-6" />
                            <h3 className="text-lg font-medium">Confirm Delete</h3>
                        </div>
                        <p className="text-gray-500 mb-6">
                            Are you sure you want to delete this payment? This action cannot be undone.
                        </p>
                        <div className="flex justify-end space-x-3">
                            <button
                                onClick={() => setShowDeleteConfirm(false)}
                                className="px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-100 rounded-lg"
                            >
                                Cancel
                            </button>
                            <button
                                onClick={handleDelete}
                                className="px-4 py-2 text-sm font-medium text-white bg-red-600 hover:bg-red-700 rounded-lg"
                            >
                                Delete
                            </button>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default PaymentDetailModal;