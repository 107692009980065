import React, { useState, useEffect, useMemo } from 'react';
import { LineChart, Line, XAxis, YAxis, ResponsiveContainer, Tooltip, CartesianGrid, Area } from 'recharts';
import { Loader2, Package, Info, ArrowUpRight, ArrowDownRight, ChevronLeft, ChevronRight } from 'lucide-react';
import { getFirestore, collection, query, where, getDocs, Timestamp, doc, getDoc } from 'firebase/firestore';

const MetricBox = ({ value, label, comparison, onClick, isSelected }) => (
    <div
        className={`text-center border-r last:border-r-0 cursor-pointer transition-colors duration-200 ${isSelected ? 'bg-blue-50' : 'hover:bg-gray-50'
            }`}
        onClick={onClick}
    >
        <div className="space-y-2 py-4">
            <div className="text-sm text-gray-500">{label}</div>
            <div className="text-2xl font-semibold">{value}</div>
            {comparison && <div className="text-xs text-gray-400">{comparison}</div>}
        </div>
    </div>
);

const ProductImage = ({ imageUrl, altText }) => {
    const [hasError, setHasError] = useState(false);

    return (
        <div className="w-14 h-14 bg-gray-100 rounded flex-shrink-0 flex items-center justify-center">
            {!hasError && imageUrl ? (
                <img
                    src={imageUrl}
                    alt={altText}
                    className="w-full h-full object-cover rounded"
                    onError={() => setHasError(true)}
                />
            ) : (
                <Package className="w-6 h-6 text-gray-400" />
            )}
        </div>
    );
};

const ItemDetails = ({ item, selectedMetric, imageUrl, timeRange }) => {
    if (!item) return (
        <div className="flex flex-col items-center justify-center h-[520px] text-gray-500">
            <Package className="w-12 h-12 mb-4 text-gray-400" />
            <p className="text-sm">Select an item to view its details</p>
        </div>
    );

    const metrics = [
        {
            label: 'Revenue',
            value: item.revenue.toFixed(2),
            prefix: 'GHS',
            comparison: 'Total earnings from this product',
            trend: 'up'
        },
        {
            label: 'Profit',
            value: item.profit.toFixed(2),
            prefix: 'GHS',
            comparison: 'After cost of goods',
            trend: item.profit > 0 ? 'up' : 'down'
        },
        {
            label: 'Profit Margin',
            value: ((item.profit / item.revenue) * 100).toFixed(1),
            suffix: '%',
            comparison: 'Percentage of revenue',
            trend: ((item.profit / item.revenue) * 100) > 20 ? 'up' : 'down'
        },
        {
            label: 'Quantity Sold',
            value: item.quantity,
            comparison: 'Units sold in this period'
        }
    ];

    return (
        <div className=''>
            {/* Header with image */}
            <div className="p-6 border-b bg-gray-50">
                <div className="flex items-start gap-4">
                    <ProductImage imageUrl={imageUrl} altText={item.name} />
                    <div className="flex-1">
                        <h3 className="text-lg font-semibold">{item.name}</h3>
                        <p className="text-sm text-gray-500 mt-1">Product Performance Details</p>
                    </div>
                    <div className={`px-3 py-1 text-sm ${selectedMetric === 'revenue' ? 'bg-blue-100 text-blue-700' : 'bg-green-100 text-green-700'
                        }`}>
                        {selectedMetric === 'revenue' ? 'Revenue' : 'Profit'} View
                    </div>
                </div>
            </div>

            {/* Metrics Grid */}
            <div className="p-6 grid grid-cols-2 gap-4">
                {metrics.map((metric, index) => (
                    <div key={index} className="bg-white p-4  border hover:shadow-md transition-shadow">
                        <div className="flex justify-between items-start mb-2">
                            <span className="text-sm font-medium text-gray-500">{metric.label}</span>
                            {metric.trend && (
                                <span className={metric.trend === 'up' ? 'text-green-500' : 'text-red-500'}>
                                    {metric.trend === 'up' ? <ArrowUpRight className="w-4 h-4" /> : <ArrowDownRight className="w-4 h-4" />}
                                </span>
                            )}
                        </div>
                        <div className="text-2xl font-semibold mb-1">
                            {metric.prefix && `${metric.prefix} `}
                            {metric.value}
                            {metric.suffix && ` ${metric.suffix}`}
                        </div>
                        <div className="text-xs text-gray-400">{metric.comparison}</div>
                    </div>
                ))}
            </div>

            {/* Share of Total Section */}
            <div className="p-6 border-t">
                <div className="mb-2">
                    <span className="text-sm font-medium text-gray-500">Share of Total {selectedMetric === 'revenue' ? 'Revenue' : 'Profit'}</span>
                </div>
                <div className="relative h-5 bg-gray-100 rounded-sm overflow-hidden">
                    <div
                        className={`absolute left-0 top-0 h-full rounded-sm ${selectedMetric === 'revenue' ? 'bg-blue-500' : 'bg-green-500'
                            }`}
                        style={{
                            width: `${((item[selectedMetric] / (selectedMetric === 'revenue' ? item.totalRevenue : item.totalProfit)) * 100)}%`
                        }}
                    />
                </div>
                <div className="mt-2 text-sm text-gray-500">
                    {((item[selectedMetric] / (selectedMetric === 'revenue' ? item.totalRevenue : item.totalProfit)) * 100).toFixed(1)}% of total
                </div>
            </div>
        </div>
    );
};

const GrossFinancials = ({ timeRange, storeId }) => {
    const [selectedMetric, setSelectedMetric] = useState('revenue');
    const [selectedItem, setSelectedItem] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState(null);
    const [currentPage, setCurrentPage] = useState(1);
    const [data, setData] = useState({
        currentPeriod: [],
        previousPeriod: []
    });
    const [productImages, setProductImages] = useState({});
    const itemsPerPage = 10;

    const db = getFirestore();

    useEffect(() => {
        const fetchInventoryImages = async () => {
            if (!storeId || !data.currentPeriod.length) return;

            try {
                // Get unique inventory IDs
                const inventoryIds = new Set();
                data.currentPeriod.forEach(sale => {
                    sale.items.forEach(item => {
                        if (item.inventoryItemId) {
                            inventoryIds.add(item.inventoryItemId);
                        }
                    });
                });

                // Fetch images for each inventory item
                const imagePromises = Array.from(inventoryIds).map(async (inventoryId) => {
                    const inventoryRef = doc(db, 'Stores', storeId, 'Inventory', inventoryId);
                    const inventoryDoc = await getDoc(inventoryRef);

                    if (inventoryDoc.exists()) {
                        return [inventoryId, inventoryDoc.data().imageUrl];
                    }
                    return [inventoryId, null];
                });

                const imageResults = await Promise.all(imagePromises);
                setProductImages(Object.fromEntries(imageResults));

            } catch (error) {
                console.error('Error fetching inventory images:', error);
            }
        };

        fetchInventoryImages();
    }, [storeId, data.currentPeriod, db]);

    useEffect(() => {
        const fetchData = async () => {
            if (!storeId || !timeRange.start || !timeRange.end) return;

            setIsLoading(true);
            try {
                const currentStartDate = new Date(timeRange.start);
                currentStartDate.setHours(0, 0, 0, 0);
                const currentEndDate = new Date(timeRange.end);
                currentEndDate.setHours(23, 59, 59, 999);

                const daysDifference = Math.ceil((currentEndDate - currentStartDate) / (1000 * 60 * 60 * 24));
                const previousEndDate = new Date(currentStartDate);
                previousEndDate.setDate(previousEndDate.getDate() - 1);
                const previousStartDate = new Date(previousEndDate);
                previousStartDate.setDate(previousStartDate.getDate() - daysDifference + 1);

                const [currentPeriodDocs, previousPeriodDocs] = await Promise.all([
                    getDocs(query(
                        collection(db, 'Stores', storeId, 'Sales'),
                        where('saleDate', '>=', Timestamp.fromDate(currentStartDate)),
                        where('saleDate', '<=', Timestamp.fromDate(currentEndDate))
                    )),
                    getDocs(query(
                        collection(db, 'Stores', storeId, 'Sales'),
                        where('saleDate', '>=', Timestamp.fromDate(previousStartDate)),
                        where('saleDate', '<=', Timestamp.fromDate(previousEndDate))
                    ))
                ]);

                setData({
                    currentPeriod: currentPeriodDocs.docs.map(doc => ({
                        ...doc.data(),
                        id: doc.id
                    })),
                    previousPeriod: previousPeriodDocs.docs.map(doc => ({
                        ...doc.data(),
                        id: doc.id
                    }))
                });
                setSelectedItem(null);
            } catch (err) {
                setError('Failed to load financial data');
                console.error('Error loading financial data:', err);
            } finally {
                setIsLoading(false);
            }
        };

        fetchData();
    }, [timeRange, storeId, db]);

    const processedData = useMemo(() => {
        if (!data?.currentPeriod) return { metrics: {}, itemsData: [] };

        const processedPeriodData = (sales) => {
            let totalRevenue = 0;
            let totalCost = 0;
            const itemData = {};

            sales.forEach(sale => {
                sale.items.forEach(item => {
                    const revenue = item.price * item.quantity;
                    const cost = (item.costPrice || 0) * item.quantity;
                    const quantity = item.quantity;

                    const itemKey = item.itemName || item.name;
                    if (!itemData[itemKey]) {
                        itemData[itemKey] = {
                            name: itemKey,
                            revenue: 0,
                            profit: 0,
                            quantity: 0,
                            inventoryItemId: item.inventoryItemId
                        };
                    }
                    itemData[itemKey].revenue += revenue;
                    itemData[itemKey].profit += (revenue - cost);
                    itemData[itemKey].quantity += quantity;
                    totalRevenue += revenue;
                    totalCost += cost;
                });
            });

            const totalProfit = totalRevenue - totalCost;

            Object.values(itemData).forEach(item => {
                item.totalRevenue = totalRevenue;
                item.totalProfit = totalProfit;
            });

            return {
                totalRevenue,
                totalProfit,
                itemData
            };
        };

        const currentPeriodData = processedPeriodData(data.currentPeriod);
        const previousPeriodData = processedPeriodData(data.previousPeriod);

        return {
            metrics: {
                revenue: currentPeriodData.totalRevenue,
                profit: currentPeriodData.totalProfit,
                revenueGrowth: ((currentPeriodData.totalRevenue - previousPeriodData.totalRevenue) / previousPeriodData.totalRevenue * 100) || 0,
                profitGrowth: ((currentPeriodData.totalProfit - previousPeriodData.totalProfit) / previousPeriodData.totalProfit * 100) || 0
            },
            itemsData: Object.values(currentPeriodData.itemData)
                .sort((a, b) => b[selectedMetric] - a[selectedMetric])
        };
    }, [data, selectedMetric]);

    const paginatedData = useMemo(() => {
        const startIndex = (currentPage - 1) * itemsPerPage;
        const endIndex = startIndex + itemsPerPage;
        return processedData.itemsData.slice(startIndex, endIndex);
    }, [processedData.itemsData, currentPage]);

    const totalPages = Math.ceil((processedData.itemsData || []).length / itemsPerPage);

    const handleNextPage = () => {
        if (currentPage < totalPages) {
            setCurrentPage(prev => prev + 1);
        }
    };

    const handlePrevPage = () => {
        if (currentPage > 1) {
            setCurrentPage(prev => prev - 1);
        }
    };

    if (isLoading) {
        return (
            <div className="flex items-center justify-center h-96">
                <div className="flex flex-col items-center gap-3">
                    <Loader2 className="h-8 w-8 animate-spin text-gray-600" />
                    <div className="text-lg text-gray-600">Loading financial data...</div>
                </div>
            </div>
        );
    }

    if (error) {
        return (
            <div className="flex items-center justify-center h-96 text-red-500">
                {error}
            </div>
        );
    }

    return (
        <div className="space-y-6">
            <div className="bg-white border shadow-sm">
                <div className="grid grid-cols-2">
                    <MetricBox
                        value={`GHS ${processedData.metrics.revenue.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}`}
                        label="Total Gross Revenue"
                        comparison={`${processedData.metrics.revenueGrowth.toFixed(1)}% vs previous period`}
                        onClick={() => setSelectedMetric('revenue')}
                        isSelected={selectedMetric === 'revenue'}
                    />
                    <MetricBox
                        value={`GHS ${processedData.metrics.profit.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}`}
                        label="Total Gross Profit"
                        comparison={`${processedData.metrics.profitGrowth.toFixed(1)}% vs previous period`}
                        onClick={() => setSelectedMetric('profit')}
                        isSelected={selectedMetric === 'profit'}
                    />
                </div>
            </div>

            <div className="flex flex-col items-center gap-y-10 mt-4">
                <div className="h-[2px] w-32 bg-gray-200 mt-5"></div>
                <h2 className="text-xl font-medium text-center">
                    Product {selectedMetric === 'revenue' ? 'Revenue' : 'Profit'} Breakdown
                </h2>

                <div className="flex w-full gap-6 mt-4">
                    {/* Left side - Table */}
                    <div className="w-1/2">
                        <div className="bg-white overflow-hidden">
                            <div className="grid grid-cols-12 px-6 py-3 text-sm font-medium text-gray-500 border-b">
                                <div className="col-span-1">#</div>
                                <div className="col-span-7">Product</div>
                                <div className="col-span-4 text-right">
                                    {selectedMetric === 'revenue' ? 'Revenue' : 'Profit'}
                                </div>
                            </div>

                            <div className="divide-y max-h-[520px] overflow-y-auto">
                                {paginatedData.map((item, index) => {
                                    const value = item[selectedMetric];
                                    const totalValue = processedData.metrics[selectedMetric];
                                    const percentageShare = ((value / totalValue) * 100).toFixed(1);
                                    const globalIndex = (currentPage - 1) * itemsPerPage + index + 1;

                                    return (
                                        <div
                                            key={index}
                                            className={`grid grid-cols-12 px-6 py-4 hover:bg-gray-50 transition-colors items-center cursor-pointer ${selectedItem?.name === item.name ? 'bg-blue-50 hover:bg-blue-50' : ''
                                                }`}
                                            onClick={() => setSelectedItem(item)}
                                        >
                                            <div className="col-span-1 text-gray-500">{globalIndex}</div>
                                            <div className="col-span-7">
                                                <div className="flex items-center gap-3">
                                                    <ProductImage
                                                        imageUrl={productImages[item.inventoryItemId]}
                                                        altText={item.name}
                                                    />
                                                    <span className="font-medium">{item.name}</span>
                                                </div>
                                            </div>
                                            <div className="col-span-4 text-right">
                                                <div className="flex flex-row items-center justify-end space-x-2">
                                                    <span className="font-medium">GHS {value.toFixed(2)}</span>
                                                    {/* <span className="text-sm text-gray-500">({percentageShare}%)</span> */}
                                                </div>
                                            </div>
                                        </div>
                                    );
                                })}
                            </div>

                            {/* Pagination Controls */}
                            <div className="px-6 py-4 bg-gray-50 border-t border-gray-200">
                                <div className="flex items-center justify-between">
                                    <div className="text-sm text-gray-700">
                                        Showing {((currentPage - 1) * itemsPerPage) + 1} to {Math.min(currentPage * itemsPerPage, processedData.itemsData.length)} of{' '}
                                        {processedData.itemsData.length} results
                                    </div>
                                    <div className="flex space-x-2">
                                        <button
                                            onClick={handlePrevPage}
                                            disabled={currentPage === 1}
                                            className="px-4 py-2 border rounded-lg text-sm font-medium text-gray-700 hover:bg-gray-50 disabled:opacity-50 disabled:cursor-not-allowed"
                                        >
                                            Previous
                                        </button>
                                        <button
                                            onClick={handleNextPage}
                                            disabled={currentPage === totalPages}
                                            className="px-4 py-2 border rounded-lg text-sm font-medium text-gray-700 hover:bg-gray-50 disabled:opacity-50 disabled:cursor-not-allowed"
                                        >
                                            Next
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>


                    {/* Right side - Item Details */}
                    <div className="w-1/2">
                        <div className="bg-gray-50 shadow-sm  overflow-hidden p-4">
                            <ItemDetails
                                item={selectedItem}
                                selectedMetric={selectedMetric}
                                imageUrl={selectedItem ? productImages[selectedItem.inventoryItemId] : null}
                                timeRange={timeRange}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default GrossFinancials;