import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { Crown, FilePlus, PlusCircle } from 'lucide-react';
import Sidebar from '../components/sidebar';
import TimePeriodPicker from '../components/time_period_picker';
import AddExpenseModal from '../components/expenses/add_expense';
import AddCategoryModal from '../components/expenses/add_expense_category';
import ExpensesOverview from '../components/expenses/expenses_overview';
import ExpensesList from '../components/expenses/expenses_list';
import ExpensesCategoriesTab from '../components/expenses/expense_categories';
import { useAuth } from '../../auth/AuthContext';

// Restricted Access Component
const RestrictedAccess = () => (
    <div className="flex h-screen bg-white">
        <Sidebar />
        <div className="flex-1 flex items-center justify-center">
            <div className="text-center p-8">
                <Crown className="h-16 w-16 text-yellow-500 mx-auto mb-4" />
                <h1 className="text-2xl font-semibold mb-2">Pro Feature</h1>
                <p className="text-gray-600 mb-4">
                    The Expenses management feature is only available on Pro and Enterprise plans.
                </p>
                <p className="text-gray-500 text-sm">
                    Please upgrade your plan to access this feature.
                </p>
            </div>
        </div>
    </div>
);

const Expenses = () => {
    const { hasProAccess } = useAuth();
    const navigate = useNavigate();
    const location = useLocation();
    const [selectedTab, setSelectedTab] = useState('overview');
    const [timeRange, setTimeRange] = useState({
        label: 'Last 30 Days',
        start: new Date(new Date().setDate(new Date().getDate() - 30)),
        end: new Date()
    });
    const [isAddCategoryModalOpen, setIsAddCategoryModalOpen] = useState(false);
    const [isAddExpenseModalOpen, setIsAddExpenseModalOpen] = useState(false);

    const tabs = [
        { id: 'overview', name: 'Overview' },
        { id: 'all_expenses', name: 'All Expenses' },
        { id: 'categories', name: 'Categories' }
    ];

    useEffect(() => {
        const tabFromUrl = location.hash.slice(1);
        const validTab = tabs.find(tab => tab.id === tabFromUrl);
        if (validTab) {
            setSelectedTab(validTab.id);
        } else if (location.hash) {
            navigate('#overview', { replace: true });
        }
    }, [location.hash, navigate]);

    const handleTabChange = (tabId) => {
        setSelectedTab(tabId);
        navigate(`#${tabId}`);
    };

    const handleDateRangeChange = (start, end, label) => {
        setTimeRange({ start, end, label });
    };

    const renderTabContent = () => {
        switch (selectedTab) {
            case 'overview':
                return <ExpensesOverview timeRange={timeRange} />;
            case 'all_expenses':
                return <ExpensesList timeRange={timeRange} />;
            case 'categories':
                return <ExpensesCategoriesTab timeRange={timeRange} />;
            default:
                return null;
        }
    };

    if (!hasProAccess) {
        return <RestrictedAccess />;
    }

    return (
        <div className="flex h-screen bg-white overflow-hidden">
            <Sidebar />
            <div className="flex-1 flex flex-col overflow-hidden">
                <div className="flex-1 overflow-y-auto p-8">
                    <div className="flex justify-between items-center mb-10">
                        <h1 className="text-3xl font-semibold">Expenses Management</h1>
                        <div className="flex items-center space-x-4">
                            <TimePeriodPicker
                                onDateRangeChange={handleDateRangeChange}
                                selectedLabel={timeRange.label}
                                onLabelChange={(label) => setTimeRange(prev => ({ ...prev, label }))}
                            />
                            <button
                                onClick={() => setIsAddCategoryModalOpen(true)}
                                className="bg-blue-500 text-white px-4 py-2 rounded-lg flex items-center hover:bg-blue-600 transition-colors"
                            >
                                <FilePlus className="h-5 w-5 mr-2" />
                                Add Category
                            </button>
                            <button
                                onClick={() => setIsAddExpenseModalOpen(true)}
                                className="bg-blue-500 text-white px-4 py-2 rounded-lg flex items-center hover:bg-blue-600 transition-colors"
                            >
                                <PlusCircle className="h-5 w-5 mr-2" />
                                Record Expense
                            </button>
                        </div>
                    </div>

                    <div className="flex space-x-6 mb-8">
                        {tabs.map((tab) => (
                            <button
                                key={tab.id}
                                className={`text-lg font-medium ${selectedTab === tab.id
                                    ? 'text-blue-600 border-b-2 border-blue-600'
                                    : 'text-gray-500 hover:text-gray-700'
                                    }`}
                                onClick={() => handleTabChange(tab.id)}
                            >
                                {tab.name}
                            </button>
                        ))}
                    </div>

                    {renderTabContent()}

                    <AddExpenseModal
                        isOpen={isAddExpenseModalOpen}
                        onClose={() => setIsAddExpenseModalOpen(false)}
                        onExpenseAdded={() => setIsAddExpenseModalOpen(false)}
                    />
                    <AddCategoryModal
                        isOpen={isAddCategoryModalOpen}
                        onClose={() => setIsAddCategoryModalOpen(false)}
                        onCategoryAdded={() => setIsAddCategoryModalOpen(false)}
                    />
                </div>
            </div>
        </div>
    );
};

export default Expenses;