// db-service.js
import { openDB } from 'idb';

const DB_NAME = 'posOfflineDB';
const DB_VERSION = 1;
const CHUNK_SIZE = 50;

const STORES = {
    INVENTORY: 'inventory',
    PENDING_SALES: 'pendingSales',
    SYNC_META: 'syncMeta',
};

export class DBService {
    constructor() {
        this.db = null;
        this.initPromise = null;
        this.isInitializing = false;
    }

    async init() {
        if (this.db) return this.db;
        if (this.initPromise) return this.initPromise;

        try {
            this.initPromise = openDB(DB_NAME, DB_VERSION, {
                upgrade(db) {
                    if (!db.objectStoreNames.contains(STORES.INVENTORY)) {
                        db.createObjectStore(STORES.INVENTORY, { keyPath: 'id' });
                    }
                    if (!db.objectStoreNames.contains(STORES.PENDING_SALES)) {
                        const pendingSalesStore = db.createObjectStore(STORES.PENDING_SALES, {
                            keyPath: 'offlineId',
                            autoIncrement: true
                        });
                        // Create index with the correct name 'syncStatus'
                        pendingSalesStore.createIndex('syncStatus', 'syncStatus');
                    }
                    if (!db.objectStoreNames.contains(STORES.SYNC_META)) {
                        db.createObjectStore(STORES.SYNC_META, { keyPath: 'id' });
                    }
                }
            });

            this.db = await this.initPromise;
            return this.db;
        } catch (error) {
            console.error('Database initialization error:', error);
            this.initPromise = null;
            this.db = null;
            throw error;
        }
    }

    async saveInventory(items, progressCallback = null) {
        let processedItems = 0;
        const totalItems = items.length;

        try {
            if (!this.db) {
                await this.init();
            }

            // Clear existing inventory
            const clearTx = this.db.transaction(STORES.INVENTORY, 'readwrite');
            await clearTx.objectStore(STORES.INVENTORY).clear();
            await clearTx.done;

            // Process in chunks
            for (let i = 0; i < items.length; i += CHUNK_SIZE) {
                const chunk = items.slice(i, i + CHUNK_SIZE);
                const tx = this.db.transaction(STORES.INVENTORY, 'readwrite');
                const store = tx.objectStore(STORES.INVENTORY);

                await Promise.all(chunk.map(item =>
                    store.put({
                        ...item,
                        lastUpdated: new Date().toISOString()
                    })
                ));

                await tx.done;

                processedItems += chunk.length;
                if (progressCallback) {
                    progressCallback({
                        processed: processedItems,
                        total: totalItems,
                        percentage: Math.round((processedItems / totalItems) * 100)
                    });
                }
            }

            // Update sync metadata
            const metaTx = this.db.transaction(STORES.SYNC_META, 'readwrite');
            await metaTx.objectStore(STORES.SYNC_META).put({
                id: 'syncMeta',
                lastInventorySync: new Date().toISOString(),
                inventoryCount: totalItems
            });
            await metaTx.done;

            console.log(`Successfully saved ${processedItems} items to IndexedDB`);
            return { success: true, itemsProcessed: processedItems };

        } catch (error) {
            console.error('Error saving inventory:', error);
            return { success: false, error: error.message, itemsProcessed: processedItems };
        }
    }


    async getInventory() {
        const db = await this.init();
        return db.getAll(STORES.INVENTORY);
    }

    async savePendingSale(saleData) {
        if (!this.db) {
            await this.init();
        }

        try {
            const tx = this.db.transaction(STORES.PENDING_SALES, 'readwrite');
            // Add syncStatus field to the sale data
            const saleWithStatus = {
                ...saleData,
                syncStatus: 'pending',
                createdAt: new Date().toISOString()
            };
            await tx.objectStore(STORES.PENDING_SALES).add(saleWithStatus);
            await tx.done;

            console.log('Sale saved for offline sync');
            return { success: true };
        } catch (error) {
            console.error('Error saving offline sale:', error);
            return { success: false, error: error.message };
        }
    }

    async getPendingSales() {
        if (!this.db) {
            await this.init();
        }

        try {
            const tx = this.db.transaction(STORES.PENDING_SALES, 'readonly');
            const store = tx.objectStore(STORES.PENDING_SALES);
            const index = store.index('syncStatus');

            // Get all sales with 'pending' status
            const pendingSales = await index.getAll('pending');
            return pendingSales;
        } catch (error) {
            console.error('Error getting pending sales:', error);
            throw error;
        }
    }

    async markSaleAsSynced(offlineId) {
        if (!this.db) {
            await this.init();
        }

        try {
            const tx = this.db.transaction(STORES.PENDING_SALES, 'readwrite');
            const store = tx.objectStore(STORES.PENDING_SALES);

            const sale = await store.get(offlineId);
            if (sale) {
                sale.syncStatus = 'completed';
                sale.syncedAt = new Date().toISOString();
                await store.put(sale);
            }
            await tx.done;

            return { success: true };
        } catch (error) {
            console.error('Error marking sale as synced:', error);
            throw error;
        }
    }

    async clearSyncedSales() {
        if (!this.db) {
            await this.init();
        }

        try {
            const tx = this.db.transaction(STORES.PENDING_SALES, 'readwrite');
            const store = tx.objectStore(STORES.PENDING_SALES);
            const index = store.index('syncStatus');

            // Get all completed sales
            const completedSales = await index.getAll('completed');

            // Delete them
            for (const sale of completedSales) {
                await store.delete(sale.offlineId);
            }

            await tx.done;
            return { success: true, count: completedSales.length };
        } catch (error) {
            console.error('Error clearing synced sales:', error);
            throw error;
        }
    }
}

export const dbService = new DBService();