// OverviewSection.js
import React, { useState, useEffect, useMemo } from 'react';
import { LineChart, Line, XAxis, YAxis, ResponsiveContainer, Tooltip, CartesianGrid, Area } from 'recharts';
import { getFirestore, collection, query, where, getDocs, doc, getDoc, Timestamp } from 'firebase/firestore';
import { Loader2, Package } from 'lucide-react';

const MetricBox = ({ value, label, comparison, onClick, isSelected }) => (
    <div
        className={`text-center border-r last:border-r-0 cursor-pointer transition-colors duration-200 ${isSelected ? 'bg-blue-50' : 'hover:bg-gray-50'
            }`}
        onClick={onClick}
    >
        <div className="space-y-2 py-4">
            <div className="text-sm text-gray-500">{label}</div>
            <div className="text-2xl font-semibold">{value}</div>
            <div className="text-xs text-gray-400">{comparison}</div>
        </div>
    </div>
);

const TopProductsTable = ({ salesData, storeId }) => {
    const [productImages, setProductImages] = useState({});
    const [imageErrors, setImageErrors] = useState({});
    const [isLoadingImages, setIsLoadingImages] = useState(true);
    const db = getFirestore();

    const processedProducts = useMemo(() => {
        const productStats = {};
        let totalRevenue = 0;

        salesData.forEach(sale => {
            const items = sale.items || [];

            items.forEach(item => {
                const productKey = item.inventoryItemId;
                if (!productKey) return;

                const revenue = (item.price || 0) * (item.quantity || 0);
                const cost = (item.costPrice || 0) * (item.quantity || 0);
                totalRevenue += revenue;

                if (!productStats[productKey]) {
                    productStats[productKey] = {
                        id: item.inventoryItemId,
                        name: item.name || item.itemName || item.productName,
                        revenue: 0,
                        cost: 0,
                        quantitySold: 0,
                        price: item.price || 0,
                        costPrice: item.costPrice || 0,
                        category: item.category || '',
                        description: item.description || ''
                    };
                }

                productStats[productKey].revenue += revenue;
                productStats[productKey].cost += cost;
                productStats[productKey].quantitySold += (item.quantity || 0);
            });
        });

        return Object.values(productStats)
            .map(product => ({
                ...product,
                profitMargin: ((product.revenue - product.cost) / product.revenue * 100).toFixed(1),
                percentageShare: ((product.revenue / (totalRevenue || 1)) * 100).toFixed(1)
            }))
            .sort((a, b) => b.revenue - a.revenue)
            .slice(0, 5);
    }, [salesData]);

    // Fetch inventory images
    useEffect(() => {
        const fetchInventoryImages = async () => {
            if (!storeId || processedProducts.length === 0) return;

            setIsLoadingImages(true);
            const imageUrls = {};

            try {
                await Promise.all(
                    processedProducts.map(async (product) => {
                        const inventoryRef = doc(
                            db,
                            'Stores',
                            storeId,
                            'Inventory',
                            product.id
                        );
                        const inventoryDoc = await getDoc(inventoryRef);

                        if (inventoryDoc.exists()) {
                            const data = inventoryDoc.data();
                            imageUrls[product.id] = data.imageUrl || '/api/placeholder/120/68';
                        } else {
                            imageUrls[product.id] = '/api/placeholder/120/68';
                        }
                    })
                );

                setProductImages(imageUrls);
            } catch (error) {
                console.error('Error fetching inventory images:', error);
            } finally {
                setIsLoadingImages(false);
            }
        };

        fetchInventoryImages();
    }, [db, storeId, processedProducts]);

    return (
        <div className="flex flex-col items-center justify-center gap-4 py-8">
            <div className="h-[2px] mt-12 rounded-lg w-32 bg-gray-200"></div>
            <h2 className="text-xl font-semibold p-6 text-center">Your top products in this period</h2>

            {!salesData || salesData.length === 0 ? (
                <div className="w-full divide-y">
                    <div className="grid grid-cols-12 px-6 py-3 text-sm text-gray-500">
                        <div className="col-span-1">#</div>
                        <div className="col-span-5">Product</div>
                        <div className="col-span-2 text-left">Revenue</div>
                        <div className="col-span-2 text-right">Margin</div>
                        <div className="col-span-2 text-right">Quantity</div>
                    </div>
                    <div className="px-6 py-12 text-center">
                        <Package className="h-12 w-12 mx-auto mb-4 text-gray-400" />
                        <p className="text-gray-500">No sales data available for this period.</p>
                    </div>
                </div>
            ) : (
                <div className="divide-y w-full">
                    <div className="grid grid-cols-12 px-6 py-3 text-sm text-gray-500">
                        <div className="col-span-1">#</div>
                        <div className="col-span-5">Product</div>
                        <div className="col-span-2 text-left">Revenue</div>
                        <div className="col-span-2 text-right">Margin</div>
                        <div className="col-span-2 text-right">Quantity</div>
                    </div>

                    {processedProducts.map((product, index) => (
                        <div
                            key={product.id || index}
                            className="grid grid-cols-12 px-6 py-4 hover:bg-gray-50 transition-colors items-center"
                        >
                            <div className="col-span-1 text-gray-500">{index + 1}</div>
                            <div className="col-span-5">
                                <div className="flex gap-4">
                                    {isLoadingImages ? (
                                        <div className="w-14 h-14 bg-gray-100 rounded flex items-center justify-center">
                                            <Loader2 className="h-6 w-6 animate-spin text-gray-400" />
                                        </div>
                                    ) : imageErrors[product.id] ? (
                                        <div className="w-14 h-14 bg-gray-100 rounded flex items-center justify-center">
                                            <Package className="h-6 w-6 text-gray-400" />
                                        </div>
                                    ) : (
                                        <img
                                            src={productImages[product.id] || '/api/placeholder/120/68'}
                                            alt={product.name}
                                            className="w-14 h-14 rounded border object-contain"
                                            onError={() => {
                                                setImageErrors(prev => ({
                                                    ...prev,
                                                    [product.id]: true
                                                }));
                                            }}
                                        />
                                    )}
                                    <div className="flex flex-col justify-center">
                                        <span className="font-medium">{product.name}</span>
                                    </div>
                                </div>
                            </div>
                            <div className="col-span-2 text-right">
                                <div className="flex flex-row items-center space-x-2">
                                    <span>GHS {product.revenue.toFixed(2)}</span>
                                    <span className="text-sm text-gray-500">({product.percentageShare}%)</span>
                                </div>
                            </div>
                            <div className="col-span-2 text-right">
                                <span className={`font-medium ${parseFloat(product.profitMargin) > 30 ? 'text-green-600' :
                                    parseFloat(product.profitMargin) > 15 ? 'text-blue-600' :
                                        'text-orange-600'
                                    }`}>
                                    {product.profitMargin}%
                                </span>
                            </div>
                            <div className="col-span-2 text-right">{product.quantitySold}</div>
                        </div>
                    ))}
                </div>
            )}
        </div>
    );
};

const OverviewSection = ({ timeRange, storeId }) => {
    const [selectedMetric, setSelectedMetric] = useState('revenue');
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState(null);
    const [data, setData] = useState({
        sales: [],
        expenses: []
    });

    const db = getFirestore();

    useEffect(() => {
        const fetchData = async () => {
            if (!storeId || !timeRange.start || !timeRange.end) return;

            setIsLoading(true);
            try {
                // Create start date at beginning of day (00:00:00)
                const startDate = new Date(timeRange.start);
                startDate.setHours(0, 0, 0, 0);

                // Create end date at end of day (23:59:59.999)
                const endDate = new Date(timeRange.end);
                endDate.setHours(23, 59, 59, 999);

                const salesQuery = query(
                    collection(db, 'Stores', storeId, 'Sales'),
                    where('saleDate', '>=', Timestamp.fromDate(startDate)),
                    where('saleDate', '<=', Timestamp.fromDate(endDate))
                );

                const expensesQuery = query(
                    collection(db, 'Stores', storeId, 'Expenses'),
                    where('date', '>=', Timestamp.fromDate(startDate)),
                    where('date', '<=', Timestamp.fromDate(endDate))
                );

                const [salesDocs, expensesDocs] = await Promise.all([
                    getDocs(salesQuery),
                    getDocs(expensesQuery)
                ]);

                const rawSales = salesDocs.docs.map(doc => ({
                    ...doc.data(),
                    id: doc.id
                }));

                setData({
                    sales: rawSales,
                    expenses: expensesDocs.docs.map(doc => ({ ...doc.data(), id: doc.id }))
                });
            } catch (err) {
                setError('Failed to load overview data');
                console.error('Error loading overview data:', err);
            } finally {
                setIsLoading(false);
            }
        };

        fetchData();
    }, [timeRange, storeId, db]);

    const processedData = useMemo(() => {
        if (!data.sales.length) {
            return {
                metrics: {
                    revenue: 0,
                    grossProfit: 0,
                    expenses: 0,
                    totalQuantity: 0
                },
                salesData: []
            };
        }

        // Process daily totals
        const dailyTotals = {};
        let totalRevenue = 0;
        let totalCogs = 0;
        let totalQuantity = 0;

        data.sales.forEach(sale => {
            const saleDate = sale.saleDate.toDate();
            const dateKey = saleDate.toISOString().split('T')[0];

            if (!dailyTotals[dateKey]) {
                dailyTotals[dateKey] = {
                    revenue: 0,
                    profit: 0,
                    quantity: 0
                };
            }

            sale.items.forEach(item => {
                const revenue = item.price * item.quantity;
                const cogs = (item.costPrice || 0) * item.quantity;
                const quantity = item.quantity;

                dailyTotals[dateKey].revenue += revenue;
                dailyTotals[dateKey].profit += (revenue - cogs);
                dailyTotals[dateKey].quantity += quantity;

                totalRevenue += revenue;
                totalCogs += cogs;
                totalQuantity += quantity;
            });
        });

        const totalExpenses = data.expenses.reduce((sum, expense) =>
            sum + (expense.amount || 0), 0
        );

        // Create sales data array
        const startDate = new Date(timeRange.start);
        startDate.setHours(0, 0, 0, 0);
        const endDate = new Date(timeRange.end);
        endDate.setHours(23, 59, 59, 999);

        const salesData = [];
        let currentDate = new Date(startDate);

        while (currentDate <= endDate) {
            const dateKey = currentDate.toISOString().split('T')[0];
            salesData.push({
                date: dateKey,
                revenue: dailyTotals[dateKey]?.revenue || 0,
                profit: dailyTotals[dateKey]?.profit || 0,
                quantity: dailyTotals[dateKey]?.quantity || 0,
                formattedDate: currentDate.toLocaleDateString('en-US', {
                    month: 'short',
                    day: 'numeric'
                })
            });

            currentDate.setDate(currentDate.getDate() + 1);
        }

        return {
            metrics: {
                revenue: totalRevenue,
                grossProfit: totalRevenue - totalCogs,
                expenses: totalExpenses,
                totalQuantity
            },
            salesData
        };
    }, [data, timeRange]);

    const getChartData = (metric) => {
        switch (metric) {
            case 'revenue':
                return {
                    data: processedData.salesData,
                    dataKey: 'revenue',
                    label: 'Revenue',
                    color: '#4299E1'
                };
            case 'grossProfit':
                return {
                    data: processedData.salesData,
                    dataKey: 'profit',
                    label: 'Gross Profit',
                    color: '#48BB78'
                };
            case 'totalQuantity':
                return {
                    data: processedData.salesData,
                    dataKey: 'quantity',
                    label: 'Items Sold',
                    color: '#ED8936'
                };
            case 'expenses':
                return {
                    data: processedData.salesData,
                    dataKey: 'expenses',
                    label: 'Expenses',
                    color: '#F56565'
                };
            default:
                return {
                    data: processedData.salesData,
                    dataKey: 'revenue',
                    label: 'Revenue',
                    color: '#4299E1'
                };
        }
    };

    const getDateRangeText = () => {
        if (!timeRange.start || !timeRange.end) return '';

        const start = new Date(timeRange.start);
        const end = new Date(timeRange.end);
        const diffTime = Math.abs(end - start);
        const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));

        // Format dates
        const formatOptions = { month: 'short', day: 'numeric' };
        const startStr = start.toLocaleDateString('en-US', formatOptions);
        const endStr = end.toLocaleDateString('en-US', formatOptions);

        return `the last ${diffDays} days`;
    };

    if (isLoading) {
        return (
            <div className="flex items-center justify-center h-96">
                <div className="flex flex-col items-center gap-3">
                    <Loader2 className="h-8 w-8 animate-spin text-gray-600" />
                    <div className="text-lg text-gray-600">Loading overview data...</div>
                </div>
            </div>
        );
    }

    if (error) {
        return (
            <div className="flex items-center justify-center h-96 text-red-500">
                {error}
            </div>
        );
    }

    return (
        <div className="bg-white">
            <div className="">
                <h2 className="text-2xl font-bold mb-8 text-center">
                    Your store made GHS {processedData.metrics.revenue.toFixed(2)} in {getDateRangeText()}
                </h2>

                <div className="border">
                    <div className="grid grid-cols-4 mb-4">
                        <MetricBox
                            value={`GHS ${processedData.metrics.revenue.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}`}
                            label="Revenue"
                            comparison="966 less than usual"
                            onClick={() => setSelectedMetric('revenue')}
                            isSelected={selectedMetric === 'revenue'}
                        />
                        <MetricBox
                            value={`GHS ${processedData.metrics.grossProfit.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}`}
                            label="Gross Profit"
                            comparison="65.3 less than usual"
                            onClick={() => setSelectedMetric('grossProfit')}
                            isSelected={selectedMetric === 'grossProfit'}
                        />
                        <MetricBox
                            value={processedData.metrics.totalQuantity}
                            label="Items Sold"
                            comparison="About the same as usual"
                            onClick={() => setSelectedMetric('totalQuantity')}
                            isSelected={selectedMetric === 'totalQuantity'}
                        />
                        <MetricBox
                            value={`GHS ${processedData.metrics.expenses.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}`}
                            label="Expenses"
                            comparison="Total for period"
                            onClick={() => setSelectedMetric('expenses')}
                            isSelected={selectedMetric === 'expenses'}
                        />
                    </div>

                    <div className="h-72 p-8">
                        <ResponsiveContainer width="100%" height="100%">
                            <LineChart data={getChartData(selectedMetric).data}>
                                <XAxis
                                    dataKey="formattedDate"
                                    axisLine={false}
                                    tickLine={false}
                                    dy={10}
                                    tick={{ fontSize: 12 }}
                                />
                                <YAxis
                                    axisLine={false}
                                    tickLine={false}
                                    dx={-10}
                                    tick={{ fontSize: 12 }}
                                />
                                <CartesianGrid
                                    horizontal={true}
                                    vertical={false}
                                    stroke="#e2e8f0"
                                    strokeWidth={1}
                                />
                                <Tooltip
                                    formatter={(value) => [
                                        `${selectedMetric === 'totalQuantity' ? value : `GHS ${value.toFixed(2)}`}`,
                                        getChartData(selectedMetric).label
                                    ]}
                                    contentStyle={{
                                        backgroundColor: 'white',
                                        border: '1px solid #f0f0f0',
                                        borderRadius: '8px',
                                        padding: '8px'
                                    }}
                                />
                                <Area
                                    type="linear"
                                    dataKey={getChartData(selectedMetric).dataKey}
                                    fill={getChartData(selectedMetric).color}
                                    fillOpacity={0.2}
                                    strokeWidth={0}
                                    baseLine={0}
                                />
                                <Line
                                    type="linear"
                                    dataKey={getChartData(selectedMetric).dataKey}
                                    stroke={getChartData(selectedMetric).color}
                                    strokeWidth={3}
                                    dot={false}
                                    activeDot={{ r: 4 }}
                                />
                            </LineChart>
                        </ResponsiveContainer>
                    </div>
                </div>

                {/* Add TopProductsTable component */}
                <TopProductsTable
                    salesData={data.sales}
                    storeId={storeId}
                />
            </div>
        </div>
    );
};


export { OverviewSection };