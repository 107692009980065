import React, { useState, useEffect } from 'react';
import { X, ImageOff, Pencil, Trash2, Plus, Minus, Loader2, AlertTriangle, Check, Power } from 'lucide-react';
import { doc, updateDoc, deleteDoc, getFirestore, increment, serverTimestamp } from 'firebase/firestore';
import ProductEditModal from './inventory_item_edit';

const ProductDetailsModal = ({ item: initialItem, isOpen, onClose, onDelete, onItemUpdate, storeId }) => {
    const [item, setItem] = useState(initialItem);
    const [showEditModal, setShowEditModal] = useState(false);
    const [restockAmount, setRestockAmount] = useState(0);
    const [isRestocking, setIsRestocking] = useState(false);
    const [isDisabling, setIsDisabling] = useState(false);
    const [isDeleting, setIsDeleting] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [successMessage, setSuccessMessage] = useState('');

    const db = getFirestore();

    useEffect(() => {
        const handleEscape = (e) => {
            if (e.key === 'Escape') onClose();
        };

        if (isOpen) {
            document.addEventListener('keydown', handleEscape);
            document.body.style.overflow = 'hidden';
        }

        return () => {
            document.removeEventListener('keydown', handleEscape);
            document.body.style.overflow = 'unset';
        };
    }, [isOpen, onClose]);

    useEffect(() => {
        if (successMessage) {
            const timer = setTimeout(() => {
                setSuccessMessage('');
                if (successMessage.includes('deleted')) {
                    onClose();
                }
            }, 3000);
            return () => clearTimeout(timer);
        }
    }, [successMessage, onClose]);

    const handleRestock = async () => {
        if (!restockAmount) return;
        setIsRestocking(true);
        setErrorMessage('');

        try {
            if (!storeId || !item?.id) {
                throw new Error('Missing required data');
            }

            const itemRef = doc(db, 'Stores', storeId, 'Inventory', item.id);
            const newQuantity = item.quantity + restockAmount;

            await updateDoc(itemRef, {
                quantity: increment(restockAmount),
                updatedAt: serverTimestamp()
            });

            const updatedItem = {
                ...item,
                quantity: newQuantity
            };
            setItem(updatedItem);
            onItemUpdate(updatedItem); // Propagate update to parent
            setSuccessMessage(`Successfully added ${restockAmount} items to stock`);
            setRestockAmount(0);
        } catch (error) {
            console.error('Error restocking:', error);
            setErrorMessage('Failed to restock item');
        } finally {
            setIsRestocking(false);
        }
    };

    const handleEditSave = async (updatedData) => {
        try {
            const itemRef = doc(db, 'Stores', storeId, 'Inventory', item.id);
            const dataToUpdate = {
                ...updatedData,
                updatedAt: serverTimestamp()
            };

            await updateDoc(itemRef, dataToUpdate);

            const updatedItem = {
                ...item,
                ...updatedData
            };
            setItem(updatedItem);
            onItemUpdate(updatedItem); // Propagate update to parent
            setShowEditModal(false);
            setSuccessMessage('Product successfully updated');
        } catch (error) {
            console.error('Error updating product:', error);
            setErrorMessage('Failed to update product');
        }
    };

    const handleToggleDisable = async () => {
        setIsDisabling(true);
        setErrorMessage('');

        try {
            if (!storeId || !item?.id) {
                throw new Error('Missing required data');
            }

            const itemRef = doc(db, 'Stores', storeId, 'Inventory', item.id);
            const newStatus = !item.disabled;

            await updateDoc(itemRef, {
                disabled: newStatus,
                updatedAt: serverTimestamp()
            });

            const updatedItem = {
                ...item,
                disabled: newStatus
            };
            setItem(updatedItem);
            onItemUpdate(updatedItem); // Propagate update to parent
            setSuccessMessage(`Successfully ${newStatus ? 'disabled' : 'enabled'} the product`);
        } catch (error) {
            console.error('Error toggling disable status:', error);
            setErrorMessage('Failed to update product status');
        } finally {
            setIsDisabling(false);
        }
    };

    const handleDelete = async () => {
        if (!window.confirm('Are you sure you want to delete this product? This action cannot be undone.')) {
            return;
        }

        setIsDeleting(true);
        setErrorMessage('');

        try {
            if (!storeId || !item?.id) {
                throw new Error('Missing required data');
            }

            const itemRef = doc(db, 'Stores', storeId, 'Inventory', item.id);
            await deleteDoc(itemRef);
            setSuccessMessage('Product successfully deleted');
        } catch (error) {
            console.error('Error deleting product:', error);
            setErrorMessage('Failed to delete product');
        } finally {
            setIsDeleting(false);
        }
    };

    const getStatusStyle = (quantity, reorderPoint, disabled) => {
        if (disabled) {
            return "text-gray-600 bg-gray-50 border-gray-200";
        } else if (quantity === 0) {
            return "text-red-600 bg-red-50 border-red-200";
        } else if (quantity <= reorderPoint) {
            return "text-yellow-600 bg-yellow-50 border-yellow-200";
        }
        return "text-green-600 bg-green-50 border-green-200";
    };

    const getStatusText = (quantity, reorderPoint, disabled) => {
        if (disabled) {
            return "Disabled";
        } else if (quantity === 0) {
            return "Out of Stock";
        } else if (quantity <= reorderPoint) {
            return "Low Stock";
        }
        return "In Stock";
    };

    if (!isOpen) return null;

    const statusStyle = getStatusStyle(item.quantity, item.reorderPoint, item.disabled);
    const statusText = getStatusText(item.quantity, item.reorderPoint, item.disabled);

    return (
        <div className="fixed inset-0 z-50 overflow-y-auto">
            <div className="fixed inset-0 bg-black bg-opacity-50" onClick={onClose} />

            <div className="flex min-h-full items-center justify-center p-4">
                <div className="relative bg-white rounded-lg shadow-xl w-full max-w-4xl mx-auto">
                    <div className="flex items-center justify-between p-4 border-b">
                        <h2 className="text-lg font-semibold">Product Details</h2>
                        <button
                            onClick={onClose}
                            className="p-1 hover:bg-gray-100 rounded-full"
                        >
                            <X className="h-5 w-5" />
                        </button>
                    </div>

                    {successMessage && (
                        <div className="mx-6 mt-4 p-3 bg-green-50 border border-green-200 rounded-lg flex items-center gap-2 text-green-700">
                            <Check className="h-5 w-5" />
                            <span>{successMessage}</span>
                        </div>
                    )}

                    {errorMessage && (
                        <div className="mx-6 mt-4 p-3 bg-red-50 border border-red-200 rounded-lg flex items-center gap-2 text-red-700">
                            <AlertTriangle className="h-5 w-5" />
                            <span>{errorMessage}</span>
                        </div>
                    )}

                    <div className="flex">
                        <div className="w-2/5 p-6 bg-gray-50 border-r">
                            <div className="flex flex-col items-center justify-center h-full">
                                <div className={`w-64 h-64 rounded-lg overflow-hidden bg-white flex items-center justify-center border ${item.disabled ? 'opacity-50' : ''}`}>
                                    {item.imageUrl ? (
                                        <img
                                            src={item.imageUrl}
                                            alt={item.itemName}
                                            className="w-full h-full object-contain"
                                        />
                                    ) : (
                                        <ImageOff className="h-12 w-12 text-gray-400" />
                                    )}
                                </div>

                                <div className={`mt-4 px-4 py-2 border rounded-lg ${statusStyle}`}>
                                    {statusText}
                                </div>
                            </div>
                        </div>

                        <div className="flex-1 p-6">
                            <div className="space-y-5">
                                <div className="bg-gray-50 rounded-lg border border-gray-200">
                                    <div className="p-4 space-y-4">
                                        <div>
                                            <label className="text-sm text-gray-500">Product Name</label>
                                            <p className="font-medium">{item.itemName}</p>
                                        </div>

                                        <div>
                                            <label className="text-sm text-gray-500">SKU</label>
                                            <p className="font-medium">{item.sku || 'N/A'}</p>
                                        </div>

                                        <div className="grid grid-cols-2 gap-4">
                                            <div>
                                                <label className="text-sm text-gray-500">Current Stock</label>
                                                <p className="font-medium">{item.quantity}</p>
                                            </div>
                                            <div>
                                                <label className="text-sm text-gray-500">Low Stock Alert</label>
                                                <p className="font-medium">{item.reorderPoint}</p>
                                            </div>
                                        </div>

                                        <div className="grid grid-cols-2 gap-4">
                                            <div>
                                                <label className="text-sm text-gray-500">Selling Price</label>
                                                <p className="font-medium">GHS {item.sellingPrice?.toFixed(2)}</p>
                                            </div>
                                            <div>
                                                <label className="text-sm text-gray-500">Cost Price</label>
                                                <p className="font-medium">GHS {item.costPrice?.toFixed(2)}</p>
                                            </div>
                                        </div>

                                        {!item.disabled && (
                                            <div className="border-t pt-4 mt-2">
                                                <div className="flex items-center justify-between mb-3">
                                                    <h3 className="text-sm font-medium">Add Stock</h3>
                                                </div>

                                                <div className="flex items-center gap-3">
                                                    <div className="flex items-center bg-white border rounded-md">
                                                        <button
                                                            onClick={() => setRestockAmount(Math.max(0, restockAmount - 1))}
                                                            className="p-2 hover:bg-gray-50 text-gray-500 hover:text-gray-700 border-r disabled:opacity-50"
                                                            disabled={isRestocking || restockAmount <= 0}
                                                        >
                                                            <Minus className="h-4 w-4" />
                                                        </button>
                                                        <input
                                                            type="number"
                                                            value={restockAmount}
                                                            onChange={(e) => setRestockAmount(Math.max(0, parseInt(e.target.value) || 0))}
                                                            className="w-20 text-center py-2 border-0 focus:ring-0 text-sm"
                                                            disabled={isRestocking}
                                                        />
                                                        <button
                                                            onClick={() => setRestockAmount(restockAmount + 1)}
                                                            className="p-2 hover:bg-gray-50 text-gray-500 hover:text-gray-700 border-l disabled:opacity-50"
                                                            disabled={isRestocking}
                                                        >
                                                            <Plus className="h-4 w-4" />
                                                        </button>
                                                    </div>

                                                    <button
                                                        onClick={handleRestock}
                                                        disabled={isRestocking || !restockAmount}
                                                        className="flex items-center px-4 py-2 bg-blue-600 text-white text-sm font-medium rounded-md hover:bg-blue-700 disabled:bg-blue-300"
                                                    >
                                                        {isRestocking ? (
                                                            <>
                                                                <Loader2 className="h-4 w-4 animate-spin mr-1" />
                                                                <span>Adding...</span>
                                                            </>
                                                        ) : (
                                                            <span>Add Stock</span>
                                                        )}
                                                    </button>
                                                </div>
                                            </div>
                                        )}

                                        {item.sellingPrice < item.costPrice && (
                                            <div className="flex items-center text-sm text-red-600">
                                                <AlertTriangle className="h-4 w-4 mr-1" />
                                                Selling price is lower than cost price
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </div>

                            <div className="flex justify-end gap-3 mt-4">
                                <button
                                    onClick={handleDelete}
                                    disabled={isDeleting}
                                    className="px-4 py-2 text-red-500 hover:text-red-700 flex items-center gap-2 disabled:opacity-50"
                                >
                                    {isDeleting ? (
                                        <>
                                            <Loader2 className="h-4 w-4 animate-spin" />
                                            <span>Deleting...</span>
                                        </>
                                    ) : (
                                        <>
                                            <Trash2 className="h-4 w-4" />
                                            <span>Delete</span>
                                        </>
                                    )}
                                </button>
                                {/* <button
                                    onClick={handleToggleDisable}
                                    disabled={isDisabling}
                                    className={`px-4 py-2 rounded-lg flex items-center gap-2 disabled:opacity-50 ${item.disabled
                                        ? 'bg-green-100 text-green-700 hover:bg-green-200'
                                        : 'bg-gray-100 text-gray-700 hover:bg-gray-200'
                                        }`}
                                >
                                    <Power className="h-4 w-4" />
                                    {isDisabling ? 'Updating...' : (item.disabled ? 'Enable' : 'Disable')}
                                </button> */}
                                <button
                                    onClick={() => setShowEditModal(true)}
                                    className="px-4 py-2 bg-gray-100 text-gray-700 rounded-lg hover:bg-gray-200 flex items-center gap-2"
                                >
                                    <Pencil className="h-4 w-4" />
                                    Edit
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {showEditModal && (
                <ProductEditModal
                    item={item}
                    isOpen={showEditModal}
                    onClose={() => setShowEditModal(false)}
                    onSave={handleEditSave}
                    storeId={storeId}
                />
            )}
        </div>
    );
};

export default ProductDetailsModal;