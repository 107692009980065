import React, { useState, useEffect } from 'react';
import { Search, ArrowUpRight, ArrowDownRight } from 'lucide-react';
import {
    collection,
    query,
    where,
    orderBy,
    getDocs,
    getFirestore,
    Timestamp
} from 'firebase/firestore';
import { getAuth } from 'firebase/auth';
import PaymentDetailModal from './payment_detail_modal';

const PaymentTypeBadge = ({ type, direction }) => {
    const getBadgeColors = () => {
        if (direction === 'out') {
            return 'bg-orange-100 text-orange-800';
        }
        return type === 'sale' ? 'bg-green-100 text-green-800' : 'bg-blue-100 text-blue-800';
    };

    return (
        <div className="flex space-x-2">
            <span className={`px-2 py-1 rounded-full text-xs ${getBadgeColors()}`}>
                {type === 'sale' ? 'Sale' : 'Manual'}
            </span>
            <span className={`px-2 py-1 rounded-full text-xs ${direction === 'out' ? 'bg-orange-100 text-orange-800' : 'bg-green-100 text-green-800'}`}>
                {direction === 'out' ? 'Payment Out' : 'Payment In'}
            </span>
        </div>
    );
};

const PaymentsTable = ({ timeRange }) => {
    const [payments, setPayments] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState(null);
    const [searchTerm, setSearchTerm] = useState('');
    const [currentPage, setCurrentPage] = useState(1);
    const [storeId, setStoreId] = useState(null);
    const [selectedPayment, setSelectedPayment] = useState(null);

    const paymentsPerPage = 20;
    const db = getFirestore();
    const auth = getAuth();

    const formatDate = (date) => {
        return date.toLocaleString('en-GB', {
            day: '2-digit',
            month: 'short',
            year: 'numeric',
            hour: '2-digit',
            minute: '2-digit',
            hour12: true
        });
    };

    useEffect(() => {
        const fetchPayments = async () => {
            try {
                setIsLoading(true);
                const currentUser = auth.currentUser;
                if (!currentUser) throw new Error('No authenticated user found');

                const userDoc = await getDocs(
                    query(collection(db, 'Users'),
                        where('email', '==', currentUser.email))
                );

                if (userDoc.empty) throw new Error('User data not found');
                const userData = userDoc.docs[0].data();
                setStoreId(userData.storeId);

                // Create proper date range
                const start = new Date(timeRange.start);
                start.setHours(0, 0, 0, 0);
                const end = new Date(timeRange.end);
                end.setHours(23, 59, 59, 999);

                // Fetch sales-based payments
                const salesQuery = query(
                    collection(db, 'Stores', userData.storeId, 'Sales'),
                    where('saleDate', '>=', Timestamp.fromDate(start)),
                    where('saleDate', '<=', Timestamp.fromDate(end)),
                    orderBy('saleDate', 'desc')
                );

                const salesSnapshot = await getDocs(salesQuery);
                const salesPayments = salesSnapshot.docs.map(doc => {
                    const saleData = doc.data();
                    return {
                        id: doc.id,
                        amount: saleData.items.reduce((sum, item) => sum + (item.price * item.quantity), 0),
                        date: saleData.saleDate.toDate(),
                        description: `Sale - Order #${saleData.orderNumber || `ORD${String(parseInt(doc.id.slice(-6), 16)).padStart(6, '0')}`}`,
                        paymentType: 'sale',
                        direction: 'in'
                    };
                });

                // Fetch manual payments
                const manualPaymentsQuery = query(
                    collection(db, 'Stores', userData.storeId, 'Payments'),
                    where('date', '>=', Timestamp.fromDate(start)),
                    where('date', '<=', Timestamp.fromDate(end)),
                    orderBy('date', 'desc')
                );

                const manualPaymentsSnapshot = await getDocs(manualPaymentsQuery);
                const manualPayments = manualPaymentsSnapshot.docs.map(doc => ({
                    id: doc.id,
                    ...doc.data(),
                    date: doc.data().date.toDate()
                }));

                // Combine and sort all payments
                const allPayments = [...salesPayments, ...manualPayments].sort(
                    (a, b) => b.date - a.date
                );

                setPayments(allPayments);
                setError(null);
            } catch (err) {
                console.error('Error fetching payments:', err);
                setError('Failed to load payments data');
            } finally {
                setIsLoading(false);
            }
        };

        if (timeRange?.start && timeRange?.end) {
            fetchPayments();
        }
    }, [timeRange, db, auth]);

    const handleDeletePayment = (paymentId) => {
        setPayments(prevPayments => prevPayments.filter(p => p.id !== paymentId));
    };

    const filteredPayments = payments.filter(payment =>
        payment.description.toLowerCase().includes(searchTerm.toLowerCase())
    );

    const totalPages = Math.ceil(filteredPayments.length / paymentsPerPage);
    const indexOfLastPayment = currentPage * paymentsPerPage;
    const indexOfFirstPayment = indexOfLastPayment - paymentsPerPage;
    const currentPayments = filteredPayments.slice(indexOfFirstPayment, indexOfLastPayment);

    if (isLoading) {
        return (
            <div className="flex items-center justify-center h-64">
                <div className="text-gray-500">Loading payments data...</div>
            </div>
        );
    }

    if (error) {
        return (
            <div className="flex items-center justify-center h-64">
                <div className="text-red-500">{error}</div>
            </div>
        );
    }

    return (
        <div className="flex-1 overflow-y-auto">
            <div className="mb-5 mt-10">
                <div className="relative">
                    <input
                        type="text"
                        placeholder="Search payments..."
                        value={searchTerm}
                        onChange={(e) => setSearchTerm(e.target.value)}
                        className="pl-10 pr-4 py-2 rounded-lg border border-gray-300 focus:outline-none focus:ring-2 focus:ring-blue-500"
                    />
                    <div className="absolute left-3 top-1/2 transform -translate-y-1/2">
                        <Search className="h-5 w-5 text-gray-400" />
                    </div>
                </div>
            </div>

            <div className="bg-white overflow-hidden border p-4">
                <table className="w-full">
                    <thead>
                        <tr className="bg-gray-100">
                            <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                Date
                            </th>
                            <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                Description
                            </th>
                            <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                Type
                            </th>
                            <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                Amount
                            </th>
                        </tr>
                    </thead>
                    <tbody className="bg-white divide-y divide-gray-200">
                        {currentPayments.length === 0 ? (
                            <tr>
                                <td colSpan="4" className="px-6 py-4 text-center text-gray-500">
                                    No payments found for the selected period
                                </td>
                            </tr>
                        ) : (
                            currentPayments.map((payment) => (
                                <tr
                                    key={payment.id}
                                    className="hover:bg-gray-50 cursor-pointer"
                                    onClick={() => setSelectedPayment(payment)}
                                >
                                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                                        {formatDate(payment.date)}
                                    </td>
                                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                                        {payment.description}
                                    </td>
                                    <td className="px-6 py-4 whitespace-nowrap text-sm">
                                        <PaymentTypeBadge
                                            type={payment.paymentType}
                                            direction={payment.direction || (payment.amount < 0 ? 'out' : 'in')}
                                        />
                                    </td>
                                    <td className="px-6 py-4 whitespace-nowrap text-sm">
                                        <div className="flex items-center space-x-1">
                                            {payment.direction === 'out' || payment.amount < 0 ? (
                                                <ArrowUpRight className="h-4 w-4 text-orange-500" />
                                            ) : (
                                                <ArrowDownRight className="h-4 w-4 text-green-500" />
                                            )}
                                            <span className={`font-medium ${payment.direction === 'out' || payment.amount < 0 ? 'text-orange-600' : 'text-green-600'}`}>
                                                <span className="text-xs mr-1">GHS</span>
                                                {Math.abs(payment.amount).toFixed(2)}
                                            </span>
                                        </div>
                                    </td>
                                </tr>
                            ))
                        )}
                    </tbody>
                </table>

                {currentPayments.length > 0 && (
                    <div className="px-6 py-4 bg-gray-50 border-t border-gray-200">
                        <div className="flex items-center justify-between">
                            <div className="text-sm text-gray-700">
                                Showing {indexOfFirstPayment + 1} to {Math.min(indexOfLastPayment, filteredPayments.length)} of{' '}
                                {filteredPayments.length} results
                            </div>
                            <div className="flex space-x-2">
                                <button
                                    onClick={() => setCurrentPage(prev => Math.max(prev - 1, 1))}
                                    disabled={currentPage === 1}
                                    className="px-4 py-2 border rounded-lg text-sm font-medium text-gray-700 hover:bg-gray-50 disabled:opacity-50 disabled:cursor-not-allowed"
                                >
                                    Previous
                                </button>
                                <button
                                    onClick={() => setCurrentPage(prev => Math.min(prev + 1, totalPages))}
                                    disabled={currentPage === totalPages}
                                    className="px-4 py-2 border rounded-lg text-sm font-medium text-gray-700 hover:bg-gray-50 disabled:opacity-50 disabled:cursor-not-allowed"
                                >
                                    Next
                                </button>
                            </div>
                        </div>
                    </div>
                )}
            </div>

            {selectedPayment && (
                <PaymentDetailModal
                    payment={selectedPayment}
                    onClose={() => setSelectedPayment(null)}
                    onDelete={handleDeletePayment}
                    storeId={storeId}
                />
            )}
        </div>
    );
};

export default PaymentsTable;