import React, { useState, useEffect } from 'react';
import { BarChart2, Package, DollarSign, Coffee, Smartphone, Menu, X } from 'lucide-react';
import { useNavigate } from 'react-router-dom';

// Import images
import logo from '../../assets/hotKioskLogocolor.png';
import PhoneMockup from './PhoneMockup';
import FeaturesGrid from './FeaturesGrid';
import CallToAction from './CallToAction';
import Hero from './Hero';

const Home = () => {
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const [scrollPosition, setScrollPosition] = useState(0);
    const navigate = useNavigate();

    useEffect(() => {
        const handleScroll = () => {
            setScrollPosition(window.pageYOffset);
        };
        window.addEventListener('scroll', handleScroll);
        return () => window.removeEventListener('scroll', handleScroll);
    }, []);

    return (
        <div className="min-h-screen bg-gray-900 text-white">
            {/* Header */}
            <header className={`bg-black bg-opacity-${scrollPosition > 50 ? '90' : '50'} backdrop-filter backdrop-blur-lg fixed w-full z-20 transition-all duration-300`}>
                <div className="container mx-auto flex justify-between items-center py-4 px-4">
                    <div className="flex items-center">
                        <img
                            src={logo}
                            alt="HotKiosk Logo"
                            className="h-12 mr-2"
                        />
                    </div>
                    <nav className="hidden md:flex items-center space-x-6">
                        <ul className="flex space-x-6">
                            <li><a href="/support" className="hover:text-red-400 transition duration-300">Support</a></li>
                        </ul>
                        <button
                            className="bg-red-500 hover:bg-red-600 text-white px-4 py-2 rounded-lg transition duration-300"
                            onClick={() => navigate('/login')}
                        >
                            Sign In
                        </button>
                    </nav>
                    <button className="md:hidden" onClick={() => setIsMenuOpen(!isMenuOpen)}>
                        {isMenuOpen ? <X className="w-6 h-6" /> : <Menu className="w-6 h-6" />}
                    </button>
                </div>
                {isMenuOpen && (
                    <nav className="md:hidden bg-black bg-opacity-90 backdrop-filter backdrop-blur-lg">
                        <ul className="flex flex-col items-center py-4 space-y-4">
                            <li><a href="/support" className="hover:text-red-400 transition duration-300" onClick={() => setIsMenuOpen(false)}>Support</a></li>
                            <li className="px-4 w-full">
                                <div className="text-sm text-red-400 bg-red-500/10 border border-red-500/20 rounded-lg p-3 text-center">
                                    Sign in is only available on desktop devices, use app on mobile
                                </div>
                            </li>
                        </ul>
                    </nav>
                )}
            </header>

            {/* Rest of the component remains unchanged */}
            <Hero />
            <PhoneMockup />
            <FeaturesGrid />
            <CallToAction />

            <footer className="bg-gray-900 py-8">
                <div className="container mx-auto px-4">
                    <div className="flex flex-col md:flex-row justify-between items-center">
                        <p className="text-gray-400 mb-4 md:mb-0">&copy; {new Date().getFullYear()} HotKiosk. All rights reserved.</p>
                        <div className="flex space-x-4">
                            <a href="/privacy" className="text-gray-400 hover:text-white transition duration-300">Privacy Policy</a>
                            <a href="/terms" className="text-gray-400 hover:text-white transition duration-300">Terms of Service</a>
                        </div>
                    </div>
                </div>
            </footer>
        </div>
    );
};

export default Home;