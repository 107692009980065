import React, { useState, useEffect } from 'react';
import { PieChart, Pie, Cell, Legend, ResponsiveContainer } from 'recharts';
import { getFirestore, collection, query, getDocs, orderBy, deleteDoc, doc, where, Timestamp } from 'firebase/firestore';
import { Loader2, PlusCircle } from 'lucide-react';

const ExpensesCategoriesTab = ({ timeRange }) => {
    const [categories, setCategories] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [isAddModalOpen, setIsAddModalOpen] = useState(false);
    const [categoryStats, setCategoryStats] = useState({});
    const [showModal, setShowModal] = useState(false);
    const [selectedCategory, setSelectedCategory] = useState(null);
    const [modalType, setModalType] = useState(null);

    const COLORS = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042', '#8884d8'];

    const db = getFirestore();
    const storeId = localStorage.getItem('storeId');

    const renderCustomizedLabel = ({ cx, cy, midAngle, innerRadius, outerRadius, percent }) => {
        const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
        const x = cx + radius * Math.cos(-midAngle * Math.PI / 180);
        const y = cy + radius * Math.sin(-midAngle * Math.PI / 180);

        return percent > 0.05 ? (
            <text x={x} y={y} fill="white" textAnchor="middle" dominantBaseline="middle">
                {`${(percent * 100).toFixed(0)}%`}
            </text>
        ) : null;
    };

    const handleDeleteAttempt = (category) => {
        setSelectedCategory(category);
        const stats = categoryStats[category.id];

        if (stats?.transactionCount > 0) {
            setModalType('warning');
        } else {
            setModalType('confirm');
        }
        setShowModal(true);
    };

    const handleConfirmDelete = async () => {
        try {
            await deleteDoc(doc(db, 'Stores', storeId, 'ExpenseCategories', selectedCategory.id));
            setCategories(categories.filter(cat => cat.id !== selectedCategory.id));
            setShowModal(false);
        } catch (err) {
            console.error('Error deleting category:', err);
            setError('Unable to delete category. Please try again.');
        }
    };

    useEffect(() => {
        const fetchCategories = async () => {
            try {
                setLoading(true);
                const categoriesRef = collection(db, 'Stores', storeId, 'ExpenseCategories');
                const categoriesQuery = query(categoriesRef, orderBy('createdAt', 'desc'));

                const expensesRef = collection(db, 'Stores', storeId, 'Expenses');
                const expensesQuery = query(
                    expensesRef,
                    where('date', '>=', Timestamp.fromDate(timeRange.start)),
                    where('date', '<=', Timestamp.fromDate(timeRange.end))
                );

                const [categoriesSnapshot, expensesSnapshot] = await Promise.all([
                    getDocs(categoriesQuery),
                    getDocs(expensesQuery)
                ]);

                const expensesData = expensesSnapshot.docs.map(doc => ({
                    ...doc.data(),
                    amount: Number(doc.data().amount) || 0,
                    date: doc.data().date?.toDate() || null
                }));

                const stats = calculateCategoryStats(
                    categoriesSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() })),
                    expensesData
                );

                setCategories(categoriesSnapshot.docs.map(doc => ({
                    id: doc.id,
                    ...doc.data(),
                    amount: stats[doc.id]?.totalAmount || 0,
                    count: stats[doc.id]?.transactionCount || 0
                })));

                setCategoryStats(stats);
            } catch (err) {
                console.error('Error fetching categories:', err);
                setError('Failed to load categories');
            } finally {
                setLoading(false);
            }
        };

        fetchCategories();
    }, [timeRange]);

    const calculateCategoryStats = (categories, expenses) => {
        return categories.reduce((acc, category) => {
            const categoryExpenses = expenses.filter(exp =>
                exp.category?.toLowerCase() === category.name?.toLowerCase()
            );

            const totalAmount = categoryExpenses.reduce((sum, exp) => sum + exp.amount, 0);
            const averageAmount = categoryExpenses.length ? totalAmount / categoryExpenses.length : 0;

            const monthlyTrends = categoryExpenses.reduce((trends, exp) => {
                if (exp.date) {
                    const monthYear = exp.date.toLocaleDateString('en-US', {
                        month: 'short',
                        year: 'numeric'
                    });
                    trends[monthYear] = (trends[monthYear] || 0) + exp.amount;
                }
                return trends;
            }, {});

            acc[category.id] = {
                totalAmount,
                averageAmount,
                transactionCount: categoryExpenses.length,
                monthlyTrends: Object.entries(monthlyTrends)
                    .map(([month, amount]) => ({
                        month,
                        amount
                    }))
                    .sort((a, b) => new Date(a.month) - new Date(b.month))
            };

            return acc;
        }, {});
    };

    const Modal = () => (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
            <div className="bg-white rounded-lg p-6 max-w-md w-full m-4">
                {modalType === 'warning' ? (
                    <>
                        <h3 className="text-lg font-semibold mb-2">Cannot Delete Category</h3>
                        <p className="text-gray-600 mb-4">
                            "{selectedCategory?.name}" has {categoryStats[selectedCategory?.id]?.transactionCount} transactions.
                            Please move these transactions to another category before deleting.
                        </p>
                        <div className="flex justify-end">
                            <button
                                onClick={() => setShowModal(false)}
                                className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600 transition-colors"
                            >
                                Understood
                            </button>
                        </div>
                    </>
                ) : (
                    <>
                        <h3 className="text-lg font-semibold mb-2">Delete Category</h3>
                        <p className="text-gray-600 mb-4">
                            Are you sure you want to delete "{selectedCategory?.name}"? This action cannot be undone.
                        </p>
                        <div className="flex justify-end gap-2">
                            <button
                                onClick={() => setShowModal(false)}
                                className="px-4 py-2 rounded border border-gray-300 hover:bg-gray-50 transition-colors"
                            >
                                Cancel
                            </button>
                            <button
                                onClick={handleConfirmDelete}
                                className="bg-red-500 text-white px-4 py-2 rounded hover:bg-red-600 transition-colors"
                            >
                                Delete
                            </button>
                        </div>
                    </>
                )}
            </div>
        </div>
    );

    if (loading) {
        return (
            <div className="flex items-center justify-center p-8">
                <div className="flex flex-col items-center gap-3">
                    <Loader2 className="h-8 w-8 animate-spin text-gray-600" />
                    <div className="text-lg">Loading categories...</div>
                </div>
            </div>
        );
    }

    return (
        <div className="">

            {error && (
                <div className="mb-6 p-4 bg-red-50 border border-red-200 text-red-700 rounded-lg">
                    {error}
                </div>
            )}

            <div className="grid grid-cols-5 gap-8 items-start">
                <div className="col-span-2 flex items-center justify-center">
                    <ResponsiveContainer width="100%" height={400}>
                        <PieChart>
                            <Pie
                                data={categories}
                                dataKey="amount"
                                nameKey="name"
                                cx="50%"
                                cy="50%"
                                innerRadius={80}
                                outerRadius={140}
                                fill="#8884d8"
                                labelLine={false}
                                label={renderCustomizedLabel}
                            >
                                {categories.map((entry, index) => (
                                    <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                                ))}
                            </Pie>
                            <Legend verticalAlign="bottom" height={36} />
                        </PieChart>
                    </ResponsiveContainer>
                </div>

                <div className="col-span-3 grid grid-cols-1 gap-2">
                    {categories.map(category => (
                        <div key={category.id} className="border p-4">
                            <div className="flex items-center justify-between">
                                <div className="flex flex-col">
                                    <h3 className="font-medium capitalize">{category.name}</h3>
                                    <span className="text-xs text-gray-500">{category.count} expenses</span>
                                </div>
                                <span className="font-medium">
                                    <span className="text-xs mr-1">GHS</span>
                                    {category.amount.toFixed(2)}
                                </span>
                            </div>
                        </div>
                    ))}
                </div>
            </div>

            {showModal && <Modal />}
        </div>
    );
};

export default ExpensesCategoriesTab;