import React, { useState } from 'react';
import { Check, Clock, ArrowRight, AlertTriangle, ChevronDown, ChevronUp, X, Loader2 } from 'lucide-react';
import { getFunctions, httpsCallable } from 'firebase/functions';
import Alert from '../alert';
import { useToast } from '../toast';


const CancelSubscriptionModal = ({ isOpen, onClose, storeId, onError }) => {
    const [isLoading, setIsLoading] = useState(false);

    const handleConfirmCancel = async () => {
        setIsLoading(true);
        try {
            const functions = getFunctions();
            const generateManagementLink = httpsCallable(functions, 'generateSubscriptionManagementLink');

            const result = await generateManagementLink({ storeId });

            if (result.data.success) {
                const popup = window.open(
                    result.data.data.managementUrl,
                    'Subscription Management',
                    'width=1000,height=800,left=200,top=100'
                );

                if (!popup || popup.closed || typeof popup.closed === 'undefined') {
                    throw new Error('Popup blocked by browser');
                }

                onClose();

                const checkPopupClosure = setInterval(() => {
                    if (popup.closed) {
                        clearInterval(checkPopupClosure);
                        window.location.reload();
                    }
                }, 500);
            } else {
                throw new Error('Failed to generate management link');
            }
        } catch (error) {
            console.error('Error generating management link:', error);
            onError(error.message === 'Popup blocked by browser'
                ? 'Please allow popups to access subscription management.'
                : 'Could not access subscription management. Please try again later.');
            setIsLoading(false);
        }
    };

    if (!isOpen) return null;

    return (
        <div className="fixed inset-0 bg-black/50 flex items-center justify-center z-50 p-4">
            <div className="bg-white rounded-lg max-w-md w-full relative">
                <button
                    onClick={onClose}
                    className="absolute right-4 top-4 text-gray-400 hover:text-gray-600"
                    disabled={isLoading}
                >
                    <X className="w-5 h-5" />
                </button>

                <div className="p-6">
                    <div className="mb-6">
                        <AlertTriangle className="w-12 h-12 text-amber-500 mx-auto mb-4" />
                        <h3 className="text-lg font-semibold text-gray-900 mb-2 text-center">
                            Before You Cancel
                        </h3>
                        <p className="text-gray-600 text-center">
                            Are you sure you want to cancel? This is your last chance to keep your premium benefits and special pricing.
                        </p>
                    </div>

                    <div className="flex gap-3">
                        <button
                            onClick={onClose}
                            className="flex-1 px-4 py-2 border border-gray-300 text-gray-700 rounded-lg hover:bg-gray-50 disabled:opacity-50"
                            disabled={isLoading}
                        >
                            Keep Subscription
                        </button>
                        <button
                            onClick={handleConfirmCancel}
                            className="flex-1 px-4 py-2 bg-red-500 text-white rounded-lg hover:bg-red-600 disabled:opacity-50 flex items-center justify-center"
                            disabled={isLoading}
                        >
                            {isLoading ? (
                                <>
                                    <Loader2 className="animate-spin w-4 h-4 mr-2" />
                                    Redirecting...
                                </>
                            ) : (
                                'Cancel Subscription'
                            )}
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
};

const MomoCancelModal = ({ isOpen, onClose, onConfirm, isLoading }) => {
    if (!isOpen) return null;

    return (
        <div className="fixed inset-0 bg-black/50 flex items-center justify-center z-50 p-4">
            <div className="bg-white rounded-lg max-w-md w-full relative">
                <button
                    onClick={onClose}
                    className="absolute right-4 top-4 text-gray-400 hover:text-gray-600"
                    disabled={isLoading}
                >
                    <X className="w-5 h-5" />
                </button>

                <div className="p-6">
                    <div className="mb-6">
                        <AlertTriangle className="w-12 h-12 text-amber-500 mx-auto mb-4" />
                        <h3 className="text-lg font-semibold text-gray-900 mb-2 text-center">
                            Cancel Mobile Money Subscription
                        </h3>
                        <p className="text-gray-600 text-center">
                            Are you sure you want to cancel your subscription? Your will lose access immediately.
                        </p>
                    </div>

                    <div className="flex gap-3">
                        <button
                            onClick={onClose}
                            className="flex-1 px-4 py-2 border border-gray-300 text-gray-700 rounded-lg hover:bg-gray-50 disabled:opacity-50"
                            disabled={isLoading}
                        >
                            Keep Subscription
                        </button>
                        <button
                            onClick={onConfirm}
                            className="flex-1 px-4 py-2 bg-red-500 text-white rounded-lg hover:bg-red-600 disabled:opacity-50 flex items-center justify-center"
                            disabled={isLoading}
                        >
                            {isLoading ? (
                                <>
                                    <Loader2 className="animate-spin w-4 h-4 mr-2" />
                                    Cancelling...
                                </>
                            ) : (
                                'Confirm Cancellation'
                            )}
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
};

const SubscriptionDetailsSection = ({ subscription, storeId }) => {
    const [isLoading, setIsLoading] = useState(false);
    const [showFullHistory, setShowFullHistory] = useState(false);
    const [showCancelModal, setShowCancelModal] = useState(false);
    const [showMomoCancelModal, setShowMomoCancelModal] = useState(false);
    const [isCancellingMomo, setIsCancellingMomo] = useState(false);
    const { showToast, Toast } = useToast();
    const [alert, setAlert] = useState({
        isOpen: false,
        title: '',
        message: '',
        type: 'error'
    });
    const isNonRenewing = subscription?.status === 'non-renewing';
    const isMomo = subscription?.provider === 'momo';

    const handleError = (message) => {
        setAlert({
            isOpen: true,
            title: 'Error',
            message,
            type: 'error'
        });
    };

    const handleChangePlan = async () => {
        setIsLoading(true);
        try {
            const functions = getFunctions();
            const generateManagementLink = httpsCallable(functions, 'generateSubscriptionManagementLink');

            const result = await generateManagementLink({ storeId });

            if (result.data.success) {
                const popup = window.open(
                    result.data.data.managementUrl,
                    'Subscription Management',
                    'width=1000,height=800,left=200,top=100'
                );

                if (!popup || popup.closed || typeof popup.closed === 'undefined') {
                    throw new Error('Popup blocked by browser');
                }

                const checkPopupClosure = setInterval(() => {
                    if (popup.closed) {
                        clearInterval(checkPopupClosure);
                        window.location.reload();
                    }
                }, 500);
            } else {
                throw new Error('Failed to generate management link');
            }
        } catch (error) {
            console.error('Error generating management link:', error);
            handleError(error.message === 'Popup blocked by browser'
                ? 'Please allow popups to access subscription management.'
                : 'Could not access subscription management. Please try again later.');
        } finally {
            setIsLoading(false);
        }
    };

    const formatDate = (dateString) => {
        if (!dateString) return '';
        const date = new Date(dateString);
        return date.toLocaleDateString('en-GB', {
            day: '2-digit',
            month: 'short',
            year: 'numeric'
        });
    };

    const formatCurrency = (amount) => {
        return new Intl.NumberFormat('en-GH', {
            style: 'currency',
            currency: 'GHS',
            minimumFractionDigits: 2
        }).format(amount);
    };

    const handleMomoCancel = async () => {
        setIsCancellingMomo(true);
        try {
            const functions = getFunctions();
            const cancelMomoSubscription = httpsCallable(functions, 'cancelMomoSubscription');

            const response = await cancelMomoSubscription({
                storeId,
                cancelReason: 'User requested cancellation'
            });

            if (response.data?.status) {
                setAlert({
                    isOpen: true,
                    title: 'Success',
                    message: 'Your subscription has been cancelled.',
                    type: 'success'
                });
                setShowMomoCancelModal(false);
                // Refresh the page to show updated subscription status
                window.location.reload();
            } else {
                throw new Error('Failed to cancel subscription');
            }
        } catch (error) {
            console.error('Error cancelling MoMo subscription:', error);
            setAlert({
                isOpen: true,
                title: 'Error',
                message: error.message || 'Failed to cancel subscription. Please try again.',
                type: 'error'
            });
        } finally {
            setIsCancellingMomo(false);
        }
    };

    const nextBillingDate = formatDate(subscription?.nextBillingDate);
    const startDate = formatDate(subscription?.startDate);

    const visibleHistory = showFullHistory
        ? subscription?.billingHistory || []
        : subscription?.billingHistory?.slice(0, 5) || [];

    const formattedHistory = visibleHistory.map(bill => ({
        ...bill,
        date: formatDate(bill.date)
    }));

    const handleExtendSubscription = () => {
        // Redirect to the subscription plans page with momo selected
        // window.location.href = '/settings?section=subscription&action=extend';
        showToast('Functionality coming soon', 'info');
    };

    return (
        <div className="bg-white">
            {/* Current Plan Summary */}
            <div className="border-b pb-8">
                <div className="flex justify-between items-center mb-6">
                    <div className="flex items-center gap-3">
                        {isNonRenewing ? (
                            <div className="flex items-center gap-2 text-sm bg-amber-50 text-amber-700 px-3 py-1 rounded-full">
                                <AlertTriangle className="w-4 h-4" />
                                Cancelled
                            </div>
                        ) : (
                            <span className="text-sm text-green-600 bg-green-50 px-3 py-1 rounded-full">
                                Active Subscription
                            </span>
                        )}
                    </div>

                    {isMomo ? (
                        <button
                            onClick={handleExtendSubscription}
                            className="text-sm text-gray-600 hover:text-gray-900 flex items-center gap-2"
                        >
                            Extend Subscription
                            <ArrowRight className="w-4 h-4" />
                        </button>
                    ) : (
                        <button
                            onClick={handleChangePlan}
                            disabled={isLoading}
                            className={`text-sm text-gray-600 hover:text-gray-900 flex items-center gap-2 ${isLoading ? 'opacity-50 cursor-not-allowed' : ''}`}
                        >
                            {isLoading ? (
                                <>
                                    <Loader2 className="animate-spin w-4 h-4" />
                                    Redirecting...
                                </>
                            ) : (
                                <>
                                    Manage Subscription
                                    <ArrowRight className="w-4 h-4" />
                                </>
                            )}
                        </button>
                    )}
                </div>

                <div className="space-y-3">
                    <div className="flex items-baseline gap-2">
                        <h1 className="text-xl font-semibold text-gray-900">{subscription?.planName} Plan</h1>
                    </div>

                    {isMomo && (
                        <div className="bg-blue-50 border border-blue-200 rounded-lg p-4 mt-4">
                            <div className="flex gap-3">
                                <Clock className="w-5 h-5 text-blue-600 flex-shrink-0 mt-0.5" />
                                <div className="space-y-1">
                                    <h3 className="font-medium text-blue-800">Manual Renewal Required</h3>
                                    <p className="text-sm text-blue-700">
                                        Your subscription will need to be manually renewed by {nextBillingDate}. You'll receive a notification when renewal is due. To continue using our services after this date, simply make another Mobile Money payment.
                                    </p>
                                </div>
                            </div>
                        </div>
                    )}

                    {isNonRenewing && (
                        <div className="bg-amber-50 border border-amber-200 rounded-lg p-4">
                            <div className="flex gap-3">
                                <AlertTriangle className="w-5 h-5 text-amber-600 flex-shrink-0 mt-0.5" />
                                <div className="space-y-1">
                                    <h3 className="font-medium text-amber-800">Subscription Ending</h3>
                                    <p className="text-sm text-amber-700">
                                        Your subscription will end on {nextBillingDate}. To continue using our services after this date, please renew your subscription.
                                    </p>
                                </div>
                            </div>
                        </div>
                    )}
                </div>
            </div>

            {/* Quick Stats */}
            <div className="grid md:grid-cols-3 py-8 gap-8 border-b">
                <div>
                    <h3 className="text-sm font-medium text-gray-500 mb-1">
                        {isMomo ? 'Next Payment Due' : 'Next Payment'}
                    </h3>
                    <p className="text-lg text-gray-900">{nextBillingDate}</p>
                </div>
                <div>
                    <h3 className="text-sm font-medium text-gray-500 mb-1">Payment Method</h3>
                    <p className="text-lg text-gray-900">
                        {isMomo ? 'Mobile Money' : subscription?.paymentMethod}
                    </p>
                </div>
                <div>
                    <h3 className="text-sm font-medium text-gray-500 mb-1">Billing Period</h3>
                    <p className="text-lg text-gray-900">{subscription?.planDetails?.interval || '-'}</p>
                </div>
            </div>

            {/* Side by Side Layout */}
            <div className="grid md:grid-cols-2 gap-8">
                {/* Plan Features */}
                <div className="py-8">
                    <h2 className="text-lg font-medium text-gray-900 mb-4">Plan Features</h2>
                    <div className="space-y-4">
                        {subscription?.features?.map((feature, index) => (
                            <div key={index} className="flex items-center gap-2">
                                <Check className="w-5 h-5 text-green-500" />
                                <span className="text-gray-600">{feature}</span>
                            </div>
                        ))}
                    </div>
                </div>

                {/* Payment History */}
                <div className="py-8">
                    <div className="flex justify-between items-center mb-4">
                        <h2 className="text-lg font-medium text-gray-900">Payment History</h2>
                        {formattedHistory.length > 5 && (
                            <button
                                onClick={() => setShowFullHistory(!showFullHistory)}
                                className="text-sm text-gray-600 hover:text-gray-900 flex items-center gap-1"
                            >
                                {showFullHistory ? (
                                    <>
                                        Show Less
                                        <ChevronUp className="w-4 h-4" />
                                    </>
                                ) : (
                                    <>
                                        Show All
                                        <ChevronDown className="w-4 h-4" />
                                    </>
                                )}
                            </button>
                        )}
                    </div>
                    <div className="w-full">
                        <table className="min-w-full divide-y divide-gray-200">
                            <thead>
                                <tr>
                                    <th className="text-left text-sm font-medium text-gray-500 py-2">Date</th>
                                    <th className="text-left text-sm font-medium text-gray-500 py-2">Total</th>
                                    <th className="text-left text-sm font-medium text-gray-500 py-2">Status</th>
                                </tr>
                            </thead>
                            <tbody className="divide-y divide-gray-200">
                                {formattedHistory.map((bill, index) => (
                                    <tr key={index}>
                                        <td className="py-3 text-sm text-gray-900">{bill.date}</td>
                                        <td className="py-3 text-sm text-gray-900">{formatCurrency(bill.total)}</td>
                                        <td className="py-3">
                                            <span className="text-sm text-green-600">
                                                {bill.status}
                                            </span>
                                        </td>
                                    </tr>
                                ))}
                                {formattedHistory.length === 0 && (
                                    <tr>
                                        <td colSpan={3} className="py-4 text-center text-sm text-gray-500">
                                            No payment history available
                                        </td>
                                    </tr>
                                )}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>

            {/* Actions - Only show for non-momo subscriptions */}
            {/* {!isMomo && (
                <div className="py-8 flex justify-between items-center border-t">
                    <button
                        onClick={() => setShowCancelModal(true)}
                        className="text-sm text-red-600 hover:text-red-700"
                    >
                        Cancel subscription
                    </button>
                </div>
            )} */}

            <div className="py-8 flex justify-between items-center border-t">
                <button
                    onClick={() => isMomo ? setShowMomoCancelModal(true) : setShowCancelModal(true)}
                    className="text-sm text-red-600 hover:text-red-700"
                >
                    Cancel subscription
                </button>
            </div>

            {/* Cancel Modal - Only for non-momo subscriptions */}
            {isMomo ? (
                <MomoCancelModal
                    isOpen={showMomoCancelModal}
                    onClose={() => setShowMomoCancelModal(false)}
                    onConfirm={handleMomoCancel}
                    isLoading={isCancellingMomo}
                />
            ) : (
                <CancelSubscriptionModal
                    isOpen={showCancelModal}
                    onClose={() => setShowCancelModal(false)}
                    storeId={storeId}
                    onError={handleError}
                />
            )}

            {/* Alert Component */}
            <Alert
                isOpen={alert.isOpen}
                onClose={() => setAlert({ ...alert, isOpen: false })}
                title={alert.title}
                message={alert.message}
                type={alert.type}
            />
            <Toast />
        </div>
    );
};

export default SubscriptionDetailsSection;