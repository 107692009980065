import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { getFirestore, collection, query, getDocs, orderBy, Timestamp, doc, updateDoc, where, limit } from 'firebase/firestore';
import { DollarSign, AlertCircle, Folders } from 'lucide-react';
import { getAuth } from 'firebase/auth';
import ExpenseRow from '../expenses_row';
import RecurringExpenses from '../expenses_recurring';

const ExpensesOverview = ({ timeRange }) => {
    const [expenses, setExpenses] = useState([]);
    const [categories, setCategories] = useState([]);
    const [recurringExpenses, setRecurringExpenses] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [stats, setStats] = useState({
        paidExpenses: 0,
        totalUnpaidExpenses: 0,
        numberOfCategories: 0
    });

    const db = getFirestore();
    const storeId = localStorage.getItem('storeId');

    const fetchData = async () => {
        try {
            setLoading(true);
            const startTimestamp = Timestamp.fromDate(timeRange.start);
            const endTimestamp = Timestamp.fromDate(timeRange.end);

            const [expensesSnapshot, recurringExpensesSnapshot, categoriesSnapshot] = await Promise.all([
                getDocs(query(
                    collection(db, 'Stores', storeId, 'Expenses'),
                    where('date', '>=', startTimestamp),
                    where('date', '<=', endTimestamp),
                    orderBy('date', 'desc')
                )),
                getDocs(query(
                    collection(db, 'Stores', storeId, 'RecurringExpenses'),
                    orderBy('nextDueDate', 'desc'),
                    limit(4)
                )),
                getDocs(collection(db, 'Stores', storeId, 'ExpenseCategories'))
            ]);

            const expensesData = expensesSnapshot.docs.map(doc => ({
                id: doc.id,
                ...doc.data(),
                amount: Number(doc.data().amount) || 0,
                date: doc.data().date?.toDate?.() || null,
                paidDate: doc.data().paidDate?.toDate?.() || null,
                dueDate: doc.data().dueDate?.toDate?.() || null
            }));

            const recurringExpensesData = recurringExpensesSnapshot.docs.map(doc => ({
                id: doc.id,
                ...doc.data(),
                amount: Number(doc.data().amount) || 0,
                nextDueDate: doc.data().nextDueDate?.toDate?.() || null,
                date: doc.data().date?.toDate?.() || null,
                lastBilledDate: doc.data().lastBilledDate?.toDate?.() || null
            }));

            const categoriesData = categoriesSnapshot.docs.map(doc => ({
                id: doc.id,
                ...doc.data(),
            }));

            setExpenses(expensesData);
            setRecurringExpenses(recurringExpensesData);
            setCategories(categoriesData);
            setStats({
                paidExpenses: expensesData.filter(exp => exp.paid).reduce((sum, exp) => sum + exp.amount, 0),
                totalUnpaidExpenses: expensesData.filter(exp => !exp.paid).reduce((sum, exp) => sum + exp.amount, 0),
                numberOfCategories: categoriesData.length
            });
        } catch (err) {
            console.error('Error fetching data:', err);
            setError('Failed to load data');
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchData();
    }, [timeRange]);

    const updateExpensePaymentStatus = async (expenseId, isPaid) => {
        const originalExpenses = [...expenses];
        const auth = getAuth();
        const expenseToUpdate = expenses.find(exp => exp.id === expenseId);

        if (!expenseToUpdate) throw new Error('Expense not found');

        try {
            setExpenses(prevExpenses =>
                prevExpenses.map(exp =>
                    exp.id === expenseId
                        ? { ...exp, paid: isPaid, paidDate: isPaid ? new Date() : null }
                        : exp
                )
            );

            setStats(prev => ({
                ...prev,
                paidExpenses: isPaid
                    ? prev.paidExpenses + expenseToUpdate.amount
                    : prev.paidExpenses - expenseToUpdate.amount,
                totalUnpaidExpenses: isPaid
                    ? prev.totalUnpaidExpenses - expenseToUpdate.amount
                    : prev.totalUnpaidExpenses + expenseToUpdate.amount
            }));

            const expenseRef = doc(db, 'Stores', storeId, 'Expenses', expenseId);
            await updateDoc(expenseRef, {
                paid: isPaid,
                paidDate: isPaid ? Timestamp.now() : null,
                updatedAt: Timestamp.now(),
                updatedBy: auth.currentUser?.uid || 'unknown'
            });
        } catch (error) {
            setExpenses(originalExpenses);
            throw error;
        }
    };

    if (loading) {
        return <div>Loading...</div>;
    }

    return (
        <div className="space-y-8">
            {/* Stats Cards */}
            <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
                <Link
                    to="/expenses/list?paid=true"
                    className="bg-white border p-6 hover:ring-2 hover:ring-orange-600 hover:cursor-pointer"
                >
                    <div className="flex items-center justify-between mb-2">
                        <h3 className="text-sm text-gray-500">Paid Expenses</h3>
                        <DollarSign className="text-emerald-500 h-6 w-6" />
                    </div>
                    <p className="text-2xl font-bold text-gray-900">
                        <span className="mr-1 text-gray-800">GHS</span>
                        {stats.paidExpenses.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}

                    </p>
                    <p className="text-sm text-gray-500 mt-2">Including recurring expenses</p>
                </Link>

                <Link
                    to="/expenses/list?paid=false"
                    className="bg-white border p-6 hover:ring-2 hover:ring-orange-600 hover:cursor-pointer"
                >
                    <div className="flex items-center justify-between mb-2">
                        <h3 className="text-sm text-gray-500">Unpaid Expenses</h3>
                        <AlertCircle className="text-amber-500 h-6 w-6" />
                    </div>
                    <p className="text-2xl font-bold text-gray-900">
                        <span className="mr-1 text-gray-800">GHS</span>
                        {stats.totalUnpaidExpenses.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}
                    </p>
                    <p className="text-sm text-gray-500 mt-2">Including recurring expenses</p>
                </Link>

                <Link
                    to="/expenses/categories"
                    className="bg-white border p-6 hover:ring-2 hover:ring-orange-600 hover:cursor-pointer"
                >
                    <div className="flex items-center justify-between mb-2">
                        <h3 className="text-sm text-gray-500">Categories</h3>
                        <Folders className="text-purple-500 h-6 w-6" />
                    </div>
                    <p className="text-2xl font-bold text-gray-900">{stats.numberOfCategories}</p>
                    <p className="text-sm text-gray-500 mt-2">Total expense categories</p>
                </Link>
            </div>

            {/* Recent Expenses Table */}
            <div>
                <div className="flex justify-between items-center mb-4">
                    <h2 className="text-xl font-semibold">Recent Single Expenses</h2>
                    <Link to="/expenses/list" className="text-sm text-blue-600 hover:text-blue-700">View All</Link>
                </div>
                <div className="border p-5">
                    <div className="overflow-x-auto">
                        <table className="w-full">
                            <thead>
                                <tr className="bg-gray-100">
                                    <th className="px-6 py-4 text-left text-sm font-medium text-gray-500">Date</th>
                                    <th className="px-6 py-4 text-left text-sm font-medium text-gray-500">Description</th>
                                    <th className="px-6 py-4 text-left text-sm font-medium text-gray-500">Category</th>
                                    <th className="px-6 py-4 text-left text-sm font-medium text-gray-500">Amount</th>
                                    <th className="px-6 py-4 text-left text-sm font-medium text-gray-500">Status</th>
                                    <th className="px-6 py-4 text-left text-sm font-medium text-gray-500">Paid Date</th>
                                </tr>
                            </thead>
                            <tbody className="divide-y">
                                {expenses.slice(0, 5).map((expense) => (
                                    <ExpenseRow
                                        key={expense.id}
                                        expense={expense}
                                        onUpdatePaymentStatus={updateExpensePaymentStatus}
                                        loading={loading}
                                    />
                                ))}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>

            <RecurringExpenses expenses={recurringExpenses} />
        </div>
    );
};

export default ExpensesOverview;