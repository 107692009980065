import jsPDF from 'jspdf';
import 'jspdf-autotable';

export const generateSalesPDF = (sale) => {
    const pdf = new jsPDF();
    const pageWidth = pdf.internal.pageSize.width;

    pdf.setFontSize(20);
    pdf.text('Sales Receipt', pageWidth / 2, 20, { align: 'center' });

    pdf.setFontSize(12);
    pdf.text(`Receipt No: ${sale.orderNumber || `ORD${String(parseInt(sale.id.slice(-6), 16)).padStart(6, '0')}`}`, 20, 40);
    pdf.text(`Date: ${sale.saleDate.toLocaleString()}`, 20, 50);

    pdf.autoTable({
        startY: 60,
        head: [[
            'Item',
            'Quantity',
            'Unit Price (GHS)',
            'Total (GHS)'
        ]],
        body: sale.items.map(item => [
            item.itemName,
            item.quantity,
            item.price.toFixed(2),
            (item.price * item.quantity).toFixed(2)
        ]),
        theme: 'striped',
        headStyles: { fillColor: [66, 66, 66] },
        margin: { top: 60, right: 20, bottom: 40, left: 20 }
    });

    const finalY = pdf.previousAutoTable.finalY + 10;
    pdf.text(`Total Amount: GHS ${sale.totalAmount.toFixed(2)}`, pageWidth - 30, finalY, { align: 'right' });

    return pdf;
};