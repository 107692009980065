import React, { useState, useEffect, useRef, useCallback } from 'react';
import { getFirestore, collection, query, getDocs, orderBy, Timestamp, doc, updateDoc, limit, deleteDoc, startAfter, where, writeBatch } from 'firebase/firestore';
import { useSearchParams } from 'react-router-dom';
import { getAuth } from 'firebase/auth';
import { Loader2, Clock, CheckCircle, XCircle, Filter, PlusCircle } from 'lucide-react';
import { Link } from 'react-router-dom';
import ExpenseRow from '../expenses_row';
import AddExpenseModal from '../expenses/add_expense';
import RecurringExpenseModal from '../recurring_expense_modal';

const EXPENSES_PER_PAGE = 20;

const ExpensesList = ({ timeRange }) => {
    const [searchParams] = useSearchParams();
    const paidFilter = searchParams.get('paid');
    const [expenses, setExpenses] = useState([]);
    const [recurringExpenses, setRecurringExpenses] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [hasMore, setHasMore] = useState(true);
    const [lastDoc, setLastDoc] = useState(null);
    const [activeTab, setActiveTab] = useState('single');
    const [selectedRecurringExpense, setSelectedRecurringExpense] = useState(null);
    const [markingAsPaid, setMarkingAsPaid] = useState(false);
    const [deleting, setDeleting] = useState(false);
    const [isAddExpenseModalOpen, setIsAddExpenseModalOpen] = useState(false);
    const observer = useRef();

    const db = getFirestore();
    const auth = getAuth();
    const storeId = localStorage.getItem('storeId');

    const lastExpenseElementRef = useCallback(node => {
        if (loading) return;
        if (observer.current) observer.current.disconnect();
        observer.current = new IntersectionObserver(entries => {
            if (entries[0].isIntersecting && hasMore) {
                fetchMoreData();
            }
        });
        if (node) observer.current.observe(node);
    }, [loading, hasMore]);

    const fetchData = async (isInitial = true, startDate = timeRange.start, endDate = timeRange.end) => {
        try {
            setLoading(true);
            const expensesRef = collection(db, 'Stores', storeId, 'Expenses');
            let expensesQuery = query(
                expensesRef,
                where('date', '>=', Timestamp.fromDate(startDate)),
                where('date', '<=', Timestamp.fromDate(endDate)),
                orderBy('date', 'desc'),
                orderBy('createdAt', 'desc'),
                limit(EXPENSES_PER_PAGE)
            );

            if (!isInitial && lastDoc) {
                expensesQuery = query(
                    expensesRef,
                    where('date', '>=', Timestamp.fromDate(startDate)),
                    where('date', '<=', Timestamp.fromDate(endDate)),
                    orderBy('date', 'desc'),
                    orderBy('createdAt', 'desc'),
                    startAfter(lastDoc),
                    limit(EXPENSES_PER_PAGE)
                );
            }

            const [expensesSnapshot, recurringExpensesSnapshot] = await Promise.all([
                getDocs(expensesQuery),
                isInitial ? getDocs(query(
                    collection(db, 'Stores', storeId, 'RecurringExpenses'),
                    orderBy('createdAt', 'desc'),
                    limit(4)
                )) : null
            ]);

            const expensesData = expensesSnapshot.docs.map(doc => ({
                id: doc.id,
                ...doc.data(),
                amount: Number(doc.data().amount) || 0,
                date: doc.data().date?.toDate?.() || null,
                paidDate: doc.data().paidDate?.toDate?.() || null,
                dueDate: doc.data().dueDate?.toDate?.() || null,
                createdAt: doc.data().createdAt?.toDate?.() || null
            }));

            if (isInitial) {
                const recurringExpensesData = recurringExpensesSnapshot.docs.map(doc => ({
                    id: doc.id,
                    ...doc.data(),
                    amount: Number(doc.data().amount) || 0,
                    nextDueDate: doc.data().nextDueDate?.toDate?.() || null,
                    date: doc.data().date?.toDate?.() || null,
                    lastBilledDate: doc.data().lastBilledDate?.toDate?.() || null,
                    createdAt: doc.data().createdAt?.toDate?.() || null
                }));
                setRecurringExpenses(recurringExpensesData);
            }

            if (isInitial) {
                setExpenses(expensesData);
            } else {
                setExpenses(prev => [...prev, ...expensesData]);
            }

            setLastDoc(expensesSnapshot.docs[expensesSnapshot.docs.length - 1]);
            setHasMore(expensesSnapshot.docs.length === EXPENSES_PER_PAGE);
        } catch (err) {
            console.error('Error fetching expenses:', err);
            setError('Failed to load expenses');
        } finally {
            setLoading(false);
        }
    };

    const fetchMoreData = () => {
        if (!loading && hasMore) {
            fetchData(false);
        }
    };

    useEffect(() => {
        setLastDoc(null);
        setHasMore(true);
        setExpenses([]);
        fetchData(true, timeRange.start, timeRange.end);
    }, [timeRange]);

    // Keep all the existing functions (handleDeleteRecurringExpense, handleDeleteExpense, 
    // handleMarkRecurringAsPaid, updateExpensePaymentStatus, getPaymentStatus, getDueStatus, getFilteredExpenses)
    // [All functions from the original code remain exactly the same]

    const handleDeleteRecurringExpense = async (expenseId) => {
        try {
            setDeleting(true);
            await deleteDoc(doc(db, 'Stores', storeId, 'RecurringExpenses', expenseId));
            setRecurringExpenses(currentExpenses => currentExpenses.filter(exp => exp.id !== expenseId));
            setSelectedRecurringExpense(null);
        } catch (error) {
            console.error('Error deleting recurring expense:', error);
        } finally {
            setDeleting(false);
        }
    };

    const handleDeleteExpense = async (expenseId) => {
        try {
            setDeleting(true);
            await deleteDoc(doc(db, 'Stores', storeId, 'Expenses', expenseId));
            setExpenses(currentExpenses => currentExpenses.filter(exp => exp.id !== expenseId));
        } catch (error) {
            console.error('Error deleting expense:', error);
        } finally {
            setDeleting(false);
        }
    };

    const handleMarkRecurringAsPaid = async (expenseId) => {
        const expenseToUpdate = recurringExpenses.find(exp => exp.id === expenseId);
        if (!expenseToUpdate) return;

        try {
            setMarkingAsPaid(true);
            const batch = writeBatch(db);

            const recurringExpenseRef = doc(db, 'Stores', storeId, 'RecurringExpenses', expenseId);
            batch.update(recurringExpenseRef, {
                lastBilledDate: Timestamp.now(),
                paid: true,
                paidDate: Timestamp.now(),
                updatedAt: Timestamp.now(),
                updatedBy: auth.currentUser?.uid || 'unknown'
            });

            const singleExpenseRef = doc(collection(db, 'Stores', storeId, 'Expenses'));
            const singleExpenseData = {
                description: expenseToUpdate.description,
                amount: expenseToUpdate.amount,
                category: expenseToUpdate.category || 'Uncategorized',
                date: Timestamp.now(),
                paid: true,
                paidDate: Timestamp.now(),
                dueDate: Timestamp.fromDate(expenseToUpdate.nextDueDate),
                notes: `Recurring expense payment - ${expenseToUpdate.recurringPeriod}`,
                createdAt: Timestamp.now(),
                createdBy: auth.currentUser?.uid || 'unknown',
                updatedAt: Timestamp.now(),
                updatedBy: auth.currentUser?.uid || 'unknown',
                recurringExpenseId: expenseId
            };
            batch.set(singleExpenseRef, singleExpenseData);

            await batch.commit();

            setRecurringExpenses(currentExpenses =>
                currentExpenses.map(exp =>
                    exp.id === expenseId
                        ? {
                            ...exp,
                            lastBilledDate: new Date(),
                            paid: true,
                            paidDate: new Date()
                        }
                        : exp
                )
            );

            const singleExpense = {
                id: singleExpenseRef.id,
                ...singleExpenseData,
                date: new Date(),
                paidDate: new Date(),
                dueDate: expenseToUpdate.nextDueDate
            };

            if (timeRange &&
                singleExpense.date >= timeRange.start &&
                singleExpense.date <= timeRange.end) {
                setExpenses(currentExpenses => [singleExpense, ...currentExpenses]);
            }

            setSelectedRecurringExpense(null);
        } catch (error) {
            console.error('Error marking recurring expense as paid:', error);
        } finally {
            setMarkingAsPaid(false);
        }
    };

    const updateExpensePaymentStatus = async (expenseId, isPaid) => {
        const originalExpenses = [...expenses];
        const expenseToUpdate = expenses.find(exp => exp.id === expenseId);

        if (!expenseToUpdate) throw new Error('Expense not found');

        try {
            setExpenses(currentExpenses =>
                currentExpenses.map(exp =>
                    exp.id === expenseId
                        ? { ...exp, paid: isPaid, paidDate: isPaid ? new Date() : null }
                        : exp
                )
            );

            const expenseRef = doc(db, 'Stores', storeId, 'Expenses', expenseId);
            await updateDoc(expenseRef, {
                paid: isPaid,
                paidDate: isPaid ? Timestamp.now() : null,
                updatedAt: Timestamp.now(),
                updatedBy: auth.currentUser?.uid || 'unknown'
            });
        } catch (error) {
            setExpenses(originalExpenses);
            throw error;
        }
    };

    const getPaymentStatus = (expense) => {
        if (!expense.nextDueDate) return { isPaid: false, text: 'No due date', color: 'gray' };

        const today = new Date();
        const nextDue = new Date(expense.nextDueDate);
        const lastBilled = expense.lastBilledDate ? new Date(expense.lastBilledDate) : null;
        const currentPeriodStart = new Date(nextDue);

        // Calculate current period start based on recurring period
        if (expense.recurringPeriod === 'monthly') {
            currentPeriodStart.setMonth(currentPeriodStart.getMonth() - 1);
        } else if (expense.recurringPeriod === 'weekly') {
            currentPeriodStart.setDate(currentPeriodStart.getDate() - 7);
        } else if (expense.recurringPeriod === 'yearly') {
            currentPeriodStart.setFullYear(currentPeriodStart.getFullYear() - 1);
        } else if (expense.recurringPeriod === 'quarterly') {
            currentPeriodStart.setMonth(currentPeriodStart.getMonth() - 3);
        } else if (expense.recurringPeriod === 'custom' && expense.customPeriod) {
            const { value, unit } = expense.customPeriod;
            if (unit === 'days') {
                currentPeriodStart.setDate(currentPeriodStart.getDate() - value);
            } else if (unit === 'weeks') {
                currentPeriodStart.setDate(currentPeriodStart.getDate() - (value * 7));
            } else if (unit === 'months') {
                currentPeriodStart.setMonth(currentPeriodStart.getMonth() - value);
            }
        }

        const isPaidForCurrentPeriod = expense.paid && lastBilled &&
            lastBilled >= currentPeriodStart && lastBilled <= nextDue;

        if (isPaidForCurrentPeriod) {
            return {
                isPaid: true,
                text: 'Paid for current period',
                color: 'emerald',
                lastPaymentDate: lastBilled
            };
        }

        if (today > nextDue) {
            return {
                isPaid: false,
                text: 'Payment overdue',
                color: 'red',
                lastPaymentDate: lastBilled
            };
        }

        if (today >= currentPeriodStart && today <= nextDue) {
            return {
                isPaid: false,
                text: 'Payment due',
                color: 'amber',
                lastPaymentDate: lastBilled
            };
        }

        return {
            isPaid: true,
            text: 'Not yet due',
            color: 'gray',
            lastPaymentDate: lastBilled
        };
    };

    const getDueStatus = (nextDueDate) => {
        if (!nextDueDate) return { text: 'Not set', color: 'gray', isOverdue: false };

        const today = new Date();
        const due = nextDueDate instanceof Date ? nextDueDate : new Date(nextDueDate);

        if (isNaN(due.getTime())) {
            return { text: 'Invalid date', color: 'gray', isOverdue: false };
        }

        const daysUntilDue = Math.ceil((due - today) / (1000 * 60 * 60 * 24));

        if (daysUntilDue < 0) return { text: `${Math.abs(daysUntilDue)} days overdue`, color: 'red', isOverdue: true };
        if (daysUntilDue === 0) return { text: 'Due today', color: 'orange', isOverdue: false };
        return { text: `Renews in ${daysUntilDue} days`, color: 'blue', isOverdue: false };
    };

    const getFilteredExpenses = () => {
        if (paidFilter === 'true') return expenses.filter(expense => expense.paid);
        if (paidFilter === 'false') return expenses.filter(expense => !expense.paid);
        return expenses;
    };

    if (loading && expenses.length === 0) {
        return (
            <div className="flex items-center justify-center p-8">
                <div className="flex flex-col items-center gap-3">
                    <Loader2 className="h-8 w-8 animate-spin text-gray-600" />
                    <div className="text-lg">Loading expenses...</div>
                </div>
            </div>
        );
    }

    return (
        <div className="relative">
            <div className="absolute top-0 right-0 flex justify-end items-center">
                <div className="flex space-x-2">
                    {activeTab === 'single' && (
                        <>
                            {paidFilter && (
                                <div className="flex items-center">
                                    <Link
                                        to="/expenses"
                                        className="ml-2 text-gray-500 hover:text-gray-700 text-sm"
                                    >
                                        Clear filter
                                    </Link>
                                </div>
                            )}

                            <div className="flex space-x-2">
                                <Link
                                    to="/expenses?paid=true"
                                    className={`px-3 py-2 rounded-lg text-sm font-medium transition-colors ${paidFilter === 'true'
                                        ? 'bg-green-100 text-green-700'
                                        : 'hover:bg-gray-100 text-gray-600'
                                        }`}
                                >
                                    Paid
                                </Link>
                                <Link
                                    to="/expenses?paid=false"
                                    className={`px-3 py-2 rounded-lg text-sm font-medium transition-colors ${paidFilter === 'false'
                                        ? 'bg-red-100 text-red-700'
                                        : 'hover:bg-gray-100 text-gray-600'
                                        }`}
                                >
                                    Unpaid
                                </Link>
                            </div>
                        </>
                    )}
                </div>
            </div>

            <div className="w-full">
                <div className="border-b border-gray-200">
                    <nav className="flex -mb-px space-x-8" aria-label="Tabs">
                        <button
                            onClick={() => setActiveTab('single')}
                            className={`py-4 px-1 text-sm font-medium border-b-2 transition-colors duration-200 whitespace-nowrap ${activeTab === 'single'
                                ? 'border-blue-500 text-blue-600'
                                : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300'
                                }`}
                        >
                            Single Expenses
                        </button>
                        <button
                            onClick={() => setActiveTab('recurring')}
                            className={`py-4 px-1 text-sm font-medium border-b-2 transition-colors duration-200 whitespace-nowrap ${activeTab === 'recurring'
                                ? 'border-blue-500 text-blue-600'
                                : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300'
                                }`}
                        >
                            Recurring Expenses
                        </button>
                    </nav>
                </div>

                {/* Single Expenses Table */}
                <div className={`border p-5 ${activeTab === 'single' ? 'block' : 'hidden'}`}>
                    <div className="overflow-x-auto">
                        <table className="w-full">
                            <thead>
                                <tr className="bg-gray-100">
                                    <th className="px-6 py-4 text-left text-sm font-medium text-gray-500">Description</th>
                                    <th className="px-6 py-4 text-left text-sm font-medium text-gray-500">Category</th>
                                    <th className="px-6 py-4 text-left text-sm font-medium text-gray-500">Date</th>
                                    <th className="px-6 py-4 text-left text-sm font-medium text-gray-500">Amount</th>
                                    <th className="px-6 py-4 text-left text-sm font-medium text-gray-500">Status</th>
                                    <th className="px-6 py-4 text-left text-sm font-medium text-gray-500">Paid Date</th>
                                </tr>
                            </thead>
                            <tbody className="divide-y">
                                {getFilteredExpenses().map((expense, index) => (
                                    <ExpenseRow
                                        key={expense.id}
                                        expense={expense}
                                        onUpdatePaymentStatus={updateExpensePaymentStatus}
                                        onDelete={handleDeleteExpense}
                                        loading={loading}
                                        deleting={deleting}
                                        ref={index === getFilteredExpenses().length - 1 ? lastExpenseElementRef : null}
                                    />
                                ))}
                            </tbody>
                        </table>
                        {loading && expenses.length > 0 && (
                            <div className="flex justify-center p-4">
                                <Loader2 className="h-6 w-6 animate-spin text-gray-600" />
                            </div>
                        )}
                    </div>
                </div>

                {/* Recurring Expenses Table */}
                <div className={`border p-5 ${activeTab === 'recurring' ? 'block' : 'hidden'}`}>
                    <div className="mb-4 p-4 bg-blue-50 border border-blue-200 rounded-md">
                        <p className="text-sm text-blue-700 flex items-center">
                            <svg className="w-4 h-4 mr-2" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                            </svg>
                            Note: When recurring expenses are paid, they will appear as individual entries in the Single Expenses table
                        </p>
                    </div>
                    <div className="overflow-x-auto">
                        <table className="w-full">
                            <thead>
                                <tr className="bg-gray-100">
                                    <th className="px-6 py-4 text-left text-sm font-medium text-gray-500">Description</th>
                                    <th className="px-6 py-4 text-left text-sm font-medium text-gray-500">Amount</th>
                                    <th className="px-6 py-4 text-left text-sm font-medium text-gray-500">Frequency</th>
                                    <th className="px-6 py-4 text-left text-sm font-medium text-gray-500">Next Due</th>
                                    <th className="px-6 py-4 text-left text-sm font-medium text-gray-500">Status</th>
                                    <th className="px-6 py-4 text-left text-sm font-medium text-gray-500">Last Payment</th>
                                </tr>
                            </thead>
                            <tbody className="divide-y">
                                {recurringExpenses.map((expense) => {
                                    const paymentStatus = getPaymentStatus(expense);
                                    const dueStatus = getDueStatus(expense.nextDueDate);
                                    return (
                                        <tr
                                            key={expense.id}
                                            className="hover:bg-gray-50 cursor-pointer"
                                            onClick={() => setSelectedRecurringExpense(expense)}
                                        >
                                            <td className="px-6 py-3">
                                                <div className="font-normal text-sm">{expense.description}</div>
                                            </td>
                                            <td className="px-6 py-2">
                                                <span className="font-medium">
                                                    <span className="text-xs mr-1 text-gray-500">GHS</span>
                                                    <span className="text-sm mr-1">{expense.amount.toFixed(2)}</span>
                                                </span>
                                            </td>
                                            <td className="px-6 py-2">
                                                <span className="text-sm text-gray-600 capitalize">
                                                    {expense.recurringPeriod === 'custom'
                                                        ? `Every ${expense.customPeriod.value} ${expense.customPeriod.unit}`
                                                        : expense.recurringPeriod}
                                                </span>
                                            </td>
                                            <td className="px-6 py-2">
                                                <div className={`flex items-center gap-1 text-sm ${dueStatus.color === 'red' ? 'text-red-600' :
                                                    dueStatus.color === 'orange' ? 'text-orange-600' :
                                                        dueStatus.color === 'blue' ? 'text-blue-600' :
                                                            'text-gray-600'
                                                    }`}>
                                                    <Clock className="w-4 h-4" />
                                                    <span>{dueStatus.text}</span>
                                                </div>
                                            </td>
                                            <td className="px-6 py-2">
                                                <span className={`flex items-center gap-1 text-xs px-2 py-0.5 rounded-full w-fit ${paymentStatus.color === 'emerald' ? 'text-emerald-600 bg-emerald-50' :
                                                    paymentStatus.color === 'red' ? 'text-red-600 bg-red-50' :
                                                        paymentStatus.color === 'amber' ? 'text-amber-600 bg-amber-50' :
                                                            'text-gray-600 bg-gray-50'
                                                    }`}>
                                                    {paymentStatus.isPaid ? (
                                                        <CheckCircle className="w-3 h-3" />
                                                    ) : (
                                                        <XCircle className="w-3 h-3" />
                                                    )}
                                                    {paymentStatus.text}
                                                </span>
                                            </td>
                                            <td className="px-6 py-2 text-sm text-gray-500">
                                                {paymentStatus.lastPaymentDate ?
                                                    paymentStatus.lastPaymentDate.toLocaleDateString() :
                                                    'No payment recorded'}
                                            </td>
                                        </tr>
                                    );
                                })}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>

            {selectedRecurringExpense && (
                <RecurringExpenseModal
                    expense={selectedRecurringExpense}
                    onClose={() => setSelectedRecurringExpense(null)}
                    onMarkPaid={handleMarkRecurringAsPaid}
                    onDelete={handleDeleteRecurringExpense}
                    loading={markingAsPaid}
                    deleting={deleting}
                />
            )}
        </div>
    );
};

export default ExpensesList;