import React, { useState, useEffect } from 'react';
import { collection, query, orderBy, getDocs, getFirestore, limit, startAfter, endBefore, limitToLast } from 'firebase/firestore';
import { Loader2, TrendingUp, TrendingDown, History, PackageX, Search } from 'lucide-react';

const ITEMS_PER_PAGE = 20;

const StockHistory = () => {
    const [stockHistory, setStockHistory] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [searchTerm, setSearchTerm] = useState('');
    const [filterType, setFilterType] = useState('all');
    const [currentPage, setCurrentPage] = useState(1);
    const [firstVisible, setFirstVisible] = useState(null);
    const [lastVisible, setLastVisible] = useState(null);
    const [hasNextPage, setHasNextPage] = useState(false);
    const [hasPrevPage, setHasPrevPage] = useState(false);
    const [totalRecords, setTotalRecords] = useState(0);

    const db = getFirestore();
    const storeId = localStorage.getItem('storeId');

    const stockMovementTypes = {
        all: 'All Movements',
        stock_add: 'Stock Added',
        stock_remove: 'Stock Removed',
    };

    const fetchPage = async (direction = 'next') => {
        if (!storeId) {
            setError('Store ID not found');
            setLoading(false);
            return;
        }

        try {
            setLoading(true);
            let q;

            if (direction === 'next' && lastVisible) {
                q = query(
                    collection(db, 'Stores', storeId, 'StockHistory'),
                    orderBy('timestamp', 'desc'),
                    startAfter(lastVisible),
                    limit(ITEMS_PER_PAGE)
                );
            } else if (direction === 'prev' && firstVisible) {
                q = query(
                    collection(db, 'Stores', storeId, 'StockHistory'),
                    orderBy('timestamp', 'desc'),
                    endBefore(firstVisible),
                    limitToLast(ITEMS_PER_PAGE)
                );
            } else {
                q = query(
                    collection(db, 'Stores', storeId, 'StockHistory'),
                    orderBy('timestamp', 'desc'),
                    limit(ITEMS_PER_PAGE)
                );
            }

            const snapshot = await getDocs(q);

            // Check if we have next page
            const nextQuery = query(
                collection(db, 'Stores', storeId, 'StockHistory'),
                orderBy('timestamp', 'desc'),
                startAfter(snapshot.docs[snapshot.docs.length - 1]),
                limit(1)
            );
            const nextSnapshot = await getDocs(nextQuery);
            setHasNextPage(!nextSnapshot.empty);

            // Check if we have previous page
            const prevQuery = query(
                collection(db, 'Stores', storeId, 'StockHistory'),
                orderBy('timestamp', 'desc'),
                endBefore(snapshot.docs[0]),
                limitToLast(1)
            );
            const prevSnapshot = await getDocs(prevQuery);
            setHasPrevPage(!prevSnapshot.empty);

            if (!snapshot.empty) {
                const history = snapshot.docs.map(doc => ({
                    id: doc.id,
                    ...doc.data()
                }));

                setStockHistory(history);
                setFirstVisible(snapshot.docs[0]);
                setLastVisible(snapshot.docs[snapshot.docs.length - 1]);

                if (direction === 'next') {
                    setCurrentPage(prev => prev + 1);
                } else if (direction === 'prev') {
                    setCurrentPage(prev => prev - 1);
                }
            }
        } catch (error) {
            console.error('Error fetching stock history:', error);
            setError('Failed to load stock history');
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchPage();
        // Fetch total count for pagination
        const fetchTotalCount = async () => {
            if (!storeId) return;
            try {
                const snapshot = await getDocs(
                    collection(db, 'Stores', storeId, 'StockHistory')
                );
                setTotalRecords(snapshot.size);
            } catch (error) {
                console.error('Error fetching total count:', error);
            }
        };
        fetchTotalCount();
    }, [db, storeId]);

    const filteredHistory = stockHistory.filter(record => {
        const matchesSearch =
            record.itemName?.toLowerCase().includes(searchTerm.toLowerCase()) ||
            record.sku?.toLowerCase().includes(searchTerm.toLowerCase()) ||
            record.notes?.toLowerCase().includes(searchTerm.toLowerCase());

        const matchesType = filterType === 'all' || record.type === filterType;

        return matchesSearch && matchesType;
    });

    if (loading && stockHistory.length === 0) {
        return (
            <div className="flex items-center justify-center p-8">
                <Loader2 className="h-8 w-8 animate-spin text-gray-600" />
            </div>
        );
    }

    if (error) {
        return (
            <div className="flex flex-col items-center justify-center p-8 text-red-500">
                <PackageX className="h-12 w-12 mb-4" />
                <p className="text-lg font-medium">{error}</p>
                <p className="text-sm text-gray-500 mt-2">Please try again later or contact support if the issue persists.</p>
            </div>
        );
    }

    if (!stockHistory.length) {
        return (
            <div className="flex flex-col items-center justify-center p-8">
                <History className="h-12 w-12 text-gray-400 mb-4" />
                <h3 className="text-lg font-medium text-gray-900">No Stock History Found</h3>
                <p className="text-sm text-gray-500 mt-2">Stock movements will appear here once inventory changes are made.</p>
            </div>
        );
    }

    const startIndex = (currentPage - 1) * ITEMS_PER_PAGE + 1;
    const endIndex = Math.min(startIndex + filteredHistory.length - 1, totalRecords);

    return (
        <div className="space-y-6">
            <div className="flex items-center justify-between">
                <div className="flex gap-4 items-center">
                    <div className="relative flex-1 max-w-md">
                        <Search className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400 h-5 w-5" />
                        <input
                            type="text"
                            placeholder="Search by item name, SKU, or notes..."
                            className="pl-10 pr-4 py-2 border rounded-lg min-w-96 w-full focus:outline-none focus:ring-2 focus:ring-blue-500"
                            value={searchTerm}
                            onChange={(e) => setSearchTerm(e.target.value)}
                        />
                    </div>
                    <select
                        className="border rounded-lg px-4 py-2 bg-white focus:outline-none focus:ring-2 focus:ring-blue-500"
                        value={filterType}
                        onChange={(e) => setFilterType(e.target.value)}
                    >
                        {Object.entries(stockMovementTypes).map(([value, label]) => (
                            <option key={value} value={value}>{label}</option>
                        ))}
                    </select>
                </div>
            </div>

            <div className="border overflow-hidden p-5">
                <div className="overflow-x-auto">
                    <table className="w-full">
                        <thead>
                            <tr className="bg-gray-100">
                                <th className="px-6 py-3 text-left text-sm font-medium text-gray-500">Date</th>
                                <th className="px-6 py-3 text-left text-sm font-medium text-gray-500">Item</th>
                                <th className="px-6 py-3 text-left text-sm font-medium text-gray-500">SKU</th>
                                <th className="px-6 py-3 text-left text-sm font-medium text-gray-500">Type</th>
                                <th className="px-6 py-3 text-left text-sm font-medium text-gray-500">Change</th>
                                <th className="px-6 py-3 text-left text-sm font-medium text-gray-500">Previous Qty</th>
                                <th className="px-6 py-3 text-left text-sm font-medium text-gray-500">New Qty</th>
                                <th className="px-6 py-3 text-left text-sm font-medium text-gray-500">Updated By</th>
                                <th className="px-6 py-3 text-left text-sm font-medium text-gray-500">Notes</th>
                            </tr>
                        </thead>
                        <tbody className="divide-y divide-gray-200 bg-white">
                            {filteredHistory.map((record) => (
                                <tr key={record.id} className="hover:bg-gray-50">
                                    <td className="px-6 py-4 text-sm text-gray-500 whitespace-nowrap">
                                        {record.timestamp?.toDate().toLocaleString()}
                                    </td>
                                    <td className="px-6 py-4 text-sm text-gray-900">
                                        {record.itemName}
                                    </td>
                                    <td className="px-6 py-4 text-sm text-gray-500">
                                        {record.sku || '-'}
                                    </td>
                                    <td className="px-6 py-4 text-sm">
                                        <span className={`px-2 py-1 rounded-full text-xs font-medium ${record.type === 'stock_add' ? 'bg-green-100 text-green-800' :
                                            record.type === 'stock_remove' ? 'bg-red-100 text-red-800' :
                                                record.type === 'sale' ? 'bg-blue-100 text-blue-800' :
                                                    record.type === 'return' ? 'bg-purple-100 text-purple-800' :
                                                        'bg-gray-100 text-gray-800'
                                            }`}>
                                            {stockMovementTypes[record.type] || record.type}
                                        </span>
                                    </td>
                                    <td className="px-6 py-4 text-sm">
                                        <div className="flex items-center">
                                            {record.changeType === 'increase' ?
                                                <TrendingUp className="h-4 w-4 text-green-500 mr-1" /> :
                                                <TrendingDown className="h-4 w-4 text-red-500 mr-1" />
                                            }
                                            {record.changeAmount}
                                        </div>
                                    </td>
                                    <td className="px-6 py-4 text-sm text-gray-500">
                                        {record.previousQuantity}
                                    </td>
                                    <td className="px-6 py-4 text-sm text-gray-500">
                                        {record.currentQuantity}
                                    </td>
                                    <td className="px-6 py-4 text-sm text-gray-500">
                                        {record.updatedBy || 'System'}
                                    </td>
                                    <td className="px-6 py-4 text-sm text-gray-500">
                                        {record.notes || '-'}
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>

                {/* Pagination */}
                <div className="px-6 py-4 bg-gray-50 border-t border-gray-200">
                    <div className="flex items-center justify-between">
                        <div className="text-sm text-gray-700">
                            Showing {startIndex} to {endIndex} of {totalRecords} results
                        </div>
                        <div className="flex space-x-2">
                            <button
                                onClick={() => fetchPage('prev')}
                                disabled={!hasPrevPage || loading}
                                className="px-4 py-2 border rounded-lg text-sm font-medium text-gray-700 hover:bg-gray-50 disabled:opacity-50 disabled:cursor-not-allowed"
                            >
                                Previous
                            </button>
                            <button
                                onClick={() => fetchPage('next')}
                                disabled={!hasNextPage || loading}
                                className="px-4 py-2 border rounded-lg text-sm font-medium text-gray-700 hover:bg-gray-50 disabled:opacity-50 disabled:cursor-not-allowed"
                            >
                                Next
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default StockHistory;