import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { Calendar, CrownIcon } from 'lucide-react';
import { getAuth } from 'firebase/auth';
import { collection, query, where, getDocs, getFirestore } from 'firebase/firestore';
import Sidebar from '../components/sidebar';
import { OverviewSection } from '../components/reports/reports_overview';
import TimePeriodPicker from '../components/time_period_picker';
import { SalesReports } from '../components/reports/reports_sales';
import { InventoryReports } from '../components/reports/reports_inventory';
import GrossFinancials from '../components/reports/reports_gross_profit';
import { useAuth } from '../../auth/AuthContext';

// Locked Feature Component
const LockedFeature = ({ featureName, requiredPlan }) => (
    <div className="flex flex-col items-center justify-center h-96 bg-gray-50 shadow-sm">
        <CrownIcon className="h-16 w-16 text-yellow-500 mx-auto mb-4" />
        <h2 className="text-xl font-semibold mb-2">{featureName}</h2>
        <p className="text-gray-600 mb-2">
            {requiredPlan === "Enterprise"
                ? `This feature is only available on the ${requiredPlan} plan`
                : `This feature requires ${requiredPlan} plan or higher`
            }
        </p>
        <p className="text-sm text-gray-500">Please upgrade your plan to access this feature</p>
    </div>
);


const Reports = () => {
    const { hasProAccess, hasEnterpriseAccess } = useAuth();
    const navigate = useNavigate();
    const location = useLocation();
    const [selectedTab, setSelectedTab] = useState('overview');
    const [timeRange, setTimeRange] = useState({
        start: new Date(new Date().setDate(new Date().getDate() - 7)),
        end: new Date(),
        label: 'Last 7 Days'
    });
    const [storeId, setStoreId] = useState(null);
    const [error, setError] = useState(null);

    // const tabs = [
    //     { id: 'overview', name: 'Overview', component: <OverviewSection timeRange={timeRange} storeId={storeId} /> },
    //     { id: 'gross_profit', name: 'Basic Financials', component: <GrossFinancials timeRange={timeRange} storeId={storeId} /> },
    //     { id: 'sales', name: 'Sales', component: <SalesReports timeRange={timeRange} storeId={storeId} /> },
    //     { id: 'inventory', name: 'Inventory', component: <InventoryReports timeRange={timeRange} storeId={storeId} /> },
    //     { id: 'profit_n_loss', name: 'Profit & Loss', component: <div> Coming Soon</div> },
    //     { id: 'clients', name: 'Clients', component: <div> Coming Soon</div> },
    // ];

    const tabs = [
        {
            id: 'overview',
            name: 'Overview',
            requiresPro: false,
            component: <OverviewSection timeRange={timeRange} storeId={storeId} />
        },
        {
            id: 'gross_profit',
            name: 'Basic Financials',
            requiresPro: false,
            component: <GrossFinancials timeRange={timeRange} storeId={storeId} />
        },
        {
            id: 'sales',
            name: 'Sales',
            requiresPro: true,
            component: hasProAccess ?
                <SalesReports timeRange={timeRange} storeId={storeId} /> :
                <LockedFeature featureName="Sales Analytics" requiredPlan="Pro" />
        },
        {
            id: 'inventory',
            name: 'Inventory',
            requiresPro: true,
            component: hasProAccess ?
                <InventoryReports timeRange={timeRange} storeId={storeId} /> :
                <LockedFeature featureName="Inventory Analytics" requiredPlan="Pro" />
        },
        {
            id: 'profit_n_loss',
            name: 'Profit & Loss',
            requiresEnterprise: true,
            component: hasEnterpriseAccess ?
                <div>Coming Soon</div> :
                <LockedFeature featureName="Profit & Loss Analysis" requiredPlan="Enterprise" />
        },
        {
            id: 'clients',
            name: 'Clients',
            requiresEnterprise: true,
            component: hasEnterpriseAccess ?
                <div>Coming Soon</div> :
                <LockedFeature featureName="Client Analytics" requiredPlan="Enterprise" />
        },
    ];

    const db = getFirestore();
    const auth = getAuth();

    // Initialize the selected tab based on URL hash on component mount
    useEffect(() => {
        const tabFromUrl = location.hash.slice(1); // Remove the # from the hash
        const validTab = tabs.find(tab => tab.id === tabFromUrl);
        if (validTab) {
            setSelectedTab(validTab.id);
        } else if (location.hash) {
            // If there's an invalid hash, navigate to default tab
            navigate('#overview', { replace: true });
        } else {
            // If no hash, add the default
            navigate('#overview', { replace: true });
        }
    }, [location.hash, navigate]);

    // Fetch user data to get storeId
    useEffect(() => {
        const fetchUserData = async () => {
            try {
                const currentUser = auth.currentUser;
                if (!currentUser) {
                    throw new Error('No authenticated user found');
                }

                const userDoc = await getDocs(
                    query(
                        collection(db, 'Users'),
                        where('email', '==', currentUser.email)
                    )
                );

                if (!userDoc.empty) {
                    const userData = userDoc.docs[0].data();
                    setStoreId(userData.storeId);
                } else {
                    throw new Error('User data not found');
                }
            } catch (error) {
                console.error('Error fetching user data:', error);
                setError('Failed to authenticate user');
            }
        };

        if (auth.currentUser) {
            fetchUserData();
        }
    }, [db, auth]);

    const handleTabChange = (tabId) => {
        setSelectedTab(tabId);
        navigate(`#${tabId}`);
    };

    const handleDateRangeChange = (start, end, label) => {
        setTimeRange({
            start,
            end,
            label
        });
    };

    if (error) {
        return (
            <div className="flex h-screen bg-white overflow-hidden">
                <Sidebar />
                <div className="flex-1 flex flex-col overflow-hidden">
                    <div className="flex items-center justify-center flex-1 text-red-500">
                        {error}
                    </div>
                </div>
            </div>
        );
    }

    return (
        <div className="flex h-screen bg-white overflow-hidden">
            <Sidebar />
            <div className="flex-1 flex flex-col overflow-hidden">
                <div className="flex-1 overflow-y-auto p-8">
                    <div className="flex justify-between items-center mb-10">
                        <h1 className="text-3xl font-semibold">Reports & Analytics</h1>
                        <TimePeriodPicker
                            onDateRangeChange={handleDateRangeChange}
                            selectedLabel={timeRange.label}
                            onLabelChange={(label) => setTimeRange(prev => ({ ...prev, label }))}
                        />
                    </div>

                    <div className="flex space-x-6 mb-8">
                        {tabs.map((tab) => (
                            <button
                                key={tab.id}
                                className={`text-lg font-medium ${selectedTab === tab.id
                                    ? 'text-blue-600 border-b-2 border-blue-600'
                                    : 'text-gray-500 hover:text-gray-700'
                                    }`}
                                onClick={() => handleTabChange(tab.id)}
                            >
                                {tab.name}
                            </button>
                        ))}
                    </div>

                    <div className="space-y-8">
                        {tabs.find(tab => tab.id === selectedTab)?.component}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Reports;